<template>
    <!-- Form type in show mode -->
    <template v-if="formType_ === 'show'">
        <template v-if="!this.$props.columnSchema.bloquear_visualizacao">
            <!-- <template v-if="detailTable"> -->
            <template v-if="detailTable">
                <div class="h-100 form-control disabled border-dashed bg-light" @click="formShowInputClick">
                    <span v-if="this.$props.columnSchema.id_lista_visualizacao">{{this.$props.columnSchema.sis_lista_edicao?.tipo_lista == 1 ? this.$props.formState.getValue(this.$props.supportRepeatedInput ? column.id : column.nome) : getValueForSelectVisualization()}}</span>
                    <span v-else-if="this.$props.columnSchema.opcao_selecao">{{this.$props.columnSchema.tipo_campo == 21 ? getValueForRadio() : this.$props.columnSchema.tipo_campo == 22 ? getValueForCheckbox() : ''}}</span>
                    <span v-else>{{this.$props.formState.getValue(this.$props.supportRepeatedInput ? column.id : column.nome)}}</span>
                </div>
            </template>
            <template v-else-if="this.$props.columnSchema.id_lista_visualizacao">
                <label v-if="this.$props.showLabel" class="form-label text-dark">{{ column.titulo }}</label>
                <input
                    @click="formShowInputClick"
                    :id="component_id"
                    readonly
                    type="text"
                    class="form-control disabled border-dashed"
                    :value="this.$props.columnSchema.sis_lista_edicao?.tipo_lista == 1 ? this.$props.formState.getValue(this.$props.supportRepeatedInput ? column.id : column.nome) : getValueForSelectVisualization()"
                />
            </template>
            <template v-else-if="this.$props.columnSchema.opcao_selecao">
                <label v-if="this.$props.showLabel" class="form-label text-dark">{{ column.titulo }}</label>
                <input
                    @click="formShowInputClick"
                    :id="component_id"
                    readonly
                    type="text"
                    class="form-control disabled border-dashed"
                    :value="this.$props.columnSchema.tipo_campo == 21 ? getValueForRadio() : this.$props.columnSchema.tipo_campo == 22 ? getValueForCheckbox() : ''"
                />
            </template>
            <template v-else>
                <label v-if="this.$props.showLabel" class="form-label text-dark">{{ column.titulo }}</label>
                <input
                    @click="formShowInputClick"
                    :id="component_id"
                    readonly
                    type="text"
                    class="form-control disabled border-dashed disabled-show"
                    :value="this.$props.formState.getValue(this.$props.supportRepeatedInput ? column.id : column.nome)"
                />
            </template>
        </template>
    </template>
    <!-- LIST/SELECT -->
    <template v-else-if="this.$props.columnSchema.id_lista_edicao">
        
        <label v-if="this.$props.showLabel" :for="column.id_campo_tabela" class="form-label text-dark">{{ column.titulo }} <span class="text-danger" v-if="column.requerido && formType_ !== 'show'">*</span></label> 
        <div class="input-group">
            <select
                v-if="!sis_lista_edicao.exige_filtro"
                :id="component_id"
                :input_title="column.titulo"
                :input_table_title="this.$props.formState.table_schema.denominacao"
                :readonly="isReadOnly(column, formType_)"
                :name="column.nome"
                :class="this.$props.inputClass + ' form-select'"
                :disabled="this.$props.formState.deleted || this.$props.loading || (formType_ == 'creation' && !column.permite_inclusao) || (formType_ == 'edition' && !column.permite_alteracao) || this.$props.columnSchema.bloquear_edicao"
                :required="column.requerido"
                @change="changeValue"
            >
                <template v-if="sis_lista_edicao.sis_opcao_lista_valor">
                    <template v-if="sis_lista_edicao.exige_filtro">
                        <option v-for="option  in sis_lista_edicao.sis_opcao_lista_valor.sort((a, b) => a.titulo.localeCompare(b.titulo))" :key="option.id" :value="option.id">
                          {{ option.titulo }}
                        </option>
                    </template>
                    <template v-else>
                        <option v-if="
                            formType_ == 'creation' && !column.permite_inclusao || 
                            formType_ == 'edition' && !column.permite_alteracao" value=""
                        >
                        </option>
                        <option v-else-if="formType_ == 'creation' || formType_ == 'edition' && column.permite_exclusao" value="" :selected="(this.$props.formState.getValue(this.$props.supportRepeatedInput ? column.id : column.nome) == undefined)" class="text-muted">Selecionar opção</option>
                        <option v-for="option in sis_lista_edicao.sis_opcao_lista_valor.sort((a, b) => a.titulo.localeCompare(b.titulo))" :key="option.id" :value="option.id" :selected="(this.$props.formState.getValue(this.$props.supportRepeatedInput ? column.id : column.nome) == option.id)">
                            {{ option.titulo }}
                        </option>
                    </template>
                </template>
                <option v-else selected></option>
            </select>
            <input
                v-else
                :id="component_id"
                @click="showFilterListModal()"
                readonly
                type="text"
                class="form-control border-dashed"
                :value="getValueForSelectVisualization()"
            />
            <button v-if="this.$props.columnSchema.permite_exclusao"
                @click="deleteSelection"
                class="btn btn-icon btn-light text-danger" type="button"
                style="border-color:#ced4da;"
                title="Remover"
            >
                <i class="ri-close-line fs-16"></i>
            </button>

            <button v-if="sis_lista_edicao.exige_filtro"
                :disabled="this.$props.formState.deleted || this.$props.loading || (formType_ == 'creation' && !column.permite_inclusao) || (formType_ == 'edition' && !column.permite_alteracao) || this.$props.columnSchema.bloquear_edicao"
                @click="showFilterListModal()"
                class="btn btn-icon btn-light text-primary" type="button"
                style="border-color:#ced4da;"
                title="Pesquisar"
            >
                <i class="ri-search-eye-line fs-16"></i>
            </button>
        </div>
    
        <div class="invalid-feedback">
            Preencha o campo corretamente.
        </div>

        <!-- MODAL FOR EDITION LIST FILTER -->
        <template >
            <b-modal size="lg" v-model="modalListFilterShow" hideFooter centered scrollable :title="sis_lista_edicao.titulo">
                <div>
                    <div class="row">
                        <div class="col-12">
                            <form @submit.prevent="handleListOptionsFilter(listOptionsFilterValue)">
                                <div class="input-group">
                                    <input type="text" v-model="listOptionsFilterValue" v-debounce:300ms.lock.cancelonempty="() => handleListOptionsFilter(listOptionsFilterValue)" required minlength="3" class="form-control" placeholder="Buscar...">
                                    <button v-if="inputLoading" class="btn">
                                        <b-spinner variant="primary" small></b-spinner>
                                    </button>
                                </div>
                                <small v-if="modalListFilterMinDigitShow" class="text-danger">A busca deve conter ao menos 3 caracteres</small>
                            </form>
                        </div>
                    </div>
                    <table class="table table-bordered table-hover mt-2">
                        <thead>
                            <tr>
                                <th v-for="title in this.$props.formState.getTitlesFromListValue(sis_lista_edicao)" :key="title" scope="col">
                                    {{ title }}
                                </th>
                                <th>Denominação</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="option in filteredListOptions"  @click="setSelectedOption(option)" class="cursor-pointer" :key="option.id">
                                <td
                                    v-for="code_area, index in this.$props.formState.getCodeNamesFromListValue(sis_lista_edicao)"
                                    :key="code_area"
                                    class="py-0"
                                >
                                    {{ option['codigo'+(index+1)] }}
                                </td>
                                <td class="py-0">{{ option.titulo }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </b-modal>
        </template>
    </template>

    <template v-else-if="this.$props.textArea">
        <label v-if="this.$props.showLabel" :for="column.id_campo_tabela" class="form-label text-dark">{{ column.titulo }} <span class="text-danger" v-if="column.requerido && formType_ !== 'show'">*</span></label>
        
        <div v-if="this.$props.allowCopy" @click="formShowInputClick({target: {value: this.$props.formState.getValue(this.$props.supportRepeatedInput ? column.id : column.nome)}})" title="Copiar" class="bg-light position-absolute cursor-pointer rounded p-1 text-success" style="right: 15px;bottom: 15px;">
            <i class="ri-file-copy-line align-bottom me-1 fs-20"></i>
            <div id="copy-message" class="alert alert-success position-absolute d-inline-block" role="alert" :style="{left: this.$props.leftCopy ? '-150px' : '10px'}">
                Texto copiado!!!
            </div>
        </div>
        <textarea
            :rows="textAreaRowsNumber"
            :id="component_id"
            :input_title="column.titulo"
            :input_table_title="this.$props.formState.table_schema.denominacao"
            :readonly="isReadOnly(column, formType_)"
            :name="column.nome"
            type="text"
            :class="[this.$props.inputClass + ' form-control ' + (isReadOnly(column, formType_) ? 'disabled' : '')]"
            :style="customFormat ? 'text-align: right;' : ''"
            :disabled="this.$props.formState.deleted || this.$props.loading || (formType_ == 'creation' && !column.permite_inclusao) || (formType_ == 'edition' && !column.permite_alteracao) || this.$props.columnSchema.bloquear_edicao"
            :required="column.requerido"
            @keyup="changeValue"
            :value="this.$props.formState.getValue(this.$props.supportRepeatedInput ? column.id : column.nome)"
        />
        <div class="invalid-feedback">
            Preencha o campo corretamente.
        </div>
    </template>
    <!-- TEXT -->
    <template v-else-if="type == 'text' || type == 'json' || customFormat">
        <label v-if="this.$props.showLabel" :for="column.id_campo_tabela" class="form-label text-dark">{{ column.titulo }} <span class="text-danger" v-if="column.requerido && formType_ !== 'show'">*</span></label>

        <div v-if="this.$props.allowCopy" @click="formShowInputClick({target: {value: this.$props.formState.getValue(this.$props.supportRepeatedInput ? column.id : column.nome)}})" title="Copiar" class="position-absolute cursor-pointer rounded p-1 text-success" style="right: 15px;bottom: 15px;">
            <i class="ri-file-copy-line align-bottom me-1 fs-20"></i>
            <div id="copy-message" class="alert alert-success position-absolute d-inline-block" role="alert" :style="{left: this.$props.leftCopy ? '-150px' : '10px'}">
                Texto copiado!!!
            </div>
        </div>
        <input
            :id="component_id"
            :key="column.nome"
            :input_title="column.titulo"
            :input_table_title="this.$props.formState.table_schema.denominacao"
            :readonly="isReadOnly(column, formType_)"
            :name="column.nome"
            type="text"
            :class="[this.$props.inputClass + ' form-control ' + (isReadOnly(column, formType_) ? 'disabled' : '')]"
            :style="customFormat ? valueIsNegative() ? 'text-align: right; color: red;' : 'text-align: right;' : ''"
            :disabled="this.$props.formState.deleted || this.$props.loading || (formType_ == 'creation' && !column.permite_inclusao) || (formType_ == 'edition' && !column.permite_alteracao) || this.$props.columnSchema.bloquear_edicao"
            :required="column.requerido"
            :value="value"
            v-maska
            :data-maska="customFormat ? '' : (column.formato ? getMaskType(column.formato) : '')"
            @keyup="changeValue"
            @blur="changeValue"
            :pattern="customFormat ? createNumberPattern() :(column.formato ? `.{${getMinMaxLength(column.formato)[0]},${getMinMaxLength(column.formato)[1]}}` : '.{0,}')"
        />
        <div class="invalid-feedback">
            Preencha o campo corretamente.
        </div>
    </template>

    <!-- RADIO BOLEAN -->
    <template v-else-if="type == 'radio'">
        <label v-if="this.$props.showLabel" :for="column.id_campo_tabela" class="form-label text-dark">{{ column.titulo }} <span class="text-danger" v-if="column.requerido && formType_ !== 'show'">*</span></label>
        
        <div  :class="this.$props.inputClass + ' form-switch-container ps-2'">
            <div class="form-switch form-switch-lg">
                <input
                    :id="component_id"
                    :input_title="column.titulo"
                    :input_table_title="this.$props.formState.table_schema.denominacao"
                    class="form-check-input"
                    :disabled="this.$props.formState.deleted || this.$props.loading || (formType_ == 'creation' && !column.permite_inclusao) || (formType_ == 'edition' && !column.permite_alteracao)"
                    type="checkbox"
                    role="switch"
                    @click="changeSwitchValue"
                    :readonly="isReadOnly(column, formType_)"
                    :name="column.nome"
                    :checked="formType_ == 'creation' && column.valor_padrao == 'true' || this.$props.formState.getValue(this.$props.supportRepeatedInput ? column.id : column.nome)"
                />
            </div>
        </div>
    </template>

    <!-- RADIO MULTISELECT -->
    <template v-else-if="type == 'multiselect'">
        <label v-if="this.$props.showLabel" :for="column.id_campo_tabela" class="form-label text-dark">{{ column.titulo }} <span class="text-danger" v-if="column.requerido && formType_ !== 'show'">*</span></label>
        <fieldset class="border p-2 rounded d-flex flex-column gap-1">
            <div class="form-check form-radio-primary" v-for="option in column.opcao_selecao.sort((a,b) => a.ordem_exibicao - b.ordem_exibicao)" :key="option.id">
                <input 
                    :input_title="column.titulo"
                    class="form-check-input" 
                    type="radio" 
                    :checked="this.$props.formState.getValue(this.$props.supportRepeatedInput ? column.id : column.nome) == option.id"
                    :value="option.id"
                    :name="component_id" 
                    :id="component_id+option.id"
                    :required="column.requerido"
                    :input_table_title="this.$props.formState.table_schema.denominacao"
                    @change="changeValue"
                >
                <label class="form-check-label" :for="component_id+option.id">
                    {{ option.titulo }}
                </label>
            </div>
        </fieldset>
    </template>

    <!-- CHECKBOX -->
    <template v-else-if="type == 'checkbox'">
        <label v-if="this.$props.showLabel" :for="column.id_campo_tabela" class="form-label text-dark">{{ column.titulo }} <span class="text-danger" v-if="column.requerido && formType_ !== 'show'">*</span></label>
        <fieldset class="border p-2 rounded d-flex flex-column gap-1">
            <div class="form-check" v-for="option in column.opcao_selecao.sort((a,b) => a.ordem_exibicao - b.ordem_exibicao)" :key="option.id">
                <input 
                    class="form-check-input" 
                    type="checkbox" 
                    :data-required="column.requerido"
                    :required="!this.$props.formState.getValue(this.$props.supportRepeatedInput ? column.id : column.nome) ? column.requerido : false"
                    :id="component_id+option.id"
                    :value="option.id"
                    :name="component_id"
                    :input_title="column.titulo"
                    :input_table_title="this.$props.formState.table_schema.denominacao"
                    :checked="this.$props.formState.getValue(this.$props.supportRepeatedInput ? column.id : column.nome) ? this.$props.formState.getValue(this.$props.supportRepeatedInput ? column.id : column.nome).split(';').includes(option.id) : false"
                    @change="changeCheckboxValue"
                >
                <label class="form-check-label" :for="component_id+option.id">
                    {{ option.titulo }}
                </label>
            </div>
        </fieldset>
    </template>

    <!-- DATE -->
    <template v-else-if="type == 'date'">
        <label v-if="this.$props.showLabel" :for="column.id_campo_tabela" class="form-label text-dark">{{ column.titulo }} <span class="text-danger" v-if="column.requerido && formType_ !== 'show'">*</span></label>
        <input type="text" @change="changeValue" :value="this.$props.formState.getValue(this.$props.supportRepeatedInput ? column.id : column.nome)" hidden :name="column.nome">
        <input v-if="formType_ == 'show'" type="text" :value="this.$props.formState.getValue(this.$props.supportRepeatedInput ? column.id : column.nome)" class="form-control disabled" readonly>
        <flat-pickr
            v-if="formType_ !== 'show'"
            :id="component_id"
            :value="this.$props.formState.getValue(this.$props.supportRepeatedInput ? column.id : column.nome)"
            @input="changeDateValue"
            :modelValue="this.$props.formState.getValue(this.$props.supportRepeatedInput ? column.id : column.nome) ?? null"
            :config="{...dateConfig, onReady: () => handleFlatpickrReady(component_id, column.titulo, this.$props.formState.table_schema.denominacao), defaultDate: getDate(this.$props.formState.getValue(this.$props.supportRepeatedInput ? column.id : column.nome), 'date')}"
            :class="this.$props.inputClass + ' form-control flatpickr-input ' + ' fp_'+component_id + ' ' + (isReadOnly(column, formType_) ? 'disabled' : '')"
            :required="column.requerido"
            :disabled="this.$props.formState.deleted || this.$props.loading || (formType_ == 'creation' && !column.permite_inclusao) || (formType_ == 'edition' && !column.permite_alteracao) || this.$props.columnSchema.bloquear_edicao"
        ></flat-pickr>
    </template>

    <!-- DATE TIME -->
    <template v-else-if="type == 'date-time'">
        <label v-if="this.$props.showLabel" :for="column.id_campo_tabela" class="form-label text-dark">{{ column.titulo }} <span class="text-danger" v-if="column.requerido && formType_ !== 'show'">*</span></label>
        <input type="text" @change="changeValue" :value="this.$props.formState.getValue(this.$props.supportRepeatedInput ? column.id : column.nome)" hidden :name="column.nome">
        <input v-if="formType_ == 'show'" type="text" :value="this.$props.formState.getValue(this.$props.supportRepeatedInput ? column.id : column.nome)" class="form-control disabled" readonly>
        <flat-pickr
            v-if="formType_ !== 'show'"
            :id="component_id"
            :value="this.$props.formState.getValue(this.$props.supportRepeatedInput ? column.id : column.nome)"
            @input="this.$props.formState.setMutatedValues(supportRepeatedInput_ ? column.id : column.nome, $event.target.value)"
            :modelValue="this.$props.formState.getValue(this.$props.supportRepeatedInput ? column.id : column.nome) ?? null"
            :config="{...dateTimeConfig, onReady: () => handleFlatpickrReady(component_id, column.titulo, this.$props.formState.table_schema.denominacao), defaultDate: getDate(this.$props.formState.getValue(this.$props.supportRepeatedInput ? column.id : column.nome), 'dateTime')}"
            :class="this.$props.inputClass + ' form-control flatpickr-input ' + ' fp_'+component_id + ' ' + (isReadOnly(column, formType_) ? 'disabled' : '')"
            :required="column.requerido"
            :disabled="this.$props.formState.deleted || this.$props.loading || (formType_ == 'creation' && !column.permite_inclusao) || (formType_ == 'edition' && !column.permite_alteracao)"
        ></flat-pickr>
    </template>

    <!-- TIME -->
    <template v-else-if="type == 'time'">
        <label v-if="this.$props.showLabel" :for="column.id_campo_tabela" class="form-label text-dark">{{ column.titulo }} <span class="text-danger" v-if="column.requerido && formType_ !== 'show'">*</span></label>
        <input type="text" @keyup="changeValue" :value="this.$props.formState.getValue(this.$props.supportRepeatedInput ? column.id : column.nome)" hidden :name="column.nome">
        <input v-if="formType_ == 'show'" type="text" :value="this.$props.formState.getValue(this.$props.supportRepeatedInput ? column.id : column.nome)" class="form-control disabled" readonly>
        <flat-pickr
            v-if="formType_ !== 'show'"
            :id="component_id"
            :value="this.$props.formState.getValue(this.$props.supportRepeatedInput ? column.id : column.nome)"
            @input="this.$props.formState.setMutatedValues(supportRepeatedInput_ ? column.id : column.nome, $event.target.value)"
            :modelValue="this.$props.formState.getValue(this.$props.supportRepeatedInput ? column.id : column.nome) ?? null"
            :config="{...timeConfig, onReady: () => handleFlatpickrReady(component_id, column.titulo, this.$props.formState.table_schema.denominacao), defaultDate: getDate(this.$props.formState.getValue(this.$props.supportRepeatedInput ? column.id : column.nome), 'time')}"
            :class="this.$props.inputClass + ' form-control flatpickr-input ' + ' fp_'+component_id + ' ' + (isReadOnly(column, formType_) ? 'disabled' : '')"
            :required="column.requerido"
            :disabled="this.$props.formState.deleted || this.$props.loading || (formType_ == 'creation' && !column.permite_inclusao) || (formType_ == 'edition' && !column.permite_alteracao) || this.$props.columnSchema.bloquear_edicao"
        ></flat-pickr>
    </template>

    <!-- INTEGER -->
    <template v-else-if="type == 'integer'">
        <label v-if="this.$props.showLabel" :for="column.id_campo_tabela" class="form-label text-dark">{{ column.titulo }} <span class="text-danger" v-if="column.requerido && formType_ !== 'show'">*</span></label>
        <input 
            :id="component_id"
            :input_title="column.titulo"
            :input_table_title="this.$props.formState.table_schema.denominacao"
            :name="column.nome"
            type="number"
            @keyup="changeIntegerValue"
            :value="this.$props.formState.getValue(this.$props.supportRepeatedInput ? column.id : column.nome)"
            :class="this.$props.inputClass + ' form-control ' + (isReadOnly(column, formType_) ? 'disabled' : '')"
            :required="column.requerido"
            :min="column.valor_minimo"
            :max="column.valor_maximo > 0 ? column.valor_maximo : null"
            step="1"
            :disabled="this.$props.formState.deleted || this.$props.loading || (formType_ == 'creation' && !column.permite_inclusao) || (formType_ == 'edition' && !column.permite_alteracao) || this.$props.columnSchema.bloquear_edicao"
            :readonly="isReadOnly(column, formType_)"
        />
    </template>

    <!-- NUMERIC -->
    <template v-else-if="type == 'numeric'">
        <label v-if="this.$props.showLabel" :for="column.id_campo_tabela" class="form-label text-dark">{{ column.titulo }} <span class="text-danger" v-if="column.requerido && formType_ !== 'show'">*</span></label>
        <input 
            :id="component_id"
            :input_title="column.titulo"
            :input_table_title="this.$props.formState.table_schema.denominacao"
            :name="column.nome"
            type="number"
            @keyup="changeNumericValue"
            :value="this.$props.formState.getValue(this.$props.supportRepeatedInput ? column.id : column.nome)"
            :class="this.$props.inputClass + ' form-control ' + (isReadOnly(column, formType_) ? 'disabled' : '')" 
            :required="column.requerido"
            :min="column.valor_minimo"
            :max="column.valor_maximo > 0 ? column.valor_maximo : null"
            step="any"
            :disabled="this.$props.formState.deleted || this.$props.loading || (formType_ == 'creation' && !column.permite_inclusao) || (formType_ == 'edition' && !column.permite_alteracao) || this.$props.columnSchema.bloquear_edicao"
            :readonly="isReadOnly(column, formType_)"
        />
    </template>

</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Portuguese as PortugueseLocale } from "flatpickr/dist/l10n/pt";
import moment from "moment";
import { vMaska } from "maska";
import { v4 as uuidv4 } from 'uuid';
import formatColumnValue from "../../helpers/dynamicTable/formatColumnValue";
import { vue3Debounce } from 'vue-debounce';
import { ref } from "vue";
import { getValueListById } from '../../helpers/forms/getListaValorById';

moment.locale('pt-br')

export default{
    props: {
        columnSchema: {
            type: Object,
            required: true,
        },
        loading: {
            type: Boolean,
            required: false,
        },
        formType: {
            type: String,
            required: true,
        },
        formState: {
            type: Object,
            required: true
        },
        showLabel: {
            type: Boolean,
            required: false,
        },
        forceShowInput: {
            type: Boolean,
            required: false,
        },
        inputClass:{
            type: String,
            required: false,
            default: '',
        },
        dataAlreadyExists:{
            type: Boolean,
            required: false,
            default: false,
        },
        detailTable:{
            type: Boolean,
            required: false,
            default: false,
        },
        detailTableIdName:{
            type: String,
            required: false,
            default: '',
        },
        primaryKeyValue:{
            type: null,
            required: false,
            default: null,
        },
        allowCopy: {
            type: String,
            required: false,
        },
        leftCopy: {
            type: Boolean,
            required: false,
        },
        textArea: {
            type: Boolean,
            required: false,
            default: false,
        },
        textAreaRowsNumber: {
            type: Number,
            required: false,
            default: 5,
        },
        supportRepeatedInput: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    components: {
        flatPickr,
    },
    directives: {
        maska: vMaska,
        debounce: vue3Debounce({ lock: true })
    },
    data() {
        const customFormat = ref(false)

        if (this.$props.columnSchema?.formato && this.$props.columnSchema.formato.includes("N")){
            customFormat.value = true
        }
        
        var type = this.$props.formType == 'show' ? 'text' : this.getColumnType();
        const value = this.setValue();

        this.$props.formState.setInitialValues(this.$props.supportRepeatedInput ? this.$props.columnSchema.id : this.$props.columnSchema.nome, value);
        this.$props.formState.setMutatedValues(this.$props.supportRepeatedInput ? this.$props.columnSchema.id : this.$props.columnSchema.nome, value);

        const sis_lista_edicao = this.$props.columnSchema.sis_lista_edicao;

        return {
            supportRepeatedInput_: this.$props.supportRepeatedInput,
            component_id: uuidv4(),
            sis_lista_edicao,
            inputLoading: false,
            filteredListOptions: [],
            listOptionsFilterValue: '',
            modalListFilterShow: false,
            modalListFilterMinDigitShow: false,
            edition_list_schema: {},
            column: this.$props.columnSchema,
            customFormat,
            type,
            dateTimeConfig: {
                enableTime: true,
                altFormat: "d/m/Y H:i",
                altInput: true,
                dateFormat: "Y-m-d H:i:s",
                locale: PortugueseLocale,
                allowInput: true,
            },
            dateConfig: {
                altFormat: "d/m/Y",
                altInput: true,
                dateFormat: "Y-m-d",
                locale: PortugueseLocale,
                allowInput: true,
            },
            timeConfig: {
                enableTime: true,
                noCalendar: true,
                allowInput: true,
                altFormat: "H:i",
                dateFormat: "H:i:s",
                altInput: true,
                locale: PortugueseLocale,
            }
        }
    },
    watch: {
        value: {
            handler: function (newValue) {
                if([4, 5, 6].includes(this.$props.columnSchema.tipo_campo)) {
                    const value = this.setValue();
                    this.$props.formState.setMutatedValues(this.$props.supportRepeatedInput ? this.column.id : this.column.nome, value);
                }

                if([12].includes(this.$props.columnSchema.tipo_campo) && this.$props.columnSchema.formato && newValue) {
                    const { formato } = this.column
                    const formatDefault = 'N2'
                    const brazilCurrencyFormat = /(\d)(?=(\d{3})+(?!\d))/g
                    const zeroInString = '0'
                    const oneValue = 1
                    const [, precision] = formato.includes('N') ? formato.split('N') : formatDefault
                    const value = typeof newValue === 'number' ? Number(newValue).toFixed(precision) : newValue
                    const onlyNumbers = value.toString().replace(/[.,]/g, '')

                    const numberToDivision = String(oneValue).padEnd(Number(precision) + oneValue, zeroInString)
                    const valueWithDecimal = (Number(onlyNumbers).toFixed(precision) / Number(numberToDivision)).toFixed(precision)
                    const valueWithComma = valueWithDecimal.toString().replace('.', ',')
                    const valueFormatted = valueWithComma.replace(brazilCurrencyFormat, '$1.')

                    this.$props.formState.setMutatedValues(this.$props.supportRepeatedInput ? this.column.id : this.column.nome, valueFormatted)
                }
            },
        },
    },
    computed: {
        value(){
            return this.$props.formState.getValue(this.$props.supportRepeatedInput ? this.column.id : this.column.nome)
        },
        formType_() {
            if(this.$props.formState.getInCreationStep()){
                return 'creation'
            }
            return this.$props.detailTable ? this.$props.formState.mode : this.$props.formType
            // return this.$props.formType
        },
    },
    methods: {
        setValue() {
            var value;
            let mode = this.$props.detailTable ? this.$props.formState.mode : this.$props.formType
            
            if([4, 5, 6].includes(this.$props.columnSchema.tipo_campo) && mode !== 'show'){
                value = this.$props.formState.getValue(this.$props.supportRepeatedInput ? this.$props.columnSchema.id : this.$props.columnSchema.nome) ? this.$props.formState.getValue(this.$props.supportRepeatedInput ? this.$props.columnSchema.id : this.$props.columnSchema.nome) : null
            }else{
                if(mode == 'creation'){
                    value = this.getDefaultValue(this.$props.columnSchema.valor_padrao);
                }else if(mode == 'edition' && this.$props.detailTable && this.$props.columnSchema.nome == this.$props.detailTableIdName){
                    value = this.$props.primaryKeyValue;
                }else if(mode == 'edition' && this.$props.formState.getValue(this.$props.supportRepeatedInput ? this.$props.columnSchema.id : this.$props.columnSchema.nome) == null){
                    value = this.getDefaultValue(this.$props.columnSchema.valor_padrao);
                }else if(this.$props.columnSchema.tipo_campo === 12 && this.$props.columnSchema.formato?.includes("N")){
                    value = formatColumnValue(this.$props.columnSchema, {[this.$props.supportRepeatedInput ? this.$props.columnSchema.id : this.$props.columnSchema.nome]: this.$props.formState.getValue(this.$props.supportRepeatedInput ? this.$props.columnSchema.id : this.$props.columnSchema.nome)})
                }else if(mode == 'show'){
                    value = formatColumnValue(this.$props.columnSchema, {[this.$props.supportRepeatedInput ? this.$props.columnSchema.id : this.$props.columnSchema.nome]: this.$props.formState.getValue(this.$props.supportRepeatedInput ? this.$props.columnSchema.id : this.$props.columnSchema.nome)})
                }else{
                    value = this.$props.formState.getValue(this.$props.supportRepeatedInput ? this.$props.columnSchema.id : this.$props.columnSchema.nome);
                }
            }

            if(this.getColumnType() == 'json'){
                value = value ? JSON.stringify(value) : null;
            }else if(this.customFormat){
                value = formatColumnValue(this.$props.columnSchema, {[this.$props.supportRepeatedInput ? this.$props.columnSchema.id : this.$props.columnSchema.nome]: this.$props.formState.getValue(this.$props.supportRepeatedInput ? this.$props.columnSchema.id : this.$props.columnSchema.nome)})
            }

            return value
        },
        createNumberPattern(){
            if(!this.$props.columnSchema) return '.{0,}'
            const column = this.$props.columnSchema
            const [, decimalPlaces] = column.formato.split("N")
            const minIntPartLength = Number(column.valor_minimo).toFixed(decimalPlaces).split('.')[0].length;
            const maxIntPartLength = Number(column.valor_maximo).toFixed(decimalPlaces).split('.')[0].length
            let pattern = `^\\d{${minIntPartLength},${maxIntPartLength}}`

            if (maxIntPartLength > 3) {
                const additionalGroups = Math.ceil((maxIntPartLength - 3) / 3);
                pattern += '(\\.\\d{3})*'.repeat(additionalGroups);
            }
            const parseDecimalPlaces = Number(decimalPlaces)
            const formatDecimalPlacesToRegex = parseDecimalPlaces ? `,${parseDecimalPlaces}` : ''
            pattern += `(,\\d{1${formatDecimalPlacesToRegex}})?$`

            return pattern
        },
        formShowInputClick(e){
            navigator.clipboard.writeText(e.target.value || e.target.innerText || '');
            document.getElementById('copy-message')?.classList.add('success-copy-message');
            setTimeout(() => {
                document.getElementById('copy-message')?.classList.remove('success-copy-message');
            }, 2000);
        },
        getValueForSelectOption(option){
            const codeNames = this.$props.formState.getCodeNamesFromListValue(this.sis_lista_edicao)
            const primaryKeyNameIndex = codeNames.findIndex(name => name == this.sis_lista_edicao.campo_chave_primaria)
            const pkValue = option['codigo'+(primaryKeyNameIndex + 1)]
            return pkValue;
        },
        setSelectedOption(option){
            this.sis_lista_edicao.sis_opcao_lista_valor = [{id: option['id'], titulo: option.titulo}]
            this.changeValue({ target: { value: option['id'] } });
            this.modalListFilterShow = false;
        },
        handleFlatpickrReady(id, input_title, input_table_title){
            const flatpickrInput = document.querySelectorAll('.fp_'+id)[1];
            if(!flatpickrInput) return
            flatpickrInput.setAttribute('input_title', input_title);
            flatpickrInput.setAttribute('input_table_title', input_table_title);
        },
        handleListOptionsFilter(stringMatch){
            if(stringMatch.length < 3){
                this.modalListFilterMinDigitShow = true;
                return false;
            }
            this.modalListFilterMinDigitShow = false;

            this.inputLoading = true;            
            getValueListById(this.sis_lista_edicao.id_lista_valor, stringMatch)
                .then(res => {
                    this.filteredListOptions = res.sis_lista_valor.sis_opcao_lista_valor;
                    this.inputLoading = false;
                })
                .catch((error) => {
                    this.inputLoading = false;
                    console.log(error)
                })
        },
        showFilterListModal(){
            this.filteredListOptions = []
            this.modalListFilterShow = true;
        },
        getDefaultValue(symbol){
            switch(symbol){
                case 'now()':
                    return Date.now();
                case 'true': 
                    return true;
                case 'false': 
                    return false;
                case 'gen_random_uuid()':
                    return uuidv4();
                default:
                    return null;
            }
        },
        getColumnType(){
            switch(this.$props.columnSchema.tipo_campo){
                case 2: //Boolean Radio
                    return 'radio'
                case 4: //Date
                    return 'date';
                case 5: //Date Time
                    return 'date-time';
                case 6: //Time
                    return 'time';
                case 7: //Ingeger
                    return 'integer';
                case 9: //Json
                    return 'json';
                case 10: //JSONB
                    return 'json';
                case 12: //Numeric
                    return 'numeric';
                case 21: //Radio
                    return 'multiselect';
                case 22: //checkbox
                    return 'checkbox';
                default:
                    return 'text';
            }
        },
        getDate(_, type){
            switch(type){
                default: return '';
            }
        },
        changeDateValue(e){
            this.$props.formState.setMutatedValues(this.$props.supportRepeatedInput ? this.column.id : this.column.nome, e.target.value)
        },
        deleteSelection(){
            if(this.sis_lista_edicao.exige_filtro){
                this.$props.formState.setMutatedValues(this.$props.supportRepeatedInput ? this.column.id : this.column.nome, undefined);
                this.$props.formState.setHasChanged(true);
                this.sis_lista_edicao.sis_opcao_lista_valor = null;
            }
            else if(this.sis_lista_edicao.campo_chave_primaria){
                this.$props.formState.setMutatedValues(this.$props.supportRepeatedInput ? this.column.id : this.column.nome, undefined);
                this.$props.formState.setHasChanged(true);
                if(document.getElementById(this.component_id).selectedIndex > 0) document.getElementById(this.component_id).selectedIndex = 0;
            }else{
                this.$props.formState.setMutatedValues(this.$props.supportRepeatedInput ? this.column.id : this.column.nome, undefined);
                this.$props.formState.setHasChanged(true);
                if(document.getElementById(this.component_id).selectedIndex > 0) document.getElementById(this.component_id).selectedIndex = 0;
            }
        },
        changeCheckboxValue(e){
            const value = e.target.checked ? true : false;
            if(value){
                this.$props.formState.setMutatedValues(this.$props.supportRepeatedInput ? this.column.id : this.column.nome, 
                    this.$props.formState.getValue(this.$props.supportRepeatedInput ? this.$props.columnSchema.id : this.$props.columnSchema.nome) ? 
                    this.$props.formState.getValue(this.$props.supportRepeatedInput ? this.$props.columnSchema.id : this.$props.columnSchema.nome) + ';' + e.target.value :
                    e.target.value
                );
                this.$props.formState.setHasChanged(true);
            }else{
                const values = this.$props.formState.getValue(this.$props.supportRepeatedInput ? this.$props.columnSchema.id : this.$props.columnSchema.nome).split(';');
                const index = values.indexOf(e.target.value);
                values.splice(index, 1);
                this.$props.formState.setMutatedValues(this.$props.supportRepeatedInput ? this.column.id : this.column.nome, values.join(';'));
                this.$props.formState.setHasChanged(true);
            }
        },
        changeValue(event){
            let { value } = event.target;

            if(this.$props.columnSchema.id_lista_edicao){
                if(value == ''){
                    value = null;
                }

                if(this.$props.columnSchema.tipo_campo == 7){
                    value = parseInt(value);
                }
            }
            let key = this.$props.columnSchema.nome;
            if(this.$props.supportRepeatedInput){
                key = this.$props.columnSchema.id
            }

            this.$props.formState.setMutatedValues(key, value);
            this.$props.formState.setHasChanged(true);
        },
        changeSwitchValue(e){
            let key = this.$props.columnSchema.nome;
            if(this.$props.supportRepeatedInput){
                key = this.$props.columnSchema.id
            }
            this.$props.formState.setMutatedValues(key, e.target.checked ? true : false);
            this.$props.formState.setHasChanged(true);
        },
        changeIntegerValue(e){
            let key = this.$props.columnSchema.nome;
            if(this.$props.supportRepeatedInput){
                key = this.$props.columnSchema.id
            }

            if(e.target.value.length > 0){
                this.$props.formState.setMutatedValues(key, parseInt(e.target.value.replace(/\D+/g, "")));
                this.$props.formState.setHasChanged(true);
            }
        },
        changeNumericValue(e){
            let key = this.$props.columnSchema.nome;
            if(this.$props.supportRepeatedInput){
                key = this.$props.columnSchema.id
            }
            if(e.target.value.length > 0){
                this.$props.formState.setMutatedValues(key, e.target.value.replace(/[^0-9.]/g, ''));
                this.$props.formState.setHasChanged(true);
            }
        },
        getMaskType(mask){
            const type = Array.isArray(mask) ? 'array' : typeof mask == 'string' ? 'string' : '';

            switch(type){
                case 'string':
                    return mask;
                case 'array':
                    return JSON.stringify(mask);
                default:
                    return mask;
            }
        },
        formTypeCheck(column, formType){
            switch(formType){
                case 'creation':
                    return column.permite_inclusao;
                case 'edition':
                    return column.permite_alteracao;
                default:
                    return true;
            }
        },
        isReadOnly(column, formType){
            switch(formType){
                case 'creation':
                    return !column.permite_inclusao;
                case 'edition':{
                    if(this.$props.dataAlreadyExists && !column.permite_alteracao){
                        return true;
                    }
                    break
                }
                case 'show':
                    return true;
                default:
                    return true;
            }
        },
        toInteger(e){
            return e.target.value = parseInt(e.target.value)
        },
        getMinMaxLength(mask){
            const type = Array.isArray(mask) ? 'array' : typeof mask == 'string' ? 'string' : '';
            switch(type){
                case 'string':
                    return [mask.length, mask.length];
                case 'array':
                    return [mask[0].length, mask[mask.length - 1].length]
                default:
                    return [0, 0];
            }
        },
        getValueForSelectVisualization(){
            const column = this.$props.columnSchema;
            if(!this.$props.formState.getValue(this.$props.supportRepeatedInput ? column.id : column.nome)){
                return '';
            }

            const listName = column.id_lista_visualizacao == column.id_lista_edicao || !column.sis_lista_visualizacao ? 'sis_lista_edicao' : 'sis_lista_visualizacao';
            const option = column[listName].sis_opcao_lista_valor.find(option => option.id == this.$props.formState.getValue(this.$props.supportRepeatedInput ? column.id : column.nome))
            return option?.titulo
        },
        getValueForCheckbox(){
            const column = this.$props.columnSchema;
            const values = this.$props.formState.getValue(this.$props.supportRepeatedInput ? column.id : column.nome)?.split(';')
            if(!values){
                return '';
            }
            const text =  this.$props.columnSchema.opcao_selecao.filter(os => values.includes(os.id)).map(osv => osv.titulo).join(', ')
            return text
        },
        getValueForRadio(){
            const column = this.$props.columnSchema;
            const option = column.opcao_selecao.find(option => option.id == this.$props.formState.getValue(this.$props.supportRepeatedInput ? column.id : column.nome))
            if(!option){
                return '';
            }
            return option.titulo
        },
        valueIsNegative() {
          const { id, nome } = this.$props.columnSchema
          const value = this.$props.formState.getValue(this.$props.supportRepeatedInput ? id : nome)

         if (value && typeof value === 'string') return value.includes('-')
        }
    },
    mounted() {
        this.sis_lista_edicao?.sis_opcao_lista_valor?.sort((a,b) => a.id - b.id)
    },
}
</script>
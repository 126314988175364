<script>
import { onMounted, onUnmounted, ref } from "vue";
import useDynamicTable from "../../../helpers/dynamicTable";
import BrMassActions from "@/components/custom-components/br-mass-actions";
import Lottie from "@/components/widgets/lottie.vue";
import animationData from "@/components/widgets/msoeawqm.json";
import ContasPagarContainer from '@/components/financeiro/movimentacao/contas_pagar/container.vue'
import FiltrosNavegador from "@/components/filtros-navegador";
import BuscadorSimples from '@/components/buscador_simples';
import ConfigurarTabela from "./configurar_tabela.vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default {
    props: {
        program_schema: {
            type: Object,
            required: true,
        }
    },
    setup(props) {
        const store = useStore()
        const browser_route = useRoute()
        const id_programa = ref(browser_route.params.targetId)
    
        const programSchema = {
            sis_programa: props.program_schema,
            sis_tabela: props.program_schema.sis_tabela,
        }
        const grid = ref(null);
        const table_notfound_component = ref(null)
        const DYNAMIC_TABLE_FUNCTIONS = ref(null);
        const loadingSearch = ref(false);
        const aditional_condition = ref(null);
        const updateAditionalCondition = (condition) => {
            aditional_condition.value = condition;
        }
        const topBar = ref(null);
        const displayTopBarFixed = ref(false)

        onMounted(() => {
            setData(programSchema);
            window.addEventListener('scroll', handleScrollEvent)
        })

        onUnmounted(() => {
            window.removeEventListener('scroll', handleScrollEvent)
        })

        const setLoadingSearch = (bool) => {
            loadingSearch.value = bool;
        }
        function handleScrollEvent(){
            let currentMode = document.documentElement.getAttribute("data-layout-mode");
            if (currentMode === "dark") {
                currentMode = 'bg-light'
            }
            if (currentMode === "light") {
                currentMode = 'bg-white'
            }
            let windowSize = document.documentElement.clientWidth
            let sideBarEnabled = document.querySelector('.vertical-sidebar-enable')
            let sideBarWidth = 0
            
            if(windowSize < 767){
                sideBarWidth = sideBarEnabled ? document.getElementById('scrollbar').offsetWidth + 1 : 0
            }
            if(windowSize >= 767){
                sideBarWidth = document.getElementById('scrollbar').offsetWidth + 1
            }

            if(window.scrollY >= 120 && displayTopBarFixed.value == true && topBar.value){
                topBar.value.style.width = `calc(100% - ${sideBarWidth}px)`;
            } 
            if(window.scrollY >= 120 && displayTopBarFixed.value == false && topBar.value){
                displayTopBarFixed.value = true
                topBar.value.classList.add('position-fixed', 'border-top', 'border-bottom', currentMode, 'px-4');
                topBar.value.style.top = '70px';
                topBar.value.style.right = '0px';
                topBar.value.style.zIndex = '1003';
                topBar.value.style.width = `calc(100% - ${sideBarWidth}px)`;
            }
            if(window.scrollY < 120 && displayTopBarFixed.value == true && topBar.value){
                displayTopBarFixed.value = false
                topBar.value.classList.remove('position-fixed', 'border-top', 'border-bottom', 'bg-light', 'bg-white', 'px-4');
                topBar.value.style.top = 'unset';
                topBar.value.style.right = 'unset';
                topBar.value.style.zIndex = '1';
                topBar.value.style.width = 'auto';
            }
        }
        async function restartGrid() {
            store.commit('frameworkNavegador/setBeingReturned', true);
            document.getElementById('table-wrapper').innerHTML = "";
            DYNAMIC_TABLE_FUNCTIONS.value.setTableColumns(false);
            await DYNAMIC_TABLE_FUNCTIONS.value.gridRenderData(id_programa, null, setLoadingSearch);
            handleScrollEvent()
        }
        async function onSearch() {
            await DYNAMIC_TABLE_FUNCTIONS.value.gridRenderData(id_programa, aditional_condition.value, setLoadingSearch);
            handleScrollEvent()
        }
        async function setData(programSchema) {
            document.getElementById('table-wrapper').innerHTML = "";
            DYNAMIC_TABLE_FUNCTIONS.value = useDynamicTable(grid, programSchema, table_notfound_component)
            DYNAMIC_TABLE_FUNCTIONS.value.setTableColumns();
            DYNAMIC_TABLE_FUNCTIONS.value.gridRenderData(id_programa, null, setLoadingSearch);
        }
        function getDYNAMIC_TABLE_FUNCTIONS() {
            return DYNAMIC_TABLE_FUNCTIONS;
        }
        function getGrid() {
            return grid
        }

        return {
            programSchema,
            getDYNAMIC_TABLE_FUNCTIONS,
            defaultOptions: { animationData: animationData },
            table_notfound_component,
            getGrid,
            DYNAMIC_TABLE_FUNCTIONS,
            grid,
            onSearch,
            loadingSearch,
            updateAditionalCondition,
            aditional_condition,
            restartGrid,
            topBar
        }
    },
    components: {
        BrMassActions,
        Lottie,
        ContasPagarContainer,
        FiltrosNavegador,
        BuscadorSimples,
        ConfigurarTabela
    },

};
</script>
    
<template>
    <div class="position-relative">
        <div class="row">
            <div class="card pb-4">
                <ContasPagarContainer />

                <div class="d-flex flex-column py-2" ref="topBar" id="topBar">
                    <div class="text-muted px-3 d-flex align-items-center gap-1 flex-wrap">
                        <BrMassActions v-if="DYNAMIC_TABLE_FUNCTIONS && grid"
                            :programSchema="programSchema.sis_programa"
                            :tableFunctions="DYNAMIC_TABLE_FUNCTIONS" 
                            :grid="grid" 
                            :restartGrid="restartGrid"
                        />
                        <div class="flex-1 d-flex">
                            <BuscadorSimples 
                                :onSearch="onSearch" 
                                :updateAditionalCondition="updateAditionalCondition"
                                :aditional_condition="aditional_condition"
                                :loadingSearch="loadingSearch"
                                :placeHolder="'Buscar em ' + programSchema.sis_programa?.denominacao + '...'" 
                            />
                            <FiltrosNavegador 
                                :loadingSearch="loadingSearch"
                                :search="onSearch"
                                :sis_campos_tabela="programSchema.sis_tabela.sis_campo_tabela"
                                :sis_filtros="programSchema.sis_programa.sis_filtro ?? []" 
                                :programSchema="program_schema"
                                :updateFiltros="(filtros) => {
                                    programSchema.sis_programa.sis_filtro = filtros
                                }"
                            />
                        </div>
                    </div>
                </div>

                <!-- DYNAMIC TABLE CONTENT -->
                <div class="card-body pt-1 position-relative pb-5">
                    <div id="table-wrapper"></div>
                    <div v-if="programSchema.sis_programa?.denominacao == 'Movimento de Venda'" class="text-left" style="position:absolute;left:0;bottom:0;">
                        *Comp. = Comprovantes / Conc. = Concilidados / Conf. = Conferidos
                    </div>
                    <div ref="table_notfound_component" id="table_notfound_component" class="position-absolute w-100"
                        style="top: 55px; left: 0;display:none">
                        <div class="text-center">
                            <Lottie class="avatar-xl" colors="primary:#121331,secondary:#08a88a" :options="defaultOptions"
                                :height="75" :width="75" />
                            <h5 class="mt-2">Nenhum Registro Encontrado</h5>
                        </div>
                    </div>
                    <ConfigurarTabela v-if="!loadingSearch" :restartGrid="restartGrid" :programSchema="programSchema.sis_programa"/>
                </div>
            </div>
        </div>
    </div>
</template>

<style @scoped>
#topBar{
    transition: all 0.3s;

}
</style>
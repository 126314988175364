import Swal from "sweetalert2";
import { getValueListById } from "./forms/getListaValorById";
import { sendFunctionRequest } from "./dynamicTable/sendFunctionRequest";
import store from "@/state/store";
import { v4 as uuidv4 } from "uuid";

const callFunction = async (
    p_function,
    register_id,
    program_schema,
    register_data,
    resetMainAndDetailTableForms
) => {
    const asyncJob = {
        id: uuidv4(),
        title: null,
        message: null,
        status: "running",
    };
    const body = {
        solicita_tfa: p_function.solicita_tfa,
        id_programa: program_schema.id_programa,
        condicao_adicional: "",
        lista_id_empresa:
            JSON.parse(localStorage.getItem("companiesGlobalFilter")) ?? [],
        data_trabalho: JSON.parse(localStorage.getItem("dataTrabalho")),
        navegador: false,
        ordenacao: null,
        quantidade_registros: 0,
        pagina: 0,
        rota: program_schema.rota_backend + p_function.rota_backend,
        dados: {
            [program_schema.sis_tabela.nome_tabela]: {
                registros: [],
            },
        },
    };

    program_schema.sis_tabela.sis_campo_tabela.forEach((col) => {
        if (col.chave_primaria) {
            body.dados[program_schema.sis_tabela.nome_tabela].registros.push({
                [col.nome]: register_id,
            });
        }
    });

    // Register Data
    // 1 -> Boleto
    // 3 -> Carteira
    // 1474606972 -> PIX
    // 256796645 -> TED/DOC

    if (p_function.id_funcionalidade === "6fe3cfca-a6bb-4365-9705-54bf2a45cd5a") {
        // Configurar Título Financeiro
        if (p_function.exige_registro && !register_data) {
            return Swal.fire({
                icon: "error",
                text: `Funcionalide [${p_function.denominacao}] desabilitada em modo de Criação`,
                confirmButtonColor: "#34c38f",
                confirmButtonText: "OK",
            });
        }

        const { id_tipo_cobranca } = register_data;

        if ([1, 3, 1474606972, 256796645].includes(id_tipo_cobranca)) {
            // Boleto, Carteira, PIX, TED/DOC
            let list_id = null;
            let condition = "";
            let preAditionalCondition = "";
            if ([1, 3].includes(id_tipo_cobranca)) {
                list_id = "5e74467f-f4d3-42ca-9097-b5d39bba45f3";
                // condition = `id_empresa = ${register_data.id_empresa} AND situacao = 1`
                preAditionalCondition = `CAST(TO_JSONB(vw_debito_direto_autorizado.*) AS TEXT) ~* `;
            }
            if ([1474606972, 256796645].includes(id_tipo_cobranca)) {
                list_id = "371ee293-9b2a-405a-a027-ad5b83676e24";
                condition = "id_pessoa = " + register_data.id_credor_devedor;
            }
            const res = await getValueListById(list_id, condition);
            store.commit("massActionsModals/setProperty", [
                {
                    key: "showDynamicFilterValueListModal",
                    value: true,
                },
                {
                    key: "preAditionalCondition",
                    value: preAditionalCondition,
                },
                {
                    key: "sis_lista_valor",
                    value: res.sis_lista_valor,
                },
                {
                    key: "onSave",
                    value: (ids_object) => {
                        if (
                            console.log('oi'),
                            ids_object[res.sis_lista_valor.nome_tabela].registros.length >
                            1 &&
                            p_function.exige_registro
                        ) {
                            return Swal.fire({
                                icon: "warning",
                                text: `É permitido selecionar somente um registro para executar a ação "${p_function.denominacao}".`,
                                confirmButtonText: "OK",
                            });
                        }
                        asyncJob.title =
                            program_schema.denominacao + " > " + p_function.denominacao;
                        asyncJob.message = "Operação em andamento...";
                        asyncJob.status = "running";
                        store.commit("asyncJobs/addJob", asyncJob);

                        console.log(body)

                        sendFunctionRequest(
                            { ...body, dados: { ...body.dados, ...ids_object } },
                            (res) => {
                                asyncJob.message = "Operação concluída!";
                                asyncJob.status = "done";
                                store.commit("asyncJobs/updateJob", { ...asyncJob });

                                Swal.fire({
                                    icon: "success",
                                    text: `Salvo!`,
                                    confirmButtonText: "OK",
                                });

                                resetMainAndDetailTableForms(res.data.retorno.dados);
                            },
                            (error) => {
                                asyncJob.message = error;
                                asyncJob.status = "failed";
                                store.commit("asyncJobs/updateJob", { ...asyncJob });
                            }
                        );
                    },
                },
            ]);
        }

        // if([1, 3].includes(register_data.id_tipo_cobranca)){ // BOLETO DE PAGAMENTO
        //     store.commit('massActionsModals/setProperty', [
        //         {
        //             key: 'showSingleDataInputModal',
        //             value: true
        //         },
        //         {
        //             key: 'title',
        //             value: 'Alterando Data de Vencimento'
        //         },
        //         {
        //             key: 'defaultDate',
        //             value: register_data.data_vencimento
        //         },
        //         {
        //             key: 'onSave',
        //             value: (date) => {
        //                 body.dados[program_schema.sis_tabela.nome_tabela].registros[0].data_vencimento = date;
        //                 asyncJob.title = program_schema.denominacao + ' > ' + p_function.denominacao
        //                 asyncJob.message = 'Operação em andamento...'
        //                 asyncJob.status = 'running'
        //                 store.commit('asyncJobs/addJob', asyncJob)

        //                 return sendFunctionRequest(
        //                     body,
        //                     (res) => {
        //                         asyncJob.message = 'Operação concluída!'
        //                         asyncJob.status = 'done'
        //                         store.commit('asyncJobs/updateJob', { ...asyncJob })

        //                         Swal.fire({
        //                             icon: "success",
        //                             text: `Salvo!`,
        //                             confirmButtonText: "OK",
        //                         })

        //                         resetMainAndDetailTableForms(res.data.retorno.dados)
        //                     },
        //                     (error) => {
        //                         asyncJob.message = error
        //                         asyncJob.status = 'failed'
        //                         store.commit('asyncJobs/updateJob', { ...asyncJob })
        //                     },
        //                 )
        //             }
        //         }
        //     ])
        // }
    }

    if (p_function.id_funcionalidade === "6d22f883-df90-4d94-9077-4d0ce3ec516a") {
        // Configurar Boleto
        if (p_function.exige_registro && !register_data) {
            return Swal.fire({
                icon: "error",
                text: `Funcionalide [${p_function.denominacao}] desabilitada em modo de Criação`,
                confirmButtonColor: "#34c38f",
                confirmButtonText: "OK",
            });
        }

        let list_id = "5e74467f-f4d3-42ca-9097-b5d39bba45f3";
        let condition = "";
        let preAditionalCondition = `CAST(TO_JSONB(vw_debito_direto_autorizado.*) AS TEXT) ~* `;
        const res = await getValueListById(list_id, condition);
        store.commit("massActionsModals/setProperty", [
            {
                key: "showDynamicFilterValueListModal",
                value: true,
            },
            {
                key: "preAditionalCondition",
                value: preAditionalCondition,
            },
            {
                key: "sis_lista_valor",
                value: res.sis_lista_valor,
            },
            {
                key: "onSave",
                value: (ids_object) => {
                    if (
                        ids_object[res.sis_lista_valor.nome_tabela].registros.length > 1 &&
                        p_function.exige_registro
                    ) {
                        return Swal.fire({
                            icon: "warning",
                            text: `É permitido selecionar somente um registro para executar a ação "${p_function.denominacao}".`,
                            confirmButtonText: "OK",
                        });
                    }
                    asyncJob.title =
                        program_schema.denominacao + " > " + p_function.denominacao;
                    asyncJob.message = "Operação em andamento...";
                    asyncJob.status = "running";
                    store.commit("asyncJobs/addJob", asyncJob);

                    sendFunctionRequest(
                        { ...body, dados: { ...body.dados, ...ids_object } },
                        (res) => {
                            asyncJob.message = "Operação concluída!";
                            asyncJob.status = "done";
                            store.commit("asyncJobs/updateJob", { ...asyncJob });

                            Swal.fire({
                                icon: "success",
                                text: `Salvo!`,
                                confirmButtonText: "OK",
                            });

                            resetMainAndDetailTableForms(res.data.retorno.dados);
                        },
                        (error) => {
                            asyncJob.message = error;
                            asyncJob.status = "failed";
                            store.commit("asyncJobs/updateJob", { ...asyncJob });
                        }
                    );
                },
            },
        ]);
    }

    if (p_function.id_funcionalidade === "84a4e6a3-967d-44ec-92bf-517002fb6c4a" ||
        p_function.id_funcionalidade === "75c360a4-80b8-481a-ab91-41e9d303c409") {
        // Configurar PIX OU Configurar TED/DOC
        if (p_function.exige_registro && !register_data) {
            return Swal.fire({
                icon: "error",
                text: `Funcionalide [${p_function.denominacao}] desabilitada em modo de Criação`,
                confirmButtonColor: "#34c38f",
                confirmButtonText: "OK",
            });
        }

        let list_id = "371ee293-9b2a-405a-a027-ad5b83676e24";
        let condition = "id_pessoa = " + register_data.id_credor_devedor;
        let preAditionalCondition = ""
        const res = await getValueListById(list_id, condition);
        store.commit("massActionsModals/setProperty", [
            {
                key: "showDynamicFilterValueListModal",
                value: true,
            },
            {
                key: "preAditionalCondition",
                value: preAditionalCondition,
            },
            {
                key: "sis_lista_valor",
                value: res.sis_lista_valor,
            },
            {
                key: "onSave",
                value: (ids_object) => {
                    if (
                        ids_object[res.sis_lista_valor.nome_tabela].registros.length > 1 &&
                        p_function.exige_registro
                    ) {
                        return Swal.fire({
                            icon: "warning",
                            text: `É permitido selecionar somente um registro para executar a ação "${p_function.denominacao}".`,
                            confirmButtonText: "OK",
                        });
                    }
                    asyncJob.title =
                        program_schema.denominacao + " > " + p_function.denominacao;
                    asyncJob.message = "Operação em andamento...";
                    asyncJob.status = "running";
                    store.commit("asyncJobs/addJob", asyncJob);

                    sendFunctionRequest(
                        { ...body, dados: { ...body.dados, ...ids_object } },
                        (res) => {
                            asyncJob.message = "Operação concluída!";
                            asyncJob.status = "done";
                            store.commit("asyncJobs/updateJob", { ...asyncJob });

                            Swal.fire({
                                icon: "success",
                                text: `Salvo!`,
                                confirmButtonText: "OK",
                            });

                            resetMainAndDetailTableForms(res.data.retorno.dados);
                        },
                        (error) => {
                            asyncJob.message = error;
                            asyncJob.status = "failed";
                            store.commit("asyncJobs/updateJob", { ...asyncJob });
                        }
                    );
                },
            },
        ]);
    }

};

export { callFunction };

<script>
    import Permissions from '@/components/permissions'

    export default({
        data() {
            return {
                permissionsGranted: [],
                loading: false,
            }
        },
        methods: {
            async handleSave(){
                this.loading = true;
                await this.$store.state.permissions.onSave(this.permissionsGranted);
                this.loading = false;
            },
            handleReactiveData(permissionsGranted){
                this.permissionsGranted = permissionsGranted;
            }
        },
        computed: {
            showModal: {
                get() {
                    return this.$store.state.permissions.show
                },
                set(value) {
                    this.$store.commit('permissions/setShow', value)
                }
            },
        },
        components: {
            Permissions,
        },
    })

</script>
<template>
    <div class="permissions-container">
        <b-modal v-if="!this.$store.state.permissions.error" size="lg" v-model="showModal" hideFooter centered scrollable :title="this.$store.state.permissions.title">
            <template v-if="this.$store.state.permissions.loading">
                <div class="d-flex justify-content-center align-items-center mb-3">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                </div>
            </template>
            <template v-else>
                <Permissions :permissions="JSON.parse(JSON.stringify(this.$store.state.permissions.permissionsArray))" :returnPermissionsID="handleReactiveData" />
                <div class="row">
                    <button @click="handleSave" v-if="!loading && (this.$store.state.permissions.usersID.length > 0)" class="ms-auto mt-3 btn btn-primary shadow-sm col-12 col-md-6 col-lg-3 h-100" title="Salvar">
                        <span><i class="ri-save-2-fill align-bottom me-1"></i> Salvar </span>
                    </button>
                    <button v-if="loading" class="disabled ms-auto mt-3 btn btn-primary btn-load shadow-sm col-12 col-md-6 col-lg-3 h-100">
                        <span class="d-flex align-items-center justify-content-center gap-2">
                            <span class="spinner-border flex-shrink-0" role="status"></span> Carregando
                        </span>
                    </button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
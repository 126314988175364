import { ref } from "vue"

export function useOrderBy(detailTable){
    const orderConfig = ref({
        id_tabela: detailTable.schema.id_tabela,
        columns: []
    })
    const orderConfigs = localStorage.getItem('orderConfigs') ? JSON.parse(localStorage.getItem('orderConfigs')) : []
    if(orderConfigs.find(oc => oc.id_tabela == detailTable.schema.id_tabela)){
        orderConfig.value = orderConfigs.find(oc => oc.id_tabela == detailTable.schema.id_tabela)
    }else{
        orderConfigs.push(orderConfig.value)
        localStorage.setItem('orderConfigs', JSON.stringify(orderConfigs))
    }

    const getColumnOrderConfig = (column) => {
        return orderConfig.value.columns.find(c => c.name === column.nome) ?? false
    }
    const setColumnOrderConfig = (column, order) => {
        
        let columnOrderConfig = getColumnOrderConfig(column.nome)
        if(columnOrderConfig){
            columnOrderConfig.order = order
        }else{
            columnOrderConfig = {name: column.nome, order}
            orderConfig.value.columns.push(columnOrderConfig)
        }
        
        return columnOrderConfig
    }

    const order = () => {
        const formsWithDataAlreadyExists = detailTable.forms.filter(f => f.dataAlreadyExists)
        const formsWithoutDataAlreadyExists = detailTable.forms.filter(f => !f.dataAlreadyExists)
    
        formsWithDataAlreadyExists.sort((form_A, form_B) => {
            let toBeReturned = 0

            orderConfig.value.columns.filter(c => c.order !== 'neutral').forEach(col => {
                const value_A = form_A.normalizeInitialValue(col.name)
                const value_B = form_B.normalizeInitialValue(col.name)
                const comparedResult = compare(col, value_A, value_B)
                if(comparedResult !== 0){
                    toBeReturned = comparedResult
                }
            })
                
            function compare(col, value_A, value_B){
                if(col.order === 'asc'){
                    return value_A > value_B ? 1 : value_A == value_B ? 0 : -1
                }else if(col.order === 'desc'){
                    return value_A < value_B ? 1 : value_A == value_B ? 0 : -1
                }
            }
            return toBeReturned
        })

        detailTable.forms = [...formsWithDataAlreadyExists, ...formsWithoutDataAlreadyExists]
    }

    const orderBy = (event, columnToOrder) => {
        const multipleColumns = event.shiftKey;
        const columnOrderConfig = getColumnOrderConfig(columnToOrder) || setColumnOrderConfig(columnToOrder, 'neutral')
        const currentOrder = columnOrderConfig.order
        columnOrderConfig.order = currentOrder === 'asc' ? 'desc' : currentOrder === 'desc' ? 'neutral' : 'asc'
        columnOrderConfig.tipo_campo = columnToOrder.tipo_campo
        orderConfig.value.columns = orderConfig.value.columns.filter(c => c.order !== 'neutral')

        if(!multipleColumns){
            orderConfig.value.columns = [...new Set(orderConfig.value.columns.filter(c => c.name === columnToOrder.nome))]
        }else{
            orderConfig.value.columns.unshift(columnOrderConfig)
            orderConfig.value.columns = [...new Set(orderConfig.value.columns)]
        }

        const index = orderConfigs.findIndex(oc => oc.id_tabela == orderConfig.value.id_tabela)
        orderConfigs[index] = orderConfig.value
        localStorage.setItem('orderConfigs', JSON.stringify(orderConfigs))
        order()
    }

    return {
        detail_table: detailTable,
        orderBy,
        orderConfig,
        order,
    }
}
import { bodyFunctionRequest } from "@/helpers/dynamicTable/bodyFunctionRequest";
import { sendFunctionRequest } from "@/helpers/dynamicTable/sendFunctionRequest";

export default class ChatBoxController {
  movimentoMessages = [];
  movimentoMessagesUsers = [];
  program_schema = {};
  dados = {};
  programTargetId = null;
  registerTargetId = null;
  onReceiveResponse = null

  constructor(program_schema, dados, programTargetId, registerTargetId, onReceiveResponse = null) {
    this.program_schema = program_schema;
    this.dados = dados;
    this.programTargetId = programTargetId;
    this.registerTargetId = registerTargetId;
    this.onReceiveResponse = onReceiveResponse;
  }

  setMovimentoMessages(registros) {
    const tempArray = [];
    registros.forEach((registro) =>
      tempArray.push({
        id: registro.id_anotacao,
        user_id: registro.id_usuario_inclusao,
        name: registro.usuario_inclusao,
        message: registro.anotacao,
        time: registro.data_inclusao,
        edited_time: registro.data_alteracao ? registro.data_alteracao : null,
        align:
          JSON.parse(localStorage.getItem("sis_config")).sis_usuario
            .id_usuario == registro.id_usuario_inclusao
            ? "right"
            : "left",
      })
    );

    this.movimentoMessagesUsers.value = [
      ...new Set(tempArray.map((message) => message.name)),
    ].map((name) => ({ value: name }));
    this.movimentoMessages.value = tempArray;
  }

  handleSubmitMessage(text, message_id = null) {
    let anotacaoData = { anotacao: text };
    if (message_id) {
      anotacaoData = {
        id_anotacao: message_id,
        anotacao: text,
      };
    }

    const rotaBackend = message_id
      ? this.program_schema.rota_backend +
      this.program_schema.sis_funcionalidade.find(
        (func) =>
          func.id_funcionalidade == "c03afdc4-9ef4-4545-b23a-2beaa330a9c0"
      ).rota_backend //Editar Comentário
      : this.program_schema.rota_backend +
      this.program_schema.sis_funcionalidade.find(
        (func) =>
          func.id_funcionalidade == "4cbc785e-81ca-4f1c-8fcd-a751e3f0535d"
      ).rota_backend; //Incluir Comentário

    const dados = {
      sis_anotacao: {
        registros: [anotacaoData],
      },
      [this.program_schema.sis_tabela.nome_tabela]: {
        registros: this.dados[this.program_schema.sis_tabela.nome_tabela].registros,
      },
    };

    bodyFunctionRequest(
      this.programTargetId,
      dados,
      rotaBackend,
      this.registerTargetId
    ).then((data) => {
      sendFunctionRequest(data).then((res) => {
        this.setMovimentoMessages(res.data.retorno.dados.sis_anotacao.registros);
        if(this.onReceiveResponse){
          this.onReceiveResponse(res.data.retorno.dados.sis_anotacao.registros);
        }
      });
    });
  }

  async handleDeleteMessage(message) {
    const dados = {
      sis_anotacao: {
        registros: [{ id_anotacao: message.id }],
      },
      [this.program_schema.sis_tabela.nome_tabela]: {
        registros: this.dados[this.program_schema.sis_tabela.nome_tabela].registros,
      },
    };

    await bodyFunctionRequest(
      this.programTargetId,
      dados,
      this.program_schema.rota_backend +
      this.program_schema.sis_funcionalidade.find(
        (campo_tabela) =>
          campo_tabela.id_funcionalidade ==
          "779adf9e-1da7-4984-b787-9b0d74469dd8"
      ).rota_backend, //Excluir Comentário
      this.registerTargetId
    ).then((data) => {
      sendFunctionRequest(data).then((res) => {
        this.setMovimentoMessages(res.data.retorno.dados.sis_anotacao.registros);
      });
    });
  }

  // return movimentoMessages
  getMessages() {
    return this.movimentoMessages.value;
  }
}

function getTitlesFromListValue(listValue){
    return listValue.titulo_campo_codigo ? listValue.titulo_campo_codigo.split(';') : undefined;
}
function getCodeNamesFromListValue(sis_lista_edicao){
    return sis_lista_edicao.campo_codigo ? sis_lista_edicao.campo_codigo.split(';') : undefined;
}

export {
    getTitlesFromListValue,
    getCodeNamesFromListValue
}
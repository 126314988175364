<template>
  <div class="row px-0 my-3">


    <div id="EixoX" v-if="painel_consulta?.tipo_grafico !== 6 && painel_consulta?.tipo_grafico !== 1"
      class="col-12 p-4 mb-2 card ribbon-box  border mx-3">
      <div class="card-body  w-100">
        <div style="z-index: 3" class="ribbon ribbon-secondary round-shape mb-3"><span class="fs-17">{{ 'Eixo X' }}
          </span></div>

        <div class=" d-flex w-100 p-0 my-2 justify-content-between align-item-center   row  "
          v-for="(Labels, index) in newLabels" :key="index">
          <div class="card  ribbon-box border ribbon-fill">
            <div class="card-body row">

              <div id="inputvlabels" class="col-12 col-lg-10  px-0  mr-0">

                <div v-if="index < 1">
                  <label v-show="index === 0 || index === 1" :for="'label' + index" class="form-label"> Eixo X</label>
                  <form novalidate="" class="input-group flex-1 mr-auto">
                    <input :id="'label' + index" type="text" class="form-control" v-model="Labels.nome"
                      :placeholder="index > 1 ? 'Selecione a Paginação (*)' : 'Selecione o campo (*)'" limit="1" />
                    <input :id="'titulo_label' + index" type="text" class="form-control" v-model="Labels.titulo"
                      placeholder="Selecione o titulo" limit="1" :disabled="painel_consulta?.tipo_grafico == 1" />
                  </form>
                </div>

                <div v-else
                  v-b-tooltip.hover.left="'Selecione o nome da coluna que será usado como Paginação/Filtro do gráfico.'">
                  <label v-show="index === 0 || index === 1" :for="'label' + index" class="form-label w-100">
                    Paginção</label>
                  <input :id="'label' + index" type="text" class="form-control" v-model="Labels.paginacao"
                    :placeholder="index > 1 ? 'Selecione a Paginação (*)' : 'Selecione o campo (*)'" limit="1" />
                </div>
              </div>
              <div id="excluirincluirbutlabel" class="col-12 col-lg-1 d-flex align-items-end justify-content-center">
                <button class="border btn btn-light  " @click="removeLabels(index)" v-if="newLabels.length > 1">
                  <i class="ri-delete-bin-line "></i>
                </button>
                <button @click="addLabels" class="btn btn-primary "
                  :disabled="!todosInputsLabels || painel_consulta?.tipo_grafico == 1"
                  v-if="index === newLabels.length - 1">
                  <i class="ri-add-line "></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>




    <div class="card ribbon-box mx-3 mt-2 border">
      <div style="z-index: 3" class="ribbon ribbon-secondary round-shape mb-3"><span class="fs-17">{{
        painel_consulta?.tipo_grafico == 6 ? 'Colunas' : painel_consulta?.tipo_grafico == 1 ? 'Valores' : 'Eixo Y' }}
        </span></div>
      <draggable v-bind="dragOptions" :list="newValores" @change="handleChange" :item-key="'index'" class="pt-4">
        <transition-group type="transition" name="flip-list">
          <div id="Colunas_EixoY" v-for="(Valores, index) in newValores" :key="index"
            class="col-12 position-relative row mx-1 ">

            <div class="card mt-4 ribbon-box border ribbon-fill">
              <div class="card-body row">
                <div class="ribbon ribbon-primary round-shape"><span class="fs-10">{{ Valores.ordem_column }}</span>
                </div>
                <div>
                  <span class="w-0 text-center" v-if="painel_consulta?.tipo_grafico == 1"> {{ index == 0 ? 'Valor Atual'
                    :
                    'Valor Faltante' }}</span>
                </div>
                <div id="INPUTPREORDEM" class="col-12 col-lg-2 row gap-3 align-items-center">
                  <div class="d-flex flex-column">
                    <label for="">Prefixo</label>
                    <input :id="'pref' + index" class="form-control" v-model="Valores.prefixo" placeholder="**"
                      maxlength="2" />
                  </div>
                </div>
                <div id="INPUTAGREGADORVALORES"
                  :class="[[7, 6].includes(painel_consulta?.tipo_grafico) ? 'col-lg-4' : 'col-lg-5', 'col-12 row  gap-3']">
                  <div>

                    <label class="form-label" for="">Nome da coluna</label>
                    <form novalidate="" class="input-group flex-1 mr-auto">
                      <input :id="'valor' + index" type="text" class="form-control " v-model="Valores.nome"
                        :placeholder="painel_consulta?.tipo_grafico == 1 && index == 1 ? 'Selecione o Valor Faltante (*)' : 'Nome da Coluna (*)'"
                        limit="1" />
                      <input :id="'titulo_valor' + index" type="text" class="form-control " v-model="Valores.titulo"
                        required placeholder="Selecione o titulo" limit="1" />
                    </form>
                  </div>
                  <div class="d-flex">
                    <div id="agregador" class="w-100 ">
                      <label for="">Agregador</label>
                      <Multiselect style="max-height: 38px; padding: 0;" :id="'agreg_tot' + index"
                        v-model="Valores.agregador" :multiple="false" :searchable="false"
                        :options="lista_agregador.sis_lista_valor.sis_opcao_lista_valor" placeholder="-"
                        :mode="'single'" :value-prop="'id'" :label="'titulo'" :canDeselect="false" :canClear="false"
                        open-direction="top" :classes="{
                          wrapper: 'relative mx-0 gap-0  w-100 d-flex align-items-center justify-content-between box-border cursor-pointer outline-none',
                          singleLabel: 'p-2 text-left'
                        }" />
                    </div>
                    <div v-if="![1, 6].includes(painel_consulta?.tipo_grafico)">
                      <label for="">Stack</label>
                      <input :id="'Stacke' + index" class="form-control" v-model="Valores.stack_group"
                        :placeholder="'Group ' + index" maxlength="7" />
                    </div>
                    <div id="agregadortotal" v-if="painel_consulta?.tipo_grafico == 6" class="w-100 ">
                      <div id="inputsagreg" class="d-flex flex-column"
                        v-b-tooltip.hover.left="'Selecione se haverá total e qual será o calculo.'">
                        <label for="">Total</label>
                        <Multiselect :disabled="Valores.agregador == 1" style=" max-height: 38px; padding: 0;"
                          :id="'agreg' + index" v-model="Valores.agregador_total" :multiple="false" :searchable="false"
                          :options="lista_agregador_total.sis_lista_valor.sis_opcao_lista_valor"
                          placeholder="Agregador Total (*)" :mode="'single'" :value-prop="'id'" :label="'titulo'"
                          :canDeselect="false" :canClear="false" :classes="{
                            wrapper: 'relative mx-0 gap-0  w-100 d-flex align-items-center justify-content-between box-border cursor-pointer outline-none',
                            singleLabel: 'p-2 text-left'
                          }" />
                      </div>
                    </div>
                  </div>
                </div>
                <div id="INPUTPOSICAO" :class="['col-lg-2', 'col-12 d-flex align-items-center row gap-3']"
                  v-if="[6, 7].includes(painel_consulta?.tipo_grafico)">
                  <div class="d-flex w-100 flex-column " v-if="painel_consulta?.tipo_grafico == 6">
                    <div class=" w-100 p-0">
                      <label for="">Posição</label>
                      <Multiselect style="max-height: 38px; padding: 0;" :id="'agreg_tot' + index"
                        v-model="Valores.posicao_columns" :multiple="false" :searchable="false"
                        :options="lista_posicao_coluna.sis_lista_valor.sis_opcao_lista_valor" placeholder="-"
                        :mode="'single'" :value-prop="'id'" :label="'titulo'" :canDeselect="false" :canClear="false"
                        open-direction="top" :classes="{
                          wrapper: 'relative mx-0 gap-0  w-100 d-flex align-items-center justify-content-between box-border cursor-pointer outline-none',
                          singleLabel: 'p-2 text-left'
                        }" />
                    </div>

                  </div>
                  <div v-if="painel_consulta?.tipo_grafico == 7" class="d-flex w-100 flex-column">
                    <div class=" w-100 p-0">
                      <label for="">Posição</label>
                      <Multiselect style="max-height: 38px; padding: 0;" :id="'combo' + index"
                        v-model="Valores.grafico_combo" :multiple="false" :searchable="false"
                        :options="grafico_combo.sis_lista_valor.sis_opcao_lista_valor" placeholder="-" :mode="'single'"
                        :value-prop="'id'" :label="'titulo'" :canDeselect="false" :canClear="false" open-direction="top"
                        :classes="{
                          wrapper: 'relative mx-0 gap-0  w-100 d-flex align-items-center justify-content-between box-border cursor-pointer outline-none',
                          singleLabel: 'p-2 text-left'
                        }" />
                    </div>
                  </div>
                </div>

                <div id="IDSUFEDEC" :class="['col-lg-2', 'col-12 row gap-3']">
                  <div id="inpsutsuf" class="d-flex flex-column ">
                    <label for="">Sufixo</label>
                    <input :id="'Suf' + index" class="form-control" v-model="Valores.sufixo" placeholder="kg"
                      maxlength="2" />
                  </div>
                  <div id="inputdec" class="d-flex flex-column">
                    <label for="">Decimais</label>
                    <input :disabled="Valores.agregador == 1" max="4" type="number" v-model="Valores.casadec"
                      maxlength="1" :id="'casas' + index" v-maska data-maska="#" class="form-control" placeholder="0-9">
                  </div>
                </div>
                <div id="INPUDATASETROTUO"
                  :class="['col-lg-2 ', 'col-12 d-flex flex-row justify-content-around align-items-center gap-3']">

                  <div v-if="![1, 6].includes(painel_consulta?.tipo_grafico)" class="d-flex flex-column"
                    v-b-tooltip.hover.left="'Selecione qual será a coluna que segmentará os datasets ( Ao selecionar, só aceitará esse método de dataset)'">
                    <label for="">Dataset</label>
                    <div :append-to-body="true" class="dropdown card-header-dropdown">
                      <a class="text-reset dropdown-btn show" href="#" data-bs-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="true">
                        <button class="border btn btn-light rounded-circle"><i
                            class="mdi mdi-database-minus-outline m-0 p-0"></i></button>
                        <span class="position-absolute 
                        topbar-badge fs-12 translate-middle 
                        badge rounded-pill 
                        bg-danger"><i v-if="Valores.datasetparam" class="mdi mdi-check p-0 h-0"></i></span>
                      </a>
                      <div class="dropdown-menu dropdown-menu-end" data-popper-placement="bottom-end">
                        <div class="dropdown-item">
                          <input :id="'dataset' + index" class="form-control" v-model="Valores.datasetparam" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="">
                    <div v-if="painel_consulta?.tipo_grafico != 6"
                      v-b-tooltip.hover.left="'Selecione se deve aparecer o valor dos dados de forma explícita no dataset.'">
                      <label for="">Rótulo</label>
                      <div id="inputrotulo" class="form-check form-switch form-switch-lg">
                        <input v-model="Valores.rotulo" type="checkbox" class="form-check-input" :id="'rotu' + index">
                      </div>
                    </div>
                    <div v-if="![1, 6].includes(painel_consulta?.tipo_grafico)"
                      v-b-tooltip.hover.left="'Selecione a qual lado deve está a Escala do eixo Y no gráfico.'">
                      <label :for="'sca' + index" alt="'asdasdasd'">Escala</label>
                      <div id="inputscal" class="form-check form-switch form-switch-lg">
                        <input v-model="Valores.scala" type="checkbox" class="form-check-input left-right"
                          :id="'sca' + index">
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-column "
                    v-b-tooltip.hover.left="'Selecione a cor das linhas/barra do gráfico.'">
                    <label for="">Cor</label>
                    <input v-model="Valores.cor" :id="'cores' + index" type="color">
                  </div>
                </div>
                <div style="position: absolute; z-index: 999; width: fit-content; top:0px; right: -10px; ">
                  <button @click="addValor" :disabled="!todosInputsValores" v-if="(index + 1) == newValores.length"
                    class="fs-20 btn btn-primary rounded-circle shadow-sm border">
                    <i class="ri-add-line m-0 p-0 "></i>
                  </button>
                  <button @click="removeValor(index)" v-if="newValores.length > 1"
                    class="fs-20 btn border-rounded btn-light round rounded-circle shadow-sm border">
                    <i class="ri-delete-bin-line m-0 p-0"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </draggable>
    </div>
  </div>
</template>

<script>
import 'flatpickr/dist/flatpickr.min.css';
import Multiselect from '@vueform/multiselect';
import { vMaska } from "maska";
import { VueDraggableNext } from 'vue-draggable-next';
export default {
  components: {
    Multiselect,
    draggable: VueDraggableNext
  },
  directives: {
    maska: vMaska
  },
  props: {
    newValores: {
      type: Array
    },
    addValor: {
      type: Function
    },
    removeValor: {
      type: Function
    },
    newLabels: {
      type: Array
    },
    addLabels: {
      type: Function
    },
    removeLabels: {
      type: Function
    },
    todosInputsLabels: {
      type: Boolean
    },
    todosInputsValores: {
      type: Boolean
    },
    painel_consulta: {
      type: Object || null
    },

    lista_agregador: {
      type: Object || null
    },
    lista_agregador_total: {
      type: Object || null
    },
    lista_posicao_coluna: {
      type: Object || null
    },
    grafico_combo: {
      type: Object || null
    },
  },
  setup(props) {
    const handleChange = (event) => {
      const moved = event.moved;
      if (moved && moved.element) {
        const { element, oldIndex, newIndex } = moved;
        const movedCard = props.newValores.find(card => card == element);
        const newIndexAdjusted = newIndex + 1; // Adicionando 1 para iniciar a ordem em 1
        if (movedCard) {
          movedCard.ordem_column = newIndexAdjusted;
        }
        // Atualizar ordem_column dos outros cards afetados
        props.newValores.forEach(card => {
          if (card && card !== movedCard) {
            if (oldIndex < newIndex) {
              if (card.ordem_column > oldIndex && card.ordem_column <= newIndexAdjusted) {
                card.ordem_column--;
              }
            } else {
              if (card.ordem_column >= newIndexAdjusted && card.ordem_column < oldIndex + 1) {
                card.ordem_column++;
              }
            }
          }
        })
      }
    }
    function noEmpty(ve) {
      if (!ve && ve !== 0) {
        return 0;
      }
      return ve;
    }
    return {
      noEmpty, handleChange
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 600,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    }
  },
  watch: {
    newValores: {
      handler(newValores) {
        newValores.forEach((valor) => {
          if (valor.agregador === 1) {
            valor.casadec = ''; // define o valor de casadec como vazio
            valor.prefixo = ''
            valor.sufixo = '' // define o valor de agregador_total como 0
            valor.agregador_total = 1
          }
        });
        newValores.forEach((a, i) => a.ordem_column = i + 1)
      },
      deep: true // observa alterações profundas no array newValores
    }
  }
}

</script>
<style src="@vueform/multiselect/themes/default.css">
.pickr {
  width: 100%;
  height: 100%;
}

.h-auto {
  max-height: 340px;
}

.row .col:not(:last-child) {
  margin-bottom: 1rem;
}
</style>
<style>
.form-switch .left-right {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='%23ced4da'/><path d='M 1 1 L -1 0 L 1 -1 Z' fill='%23343a40'/></svg>")
}

.form-switch .left-right:checked {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='%23ced4da'/><path d='M-1,-1 L1,0 L-1,1 Z' fill='%23343a40'/></svg>");
}

.form-switch .left-right:not(:checked) {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='%23ced4da'/><path d='M1,-1 L-1,0 L1,1 Z' fill='%23343a40'/></svg>");
}
</style>
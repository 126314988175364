<script>
import Lottie from "@/components/widgets/lottie.vue";
import animationData from "@/components/widgets/msoeawqm.json";

export default {
    components: {
        Lottie
    },
    props: {
        text: {
            type: String,
            required: false,
        }
    },
    setup() {
        return {
            defaultOptions: { animationData: animationData },
        }
    },
}
</script>
<template>
    <div class="text-center" style="">
        <Lottie class="avatar-xl" colors="primary:#121331,secondary:#08a88a" :options="defaultOptions"
            :height="75" :width="75" />
        <h5 class="mt-2" v-html="text"></h5>
    </div>
</template>
<script>
import { reactive, ref, watch, computed } from 'vue';
import { useStore } from 'vuex';
import Lottie from "@/components/widgets/lottie.vue";
import animationData from "@/components/widgets/msoeawqm.json";
import { sendFunctionRequest } from '@/helpers/dynamicTable/sendFunctionRequest';
import Swal from 'sweetalert2';
import { DateTime } from 'luxon';
import { Grid, h } from 'gridjs';
import ptBR from '../../helpers/dynamicTable/ptBrLanguage';
import { makeResizable } from '../../helpers/makeResizable';
import formatColumnValue from '../../helpers/dynamicTable/formatColumnValue';
import ChatBoxController from '../chat/chat-box-controller';
import ChatBox from '@/components/chat/chat-box.vue';

export default {
    props: {
    },
    setup() {
        const store = useStore()
        const show = ref(false)
        const saveLoading = ref(false)
        const data = ref([])
        const currentDataIndex = ref(0)
        const slideDocuments = reactive({
            currentIndex: 0,
            documents: [],
            loading: false
        })
        const chatController = ref(null)
        const loadingApprovement = ref(false)
        const visualizarDocumentosFunction = ref(null)
        const approvePaymentFunction = ref(null)
        const handleSubmitMessage = ref(() => null)
        const handleDeleteMessage = ref(() => null)

        watch(() => store.state.massActionsModals.showVisualizarDetalhesContasAPagarModal, (value) => {
            show.value = value;
            data.value = JSON.parse(JSON.stringify([...store.state.massActionsModals.data?.titulo_financeiro])) ?? [];
            if(value){
                visualizarDocumentosFunction.value = store.state.massActionsModals.programSchema.sis_funcionalidade.find(f => f.id_funcionalidade === '3e95322a-8f41-4c21-8bfa-9c9908cdbeac')
                approvePaymentFunction.value = store.state.massActionsModals.programSchema.sis_funcionalidade.find(f => f.id_funcionalidade === 'bc46e597-ada3-45db-b1c9-95726e764b9c')
                
                if(!visualizarDocumentosFunction.value) return
                currentDataIndex.value = 0;
                slideDocuments.currentIndex = 0;
                setDocumentsVisualization();
                createGridTable();
                setMessages();
                document.addEventListener('keyup', onKeyUp);
            }
            if(!value){
                document.removeEventListener('keyup', onKeyUp);
            }
        })
        watch(() => show.value, (value) => {
            if (!value) {
                store.commit('massActionsModals/setProperty', [
                    {
                        key: 'showVisualizarDetalhesContasAPagarModal',
                        value: false
                    }
                ])
                
                if(document.getElementsByTagName('body')[0].attributes['data-bs-padding-right'])
                document.getElementsByTagName('body')[0].attributes.removeNamedItem('data-bs-padding-right')
            }
        })
        function onKeyUp(event) {
            if (event.key === 'ArrowRight') {
                if(currentDataIndex.value < data.value.length - 1){
                    return nextData()
                }
            }
            if (event.key === 'ArrowLeft') {
                if(currentDataIndex.value > 0){
                    return previousData()
                }
            }
            if(event.ctrlKey && event.key === 'Enter'){
                return approvePayment()
            }
            const approveButton = document.getElementsByClassName('confirm-approve-payment')[0]
            if(!event.ctrlKey && event.key === 'Enter' && approveButton){
                approveButton.click()
            }
            if(event.key === 'Escape' && document.getElementsByClassName('confirm-approve-payment')[0]){
                return Swal.close()
            }
            if(event.key === 'Escape' && !document.getElementsByClassName('confirm-approve-payment')[0]){
                return show.value = false
            }
        }
        async function setDocumentsVisualization(){
            slideDocuments.documents = []
            if(!data.value[currentDataIndex.value]?.sis_documento.registros?.length) return
            if(!visualizarDocumentosFunction.value) {
                Swal.fire({
                    icon: "error",
                    text: "Funcionalidade visualizar documentos não habilitada para este programa!",
                    confirmButtonColor: "#34c38f",
                    confirmButtonText: "OK",
                });
                return
            }
            slideDocuments.loading = true;
            const body = {
                id_programa: store.state.massActionsModals.programSchema.id_programa,
                condicao_adicional: "",
                lista_id_empresa: JSON.parse(localStorage.getItem('companiesGlobalFilter')) ?? [],
                data_trabalho: JSON.parse(localStorage.getItem('dataTrabalho')),
                navegador: false,
                ordenacao: null,
                quantidade_registros: 0,
                pagina: 0,
                rota: store.state.massActionsModals.programSchema.rota_backend + visualizarDocumentosFunction.value.rota_backend,
                dados: {
                    sis_documento: {
                        registros: data.value[currentDataIndex.value].sis_documento.registros.map(doc => {
                            return {
                                id_documento: doc.id_documento,
                                nome_documento: doc.nome_documento,
                                link_documento: doc.link_documento
                            }
                        })
                    }
                }
            }

            const res = await sendFunctionRequest(body,
                null,
                (error) => {
                    Swal.fire({
                        icon: "error",
                        text: error,
                        confirmButtonColor: "#34c38f",
                        confirmButtonText: "OK",
                    });
                }, 
                false,
            )
            slideDocuments.documents = res.data.retorno.dados.sis_documento.registros
            slideDocuments.loading = false
        }
        const prevSlide = () => {
            if (slideDocuments.currentIndex > 0) {
                slideDocuments.currentIndex--;
            } else if (slideDocuments.currentIndex == 0) {
                slideDocuments.currentIndex = slideDocuments.documents.length - 1;
            }
        }
        const nextSlide = () => {
            if (slideDocuments.currentIndex < slideDocuments.documents.length - 1) {
                slideDocuments.currentIndex++;
            } else if (slideDocuments.currentIndex == slideDocuments.documents.length - 1) {
                slideDocuments.currentIndex = 0;
            }
        }
        const nextData = () => {
            if(currentDataIndex.value < data.value.length - 1){
                currentDataIndex.value++;
                slideDocuments.currentIndex = 0;
                setDocumentsVisualization();
                createGridTable();
                setMessages();
            }else if(currentDataIndex.value === data.value.length - 1){
                currentDataIndex.value = 0;
                slideDocuments.currentIndex = 0;
                setDocumentsVisualization();
                createGridTable();
                setMessages();
            }
        }
        const previousData = () => {
            if(currentDataIndex.value > 0){
                currentDataIndex.value--;
                slideDocuments.currentIndex = 0;
                setDocumentsVisualization();
                createGridTable();
                setMessages();
            }else if(currentDataIndex.value === 0){
                currentDataIndex.value = data.value.length - 1;
                slideDocuments.currentIndex = 0;
                setDocumentsVisualization();
                createGridTable();
                setMessages();
            }
        }
        const approvePayment = async () => {
            loadingApprovement.value = true;
            const body = {
                id_programa: store.state.massActionsModals.programSchema.id_programa,
                condicao_adicional: "",
                lista_id_empresa: JSON.parse(localStorage.getItem('companiesGlobalFilter')) ?? [],
                data_trabalho: JSON.parse(localStorage.getItem('dataTrabalho')),
                navegador: false,
                ordenacao: null,
                quantidade_registros: 0,
                pagina: 0,
                rota: store.state.massActionsModals.programSchema.rota_backend + approvePaymentFunction.value.rota_backend,
                dados: {
                    titulo_financeiro: {
                        registros: [{id_titulo_financeiro: data.value[currentDataIndex.value].id_titulo_financeiro}]
                    }
                }
            }
            const res = await sendFunctionRequest(body,
                () => {
                    data.value.splice(currentDataIndex.value, 1);
                    if(data.value.length === 0){
                        show.value = false;
                    }else{
                        if(currentDataIndex.value > data.value.length - 1){
                            currentDataIndex.value = data.value.length - 1;
                        }
                        slideDocuments.currentIndex = 0;
                        setDocumentsVisualization();
                        createGridTable();
                        setMessages();
                    }
                },
                null,
            )
            if(!res){
                Swal.fire({
                    icon: "error",
                    text: "Erro ao aprovar pagamento",
                    confirmButtonColor: "#34c38f",
                    confirmButtonText: "OK",
                });
            }
            loadingApprovement.value = false;
        }
        function createGridTable(){
            document.getElementById('detalhesContasAPagarGrid').innerHTML = ""
            const grid = ref(null)
            const tabela = store.state.massActionsModals.programSchema.sis_tabela.sis_campo_tabela
            .find(sct => sct.id_tabela_detalhe && sct.tipo_campo === 15)?.dados_tabela_detalhe.sis_tabela
            if(!tabela) return

            const dataRegistros = data.value[currentDataIndex.value][tabela.nome_tabela].registros
            if(!dataRegistros) return
            const dataFormated = dataRegistros.map(value => {
                const obj = {}
                tabela.sis_campo_tabela.map(col => {
                    obj[col.nome] = formatColumnValue(col, value)
                })
                return obj
            })

            if(!tabela) return

            const columns = tabela.sis_campo_tabela.filter(sct => sct.exibe_navegador).map(col => {
                return {
                        id: col.nome,
                        name: col.titulo,
                        hidden: col.nome == 'id' ? true : false,
                        formatter: (cell) => {
                        return h('div', {
                            className: '',
                            children: [cell],
                            style: 'white-space:nowrap; text-align: left;padding-left: 2px'
                        })
                    }
                }
            })
            grid.value = new Grid()
            grid.value.updateConfig({
                columns: columns,
                data: [],
                sort: {
                    enabled: true,
                    multiColumn: true,
                },
                resizable: true,
                language: ptBR,
                className: {
                    footer: 'pt-0',
                    td: 'px-1 py-0',
                    th: 'position-relative',
                    table: 'position-relative',
                }
            }).render(document.getElementById('detalhesContasAPagarGrid'))
            grid.value.updateConfig({
                data:  dataFormated
            }).forceRender()
            grid.value.on('ready', () => {
                const targetTable = ref(document.querySelector('#detalhesContasAPagarGrid .gridjs-wrapper .gridjs-table'));
                const observableTargetTable = document.querySelector('#detalhesContasAPagarGrid .gridjs-wrapper .gridjs-table');
                const resizeObserver = new ResizeObserver((entries) => {
                    for (const entry of entries) {
                        if (entry.contentRect && entry.contentRect.width > 0) {
                            makeResizable(targetTable)
                            resizeObserver.unobserve(observableTargetTable);
                        }
                    }
                })
                resizeObserver.observe(observableTargetTable);
            })
        }

        const setMessages = () => {
            const programShema = store.state.massActionsModals.programSchema
            const registerTargetId = data.value[currentDataIndex.value].id_titulo_financeiro
            chatController.value = new ChatBoxController(
                programShema, 
                {titulo_financeiro: {registros: [data.value[currentDataIndex.value]]}}, 
                programShema.id_programa, 
                registerTargetId,
                (new_notes) => {
                    data.value[currentDataIndex.value].sis_anotacao.registros = new_notes
                }
            )
            chatController.value.setMovimentoMessages(data.value[currentDataIndex.value].sis_anotacao?.registros || []);
            handleSubmitMessage.value = (message, id) => chatController.value.handleSubmitMessage(message, id)
            handleDeleteMessage.value = (id) => chatController.value.handleDeleteMessage(id)
        }

        const  movimentoMessages = computed(() => chatController.value?.getMessages() || [])

        return {
            defaultOptions: { animationData: animationData },
            show,
            saveLoading,
            data,
            currentDataIndex,
            slideDocuments,
            isItAPDF: (url) => {
                const regex = /\.pdf$/;
                return regex.test(url);
            },
            prevSlide,
            nextSlide,
            DateTime: DateTime,
            nextData,
            previousData,
            approvePayment,
            loadingApprovement,
            approvePaymentFunction,
            handleSubmitMessage,
            handleDeleteMessage,
            movimentoMessages,
        }
    },
    components: {
        Lottie,
        ChatBox
    },
}
</script>

<template>
    <b-modal v-model="show" fade scrollable centered size="lg" hide-footer hide-header no-enforce-focus no-close-on-esc>
        <div class="modal-content" style="
            width: 95vw;
            height: 95vh;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        ">
            <div class="modal-header">
                <h5 class="modal-title" tabindex="0">Contas a Pagar - Detalhes - <span v-if="data.length">{{currentDataIndex+1}} de {{data.length}}</span></h5>
            </div>
            <div class="modal-body">
                <div class="row" v-if="data.length == 0">
                    <Lottie colors="primary:#405189,secondary:#08a88a" :options="defaultOptions" :height="180" :width="180" />
                    <div class="mt-4 pt-2 text-center">
                    <h5>Não existem registros a serem revisados!</h5>
                    </div>
                </div>
                <div class="row">
                    <template v-if="data.length">
                        <div v-if="slideDocuments.loading" class="col-8">
                            <span class="d-flex flex-column align-items-center justify-content-center gap-2 h-100">
                                <span class="text-primary spinner-border flex-shrink-0" role="status"></span> 
                                <span>Carregando documentos...</span>
                            </span>
                        </div>
                        <div v-else-if="slideDocuments.documents.length > 0" class="col-8">
                            <div id="carouselContasAPagar" class="carousel slide" data-bs-touch="false" data-bs-interval="false">
                                <div class="carousel-inner" role="listbox">
                                    <div v-for="(file, index) in slideDocuments.documents" :key="file.id_documento" class="carousel-item" :class="index == slideDocuments.currentIndex ? ' active' : ''">
                                        <iframe class="d-block img-fluid mx-auto mt-4" style="height: 60vh; width: 100vw" :src="file.link_azure + '#view=fitH'" />
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex mt-2">
                                <p class="flex-1 text-center">{{slideDocuments.documents[slideDocuments.currentIndex].nome_documento}}</p>
                                <span class="fw-semibold">{{slideDocuments.currentIndex+1}} de {{slideDocuments.documents.length}}</span>
                            </div>
                            <a @click="prevSlide" class="carousel-control-prev slider ri-arrow-left-s-line text-white" href="#carouselContasAPagar" role="button" data-bs-slide="prev"> </a>
                            <a @click="nextSlide" class="carousel-control-next slider ri-arrow-right-s-line text-white" href="#carouselContasAPagar" role="button" data-bs-slide="next"></a>
                        </div>
                        <div v-else class="col-8">
                            <span class="d-flex flex-column align-items-center justify-content-center gap-2 h-100">
                                <span class="text-primary flex-shrink-0" role="status">Não existem documentos para visualização</span> 
                            </span>
                        </div>
                    </template>
                    <div class="col-4">
                        <template v-if="data.length">
                            <div class="card border card-border-dark mt-3">
                                <div class="d-flex justify-content-between mt-2 text-center">
                                    <div class="col">
                                        <p style="font-weight: 700; font-size: 15px" class="mb-1">Dados</p>
                                    </div>
                                </div>
                                <div class="d-flex flex-column gap-2 pb-2">
                                    <div class="col mx-2">
                                        <span class="text-muted me-1">Empresa:</span>
                                        <span style="font-weight: 700; font-size: 13px">{{data[currentDataIndex].nome_empresa}}</span>
                                    </div>
                                    <div class="col mx-2">
                                        <span class="text-muted me-1">Credor:</span>
                                        <span style="font-weight: 700; font-size: 13px">{{data[currentDataIndex].nome_credor_devedor}}</span>
                                    </div>
                                    <div class="row">
                                        <div class="col mx-2">
                                            <span class="text-muted me-1">Vencimento:</span>
                                            <span style="font-weight: 700; font-size: 13px">{{DateTime.fromISO(data[currentDataIndex].data_vencimento).toFormat('dd/MM/yyyy')}}</span>
                                        </div>
                                        <div class="col mx-2">
                                            <span class="text-muted me-1">Valor do Título:</span>
                                            <span style="font-weight: 700; font-size: 13px">{{data[currentDataIndex].valor_titulo.toLocaleString('pt-br', {minimumFractionDigits: 2})}}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col mx-2">
                                            <span class="text-muted me-1">Número:</span>
                                            <span style="font-weight: 700; font-size: 13px">{{data[currentDataIndex].numero_titulo}}</span>
                                        </div>
                                        <div class="col mx-2">
                                            <span class="text-muted me-1">Situação:</span>
                                            <span style="font-weight: 700; font-size: 13px">{{data[currentDataIndex].titulo_situacao}}</span>
                                        </div>
                                    </div>

                                    <div></div>

                                    <div class="row">
                                        <div class="col mx-2">
                                            <span class="text-muted me-1">Boleto Registrado:</span>
                                            <span style="font-weight: 700; font-size: 13px">{{data[currentDataIndex].boleto_emitido ? 'Sim' : 'Não'}}</span>
                                        </div>
                                        <div class="col mx-2">
                                            <span class="text-muted me-1">Automático:</span>
                                            <span style="font-weight: 700; font-size: 13px">{{data[currentDataIndex].lancamento_automatico ? 'Sim' : 'Não'}}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col mx-2">
                                            <span class="text-muted me-1">Tipo de Cobrança:</span>
                                            <span style="font-weight: 700; font-size: 13px">{{data[currentDataIndex].tipo_cobranca}}</span>
                                        </div>
                                        <div class="col mx-2">
                                            <span class="text-muted me-1">Data de Pagamentro:</span>
                                            <span style="font-weight: 700; font-size: 13px">{{data[currentDataIndex].data_pagamento ? DateTime.fromISO(data[currentDataIndex].data_pagamento).toFormat('dd/MM/yyyy') : ''}}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 mx-2">
                                            <span class="text-muted me-1">Conta de Liquidação:</span>
                                            <span style="font-weight: 700; font-size: 13px">{{data[currentDataIndex].denominacao_conta_liquidacao}}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 mx-2">
                                            <span class="text-muted me-1">Observação:</span>
                                            <span style="font-weight: 700; font-size: 13px">{{data[currentDataIndex].observacao}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="data[currentDataIndex].id_tipo_cobranca == '1474606972'" class="card border card-border-dark mt-1">
                                <div class="d-flex justify-content-between mt-2 text-center">
                                    <div class="col">
                                        <p style="font-weight: 700; font-size: 15px" class="mb-1">PIX</p>
                                    </div>
                                </div>
                                <div class="d-flex flex-column gap-2 pb-2">
                                    <div class="row">
                                        <div class="col-12 mx-2">
                                            <span class="text-muted me-1">Banco:</span>
                                            <span style="font-weight: 700; font-size: 13px">{{data[currentDataIndex].nome_banco_credor_devedor}}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col mx-2">
                                            <span class="text-muted me-1">Tipo:</span>
                                            <span style="font-weight: 700; font-size: 13px">{{data[currentDataIndex].titulo_tipo_chave_credor_devedor}}</span>
                                        </div>
                                        <div class="col mx-2">
                                            <span class="text-muted me-1">Chave:</span>
                                            <span style="font-weight: 700; font-size: 13px">{{data[currentDataIndex].chave_credor_devedor}}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col mx-2">
                                            <span class="text-muted me-1">Agência:</span>
                                            <span style="font-weight: 700; font-size: 13px">{{data[currentDataIndex].agencia_credor_devedor}}</span>
                                        </div>
                                        <div class="col mx-2">
                                            <span class="text-muted me-1">Número da Conta:</span>
                                            <span style="font-weight: 700; font-size: 13px">{{data[currentDataIndex].numero_conta_credor_devedor}}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col mx-2">
                                            <span class="text-muted me-1">Tipo da Conta:</span>
                                            <span style="font-weight: 700; font-size: 13px">{{data[currentDataIndex].titulo_tipo_conta_credor_devedor}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <div id="detalhesContasAPagarGrid"></div>
                        <p class="text-primary text-center" v-if="!data[currentDataIndex] || !data[currentDataIndex][$store.state.massActionsModals.programSchema?.sis_tabela.sis_campo_tabela
                        .find(sct => sct.id_tabela_detalhe && sct.tipo_campo === 15)?.dados_tabela_detalhe.sis_tabela.nome_tabela]?.registros?.length">Não existem ocorrências para visualização</p>
                    </div>
                </div>
                <div class="chat-container mt-4 mx-auto" style="max-width: 80%;">
                    <ChatBox :chatMessagesData="movimentoMessages" :onSubmitNewMessage="handleSubmitMessage"
                      :onDeleteMessage="handleDeleteMessage" />
                  </div>
            </div>
            <div class="modal-footer border-top">
                <button @click="() => show = false" class="btn btn-primary me-auto" v-b-tooltip.hover.top="'ESC'">Sair</button>
                <button @click="previousData" class="btn btn-light" :disabled="slideDocuments.loading || data.length === 1 || currentDataIndex === 0" v-b-tooltip.hover.top="'<- \'seta esquerda\''"><i class="mdi mdi-arrow-left-thick"></i> Anterior</button>
                <button @click="nextData" class="btn btn-light" :disabled="slideDocuments.loading || data.length === 1 || currentDataIndex === data.length -1" v-b-tooltip.hover.top="'-> \'seta direita\''">Próximo <i class="mdi mdi-arrow-right-thick"></i></button>
                <button v-if="loadingApprovement" class="btn btn-primary btn-load">
                    <span class="spinner-border spinner-border-sm align-top" role="status" aria-hidden="true"></span> Carregando...
                </button>
                <button v-else @click="approvePayment" :disabled="slideDocuments.loading || !approvePaymentFunction" class="btn btn-primary" v-b-tooltip.hover.top="'CTRL + ENTER'">Aprovar Pagamento</button>
            </div>
        </div>
    </b-modal>
</template>

<style scoped>
.slider {
  top: 50%;
  transform: translateY(-50%);
  background-color: #000;
  border-radius: 50%;
  height: 35px;
  width: 35px;
}

iframe {
  zoom: 1;
}
</style>
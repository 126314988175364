<script>
import { computed, defineAsyncComponent, onMounted, ref, watch } from 'vue';
import { makeResizable } from '../../helpers/makeResizable';
import { useOrderBy } from '../tabela_detalhe/orderBy';
import { useRoute } from 'vue-router';
import { sendFunctionRequest } from '../../helpers/dynamicTable/sendFunctionRequest';
import Swal from 'sweetalert2';
import { downloadDocument } from '../../helpers/donwloadDocument';

const CustomInputAsync = defineAsyncComponent(() => import('../custom_input/index'));

export default {
    props: {
        perguntas: {
            type: Array,
            required: true,
        },
        respostas: {
            type: Array,
            required: true,
        },
        formRespostas: {
            type: Object,
            required: true,
        },
        documents: {
            type: Array,
            required: true,
        },
        programSchema: {
            type: Object,
            required: true,
        },
        setShowFileModal: {
            type: Function,
            required: true,
        },
        setFiles: {
            type: Function,
            required: true,
        },
    },
    setup(props){        
        const orderedPerguntasByCategory = [...props.perguntas].sort((a, b) => a.sis_pergunta.ordem_exibicao_categoria - b.sis_pergunta.ordem_exibicao_categoria);
        const categories = getCategories(props.perguntas)
        const groupedPerguntasByCategory = Object.groupBy(orderedPerguntasByCategory, (a) => a.sis_pergunta.titulo_categoria);
        Object.keys(groupedPerguntasByCategory).forEach((key) => {
            groupedPerguntasByCategory[key] = groupedPerguntasByCategory[key].sort((a, b) => a.sis_pergunta.ordem_exibicao - b.sis_pergunta.ordem_exibicao);
        });
        const activeTab = ref('');
        const activePergunta = ref('');
        const tableRef = ref(null);
        const showDocumentsFunction = ref(null)
        const downloadDocumentsFunction = ref(null)
        downloadDocumentsFunction.value = props.programSchema.sis_funcionalidade.find(f => f.id_funcionalidade == 'b9d6d7c6-34e6-44b2-a846-147306fe1c23')
        showDocumentsFunction.value = props.programSchema.sis_funcionalidade.find(f => f.id_funcionalidade == '3e95322a-8f41-4c21-8bfa-9c9908cdbeac')
        const browser_route = useRoute();
        const table_ = ref({
            schema: {
                ...props.formRespostas[0].table_schema,
            },
            forms: props.formRespostas
        })
        const defaultBody = {
            id_programa: browser_route.params.targetId,
            condicao_adicional: "",
            lista_id_empresa: JSON.parse(localStorage.getItem('companiesGlobalFilter')) ?? [],
            data_trabalho: JSON.parse(localStorage.getItem('dataTrabalho')),
            navegador: false,
            ordenacao: null,
            quantidade_registros: 100,
            pagina: 0,
            id: browser_route.query.registro_id,
            dados: {}
        }
        const {
            order ,
            orderBy,
            orderConfig,
            detail_table,
        } = useOrderBy(table_.value)
        order()

        onMounted(() => {
            setTableResizable()

            window.addEventListener('scroll', () => {
                const table = document.getElementById('div-tabela-respostas')
                const columnTable = document.getElementById('column-tabela-respostas')
                const columnTableOffsetTop = getOffsetTop(columnTable)
                
                if(window.scrollY + 65 >= columnTableOffsetTop){
                    table.classList.add('position-fixed')
                    table.style.top = '75px'
                }else if(window.scrollY < columnTableOffsetTop){
                    table.classList.remove('position-fixed')
                    table.style.top ='0px'
                }
            })
        });
        
        function setTableResizable(){
            let targetTable = document.getElementById('table-respostas')
            if(!targetTable) return;
            const resizeObserver = new ResizeObserver((entries) => {
                for (const entry of entries) {
                    if (entry.contentRect && entry.contentRect.width > 0) {
                        makeResizable(tableRef)
                        resizeObserver.unobserve(targetTable);
                    }
                }
            })
            resizeObserver.observe(targetTable);
        }

        function getOffsetTop(element) {
            return element ? (element.offsetTop + getOffsetTop(element.offsetParent)) : 0;
        }
        
        function getCategories(perguntas){
            const cat = perguntas.map(p => {
                return {
                    ordem: p.sis_pergunta.ordem_exibicao_categoria, 
                    titulo: p.sis_pergunta.titulo_categoria
                }
            })
            let distinct = []
            for (let i = 0; i < cat.length; i++) {
                if (!distinct.find(d => d.titulo == cat[i].titulo)){
                    distinct.push({titulo: cat[i].titulo, ordem: cat[i].ordem})
                }
            }

            return distinct.sort((a, b) => a.ordem - b.ordem)
        }

        function changeActivePergunta(id_pergunta_formulario){
            activePergunta.value = id_pergunta_formulario
        }    

        const groupedRespostas = computed(() => {
            return Object.groupBy(detail_table.forms, (a) => a.initialValues.id_pergunta_formulario)[activePergunta.value];
        })

        watch(() => tableRef.value , (newValue) => {
            if (newValue) {
                const resizeObserver = new ResizeObserver((entries) => {
                    for (const entry of entries) {
                        if (entry.contentRect && entry.contentRect.width > 0) {
                            makeResizable(tableRef)
                            resizeObserver.unobserve(tableRef.value);
                        }
                    }
                })
                resizeObserver.observe(tableRef.value);
            }
        })

        const getDocumentByIdResposta = (id_resposta_formulario) => {
            return props.documents.filter(d => d.id_resposta_formulario == id_resposta_formulario)
        }

        const downloadDocuments = async (id_resposta_formulario, documents) => {
            const body = defaultBody
            body.rota = props.programSchema.rota_backend + downloadDocumentsFunction.value.rota_backend,
            body.dados = {
                sis_documento: {
                    registros: documents.map(sd => {
                        return {
                            id_documento: sd.id,
                            link_documento: sd.link_documento,
                            nome_documento: sd.nome_documento,
                            id_empresa: sd.id_empresa,
                        }
                    })
                },
                sis_resposta_formulario: {
                    registros: [
                        {
                            id_resposta_formulario: id_resposta_formulario
                        }
                    ]
                }
            }

            await sendFunctionRequest(body)
            .then((res) => {
                const data = res.data
                if (!data.status) {
                    Swal.fire({
                        icon: "error",
                        text: data.mensagem ? data.mensagem : data.erro,
                        confirmButtonColor: "#34c38f",
                        confirmButtonText: "OK",
                    });
                } else {
                    downloadDocument('Documentos.zip', data.retorno)
                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    text: error.message,
                    confirmButtonColor: "#34c38f",
                    confirmButtonText: "OK",
                });
            });
        }

        const showDocuments = async (id_resposta_formulario, documents) => {
            const body = defaultBody
            body.rota = props.programSchema.rota_backend +  showDocumentsFunction.value.rota_backend,
            body.dados = {
                sis_documento: {
                    registros: documents.map(sd => {
                        return {
                            id_documento: sd.id,
                            link_documento: sd.link_documento,
                            nome_documento: sd.nome_documento,
                            id_empresa: sd.id_empresa,
                        }
                    })
                },
                sis_resposta_formulario: {
                    registros: [
                        {
                            id_resposta_formulario: id_resposta_formulario
                        }
                    ]
                }
            }

            await sendFunctionRequest(body)
            .then((res) => {
                const data = res.data
                if (!data.status) {
                    Swal.fire({
                        icon: "error",
                        text: data.mensagem ? data.mensagem : data.erro,
                        confirmButtonColor: "#34c38f",
                        confirmButtonText: "OK",
                    });
                } else {
                    props.setFiles(data.retorno.dados.sis_documento.registros)
                    props.setShowFileModal(true)
                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    text: error.message,
                    confirmButtonColor: "#34c38f",
                    confirmButtonText: "OK",
                });
            });
        }

        return {
            changeActivePergunta,
            groupedRespostas,
            activeTab,
            tableRef,
            orderBy,
            orderConfig,
            detail_table,
            groupedPerguntasByCategory,
            categories,
            activePergunta,
            getDocumentByIdResposta,
            downloadDocumentsFunction,
            showDocumentsFunction,
            downloadDocuments,
            showDocuments,
        }
    },
    components: {
        CustomInputAsync,
    }
}
</script>
<template>
    <div class="">
        <div class="shadow-sm border-top rounded mb-4 py-4 px-2">
            <div class="row">
                <div class="col-xl-3 col-lg-4">
                    <div class="mt-2 bg-white accordion border border-bottom-0" role="tablist">
                        <div class="">
                            <div v-for="category, index in categories.filter(c => c.titulo !== null)" :key="index" class="border-bottom">
                                <div role="tab" class="rounded-0">
                                    <h2 class="accordion-header">
                                        <button @click="() => activeTab = category.titulo" class="p-3 ps-3 bg-transparent border-0 shadow-none accordion-button" type="button" v-b-toggle="'flush-'+category.titulo.split(' ').join('')">
                                            <span class="text-uppercase fs-12 fw-medium text-muted">{{category.titulo}}</span>
                                        </button>
                                    </h2>
                                </div>
                                <b-collapse :visible="activeTab === category.titulo" :id="'flush-'+category.titulo.split(' ').join('')" accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                        <div class="d-flex flex-column gap-2 ">
                                            <ul class="list-unstyled mb-0">
                                                <li v-for="pergunta, index in groupedPerguntasByCategory[category.titulo]" :key="index" class="d-flex">
                                                    <button type="button" @click="changeActivePergunta(pergunta.sis_pergunta.id_pergunta_formulario)" :class="['flex-1 py-1 border border-dashed my-1 text-right', activePergunta === pergunta.sis_pergunta.id_pergunta_formulario ? 'bg-light border-secondary' : 'bg-transparent border-muted']">
                                                        <div class="flex-grow-1 text-start">
                                                            <h5 :class="['fs-13 mb-0 mx-1 p-1 ms-3', activePergunta === pergunta.sis_pergunta.id_pergunta_formulario ? 'text-secondary' : '']">{{pergunta.sis_pergunta.titulo}}</h5>
                                                        </div>
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </b-card-body>
                                </b-collapse>
                            </div>
                            <div class="border-bottom" v-if="categories.find(c => c.titulo == null)">
                                <div role="tab" class="rounded-0">
                                    <h2 class="accordion-header">
                                        <button @click="() => activeTab = 'null'" class="p-3 ps-3 bg-transparent border-0 shadow-none accordion-button" v-b-toggle="'flush-null'" type="button">
                                            <span class="text-uppercase fs-12 fw-medium text-muted">Avulso</span>
                                        </button>
                                    </h2>
                                </div>
                                <b-collapse :visible="activeTab === 'null'" id="flush-null" accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                        <div class="d-flex flex-column gap-2 ">
                                            <div class="d-flex flex-column gap-2 px-3">
                                                <ul class="list-unstyled mb-0">
                                                    <li v-for="pergunta, index in groupedPerguntasByCategory['null']" :key="index" class="d-flex">
                                                        <button type="button" v-b-toggle="'flush-'+pergunta.sis_pergunta.id_pergunta_formulario" @click="changeActivePergunta(pergunta.sis_pergunta.id_pergunta_formulario)" :class="['flex-1 py-1 border border-dashed my-1 text-right', activePergunta === pergunta.sis_pergunta.id_pergunta_formulario ? 'bg-light border-secondary' : 'bg-transparent border-muted']">
                                                            <div class="flex-grow-1 text-start">
                                                                <h5 :class="['fs-13 mb-0 mx-1 p-1 ms-3', activePergunta === pergunta.sis_pergunta.id_pergunta_formulario ? 'text-secondary' : '']">{{pergunta.sis_pergunta.titulo}}</h5>
                                                            </div>
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </b-card-body>
                                </b-collapse>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-9 col-lg-8" id="column-tabela-respostas">
                    <div class="mt-2 bg-white tab-content" v-if="detail_table && groupedRespostas"  id="div-tabela-respostas" style="overflow-x: scroll;">
                        <table  class="table table-bordered table-nowrap" ref="tableRef" id="table-respostas" v-if="groupedRespostas.length !== 0">
                            <thead>
                                <tr>
                                    <th scope="col" class="p-2 position-relative">
                                        Documentos
                                    </th>
                                    <template v-for="column in groupedRespostas[0].table_schema.sis_campo_tabela" :key="column.id_campo_tabela">
                                        <th  scope="col" class="p-2 position-relative" v-if="column.exibe_navegador">
                                            <div class="d-flex gap-1 align-items-center">
                                                <span>{{column.titulo}}</span>
                                                <button 
                                                    @click="orderBy($event, column)" tabindex="-1" type="button" 
                                                    :class="['ms-2', 'gridjs-sort', `gridjs-sort-${orderConfig?.columns.find(c => c.name === column.nome)?.order || 'neutral'}`]"
                                                ></button>
                                            </div>
                                        </th>
                                    </template>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="form in groupedRespostas" :key="form.id">
                                    <td class="p-0">
                                        <div class="d-flex gap-1 justify-content-center" v-if="getDocumentByIdResposta(form.initialValues.id_resposta_formulario)?.length">
                                            <button v-if="downloadDocumentsFunction" @click="downloadDocuments(form.initialValues.id_resposta_formulario, getDocumentByIdResposta(form.initialValues.id_resposta_formulario))" type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-18" v-b-tooltip.hover.top="'Download Documentos'">
                                                <i class="mdi link-primary mdi-file-download align-bottom"></i>
                                            </button>
                                            <button v-if="showDocumentsFunction" @click="showDocuments(form.initialValues.id_resposta_formulario, getDocumentByIdResposta(form.initialValues.id_resposta_formulario))" type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-18" v-b-tooltip.hover.top="'Visualizar Documentos'">
                                                <i class="mdi link-info mdi-file-eye align-bottom"></i>
                                            </button>
                                        </div>
                                    </td>
                                    <td :class="['m-0 p-0 fix-td-height', !column.exibe_navegador ? 'd-none' : '']"
                                        v-for="column in groupedRespostas[0].table_schema.sis_campo_tabela" :key="form.id+column.id_campo_tabela"
                                    >
                                        <CustomInputAsync
                                            :detailTable="true"
                                            :detailTableIdName="groupedRespostas[0].detailTableIdName"
                                            :primaryKeyValue="groupedRespostas[0].mainTablePrimaryKey"
                                            :formState="form"
                                            :dataAlreadyExists="form.dataAlreadyExists"
                                            :formType="'edition'"
                                            :columnSchema="form.getColumnByName(column.nome)"
                                            :showLabel="false"
                                            :input-class="'border-dashed rounded-0 ' + (column.exibe_navegador ? 'needs-validation needs_validation_detail_table_'+ form.table_schema.id_tabela : '')"
                                        />
                                    </td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
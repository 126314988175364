<script>
import { useStore } from 'vuex';
import useProgram from '../../../../composables/program';
import { onMounted, ref } from 'vue';
import CustomInput from '@/components/custom_input/index.vue';
import { useRoute, useRouter } from 'vue-router';
import { sendFunctionRequest } from '../../../../helpers/dynamicTable/sendFunctionRequest';
import Swal from 'sweetalert2';
import { Grid } from 'gridjs';
import ptBR from '../../../../helpers/dynamicTable/ptBrLanguage';
import { makeResizable } from '../../../../helpers/makeResizable';
import { v4 as uuidv4 } from 'uuid';

export default {
    props: {
        program_schema: {
            type: Object,
            required: true,
        },
        dados: {
            type: Object,
            required: true,
        },
        registro_id: {
            type: String,
            required: false,
        },
    },
    setup(props) {
        const browser_route = useRoute();
        const router = useRouter()
        const store = useStore();
        const formType = store.state.programForm.formType;
        const programa = {
            program_schema: props.program_schema,
            dados: props.dados,
            registro_id: props.registro_id
        };
        const showConsultaDadosModal = ref(false)
        const loadingConsultaModal = ref(false)
        const {
            setProgramForm,
            addNewDetailTableForm,
            removeDetailTableForm,
            handleFormSubmit,
            sis_detail_table,
            baseFormModifier,
            loading,
            btnloading
        } = useProgram(formType, programa);

        const callFunction = async (p_function) => {
            const route = p_function.rota_backend;
            const asyncJob = {
                id: uuidv4(),
                title: p_function.denominacao,
                message: 'Operação em andamento...',
                status: 'running'
            }
            loadingConsultaModal.value = true
            store.commit('asyncJobs/addJob', asyncJob)
            const nome_tabela = props.program_schema.sis_tabela.nome_tabela
            const envio = {
                id_programa: props.program_schema.id_programa,
                condicao_adicional: "",
                lista_id_empresa: JSON.parse(localStorage.getItem('companiesGlobalFilter')) ?? [],
                data_trabalho: JSON.parse(localStorage.getItem('dataTrabalho')),
                navegador: false,
                ordenacao: null,
                quantidade_registros: 0,
                pagina: 0,
                dados: {
                    [nome_tabela]: {
                        registros: [
                            {

                                id_consulta: baseFormModifier.value.getValue('id_consulta'),
                                tipo_consulta: baseFormModifier.value.getValue('tipo_consulta'),
                                nome: baseFormModifier.value.getValue('nome'),
                            }
                        ]
                    }
                },
                rota: props.program_schema.rota_backend + route,
                id: "" + browser_route.query.registro_id
            }
            await sendFunctionRequest(envio)
                .then((res) => {
                    const data = res.data
                    if (!data.status) {
                        loadingConsultaModal.value = false
                        asyncJob.message = data.mensagem || data.erro
                        asyncJob.status = 'failed'
                        store.commit('asyncJobs/updateJob', { ...asyncJob })
                        Swal.fire({
                            icon: "error",
                            text: data.mensagem ? data.mensagem : data.erro,
                            confirmButtonColor: "#34c38f",
                            confirmButtonText: "OK",
                        });
                    } else {
                        if (p_function.id_funcionalidade == 'aa6e3c22-575c-411b-9217-b9265be3e713') {
                            const dados = data.retorno
                            asyncJob.message = 'Operação concluída!'
                            asyncJob.status = 'done'
                            store.commit('asyncJobs/updateJob', { ...asyncJob })
                            if (dados.length == 0) {
                                Swal.fire({
                                    icon: "info",
                                    text: "Nenhum dado encontrado.",
                                    confirmButtonColor: "#34c38f",
                                    confirmButtonText: "OK",
                                });
                                return
                            }
                            const columns = Object.keys(dados[0])
                            loadingConsultaModal.value = false
                            showConsultaDadosModal.value = true
                            document.getElementById('grid_consulta_wrapper').innerHTML = ''
                            const grid = new Grid()
                            grid.updateConfig({
                                columns: columns.map(col => { return { name: col, minWidth: '110px' } }),
                                data: [],
                                pagination: { enabled: true, limit: 10 },
                                search: true,
                                language: ptBR,
                                className: {
                                    footer: 'pt-0',
                                    pagination: 'text-end',
                                    paginationSummary: 'mb-1 fs-12',
                                    td: 'px-1 py-0',
                                    th: 'position-relative',
                                    paginationButton: 'px-2 py-1',
                                    table: 'position-relative',
                                },
                                style: {
                                    container: {
                                        display: 'flex', flexDirection: 'column',
                                    },
                                }
                            }).render(document.getElementById('grid_consulta_wrapper'))
                            grid.updateConfig({
                                data: dados.map((row) => columns.map(col => row[col]))
                            }).forceRender()

                            grid.on('ready', () => {
                                const targetTable = ref(document.querySelector('#grid_consulta_wrapper .gridjs-wrapper .gridjs-table'));
                                const observableTargetTable = document.querySelector('#grid_consulta_wrapper .gridjs-wrapper .gridjs-table');
                                const resizeObserver = new ResizeObserver((entries) => {
                                    for (const entry of entries) {
                                        if (entry.contentRect && entry.contentRect.width > 0) {
                                            makeResizable(targetTable)
                                            resizeObserver.unobserve(observableTargetTable);
                                        }
                                    }
                                })
                                resizeObserver.observe(observableTargetTable);
                            })
                        }
                    }
                })
        }

        onMounted(() => {
            setProgramForm();
        });


        return {
            routerBack: () => {
                store.commit('frameworkNavegador/setBeingReturned', true)
                window.history.length > 1 ? router.go(-1) : router.push('/')
            },
            formType,
            loading,
            btnloading,
            handleFormSubmit,
            baseFormModifier,
            sis_detail_table,
            addNewDetailTableForm,
            removeDetailTableForm,
            callFunction,
            showConsultaDadosModal,
            loadingConsultaModal
        };
    },
    components: {
        CustomInput,
    }

}
</script>
<template>
    <div class="row">
        <div class="card">
            <div class="my-2 mb-3">
                <template v-if="loading">
                    <div class="text-center">
                        <b-spinner variant="primary"></b-spinner>
                    </div>
                </template>
                <form v-else @submit.prevent="handleFormSubmit" class="needs-validation" novalidate>
                    <ul class="nav nav-tabs mb-3" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" data-bs-toggle="tab" href="#arrow-program" role="tab"
                                aria-selected="true">
                                <span class="">{{ program_schema.sis_tabela.denominacao }}</span>
                            </a>
                        </li>
                    </ul>

                    <!-- Tab pane base form -->
                    <div class="tab-pane active" id="arrow-program" role="tabpanel">

                        <div class="border p-3 mt-2">
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <div class="mb-3 flex-1">
                                        <CustomInput :formState="baseFormModifier" :formType="formType"
                                            :columnSchema="baseFormModifier.getColumnByName('nome')"
                                            :showLabel="true" />
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="mb-3 flex-1">
                                        <CustomInput :formState="baseFormModifier" :formType="formType"
                                            :columnSchema="baseFormModifier.getColumnByName('id_consulta')"
                                            :showLabel="true" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <div class="mb-3 flex-1">
                                        <CustomInput :formState="baseFormModifier" :formType="formType"
                                            :columnSchema="baseFormModifier.getColumnByName('tipo_consulta')"
                                            :showLabel="true" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="mb-3 flex-1">
                                        <CustomInput :formState="baseFormModifier" :formType="formType"
                                            :columnSchema="baseFormModifier.getColumnByName('consulta')"
                                            :showLabel="true" :textArea="true" :textAreaRowsNumber="12" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex text-end flex-column-reverse d-md-block flex-1 gap-1 ">
                        <a @click="routerBack"
                            class="btn btn-light border shadow-sm mt-1 mt-md-3 mx-sm-0  col-12 col-md-3 col-lg-2"
                            title="Voltar">
                            <i class="ri-arrow-go-back-fill align-bottom me-1"></i> Voltar
                        </a>
                        <button v-if="formType !== 'show' && !loadingConsultaModal && !$props.loading" type="submit"
                            class="ms-auto mx-md-3 mt-3 btn btn-primary shadow-sm col-12 col-md-6 col-lg-2"
                            title="Salvar">
                            <span><i class="ri-save-2-fill align-bottom me-1"></i> {{ (formType == 'creation' ? 'Criar'
                    :
                    'Salvar') }}
                            </span>
                        </button>
                        <b-button
                            v-if="formType !== 'show' && !loadingConsultaModal && !$props.loading && this.$props.program_schema.sis_funcionalidade.find(f => f.id_funcionalidade == 'aa6e3c22-575c-411b-9217-b9265be3e713')?.funcionalidade_habilitada"
                            v-b-tooltip.hover.top="'Obter Dados Consulta SQL'" type="button"
                            class=" mt-3 btn btn-primary shadow-sm col-12 col-md-6 col-lg-2" title="Preview"
                            @click="callFunction(this.$props.program_schema.sis_funcionalidade.find(f => f.id_funcionalidade == 'aa6e3c22-575c-411b-9217-b9265be3e713'))">
                            <span><i
                                    :class="[this.$props.program_schema.sis_funcionalidade.find(f => f.id_funcionalidade == 'aa6e3c22-575c-411b-9217-b9265be3e713')?.icone ?? 'ri-quill-pen-fill', 'align-bottom link-light']" />
                                Consultar</span>
                        </b-button>

                        <button v-if="formType !== 'show' && ($props.loading || loadingConsultaModal)"
                            class="ms-auto mt-3 btn btn-primary btn-load shadow-sm col-12 col-md-6 col-lg-3">
                            <span class="d-flex align-items-center justify-content-center gap-2">
                                <span class="spinner-border flex-shrink-0" role="status"></span> Carregando
                            </span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <b-modal id="consultaDadosModal" fade scrollable centered size="xl" hide-header hide-footer
        v-model="showConsultaDadosModal">
        <div class="card-body">
            <div class="row">
                <div class="col-12">
                    <div id="grid_consulta_wrapper"></div>
                </div>
            </div>
        </div>
    </b-modal>
</template>
<script>
import moment from 'moment';
import { computed, onMounted, reactive, ref } from 'vue';
import { sendFunctionRequest } from '@/helpers/dynamicTable/sendFunctionRequest';
import { useRoute, useRouter } from 'vue-router';
import Swal from 'sweetalert2';
import { getListValueIdByFunctionalityId } from '@/helpers/static_get_value_list_id_by_function_id';
import { getValueListById } from '@/helpers/forms/getListaValorById';
import { useStore } from 'vuex';
import uploadFile from '@/components/file/upload-file.vue';
import CKEditor from "@ckeditor/ckeditor5-vue"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { downloadDocument } from '@/helpers/donwloadDocument';
import putPrimaryKeyInFirstIndex from '@/helpers/putPrimaryKeyInFirstIndex';
import { ApiService } from '@/helpers/api.service';
import FormularioResposta from '@/views/formularios/insight/gerenciamento/formulario_resposta.vue';
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Portuguese as PortugueseLocale } from "flatpickr/dist/l10n/pt";
import CommentComponent from './comment_component.vue';

export default {
    props: {
        program_schema: {
            type: Object,
            required: true,
        },
        dados: {
            type: Object,
            required: true,
        },
        registro_id: {
            type: String,
            required: false,
        },
    },
    setup(props) {
        const related_program_column_names = [
            'dda_relacionado',
        ]

        const store = useStore()
        const router = useRouter()
        const browser_route = useRoute()
        const programa = {
            program_schema: props.program_schema,
            dados: props.dados,
            registro_id: props.registro_id
        }

        const editor = ClassicEditor
        const editorData = ref('')
        const editorConfig = {
            toolbar: [
                'heading',
                '|',
                'bold',
                'italic',
                'link',
                'bulletedList',
                'numberedList',
                '|',
                'undo',
                'redo',
            ],
            typing: {
                spellCheck: true,
            },
        }

        const editorComment = ClassicEditor
        const editorCommentData = ref('')
        const editorCommentConfig = {
            toolbar: [
                'bold',
                'italic',
                '|',
                'bulletedList',
                'numberedList',
            ],
            typing: {
                spellCheck: true,
            },
        }

        const default_body = {
            id: browser_route.query.registro_id,
            id_programa: browser_route.params.targetId,
            condicao_adicional: "",
            lista_id_empresa: JSON.parse(localStorage.getItem('companiesGlobalFilter')) ?? [],
            data_trabalho: JSON.parse(localStorage.getItem('dataTrabalho')),
            navegador: false,
            ordenacao: null,
            quantidade_registros: 100,
            pagina: 0,
        }
        const showUploadFileModal = ref(false)
        const files = ref([])
        const loadingFileUpload = ref(false)
        const currentIndex = ref(0)

        const overview = reactive({
            rating: null,
            solved: null,
            comment: '',
        })
        const showUpdateDueDateModal = ref(false)

        const function_merge_chamado = ref(programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == 'ed684b56-30f6-440b-8cfa-23c2b4f492a7'))
        const function_update_due_date = ref(programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == 'df6b1c8b-de6c-4fe6-b5f1-e229dc7b86d7'))
        const function_update_last_view = ref(programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == '6c7c2e33-6c14-4b9c-a09f-f0a767841ede'))
        const function_add_form_response = ref(programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == '9fd75089-9219-4fc0-ae85-75b3cebffe4b'))
        const function_update_form_response = ref(programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == '0056c8c9-c9bf-45b9-9cb8-2134decff719'))
        const function_remove_participant = ref(programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == '6c85577f-7d12-4083-9566-1466e59f3d1b' && sf.funcionalidade_habilitada))
        const function_add_participant = ref(programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == 'c36a3b5c-946e-4ea9-9032-a2655ab8abc4' && sf.funcionalidade_habilitada))
        const function_update_assigned_participant = ref(programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == '4fef3e70-5ec0-4347-a1ed-f00dfff05630' && sf.funcionalidade_habilitada))
        const function_add_comment = ref(programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == '10085b65-bc93-45b7-8cf7-373968647559' && sf.funcionalidade_habilitada))
        const function_update_status = ref(programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == '5990ed7d-361f-47bd-9c0b-8cb1fd54b227' && sf.funcionalidade_habilitada))
        const function_update_priority = ref(programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == 'a4200f8f-2c5f-473a-b28d-b07143e4aba4' && sf.funcionalidade_habilitada))
        const function_add_document = ref(programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == 'dd34d8e4-d993-4e6b-8c99-a411a959550a' && sf.funcionalidade_habilitada))
        const function_remove_document = ref(programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == '07fdec1e-448a-4d9a-90ea-4fc6f3e16b6d' && sf.funcionalidade_habilitada))
        const function_show_document = ref(programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == '3e95322a-8f41-4c21-8bfa-9c9908cdbeac' && sf.funcionalidade_habilitada))
        const function_download_document = ref(programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == 'b9d6d7c6-34e6-44b2-a846-147306fe1c23' && sf.funcionalidade_habilitada))
        const function_update_description = ref(programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == '1947c87d-82a4-4e50-9c85-a65885b79978' && sf.funcionalidade_habilitada))
        const function_cancel_chamado = ref(programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == '75938591-48a3-4f33-b7d7-9fe341270f22' && sf.funcionalidade_habilitada))
        const function_reopen_chamado = ref(programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == 'e80b476b-5c6d-4f90-8425-655e446a7213' && sf.funcionalidade_habilitada))
        const function_transfer_departament = ref(programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == 'f1bad18b-eccc-4d91-a462-4bfb75e0f9e3' && sf.funcionalidade_habilitada))
        const function_add_overview = ref(programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == '58c3fd6e-2c93-4f02-ba6c-46ccd76711c2'))
        
        const documentsVersions = ref([])
        const showDocumentVersionsModal = ref(false)
        const sis_usuario_respostas_formulario = ref(null)
        const formProgramSchema = ref(null)
        const formDados = ref(null)
        const overviewForm = ref(null)
        const questions = ref([])
        const responses = ref([])
        const loadingMergeChamado = ref(false)
        const loadingOverview = ref(false)
        const reopen_or_cancel_loading = ref(false)
        const loadingDepartamentTransfer = ref(false)
        const related_records = ref([])
        const showDescriptionEditor = ref(false)
        const current_user = JSON.parse(localStorage.getItem('sis_config')).sis_usuario
        const filesToShow =  ref([])
        const showFileModal = ref(false)
        const loadingNewParticipant = ref(false)
        const loadingAssignedParticipant = ref(false)
        const comment_to_mention = ref(null)
        const loadingNewComment = ref(false)
        const chamado = ref(null)
        const chamado_ocorrencias = ref([])
        const participants = ref([])
        const documents = ref([])
        const groupedDocuments = ref([])
        const statusOptions = ref([])
        const priorityOptions = ref([])
        const statusController = reactive({
            status: null,
            loading: false,
            save: async (event) => {
                statusController.loading = true
                await save_value(event.target.value, 'situacao', () => statusController.status = chamado.value.situacao, function_update_status.value.rota_backend)
                statusController.loading = false

            }
        })
        const priorityController = reactive({
            priority: null,
            loading: false,
            save: async (event) => {
                priorityController.loading = true
                await save_value(event.target.value, 'prioridade', () => priorityController.priority = chamado.value.prioridade, function_update_priority.value.rota_backend)
                priorityController.loading = false
            }
        })
        const dueDateController = reactive({
            dueDate: null,
            loading: false,
            save: async () => {
                dueDateController.loading = true
                await save_value(dueDateController.dueDate, 'data_vencimento', null, function_update_due_date.value.rota_backend, () => {
                    showUpdateDueDateModal.value = false
                })
                dueDateController.loading = false
            }
        })
        const descriptionController = reactive({
            description: null,
            loading: false,
            save: async () => {
                descriptionController.loading = true
                await save_value(descriptionController.description, 'descricao', () => descriptionController.loading = false, function_update_description.value.rota_backend, () => {
                    descriptionController.loading = false
                    showDescriptionEditor.value = false
                })
            }
        })

        function setData(dados){
            if(dados.sis_usuario_respostas_formulario.registros.length){
                sis_usuario_respostas_formulario.value = dados.sis_usuario_respostas_formulario.registros[0]
            }
            chamado.value = dados.chamado.registros[0]
            if(chamado.value.id_formulario){
                if(sis_usuario_respostas_formulario.value){
                    getFormData({ id_registro: sis_usuario_respostas_formulario.value.id_usuario_respostas_formulario, id_programa: '90a28001-b8e4-40e2-acad-1f822840543f' })
                }
                if(!sis_usuario_respostas_formulario.value){
                    getFormData({ id_registro: chamado.value.id_formulario, id_programa: '7a4ff357-f372-4734-9884-58d63cb55785' })
                }
            }
            overviewForm.value = dados.sis_formulario.registros[0]
            questions.value = overviewForm.value.perguntas_json
            responses.value = dados.sis_resposta_formulario.registros
            if(responses.value.length) {
                overview.rating = get_response_by_question_id('17f22123-0ceb-4f39-97db-2a88a5b83fb9').resposta
                overview.solved = get_response_by_question_id('ae2ca625-13e3-44fb-bcbe-8b5df5fc9ecb').resposta == '1ba7c86e-1eb0-4c91-8198-16292b081881'
                overview.comment = get_response_by_question_id('f8b953d7-e221-4fad-9dd2-21cf4bf37d2a').resposta
            }
            chamado_ocorrencias.value = dados.chamado_ocorrencia.registros
            related_records.value = getRelatedRecords(dados)
            participants.value = dados.chamado_usuario.registros.sort(cu => cu.usuario_responsavel ? -1 : 1).map(usuario => {
                return {
                    ...usuario,
                    loading: false,
                }
            })
            documents.value = dados.sis_documento.registros.sort((a, b) => -(a.id_documento>b.id_documento)||+(a.id_documento<b.id_documento)).map(doc => {
                return {
                    ...doc,
                    loading: false,
                }
            })
            groupedDocuments.value = Object.groupBy(documents.value, ({ nome_documento }) => nome_documento)
            statusOptions.value = programa.program_schema.sis_tabela
                .sis_campo_tabela.find(sct => sct.id_campo_tabela == '3ed478b4-51ac-48f5-ab43-f12624d1c32f')
                .sis_lista_edicao.sis_opcao_lista_valor.sort((a, b) => a.id - b.id)
            
            priorityOptions.value = programa.program_schema.sis_tabela
                .sis_campo_tabela.find(sct => sct.id_campo_tabela == 'c9a1ea11-49cb-4a72-bbe8-59f1b594f7f8')
                .sis_lista_edicao.sis_opcao_lista_valor.sort((a, b) => a.id - b.id)

            statusController.status = chamado.value.situacao
            priorityController.priority = chamado.value.prioridade
        }
        async function getFormData({id_programa, id_registro}){
            const body = {
                id_programa: id_programa,
                id: id_registro + '',
                condicao_adicional: "",
                lista_id_empresa: JSON.parse(localStorage.getItem('companiesGlobalFilter')) ?? [],
                data_trabalho: JSON.parse(localStorage.getItem('dataTrabalho')),
                navegador: false,
                ordenacao: null,
                quantidade_registros: 1,
                pagina: 0,
                rota: 'obtem_dados_programa_usuario'
            }
            await ApiService({
                method: 'POST',
                url: `/program`,
                data: body,
                headers: {'authorization': JSON.parse(localStorage.getItem('user')).token}
            }).then(res => {
                const data = res.data;
                if(!data.status){
                    Swal.fire({
                        icon: "error",
                        text: data.mensagem ? data.mensagem : data.erro,
                        confirmButtonColor: "#34c38f",  
                        confirmButtonText: "OK",
                    });
                    return false;
                }
                formDados.value = res.data.retorno.dados
                const fps = res.data.retorno.sis_programa
                fps.sis_tabela.sis_campo_tabela.sort((a,b) => a.ordem_exibicao - b.ordem_exibicao)
                fps.sis_tabela = putPrimaryKeyInFirstIndex(fps.sis_tabela);
                formProgramSchema.value = fps

            }).catch(error => {
                Swal.fire({
                    icon: "error",
                    text: error.response?.data?.mensagem ?? error.message,
                    confirmButtonColor: "#34c38f",  
                    confirmButtonText: "OK",
                });
                return false;
            })
        }

        const system_comments = computed(() => {return chamado_ocorrencias.value.filter(co => ![9, 10].includes(co.tipo)).sort((a, b) => -(a.id_chamado_ocorrencia>b.id_chamado_ocorrencia)||+(a.id_chamado_ocorrencia<b.id_chamado_ocorrencia))})
        const participant_assigned = computed(() => participants.value.find(p => p.usuario_responsavel))
        const get_document_by_regex = (message) => {
            if(!message) return null
            const regex = /#(\d+)/;
            const match = message.match(regex);
            let id = null
            if (match) {
                id = match[1];
            }
            return id ? documents.value.find(doc => doc.id_documento == id) : null
        }
        const comments = computed(() => {
            const data = chamado_ocorrencias.value.filter(co => [9, 10, 12].includes(co.tipo))
            for (let i = 0; i < data.length; i++) {
                if(data[i].tipo == 12){
                    data[i].documment = get_document_by_regex(data[i].mensagem)
                    data[i].loading = false
                }
            }

            const groupedComments = Object.groupBy(data, ({ id_chamado_origem }) => id_chamado_origem);
            return groupedComments
        })

        const mentioned_comment = (id_chamado_ocorrencia_mencionado) => chamado_ocorrencias.value.find(co => co.id_chamado_ocorrencia == id_chamado_ocorrencia_mencionado)
        const removeParticipant = (participant) => {
            Swal.fire({
                text: `Tem certeza que deseja remover "${participant.nome_usuario}" do chamado?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    participant.loading = true
                    const body = default_body
                    body.rota = programa.program_schema.rota_backend + function_remove_participant.value.rota_backend
                    body.dados = {
                        [programa.program_schema.sis_tabela.nome_tabela]: {
                            registros: [
                                {
                                    [props.program_schema.sis_tabela.sis_campo_tabela.find((column) => column.chave_primaria).nome]: browser_route.query.registro_id,
                                },
                            ],
                        },
                        vw_chamado_usuario: {
                            registros: [
                                { id_chamado_usuario: participant.id_chamado_usuario }
                            ]
                        }
                    }
                    await sendFunctionRequest(body,
                            (res) => {
                            const { dados } = res.data.retorno
                            programa.dados = dados
                            setData(dados)
                        },
                        () => {
                            participant.loading = false
                        },
                    )
                }
            })
        }
        
        async function save_value(value, column_name, on_error = null, rota_backend, on_success = null) {
            const body = default_body
            body.dados = {
                [programa.program_schema.sis_tabela.nome_tabela]: {
                    registros: [
                        {
                            [props.program_schema.sis_tabela.sis_campo_tabela.find((column) => column.chave_primaria).nome]: browser_route.query.registro_id,
                            [column_name]: value,
                        },
                    ],
                },
            }
            body.rota = programa.program_schema.rota_backend + rota_backend

            await sendFunctionRequest(body,
                (res) => {
                    if(on_success){
                        on_success()
                    }
                    const { dados } = res.data.retorno
                    programa.dados = dados
                    setData(dados)
                },
                () => {
                    if(on_error){
                        on_error()
                    }
                },
            )
        }

        const handleSendNewComment = async () => {
            loadingNewComment.value = true
            if(editorCommentData.value){
                const body = default_body
                body.rota = programa.program_schema.rota_backend + function_add_comment.value.rota_backend
                body.dados = {
                    [programa.program_schema.sis_tabela.nome_tabela]: {
                        registros: [
                            {
                                [props.program_schema.sis_tabela.sis_campo_tabela.find((column) => column.chave_primaria).nome]: browser_route.query.registro_id,
                            },
                        ],
                    },
                    chamado_ocorrencia: {
                        registros: [
                            {
                                mensagem: editorCommentData.value,
                                id_chamado_ocorrencia_mencionado: comment_to_mention.value?.id_chamado_ocorrencia ?? null,
                            },
                        ],
                    },
                }
                await sendFunctionRequest(body,
                    (res) => {
                        const { dados } = res.data.retorno
                        programa.dados = dados
                        setData(dados)
                        editorCommentData.value = ''
                        if(comment_to_mention.value){
                            comment_to_mention.value = null
                        }
                        setTimeout(() => {
                            const element = document.querySelector('#comments_card .simplebar-content-wrapper')
                            element.scrollTop = element.scrollHeight
                        }, 500)
                    },
                )
            }
            loadingNewComment.value = false
        }

        const addParticipants = () => {
            showListModal({
                maxRows: 999999, 
                function: function_add_participant.value, 
                onInit: () => loadingNewParticipant.value = true, 
                onEnd: () => loadingNewParticipant.value = false, 
                onStartLoadList: () => loadingNewParticipant.value = true, 
                onEndLoadList: () => loadingNewParticipant.value = false
            })
        }
        const showMergeModal = () => {
            showListModal({
                maxRows: 999999, 
                function: function_merge_chamado.value, 
                onInit: () => loadingMergeChamado.value = true, 
                onEnd: () => loadingMergeChamado.value = false, 
                onStartLoadList: () => loadingMergeChamado.value = true, 
                onEndLoadList: () => loadingMergeChamado.value = false,
                preAditionalCondition: 'CAST(TO_JSONB(vw_chamado.*) AS TEXT) ~* '
            })
        }
        const updateDepartament = () => {
            showListModal({maxRows: 1, 
                function: function_transfer_departament.value, 
                onInit: () => loadingDepartamentTransfer.value = true, 
                onEnd: () => loadingDepartamentTransfer.value = false
            })
        }
        const addOrUpdateAssignParticipant = async ({id_usuario}) => {
            const participant = participants.value.find(p => p.id_usuario == id_usuario)
            if(participant) {
                loadingAssignedParticipant.value = true
                participant.loading = true
                const body = default_body
                body.rota = programa.program_schema.rota_backend + function_update_assigned_participant.value.rota_backend
                body.dados = {
                    [programa.program_schema.sis_tabela.nome_tabela]: {
                        registros: [
                            {
                                [props.program_schema.sis_tabela.sis_campo_tabela.find((column) => column.chave_primaria).nome]: browser_route.query.registro_id,
                            },
                        ],
                    },
                    vw_departamento_usuario: {
                        registros: [
                            { id_usuario: participant.id_usuario }
                        ]
                    }
                }
                await sendFunctionRequest(body,
                    (res) => {
                        const { dados } = res.data.retorno
                        programa.dados = dados
                        setData(dados)
                    }
                )
                loadingAssignedParticipant.value = false
                participant.loading = false
            }
            if(!participant) {
                showListModal({maxRows: 1, function: function_update_assigned_participant.value, onInit: () => loadingAssignedParticipant.value = true, onEnd: () => loadingAssignedParticipant.value = false, onStartLoadList: () => loadingAssignedParticipant.value = true, onEndLoadList: () => loadingAssignedParticipant.value = false})
            }
        }

        async function showListModal(properties) {
            const id_funcionalidade = properties.function.id_funcionalidade
            const listValueIdOrNull = getListValueIdByFunctionalityId({ id_funcionalidade: id_funcionalidade, registros: [{id_chamado: browser_route.query.registro_id}] })
            if (!listValueIdOrNull) {
                return Swal.fire({
                icon: "error",
                text: `Funcionalidade com id [${id_funcionalidade}] não possui lista-valor associada`,
                confirmButtonColor: "#34c38f",
                confirmButtonText: "OK",
                });
            }
            properties.onInit()
            const res = await getValueListById(listValueIdOrNull.id_lista_valor, listValueIdOrNull.condicao_adicional)
            properties.onEnd()
            if (!res) {
                Swal.fire({
                    icon: "error",
                    text: 'Erro ao buscar lista de valores',
                    confirmButtonColor: "#34c38f",
                    confirmButtonText: "OK",
                });
                return false
            }
            store.commit('massActionsModals/setProperty', [
                {
                    key: 'showDynamicFilterValueListModal',
                    value: true
                },
                {
                    key: 'sis_lista_valor',
                    value: res.sis_lista_valor
                },
                {
                    key: 'maxRows',
                    value: properties.maxRows
                },
                {
                    key: "preAditionalCondition",
                    value: properties.preAditionalCondition ?? '',
                },
                {
                    key: 'onSave',
                    value: (ids_object) => {
                        const body = default_body
                        body.rota = programa.program_schema.rota_backend + properties.function.rota_backend
                        body.dados = {
                            [programa.program_schema.sis_tabela.nome_tabela]: {
                                registros: [
                                    {
                                        [props.program_schema.sis_tabela.sis_campo_tabela.find((column) => column.chave_primaria).nome]: browser_route.query.registro_id,
                                    },
                                ],
                            }
                        }
                        return sendFunctionRequest(
                            { ...body, dados: { ...body.dados, ...ids_object }},
                            (res) => {
                                const { dados } = res.data.retorno
                                programa.dados = dados
                                setData(dados)
                                
                                properties.onEnd()
                                Swal.fire({
                                    icon: "success",
                                    text: "Sucesso!",
                                    confirmButtonColor: "#34c38f",
                                    confirmButtonText: "OK",
                                });
                            },
                            (error) => {
                                console.log('error', error)
                                properties.onEnd()
                            },
                            true,
                            () => {
                                properties.onInit()
                            }
                        )
                    }
                }
            ])
        }

        const setShowUploadFileModal = () => {
            files.value = []
            showUploadFileModal.value = true
        }

        const saveFiles = async () => {
            const upload = ref([]);

            upload.value = await Promise.all(
                files.value.map((f) => {
                    return readAsDataURL(f);
                })
            );

            loadingFileUpload.value = true;
            const sfunction = function_add_document.value
            const body = {
                id: browser_route.query.registro_id,
                id_programa: browser_route.params.targetId,
                condicao_adicional: "",
                lista_id_empresa: JSON.parse(localStorage.getItem('companiesGlobalFilter')) ?? [],
                data_trabalho: JSON.parse(localStorage.getItem('dataTrabalho')),
                navegador: false,
                ordenacao: null,
                quantidade_registros: 100,
                pagina: 0,
                dados: {
                        sis_documento: {
                        registros: [...upload.value],
                    },
                    [programa.program_schema.sis_tabela.nome_tabela]: {
                        registros: [
                            {
                                [props.program_schema.sis_tabela.sis_campo_tabela.find((column) => column.chave_primaria).nome]: browser_route.query.registro_id,
                            },
                        ],
                    },
                },
                rota: programa.program_schema.rota_backend + sfunction.rota_backend,
            }

            sendFunctionRequest(body).then((res) => {
                if (!res.data?.status) {
                    Swal.fire({
                        icon: "error",
                        text: res.data.mensagem ? res.data.mensagem : res.data.erro,
                        confirmButtonColor: "#34c38f",
                        confirmButtonText: "OK",
                    });
                    loadingFileUpload.value = false;
                } else {
                    Swal.fire({
                        icon: "success",
                        text: "Salvo com sucesso!",
                        confirmButtonColor: "#34c38f",
                        confirmButtonText: "OK",
                    });
                    showUploadFileModal.value = false;
                    loadingFileUpload.value = false;

                    const { dados } = res.data.retorno
                    programa.dados = dados
                    setData(dados)
                }
            });
        }
        function readAsDataURL(file) {
            return new Promise((resolve) => {
                let fileReader = new FileReader();
                fileReader.onload = function () {
                    return resolve({
                        nome_documento: file.name,
                        arquivo_base64: fileReader.result.split(",")[1],
                        tamanho: file.size,
                        descricao: file.descricao,
                        id_empresa: 1
                    });
                };
                fileReader.readAsDataURL(file);
            });
        }

        const showFiles = async (files) => {
            currentIndex.value = 0
            
            const body = default_body
            body.rota = programa.program_schema.rota_backend + function_show_document.value.rota_backend
            body.dados = {
                [programa.program_schema.sis_tabela.nome_tabela]: {
                    registros: [
                        {
                            [props.program_schema.sis_tabela.sis_campo_tabela.find((column) => column.chave_primaria).nome]: browser_route.query.registro_id,
                        },
                    ],
                },
                sis_documento: {
                    registros: files.map(f => {
                        return {
                            id_documento: f.id_documento,
                            id_chamado: f.id_chamado,
                            link_documento: f.link_documento,
                            nome_documento: f.nome_documento,
                            id_empresa: f.id_empresa
                        }
                    })
                }
            }
            await sendFunctionRequest(
                body,
                (res) => {
                    const {registros} = res.data.retorno.dados.sis_documento
                    filesToShow.value = registros
                    showFileModal.value = true
                }
            )
        }

        function prevSlide() {
            if (currentIndex.value > 0) {
                currentIndex.value--
            } else if (currentIndex.value == 0) {
                currentIndex.value = filesToShow.value.length - 1
            }
        }

        function nextSlide() {
            if (currentIndex.value < filesToShow.value.length - 1) {
            currentIndex.value++
            } else if (currentIndex.value == filesToShow.value.length - 1) {
            currentIndex.value = 0
            }
        }

        const remove_document = async (document) => {
            Swal.fire({
                text: `Tem certeza que deseja remover o documento "${document.nome_documento}" do chamado?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    document.loading = true
                    const body = default_body
                    body.rota = programa.program_schema.rota_backend + function_remove_document.value.rota_backend
                    body.dados = {
                        [programa.program_schema.sis_tabela.nome_tabela]: {
                            registros: [
                                {
                                    [props.program_schema.sis_tabela.sis_campo_tabela.find((column) => column.chave_primaria).nome]: browser_route.query.registro_id,
                                },
                            ],
                        },
                        sis_documento: {
                            registros: [
                                {
                                    id_documento: document.id_documento,
                                    id_chamado: document.id_chamado,
                                    link_documento: document.link_documento,
                                    nome_documento: document.nome_documento,
                                    id_empresa: document.id_empresa
                                }
                            ]
                        }
                    }
                    await sendFunctionRequest(
                        body,
                        (res) => {
                            const { dados } = res.data.retorno
                            programa.dados = dados
                            setData(dados)
                        }
                    )
                    document.loading = false      
                }
            })
        }

        const downloadFile = async (data) => {
            const documents = data.documents
            const single = data.single

            const body = default_body
            body.rota = programa.program_schema.rota_backend + function_download_document.value.rota_backend
            body.dados = {
                [programa.program_schema.sis_tabela.nome_tabela]: {
                    registros: [
                        {
                            [props.program_schema.sis_tabela.sis_campo_tabela.find((column) => column.chave_primaria).nome]: browser_route.query.registro_id,
                        },
                    ],
                },
                sis_documento: {
                    registros: [
                        ...documents
                    ]
                }
            }
            await sendFunctionRequest(body,
                (res) => {
                    downloadDocument(`#${chamado.value.id_chamado} - ${chamado.value.titulo}${single ? ' - ' + documents[0].nome_documento : ''}` + '.zip', res.data.retorno)
                }
            )
        }

        const handleEditorDataChange = (value) => {
            descriptionController.description = value
        }
        const setShowDescriptionEditor = () => {
            editorData.value = chamado.value.descricao
            descriptionController.description = chamado.value.descricao
            showDescriptionEditor.value = true
        }

        function getRelatedRecords(dados){
            const related_records = []
            programa.program_schema.sis_tabela.sis_campo_tabela.forEach(column => {
                if(related_program_column_names.includes(column.nome)){
                    const related = dados[column.nome].registros.map(r => {
                        return {
                            id_registro: r.id_registro,
                            rota_registro: r.rota_registro,
                            url_registro: r.url_registro,
                        }
                    })
                    related_records.push(...related)
                }
            })
            return related_records
        }

        const is_gestor = computed(() => {
            const exists = participants.value.find(p => p.id_usuario == current_user.id_usuario && p.usuario_gestor)
            return exists ? true : false
        })

        async function reopen_or_cancel_chamado(reopen = true){
            const body = default_body
            body.rota = `${programa.program_schema.rota_backend}${reopen ? function_reopen_chamado.value.rota_backend : function_cancel_chamado.value.rota_backend}`
            body.dados = {
                [programa.program_schema.sis_tabela.nome_tabela]: {
                    registros: [
                        {
                            [props.program_schema.sis_tabela.sis_campo_tabela.find((column) => column.chave_primaria).nome]: browser_route.query.registro_id,
                        },
                    ],
                },
            }
            reopen_or_cancel_loading.value = true
            await sendFunctionRequest(body,
                (res) => {
                    const { dados } = res.data.retorno
                    programa.dados = dados
                    setData(dados)
                    Swal.fire({
                        icon: "success",
                        text: `Chamado ${reopen ? 'reaberto' : 'cancelado'} com sucesso!`,
                        confirmButtonColor: "#34c38f",
                        confirmButtonText: "OK",
                    });
                }
            )
            reopen_or_cancel_loading.value = false
        }

        async function submitOverview() {
            if(overview.solved === null){
                Swal.fire({
                    icon: "error",
                    text: "Por favor, informe se o chamado foi resolvido ou não.",
                    confirmButtonColor: "#34c38f",
                    confirmButtonText: "OK",
                });
                return
            }
            if(overview.rating < 1 || overview.rating > 5){
                Swal.fire({
                    icon: "error",
                    text: "Por favor, avalie o chamado de 1 a 5.",
                    confirmButtonColor: "#34c38f",
                    confirmButtonText: "OK",
                });
                return
            }
            if(!overview.comment.length && !overview.solved){
                Swal.fire({
                    icon: "error",
                    text: "Por favor, nos informe porque a sua solicitação não foi resolvida.",
                    confirmButtonColor: "#34c38f",
                    confirmButtonText: "OK",
                });
                return
            }
            
            const body = default_body
            body.rota = programa.program_schema.rota_backend + function_add_overview.value.rota_backend
            body.dados = {
                [programa.program_schema.sis_tabela.nome_tabela]: {
                    registros: [
                        {
                            [props.program_schema.sis_tabela.sis_campo_tabela.find((column) => column.chave_primaria).nome]: browser_route.query.registro_id,
                        },
                    ],
                },
                sis_formulario: {
                    registros: [
                        {
                            id_formulario: "6f072712-e512-477b-a622-b73a2f89ceef"
                        }
                    ]
                },
                sis_resposta_formulario: {
                    registros: [
                        {
                            id_pergunta_formulario: "ae2ca625-13e3-44fb-bcbe-8b5df5fc9ecb",
                            id_formulario: "6f072712-e512-477b-a622-b73a2f89ceef",
                            id_empresa: null,
                            resposta: overview.solved ? "1ba7c86e-1eb0-4c91-8198-16292b081881" : "f814f426-152c-44e1-9cad-d7451b12bfce"
                        },
                        {
                            id_pergunta_formulario: "17f22123-0ceb-4f39-97db-2a88a5b83fb9",
                            id_formulario: "6f072712-e512-477b-a622-b73a2f89ceef",
                            id_empresa: null,
                            resposta: overview.rating
                        },
                        {
                            id_pergunta_formulario: "f8b953d7-e221-4fad-9dd2-21cf4bf37d2a",
                            id_formulario: "6f072712-e512-477b-a622-b73a2f89ceef",
                            id_empresa: null,
                            resposta: overview.comment
                        }
                    ]
                }
            }

            loadingOverview.value = true
            await sendFunctionRequest(body,
                (res) => {
                    const { dados } = res.data.retorno
                    programa.dados = dados
                    setData(dados)
                    Swal.fire({
                        icon: "success",
                        text: "Avaliação enviada com sucesso!",
                        confirmButtonColor: "#34c38f",
                        confirmButtonText: "OK",
                    });
                }
            )
            loadingOverview.value = false
        }

        function get_question_by_id(id) {return questions.value.find(q => q.sis_pergunta.id_pergunta_formulario == id)}
        function get_response_by_question_id(id) {return responses.value.find(r => r.id_pergunta_formulario == id)}

        setData(programa.dados)
        onMounted(() => {
            setTimeout(() => {
                const element = document.querySelector('#comments_card .simplebar-content-wrapper')
                element.scrollTop = element.scrollHeight
            }, 500)

            const body = default_body
            body.rota = programa.program_schema.rota_backend + function_update_last_view.value.rota_backend
            body.dados = {
                [programa.program_schema.sis_tabela.nome_tabela]: {
                    registros: [
                        {
                            [props.program_schema.sis_tabela.sis_campo_tabela.find((column) => column.chave_primaria).nome]: browser_route.query.registro_id,
                        },
                    ],
                },
            }
            sendFunctionRequest(body)
        })

        const showDocumentVersions = (groupedDocuments) => {
            documentsVersions.value = groupedDocuments.reverse()
            showDocumentVersionsModal.value = true
        }
        
        return {
            documentsVersions,
            showDocumentVersions,
            showDocumentVersionsModal,
            loadingMergeChamado,
            showMergeModal,
            setData,
            sis_usuario_respostas_formulario,
            default_body,
            editorComment,
            editorCommentData,
            editorCommentConfig,
            loadingOverview,
            overviewForm,
            questions,
            responses,
            get_question_by_id,
            get_response_by_question_id,
            submitOverview,
            overview,
            function_add_overview,
            reopen_or_cancel_loading,
            loadingDepartamentTransfer,
            updateDepartament,
            function_transfer_departament,
            function_reopen_chamado,
            reopen_or_cancel_chamado,
            is_gestor,
            related_records,
            loadingAssignedParticipant,
            loadingNewParticipant,
            loadingNewComment,
            handleSendNewComment,
            chamado,
            chamado_ocorrencias,
            comments,
            mentioned_comment,
            statusOptions,
            priorityOptions,
            statusController,
            priorityController,
            system_comments,
            participants,
            removeParticipant,
            participant_assigned,
            moment,
            function_update_due_date,
            function_add_participant,
            function_remove_participant,
            function_update_assigned_participant,
            function_add_comment,
            function_update_status,
            function_update_priority,
            function_add_document,
            function_remove_document,
            function_show_document,
            function_download_document,
            function_update_form_response,
            function_add_form_response,
            comment_to_mention,
            addParticipants,
            addOrUpdateAssignParticipant,
            documents,
            setShowUploadFileModal,
            showUploadFileModal,
            loadingFileUpload,
            saveFiles,
            files,
            showFileModal,
            filesToShow,
            showFiles,
            currentIndex,
            prevSlide,
            nextSlide,
            current_user,
            remove_document,
            downloadFile,
            function_update_description,
            showDescriptionEditor,
            editor,
            editorData,
            editorConfig,
            handleEditorDataChange,
            descriptionController,
            setShowDescriptionEditor,
            function_cancel_chamado,
            formProgramSchema,
            formDados,
            programa,
            dueDateController,
            showUpdateDueDateModal,
            groupedDocuments,
            routerBack: () => {
                store.commit('frameworkNavegador/setBeingReturned', true)
                window.history.length > 1 ? router.go(-1) : router.push('/')
            },
            dateConfig: {
                enableTime: true,
                altFormat: "d/m/Y H:i",
                altInput: true,
                dateFormat: "Y-m-d H:i:s",
                locale: PortugueseLocale,
                allowInput: true,
                defaultDate: moment(chamado.value.data_vencimento).format("YYYY-MM-DD H:mm:ss"),
            },
        }
    },
    components: {
        uploadFile,
        CKEditor: CKEditor.component,
        FormularioResposta,
        flatPickr,
        CommentComponent,
    }
}
</script>

<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card mt-n4 mx-n4 mb-n5">
                <div class="bg-soft-warning">
                    <div class="card-body pb-4 mb-5">
                        <div class="row">
                            <div class="col-md mx-3">
                                <div class="row align-items-center">
                                    <div class="col-md">
                                        <h4 class="fw-semibold">#{{chamado.id_chamado}} - {{chamado.titulo}}</h4>
                                        <div class="hstack gap-3 flex-wrap">
                                            <div class="text-muted">
                                                <i class="bx bx-map-pin align-middle me-1"></i> {{ chamado.nome_departamento }}
                                            </div>
                                            <div class="vr"></div>
                                            <div class="text-muted">
                                                <i class="bx bx-buildings align-middle me-1"></i> {{ chamado.nome_empresa }}
                                            </div>
                                            <div class="vr"></div>
                                            <div class="text-muted">Criação: <span class="fw-medium">{{moment(chamado.data_inclusao).format("DD MMM, YYYY H:mm")}}</span></div>
                                            <div class="vr"></div>
                                            <div class="text-muted">Vencimento: <span class="fw-medium">{{moment(chamado.data_vencimento).format("DD MMM, YYYY H:mm")}}</span></div>
                                            <div class="vr"></div>
                                            <div class="badge rounded-pill fs-12 bg-primary">{{chamado.titulo_tipo}}</div>
                                            <div :class="['badge rounded-pill fs-12', chamado.situacao == 1 ? 'bg-info' : chamado.situacao == 2 ? 'bg-warning' : chamado.situacao == 3 ? 'bg-primary' : 'bg-dark']">{{chamado.titulo_situacao}}</div>
                                            <div :class="['badge rounded-pill fs-12',  chamado.prioridade == 1 ? 'bg-success' : chamado.prioridade == 2 ? 'bg-secondary' : chamado.prioridade == 3 ? 'bg-warning' : chamado.prioridade == 4 ? 'bg-danger' : 'bg-dark']">{{chamado.titulo_prioridade}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="chamado.situacao == 3 && chamado.id_usuario_inclusao == current_user.id_usuario && function_reopen_chamado" class="my-4">
            <button @click="reopen_or_cancel_chamado(true)" :disabled="reopen_or_cancel_loading" type="button" class="d-block mx-auto btn btn-success btn-label waves-effect waves-light">
                <i v-if="reopen_or_cancel_loading" class="mdi mdi-loading label-icon mdi-spin fs-20 align-middle me-2"></i>
                <i v-else class="mdi mdi-restart label-icon align-middle fs-16 me-2"></i>Reabrir Chamado
            </button>
        </div>
        <div v-if="chamado.situacao == 1 && !chamado_ocorrencias.filter(co => co.tipo !== 17).length && chamado.id_usuario_inclusao == current_user.id_usuario && function_cancel_chamado" class="my-4">
            <button @click="reopen_or_cancel_chamado(false)" :disabled="reopen_or_cancel_loading" type="button" class="d-block mx-auto btn btn-danger btn-label waves-effect waves-light">
                <i v-if="reopen_or_cancel_loading" class="mdi mdi-loading label-icon mdi-spin fs-20 align-middle me-2"></i>
                <i v-else class="mdi mdi-cancel label-icon align-middle fs-16 me-2"></i>Cancelar Chamado
            </button>
        </div>
        <div v-if="overviewForm">            
            <div v-if="!responses.length && current_user.id_usuario == chamado.id_usuario_inclusao && chamado.situacao == 3" class="overview-card card overflow-hidden col-12 col-lg-6 mx-auto bg-light rounded-4 border border-success border-dashed">
                <div>
                    <div class="flex-grow-1 p-3">
                        <h4 class="fs-18 lh-base fw-bold mb-0 text-success">{{overviewForm.titulo}}</h4>
                        <p class="mb-0 mt-2 pt-1 fs-14 text-muted">{{overviewForm.descricao}}</p>
                    </div>
                    <div class="p-3 rounded-5 rounded-bottom card m-0">
                        <p class="mb-2">{{get_question_by_id('ae2ca625-13e3-44fb-bcbe-8b5df5fc9ecb').sis_pergunta.titulo}} <span class="text-danger">*</span></p>
                        <div class="d-flex gap-2">
                            <button @click="() => overview.solved = true" :class="['btn d-flex flex-column text-success border', overview.solved ? 'border border-dashed border-success' : 'border-0']">
                                <i :class="['fs-20 mdi align-middle', overview.solved ? 'mdi-emoticon-excited' : 'mdi-emoticon-excited-outline']"></i>
                                SIM
                            </button>
                            <button @click="() => overview.solved = false" :class="['btn d-flex flex-column text-danger border', overview.solved == false ? 'border border-dashed border-danger' : 'border-0']">
                                <i :class="['fs-20 mdi align-middle', overview.solved == false ? 'mdi-emoticon-angry' : 'mdi-emoticon-angry-outline']"></i>
                                NÃO
                            </button>
                        </div>
                        <div class="border-0 border-top border-dashed my-3"></div>

                        <p class="mb-1">{{get_question_by_id('17f22123-0ceb-4f39-97db-2a88a5b83fb9').sis_pergunta.titulo}} <span class="text-danger">*</span></p>
                        <div class="d-flex text-muted">
                            <button v-for="i in 5" :key="i" @click="() => overview.rating = i" class="d-flex flex-column align-items-center border-0 btn btn-star-rating">
                                <i :class="['fs-20 bx', i <= overview.rating ? 'bxs-star text-warning' : 'bxs-star text-muted']"></i>
                                <span class="text-dark">{{i}}</span>
                            </button>
                        </div>
                        
                        <div class="border-0 border-top border-dashed my-3"></div>
                        <p class="mb-2">{{get_question_by_id('f8b953d7-e221-4fad-9dd2-21cf4bf37d2a').sis_pergunta.titulo}} <span v-if="!overview.solved" class="text-danger">*</span></p>
                        <textarea v-model="overview.comment" class="form-control" rows="3" placeholder="Digite aqui..."></textarea>

                        <div class="d-flex gap-3 mt-4 justify-content-end">
                            <button v-if="loadingOverview" class="ms-2 btn btn-primary btn-load col-2">
                                <span class="spinner-border spinner-border-sm align-top" role="status" aria-hidden="true"></span> Salvar
                            </button>
                            <button v-else @click="submitOverview" class="ms-2 btn btn-primary col-2">Salvar</button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="responses.length" :class="['overview-card card overflow-hidden col-12 col-lg-6 mx-auto rounded-4 border', overview.solved ? 'bg-success' : 'bg-danger']">
                <div :class="[overview.solved ? 'bg-marketplace' : '']">
                    <div class="flex-grow-1 p-3">
                        <h4 class="fs-18 lh-base fw-bold mb-0 text-light">Avaliação final do solicitante</h4>
                        <p class="mb-0 mt-2 pt-1 fs-14 text-light">{{get_response_by_question_id('f8b953d7-e221-4fad-9dd2-21cf4bf37d2a').resposta_formatada}}</p>
                    </div>
                    <div :class="['p-3 pb-3 rounded-5 rounded-bottom card m-0 d-flex flex-row justify-content-center gap-5', overview.solved ? 'bg-marketplace' : '']">
                        <div class="d-flex flex-column align-items-center">
                            <p class="mb-3 text-mutted">O problema foi resolvido?</p>
                            <div :class="['rounded pt-4 w-fit-content', overview.solved ? 'bg-soft-success' : 'bg-soft-danger']">
                                <div :class="['d-flex flex-column text-white pt-3 pb-1 px-4 position-relative rounded-bottom', overview.solved ? 'bg-success' : 'bg-danger']">
                                    <div :class="['text-center position-absolute px-1 rounded-circle', overview.solved ? 'bg-success' : 'bg-danger']" style="top: -15px;left: 50%;width: fit-content;transform: translate(-50%);">
                                        <i :class="['fs-20 mdi align-middle', overview.solved ? 'mdi-emoticon-excited' : 'mdi-emoticon-angry']"></i>
                                    </div>
                                    <span class="">{{ overview.solved ? 'Resolvido' : 'Não' }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-column align-items-center">
                            <p class="mb-3">Nota do atendiemento</p>
                            <div class="d-flex text-muted gap-2">
                                <div v-for="i in 5" :key="i" class="d-flex flex-column align-items-center border-0">
                                    <i :class="['fs-20 bx', i <= overview.rating ? 'bxs-star text-warning' : 'bxs-star text-muted']"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-xxl-8">
            <ul v-if="chamado.id_formulario" class="nav nav-pills nav-customs nav-primary rounded-top" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" data-bs-toggle="tab" href="#chamado_info"
                        role="tab">Chamado</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab" href="#chamado_form"
                        role="tab">Formulário</a>
                </li>
            </ul>
            <div class="card mb-2">
                <div class="tab-content">
                    <div class="tab-pane active" id="chamado_info" role="tabpanel">
                        <div class="m-2">
                            <div class="card-body">
                                <div class="d-flex align-items-center mb-3 gap-2">
                                    <h6 class="fw-semibold text-uppercase mb-0">Descrição</h6>
                                    <i v-if="descriptionController.loading" class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i>
                                    <div v-else-if="function_update_description && current_user.id_usuario == chamado.id_usuario_inclusao && !showDescriptionEditor" @click="setShowDescriptionEditor" class="avatar-group">
                                        <a href="javascript: void(0);" class="avatar-group-item" v-b-tooltip.hover.top="'Alterar descrição'"
                                            data-bs-toggle="tooltip" data-bs-trigger="hover"
                                            data-bs-placement="top" data-bs-original-title="Alterar descrição">
                                            <div class="avatar-xs">
                                                <div
                                                    class="avatar-title fs-16 rounded-circle bg-light border-dashed border text-primary">
                                                    <i  class="mdi mdi-pencil fs-20 align-middle"></i>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div v-if="showDescriptionEditor">
                                    <CKEditor :editor="editor" v-model="editorData" :config="editorConfig" @input="handleEditorDataChange" />
                                    
                                    <div class="d-flex justify-content-end mt-3">
                                        <button v-if="!descriptionController.loading" @click="showDescriptionEditor = false" type="button" class="btn btn-light col-2 me-2">Cancelar</button>
                                        <button v-if="descriptionController.loading" class="btn btn-success btn-load col-2">
                                            <span class="spinner-border spinner-border-sm align-top" role="status" aria-hidden="true"></span> Salvando
                                        </button>
                                        <button v-else @click="descriptionController.save" type="button" class="btn btn-success col-2">Salvar</button>
                                    </div>
                                </div>
                                <div v-else class="bg-light p-2 pt-3 rounded">
                                    <p class="text-muted" v-html="chamado.descricao"></p>
                                </div>
                            </div>

                            <div class="card-body">
                                <h6 class="fw-semibold text-uppercase mb-3">Registros Relacionados</h6>
                                
                                <p v-if="!related_records.length" class="text-muted">Nenhum registro relacionado...</p>
                                <table v-else class="table table-responsive table-centered mb-0 table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Localização no Menu</th>
                                            <th scope="col">#ID</th>
                                            <th scope="col">Ação</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td :rowspan="related_records.length + 1" style="height:1px;">
                                                <div class="h-100 d-flex align-items-center justify-content-center">
                                                    <p>{{related_records[0].rota_registro}}</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-for="related in related_records" :key="related.url_registro">
                                            <td>
                                                {{ related.id_registro }}
                                            </td>
                                            <td>
                                                <a target="_blank" :href="related.url_registro" class="btn btn-soft-primary light btn-sm">Visualizar</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            
                            <div class="card-body p-4" id="comments_card">
                                <h5 class="card-title mb-3">Comentários</h5>

                                <ul v-if="Object.keys(comments).length" class="nav nav-tabs border-0" role="tablist">
                                    <li class="nav-item">
                                      <a class="nav-link active" data-bs-toggle="tab" href="#comments-current" role="tab" aria-selected="true">
                                        <span>Atual</span>
                                      </a>
                                    </li>
                                    <li v-for="mergedId in Object.keys(comments).filter(id => id !== 'null')" :key="mergedId" class="nav-item">
                                      <a class="nav-link" data-bs-toggle="tab" :href="'#comments-' + mergedId" role="tab">
                                        <span class="">#{{ mergedId }}</span>
                                      </a>
                                    </li>
                                </ul>

                                <div class="tab-content">
                                    <div class="tab-pane active" id="comments-current" role="tabpanel">
                                        <div class="px-3 border border-bottom-0 rounded-0 rounded-top">
                                            <div data-simplebar style="height: 400px;" class=" py-2">
                                                <div v-if="!comments.null || !comments.null.length" class="text-center text-muted mt-3 bg-light rounded p-2 mx-auto" style="width:fit-content;">Nenhum comentário adicionado...</div>
                                                <div v-for="comment in comments.null" class="d-flex mb-2" :key="comment.id_chamado_ocorrencia">
                                                    <CommentComponent
                                                        :comment="comment"
                                                        :mentioned_comment="mentioned_comment"
                                                        :function_show_document="function_show_document"
                                                        :function_download_document="function_download_document"
                                                        :function_remove_document="function_remove_document"
                                                        :current_user="current_user"
                                                        :showFiles="showFiles"
                                                        :downloadFile="downloadFile"
                                                        :remove_document="remove_document"
                                                        :comment_to_mention="(value) => comment_to_mention = value"
                                                    />
                                                    <!-- <div class="d-flex flex-column mb-2">
                                                        <div class="d-flex align-items-center gap-3">
                                                            <div style="width:32px;">
                                                                <img :src="'https://api.dicebear.com/9.x/thumbs/svg?seed=' + comment.usuario_inclusao" alt="" class="avatar-xs rounded-circle" />
                                                            </div>
                                                            <h5 class="fs-13 fw-semibold mb-0">{{ comment.usuario_inclusao }} <small class="text-muted">{{moment(comment.data_inclusao).format("DD MMM YYYY - H:mm")}}</small></h5>
                                                        </div>
                                                        <div class="d-flex align-items-center gap-3">
                                                            <div style="width:32px;"></div>
                                                            <div class="flex-grow-1 bg-light rounded">                                    
                                                                <div v-if="mentioned_comment(comment.id_chamado_ocorrencia_mencionado)" class="ms-3 pt-1 px-1 mt-2 mb-2 bg-light rounded opacity-75 border-start border-3">
                                                                    <div class="d-flex mt-4">
                                                                        <div class="flex-shrink-0">
                                                                            <img :src="'https://api.dicebear.com/9.x/thumbs/svg?seed=' + mentioned_comment(comment.id_chamado_ocorrencia_mencionado).usuario_inclusao" alt="" class="avatar-xs rounded-circle" />
                                                                        </div>
                                                                        <div class="flex-grow-1 ms-3">
                                                                            <h5 class="fs-13">{{ mentioned_comment(comment.id_chamado_ocorrencia_mencionado).usuario_inclusao }} <small class="text-muted">{{moment(mentioned_comment(comment.id_chamado_ocorrencia_mencionado).data_inclusao).format("DD MMM YYYY - H:mm")}}</small></h5>
                                                                            
                                                                            <div v-if="mentioned_comment(comment.id_chamado_ocorrencia_mencionado).tipo == 12 && mentioned_comment(comment.id_chamado_ocorrencia_mencionado).documment">
                                                                                <div class="d-flex align-items-center border mb-3 rounded py-1 bg-light">
                                                                                    <div class="flex-shrink-0 avatar-sm border-end">
                                                                                        <div class="avatar-title bg-light">
                                                                                            <i class="mdi mdi-file-document-outline fs-20 text-muted"></i>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="flex-grow-1 mx-3">
                                                                                        <h6 class="mb-1">{{mentioned_comment(comment.id_chamado_ocorrencia_mencionado).documment.nome_documento}}</h6>
                                                                                        <div class="hstack gap-3 justify-content-end">
                                                                                            <span class="text-muted me-auto">{{ (mentioned_comment(comment.id_chamado_ocorrencia_mencionado).documment.tamanho / 1048576).toFixed(2) }} MB</span>
                                                                                            <a v-if="function_show_document" @click="showFiles([mentioned_comment(comment.id_chamado_ocorrencia_mencionado).documment])" href="javascript:void(0);" class="text-muted" v-b-tooltip.hover.top="'Visualizar anexo'"><i class="mdi link-info mdi-file-eye align-bottom fs-16"></i></a>
                                                                                            <a v-if="function_download_document" @click="downloadFile({documents: [mentioned_comment(comment.id_chamado_ocorrencia_mencionado).documment], single: true})" href="javascript:void(0);" class="text-muted" v-b-tooltip.hover.top="'Baixar anexo'"><i class="mdi link-primary mdi-file-download align-bottom fs-16"></i></a>
                                                                                            <i v-if="mentioned_comment(comment.id_chamado_ocorrencia_mencionado).documment.loading" class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i>
                                                                                            <a v-else-if="function_remove_document && mentioned_comment(comment.id_chamado_ocorrencia_mencionado).documment.id_usuario_inclusao == current_user.id_usuario" @click="remove_document(mentioned_comment(comment.id_chamado_ocorrencia_mencionado).documment)" href="javascript:void(0);" class="text-muted" v-b-tooltip.hover.top="'Excluir anexo'"><i class="mdi link-danger mdi-file-remove align-bottom fs-16"></i></a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div v-else class="comment p-1 text-muted" v-html="mentioned_comment(comment.id_chamado_ocorrencia_mencionado).mensagem"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div v-if="comment.tipo == 12 && comment.documment">
                                                                    <div class="d-flex align-items-center border border-bottom-0 py-1">
                                                                        <div class="flex-shrink-0 avatar-sm border-end">
                                                                            <div class="avatar-title bg-light">
                                                                                <i class="mdi mdi-file-document-outline fs-20 text-muted"></i>
                                                                            </div>
                                                                        </div>
                                                                        <div class="flex-grow-1 mx-3">
                                                                            <h6 class="mb-1">{{comment.documment.nome_documento}}</h6>
                                                                            <div class="hstack gap-3 justify-content-end">
                                                                                <span class="text-muted me-auto">{{ (comment.documment.tamanho / 1048576).toFixed(2) }} MB</span>
                                                                                <a v-if="function_show_document" @click="showFiles([comment.documment])" href="javascript:void(0);" class="text-muted" v-b-tooltip.hover.top="'Visualizar anexo'"><i class="mdi link-info mdi-file-eye align-bottom fs-16"></i></a>
                                                                                <a v-if="function_download_document" @click="downloadFile({documents: [comment.documment], single: true})" href="javascript:void(0);" class="text-muted" v-b-tooltip.hover.top="'Baixar anexo'"><i class="mdi link-primary mdi-file-download align-bottom fs-16"></i></a>
                                                                                <i v-if="comment.documment.loading" class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i>
                                                                                <a v-else-if="function_remove_document && comment.documment.id_usuario_inclusao == current_user.id_usuario" @click="remove_document(comment.documment)" href="javascript:void(0);" class="text-muted" v-b-tooltip.hover.top="'Excluir anexo'"><i class="mdi link-danger mdi-file-remove align-bottom fs-16"></i></a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div v-else class="comment p-1 text-muted" v-html="comment.mensagem"></div>
                                                                <button @click="() => comment_to_mention = comment" class=" border-top text-start w-100 border-0 badge text-muted bg-light rounded-0 rounded-bottom"><i class="mdi mdi-reply"></i> Mencionar comentário</button>
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                        <form @submit.prevent="handleSendNewComment" action="javascript:void(0);" class="">
                                            <div class="row g-3">
                                                <div class="col-lg-12" v-if="function_add_comment">
                                                    <!-- <label for="exampleFormControlTextarea1" class="form-label">Adicionar comentário</label> -->
                                                        <div class="bg-light rounded py-1 my-2" v-if="comment_to_mention">
                                                            <div class="d-flex justify-content-between mx-2">
                                                                <span class=" text-muted">Mencionando</span>
                                                                <button @click="comment_to_mention = null" class="badge bg-soft-dark text-muted border-0"><i class="mdi mdi-close fs-14"></i></button>
                                                            </div>
                                                            <div class="comment-to-mention mt-1 ms-2 row g-2 bg-light rounded opacity-75 border-start border-3">
                                                                <div class="d-flex mt-4">
                                                                    <div class="flex-shrink-0">
                                                                        <img :src="'https://api.dicebear.com/9.x/thumbs/svg?seed=' + comment_to_mention.usuario_inclusao" alt="" class="avatar-xs rounded-circle" />
                                                                    </div>
                                                                    <div class="flex-grow-1 ms-3">
                                                                        <h5 class="fs-13">{{ comment_to_mention.usuario_inclusao }} <small class="text-muted">{{moment(comment_to_mention.data_inclusao).format("DD MMM YYYY - H:mm")}}</small></h5>
                                                                        <div class="comment p-1 text-muted" v-html="comment_to_mention.mensagem"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    <div>
                                                        <CKEditor :editor="editorComment" v-model="editorCommentData" :config="editorCommentConfig" />
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 text-end">
                                                    <button type="button" v-if="function_add_document"
                                                        @click="setShowUploadFileModal"
                                                        class="border btn btn-ghost-secondary"
                                                        v-b-tooltip.hover.top="'Anexar Documentos'">
                                                        <i class="link-success ri-attachment-2 align-bottom"></i>
                                                    </button>

                                                    <template v-if="function_add_comment">
                                                        <button v-if="loadingNewComment" class="ms-2 btn btn-success btn-load col-6 col-lg-2">
                                                            <span class="spinner-border spinner-border-sm align-top" role="status" aria-hidden="true"></span> Enviando
                                                        </button>
                                                        <button v-else type="submit" class="ms-2 btn btn-success col-6 col-lg-2">Enviar</button>
                                                    </template>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="tab-pane" v-for="mergedId in Object.keys(comments).filter(id => id !== 'null')" :key="mergedId" :id="'comments-' + mergedId" role="tabpanel">
                                        <div class="px-3 border rounded">
                                            <div data-simplebar style="height: 400px;" class=" py-2">
                                                <div class="text-center text-muted mb-2 bg-light rounded p-2 mx-auto" style="width:fit-content;">Comentários mesclados do chamado #{{mergedId}}</div>
                                                <div v-if="!comments[mergedId] || !comments[mergedId].length" class="text-center text-muted mt-3 bg-light rounded p-2 mx-auto" style="width:fit-content;">Nenhum comentário adicionado...</div>
                                                <div v-for="comment in comments[mergedId]" class="d-flex mb-2" :key="comment.id_chamado_ocorrencia">
                                                    <CommentComponent
                                                        :comment="comment"
                                                        :mentioned_comment="mentioned_comment"
                                                        :function_show_document="function_show_document"
                                                        :function_download_document="function_download_document"
                                                        :function_remove_document="function_remove_document"
                                                        :current_user="current_user"
                                                        :showFiles="showFiles"
                                                        :downloadFile="downloadFile"
                                                        :remove_document="remove_document"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-content">
                    <div class="tab-pane" id="chamado_form" role="tabpanel">
                        <FormularioResposta 
                            v-if="formProgramSchema && formDados"
                            :key="formProgramSchema.id_programa"
                            :program_schema="formProgramSchema" 
                            :dados="formDados" 
                            :registro_id="(sis_usuario_respostas_formulario ? sis_usuario_respostas_formulario.id_usuario_respostas_formulario : chamado.id_formulario) +''"
                            :inModal="true"
                            :onSuccess="setData"
                            :third_party_body="{
                                ...default_body,
                                rota: programa.program_schema.rota_backend + (sis_usuario_respostas_formulario ? function_update_form_response.rota_backend : function_add_form_response.rota_backend),
                            }"
                            :additionalData="{
                                nome_tabela: 'chamado',
                                data: [{ id_chamado: chamado.id_chamado }]
                            }"
                        />
                    </div>
                </div>
            </div>

            <div class="d-flex my-3 justify-content-end">
                <a @click="routerBack" class="ms-auto btn btn-light border shadow-sm mx-sm-0 col-12 col-md-3 col-lg-2" title="Voltar">
                    <i class="ri-arrow-go-back-fill align-bottom me-1"></i> Voltar
                </a>
            </div>
        </div>
        
        <div class="col-xxl-4">
            <div class="card">
                <div class="card-header d-flex align-items-center gap-3">
                    <h5 class="card-title mb-0">Detalhes do Chamado</h5>
                    <i v-if="loadingMergeChamado" class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i>
                    <a v-else-if="is_gestor || participant_assigned?.id_usuario == current_user.id_usuario" @click="showMergeModal" href="javascript: void(0);" class="avatar-group-item" v-b-tooltip.hover.top="'Mesclar chamados'"
                        data-bs-toggle="tooltip" data-bs-trigger="hover"
                        data-bs-placement="top" data-bs-original-title="Add Members">
                        <div class="avatar-xs">
                            <div
                                class="avatar-title fs-16 rounded-circle bg-light border-dashed border text-primary">
                                <i  :class="['fs-20 align-middle', 'mdi mdi-link-variant']"></i>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="card-body">
                    <div class="table-responsive table-card">
                        <table class="table table-borderless align-middle mb-0">
                            <tbody>
                                <tr>
                                    <td class="fw-medium">Número:</td>
                                    <td>{{chamado.id_chamado}}</td>
                                </tr>
                                <tr>
                                    <td class="fw-medium">Tipo de solicitação:</td>
                                    <td>{{chamado.titulo_tipo}}</td>
                                </tr>
                                <tr>
                                    <td class="fw-medium">Solicitante:</td>
                                    <td>{{chamado.usuario_inclusao}}</td>
                                </tr>
                                <tr>
                                    <td class="fw-medium">Categoria:</td>
                                    <td>
                                        <div class="d-flex align-items-center gap-1">
                                            {{chamado.nome_departamento}}
                                            <i v-if="loadingDepartamentTransfer" class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i>
                                            <div v-else-if="is_gestor && function_transfer_departament" class="avatar-group">
                                                <a @click="updateDepartament" href="javascript: void(0);" class="avatar-group-item" v-b-tooltip.hover.top="'Transferir'"
                                                    data-bs-toggle="tooltip" data-bs-trigger="hover"
                                                    data-bs-placement="top" data-bs-original-title="Tranferir o chamado">
                                                    <div class="avatar-xs">
                                                        <div
                                                            class="avatar-title fs-16 rounded-circle bg-light border-dashed border text-primary">
                                                            <i  :class="function_transfer_departament.icone"></i>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="fw-medium">Responsável:</td>
                                    <td>
                                        <div class="avatar-group">
                                            <div v-if="participant_assigned" class="avatar-group-item"
                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                data-bs-trigger="hover" data-bs-original-title="Erica Kernan">
                                                <img :src="'https://api.dicebear.com/9.x/thumbs/svg?seed=' + participant_assigned.nome_usuario" alt="" class="rounded-circle avatar-xs" />
                                            </div>

                                            <i v-if="loadingAssignedParticipant" class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i>
                                            <a v-else-if="participant_assigned?.id_usuario !== current_user.id_usuario" @click="addOrUpdateAssignParticipant({id_usuario: current_user.id_usuario})" href="javascript: void(0);" class="avatar-group-item" v-b-tooltip.hover.top="'Assumir responsabilidade'"
                                                data-bs-toggle="tooltip" data-bs-trigger="hover"
                                                data-bs-placement="top" data-bs-original-title="Add Members">
                                                <div class="avatar-xs">
                                                    <div
                                                        class="avatar-title fs-16 rounded-circle bg-light border-dashed border text-primary">
                                                        <i  :class="['fs-20 align-middle', function_update_assigned_participant.icone]"></i>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <span v-if="participant_assigned" class="text-muted">{{participant_assigned.nome_usuario}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="fw-medium">Situação:</td>
                                    <td>
                                        <i v-if="statusController.loading" class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i>
                                        <select v-else @change="statusController.save" v-model="statusController.status" class="form-select" 
                                            :disabled="!function_update_status || (participant_assigned?.id_usuario !== current_user.id_usuario && !is_gestor)"
                                        >
                                            <option v-for="option in statusOptions" :key="option.id" :value="option.id" :selected="statusController.status == option.id">{{option.titulo}}</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="fw-medium">Prioridade:</td>
                                    <td>
                                        <i v-if="priorityController.loading" class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i>
                                        <select v-else  @change="priorityController.save" v-model="priorityController.priority" class="form-select" :disabled="!function_update_priority || (participant_assigned?.id_usuario !== current_user.id_usuario && !is_gestor)">
                                            <option v-for="option in priorityOptions" :key="option.id" :value="option.id" :selected="priorityController.priority == option.id">{{option.titulo}}</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="fw-medium">Empresa:</td>
                                    <td>{{chamado.nome_empresa}}</td>
                                </tr>
                                <tr>
                                    <td class="fw-medium">Data de Criação:</td>
                                    <td>{{moment(chamado.data_inclusao).format("DD/MM/YYYY H:mm")}}</td>
                                </tr>
                                <tr>
                                    <td class="fw-medium">Data de Vencimento:</td>
                                    <td>
                                        <div class="d-flex align-items-center gap-1">
                                            {{moment(chamado.data_vencimento).format("DD/MM/YYYY H:mm")}}
                                            <i v-if="dueDateController.loading" class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i>
                                            <div v-else-if="is_gestor" @click="() => showUpdateDueDateModal = true" class="avatar-group">
                                                <a href="javascript: void(0);" class="avatar-group-item" v-b-tooltip.hover.top="'Alterar data de vencimento'"
                                                    data-bs-toggle="tooltip" data-bs-trigger="hover"
                                                    data-bs-placement="top" data-bs-original-title="Alterar data de vencimento">
                                                    <div class="avatar-xs">
                                                        <div
                                                            class="avatar-title fs-16 rounded-circle bg-light border-dashed border text-primary">
                                                            <i  class="mdi mdi-pencil fs-20 align-middle"></i>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="fw-medium">Última atualização:</td>
                                    <td v-if="chamado.data_ultima_atualizacao">{{moment(chamado.data_ultima_atualizacao).format("DD/MM/YYYY H:mm")}}</td>
                                </tr>
                                <b-modal v-model="showUpdateDueDateModal" title="Alterar Data de Vencimento" hide-footer>
                                    <div class="">
                                        <div class="form-group">
                                            <label for="dueDate">Data de Vencimento</label>
                                            <flat-pickr v-model="dueDateController.dueDate" :config="dateConfig" class="form-control" placeholder="Selecione a Data de Vencimento" />
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-end mt-3">
                                        <button v-if="dueDateController.loading" class="ms-2 btn btn-primary btn-load col-2">
                                            <span class="spinner-border spinner-border-sm align-top" role="status" aria-hidden="true"></span> Salvar
                                        </button>
                                        <button v-else @click="dueDateController.save" class="ms-2 btn btn-primary col-2">Salvar</button>
                                    </div>
                                </b-modal>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center gap-2">
                        <h6 class="card-title fw-semibold mb-0">Participantes</h6>
                        <i v-if="loadingNewParticipant" class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i>
                        <div v-else-if="function_add_participant && (is_gestor || participant_assigned?.id_usuario == current_user.id_usuario)" @click="addParticipants" class="avatar-group">
                            <a href="javascript: void(0);" class="avatar-group-item"
                                data-bs-toggle="tooltip" data-bs-trigger="hover"
                                data-bs-placement="top" data-bs-original-title="Add Members"
                                v-b-tooltip.hover.top="'Adicionar participante'"
                            >
                                <div class="ms-auto avatar-xs">
                                    <div
                                        class="avatar-title fs-16 rounded-circle bg-light border-dashed border text-primary">
                                        +
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <span class="small text-muted">{{participants.length}} Participantes</span>
                </div>
                <div class="card-body">
                    <div data-simplebar style="height: 300px;" class="px-3 mx-n3">
                        <div v-for="participant in participants" :key="participant.id_usuario" class="mb-2 d-flex align-items-center border border-dashed p-2 rounded">
                            <div style="width:32px;">
                                <img :src="'https://api.dicebear.com/9.x/thumbs/svg?seed=' + participant.nome_usuario" alt="" class="avatar-xs rounded-circle" />
                            </div>
                            <div class="flex-grow-1 ms-3">
                                <h6 class="mb-0">{{participant.nome_usuario}}</h6>
                                <template v-if="participant.usuario_gestor && participant.usuario_responsavel">
                                    <small class="text-muted fw-semibold">Gestor e Responsável</small>
                                </template>
                                <template v-else>
                                    <small v-if="participant.usuario_gestor" class="text-muted fw-semibold">Gestor</small>
                                    <small v-if="participant.usuario_responsavel" class="text-muted fw-semibold">Responsável</small>
                                </template>
                                
                                <small v-if="participant.id_usuario === current_user.id_usuario" class="d-block text-muted">Visto pela última vez: Agora</small>
                                <small v-if="participant.id_usuario !== current_user.id_usuario && participant.data_ultima_leitura" class="d-block text-muted">Visto pela última vez: {{moment(participant.data_ultima_leitura).format("DD/MM/YYYY H:mm")}}</small>
                            </div>

                            <template v-if="participant.loading">
                                <i class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i>
                            </template>
                            <template v-else>
                                <a v-if="is_gestor && participant.id_usuario !== current_user.id_usuario && participant.id_usuario !== participant_assigned?.id_usuario"
                                    @click="addOrUpdateAssignParticipant({id_usuario: participant.id_usuario})"
                                    href="javascript: void(0);" 
                                    class="avatar-group-item me-2" 
                                    v-b-tooltip.hover.top="'Definir participante como responsável'"
                                    data-bs-toggle="tooltip" data-bs-trigger="hover"
                                    data-bs-placement="top">
                                    <div class="avatar-xs">
                                        <div
                                            class="avatar-title fs-16 rounded-circle bg-light border-dashed border text-primary">
                                            <i  :class="['align-middle', function_update_assigned_participant.icone]"></i>
                                        </div>
                                    </div>
                                </a>
                                <a v-if="function_remove_participant && !participant.usuario_gestor && (is_gestor || participant_assigned?.id_usuario == current_user.id_usuario)" 
                                    @click="removeParticipant(participant)"    
                                    href="javascript: void(0);" 
                                    class="avatar-group-item" 
                                    v-b-tooltip.hover.top="'Remover participante'"
                                    data-bs-toggle="tooltip" data-bs-trigger="hover"
                                    data-bs-placement="top">
                                    <div class="avatar-xs">
                                        <div
                                            class="avatar-title fs-16 rounded-circle bg-light border-dashed border text-primary">
                                            <i  :class="['align-middle', function_remove_participant.icone]"></i>
                                        </div>
                                    </div>
                                </a>
                            </template>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center">
                        <h6 class="card-title fw-semibold mb-0 me-2">Anexos</h6>
                        <button v-if="function_show_document" @click="showFiles(documents)" class="btn btn-ghost-secondary btn-icon btn-sm fs-18" v-b-tooltip.hover.top="'Visualizar todos os anexos'"><i class="mdi link-info mdi-file-eye align-bottom"></i></button>
                        <button v-if="function_download_document" @click="downloadFile({documents, single: false})" class="btn btn-ghost-secondary btn-icon btn-sm fs-18" v-b-tooltip.hover.top="'Baixar todos os anexos'"><i class="mdi link-primary mdi-file-download align-bottom"></i></button>
                    </div>
                    <span class="small text-muted">{{documents.length}} Anexo(s)</span>
                </div>
                <div class="card-body">
                    <div data-simplebar style="height: 300px;" class="px-3 mx-n3">
                        <template v-if="groupedDocuments">
                            <template v-for="docName in Object.keys(groupedDocuments)" :key="groupedDocuments[docName].at(-1).id_documento">
                                <div class="d-flex justify-content-end">
                                    <button v-if="groupedDocuments[docName].length > 1" class="ms-auto border-bottom-0 rounded-0 rounded-top px-2 btn btn-primary" style="width:fit-content;" @click="showDocumentVersions(groupedDocuments[docName])">
                                        <small class="">v{{groupedDocuments[docName].length / 10}} | Ver todas as versões</small>
                                    </button>
                                </div>
                                <div class="border border-dashed p-2 rounded mb-2">
                                    <div class="d-flex justify-content-between">
                                        <span class="text-muted fs-10 d-block mb-2 fw-semibold">{{groupedDocuments[docName].at(-1).usuario_inclusao}} | {{moment(groupedDocuments[docName].at(-1).data_inclusao).format("DD/MM/YYYY H:mm")}}</span>
                                        <span class="text-muted fs-10 d-block mb-2 fw-semibold">#{{groupedDocuments[docName].at(-1).id_documento}}</span>
                                    </div>
                                    <div class="d-flex align-items-center ">
                                        <div class="flex-shrink-0 avatar-sm">
                                            <div class="avatar-title bg-light rounded">
                                                <i class="mdi mdi-file-document-outline fs-20 text-muted"></i>
                                            </div>
                                        </div>
                                        <div class="flex-grow-1 ms-3">
                                            <h6 class="mb-1">{{groupedDocuments[docName].at(-1).nome_documento}}</h6>
                                            <small class="text-muted me-2">{{ (groupedDocuments[docName].at(-1).tamanho / 1048576).toFixed(2) }} MB</small>
                                        </div>
                                        <div class="hstack gap-3 fs-16">
                                            <a v-if="function_show_document" @click="showFiles([groupedDocuments[docName].at(-1)])" href="javascript:void(0);" class="text-muted" v-b-tooltip.hover.top="'Visualizar anexo'"><i class="mdi link-info mdi-file-eye align-bottom"></i></a>
                                            <a v-if="function_download_document" @click="downloadFile({documents: [groupedDocuments[docName].at(-1)], single: true})" href="javascript:void(0);" class="text-muted" v-b-tooltip.hover.top="'Baixar anexo'"><i class="mdi link-primary mdi-file-download align-bottom"></i></a>
                                            <i v-if="groupedDocuments[docName].at(-1).loading" class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i>
                                            <a v-else-if="function_remove_document && (groupedDocuments[docName].at(-1).id_usuario_inclusao == current_user.id_usuario || is_gestor)" @click="remove_document(groupedDocuments[docName].at(-1))" href="javascript:void(0);" class="text-muted" v-b-tooltip.hover.top="'Excluir anexo'"><i class="mdi link-danger mdi-file-remove align-bottom"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </template>
                        <!-- <div v-for="doc in documents" :key="doc.id_documento" class="border border-dashed p-2 rounded mb-2">
                            <div class="d-flex justify-content-between">
                                <span class="text-muted fs-10 d-block mb-2 fw-semibold">{{doc.usuario_inclusao}} | {{moment(doc.data_inclusao).format("DD/MM/YYYY H:mm")}}</span>
                                <span class="text-muted fs-10 d-block mb-2 fw-semibold">#{{doc.id_documento}}</span>
                            </div>
                            <div class="d-flex align-items-center ">
                                <div class="flex-shrink-0 avatar-sm">
                                    <div class="avatar-title bg-light rounded">
                                        <i class="mdi mdi-file-document-outline fs-20 text-muted"></i>
                                    </div>
                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <h6 class="mb-1">{{doc.nome_documento}}</h6>
                                    <small class="text-muted">{{ (doc.tamanho / 1048576).toFixed(2) }} MB</small>
                                </div>
                                <div class="hstack gap-3 fs-16">
                                    <a v-if="function_show_document" @click="showFiles([doc])" href="javascript:void(0);" class="text-muted" v-b-tooltip.hover.top="'Visualizar anexo'"><i class="mdi link-info mdi-file-eye align-bottom"></i></a>
                                    <a v-if="function_download_document" @click="downloadFile({documents: [doc], single: true})" href="javascript:void(0);" class="text-muted" v-b-tooltip.hover.top="'Baixar anexo'"><i class="mdi link-primary mdi-file-download align-bottom"></i></a>
                                    <i v-if="doc.loading" class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i>
                                    <a v-else-if="function_remove_document && (doc.id_usuario_inclusao == current_user.id_usuario || is_gestor)" @click="remove_document(doc)" href="javascript:void(0);" class="text-muted" v-b-tooltip.hover.top="'Excluir anexo'"><i class="mdi link-danger mdi-file-remove align-bottom"></i></a>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>

            <div class="card mt-4">
                <div class="card-header d-flex align-items-center justify-content-between">
                    <h6 class="card-title fw-semibold mb-0">Histórico</h6>
                    <span class="small text-muted">{{system_comments.length}} Ocorrências</span>
                </div>
                <div class="card-body">
                    <div data-simplebar style="height: 400px;" class="px-3 mx-n3">
                        <div v-for="sc in system_comments" :key="sc.id_chamado_ocorrencia" class="d-flex align-items-center border border-dashed p-2 mb-2 rounded">
                            <div class="flex-grow-1 ms-3">
                                <span class="text-muted fs-10 d-block mb-2 fw-semibold">{{sc.usuario_inclusao}} | {{moment(sc.data_inclusao).format("DD/MM/YYYY H:mm")}}</span>
                                <h6 class="mb-2"><small>{{sc.titulo_tipo}}</small></h6>
                                <small class="text-muted">
                                    <span v-html="sc.mensagem.replaceAll('\n', '<br>')"></span>
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <b-modal id="documents-versions" title="Todas as versões" fade scrollable centered size="lg" hide-footer
            v-model="showDocumentVersionsModal">
            <table class="table table-sm table-bordered text-center">
                <thead>
                    <tr>
                        <th colspan="5" class="text-center">{{documentsVersions[0]?.nome_documento}}</th>
                    </tr>
                    <tr>
                        <th class="fw-medium">Versão</th>
                        <th class="fw-medium">Descrição</th>
                        <th class="fw-medium">Data de inclusão</th>
                        <th class="fw-medium">Usuário</th>
                        <th class="fw-medium">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="doc, index in documentsVersions" :key="doc.id_documento" class="border-bottom">
                        <td>{{(index + 1) / 10}}</td>
                        <td>{{doc.descricao}}</td>
                        <td>{{moment(doc.data_inclusao).format("DD/MM/YYYY H:mm")}}</td>
                        <td>{{doc.usuario_inclusao}}</td>
                        <td>
                            <div class="d-flex gap-2 justify-content-center">
                                <a v-if="function_show_document" @click="showFiles([doc])" href="javascript:void(0);" class="text-muted" v-b-tooltip.hover.top="'Visualizar anexo'"><i class="mdi link-info mdi-file-eye align-bottom fs-16"></i></a>
                                <a v-if="function_download_document" @click="downloadFile({documents: [doc], single: true})" href="javascript:void(0);" class="text-muted" v-b-tooltip.hover.top="'Baixar anexo'"><i class="mdi link-primary mdi-file-download align-bottom fs-16"></i></a>
                                <i v-if="doc.loading" class="mdi mdi-loading mdi-spin fs-16 align-middle me-2"></i>
                                <a v-else-if="function_remove_document && doc.id_usuario_inclusao == current_user.id_usuario" @click="remove_document(doc)" href="javascript:void(0);" class="text-muted" v-b-tooltip.hover.top="'Excluir anexo'"><i class="mdi link-danger mdi-file-remove align-bottom fs-16"></i></a>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4"></td>
                        <td>
                            <div class="d-flex gap-2 justify-content-center">
                                <a v-if="function_show_document" @click="showFiles(documentsVersions)" href="javascript:void(0);" class="text-muted" v-b-tooltip.hover.top="'Visualizar todos as versões'"><i class="mdi link-info mdi-file-eye align-bottom fs-16"></i></a>
                                <a v-if="function_download_document" @click="downloadFile({documents: documentsVersions})" href="javascript:void(0);" class="text-muted" v-b-tooltip.hover.top="'Baixar todos os anexo'"><i class="mdi link-primary mdi-file-download align-bottom fs-16"></i></a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </b-modal>

        <b-modal id="chmado-upload" title="Adicione Arquivos" fade scrollable centered size="lg" hide-footer
            v-model="showUploadFileModal">
            <uploadFile :updateFile="(data) => (files = data)" />
            <div class="d-grid gap-2">
                <div v-if="loadingFileUpload" class="text-center">
                    <b-spinner variant="success" />
                </div>
                <b-button v-else class="btn btn-sm btn-success" @click="saveFiles()">
                    Salvar
                </b-button>
            </div>
        </b-modal>

        <b-modal id="generateOtpModal" fade scrollable centered size="xl" hide-header hide-footer v-model="showFileModal">
            <div class="modal-content" style="
              width: 95vw;
              height: 95vh;
              position: fixed;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            ">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalScrollableTitle">
                  Anexos
                </h5>
              </div>
              <div class="modal-body">
                <div class="row">
                    <div class="col-12" v-if="filesToShow.length">
                        <div id="carouselExampleControlsNoTouching" class="carousel slide" data-bs-touch="false" data-bs-interval="false">
                            <div class="carousel-inner" role="listbox">
                                <div v-for="(file, index) in filesToShow" :key="file.id_documento" class="carousel-item" :class="index == currentIndex ? ' active' : ''">
                                    <iframe v-if="(/\.pdf$/).test(file.nome_documento)" class="d-block img-fluid mx-auto mt-4" style="height: 60vh; width: 100vw" :src="file.link_azure + '#view=fitH'" />
                                    <img v-else class="d-block img-fluid mx-auto mt-4" style="height: auto; width: 100vw" :src="file.link_azure" />
                                </div>
                            </div>
                        </div>
                        <a @click="prevSlide" class="ms-4 carousel-control-prev slider ri-arrow-left-s-line text-white" href="#carouselExampleControlsNoTouching" role="button" data-bs-slide="prev"></a>
                        <a @click="nextSlide" class="me-4 carousel-control-next slider ri-arrow-right-s-line text-white" href="#carouselExampleControlsNoTouching" role="button" data-bs-slide="next"></a>
                    </div>
                </div>
              </div>
              <div class="modal-footer d-flex flex-column">
                <div class="d-flex justify-content-center" v-if="filesToShow.length">
                    <p class="mb-0">{{filesToShow[currentIndex].nome_documento}}</p>
                    <span class="fw-semibold ms-4">{{currentIndex+1}} de {{filesToShow.length}}</span>
                </div>
                <button @click="showFileModal = !showFileModal" class="btn btn-primary me-auto">Sair</button>
              </div>
            </div>
        </b-modal>
    </div>
</template>

<style @scoped>
.comment-to-mention{
    max-width: 520px;
    transition: all 0.3s;
}
.simplebar-content-wrapper{
    transition: all 0.2s;
}
iframe > img{
    display: none;
    margin: 0 auto;
}
.slider {
    top: 50%;
    transform: translateY(-50%);
    background-color: #000;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    position: fixed;
}
.comment{
    word-break: break-all;
    white-space: pre-line;
}
.comment p {
    margin: 0;
}
.overview-card{
    max-width: 600px;
}
</style>
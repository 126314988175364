import { Grid, h } from "gridjs"
import { RowSelection } from "gridjs/plugins/selection/dist/selection"
import { ref } from "vue"
import formatColumnValue from "./formatColumnValue"
import ptBR from "./ptBrLanguage"
import { makeResizable } from "../makeResizable"

//eslint-disable-next-line
export default function useDynamicTable(
  grid,
  program_schema,
  conciliate,
  updateConciliate,
  conciliarDesconciliar,
  retorno,
  conferirDesconferir
) {
  grid.value = new Grid()
  const currentSelectedRows = ref([])
  const currentDataIds = ref([])
  const currentPageData = ref([])
  const sortedColumns = ref([])
  const gridContainerName = 'table-wrapper'
  const atualizacao = ref(false)
  const dados = ref([])

  let table_schema = program_schema.sis_tabela.sis_campo_tabela.find(campo_tabela => campo_tabela.nome == 'sis_comprovante').dados_tabela_detalhe.sis_tabela
  const functions = program_schema.sis_programa.sis_funcionalidade
  let index = table_schema.sis_campo_tabela.findIndex(coluna => coluna.chave_primaria)

  function formatValue(columnObj, valueObj, functions, conferirDesconferir) {
    return formatColumnValue(columnObj, valueObj, functions, conferirDesconferir)
  }

  function setTableColumns(registros) {
    setSavedSortedColumns(gridContainerName)
    registros.sort(compareByColumns(sortedColumns.value))

    const columns_schema = table_schema.sis_campo_tabela
    const columns = []
    const columnValues = []
    currentPageData.value = registros

    columns_schema.map(col => {
      columns.push({
        minWidth: col.nome == 'conferido' ? '' : '150px',
        id: col.nome,
        name: col.titulo,
        hidden: !col.exibe_navegador,
        allowCopy: true,
        formatter: (cell) => {
          return h('div', {
            className: '',
            children: [cell],
          })
        },
        sort: col.tipo_campo == 7 || col.tipo_campo == 12 ? {
            compare: (a, b) => {
              a = a.toString().replace('.', '')
              a = parseFloat(a.replace(',', '.'))
              b = b.toString().replace('.', '')
              b = parseFloat(b.replace(',', '.'))
              if(a == '') {
                return 1
              }else if(b == '') {
                return -1
              }else if(a > b) {
                return 1
              }else if(a < b) {
                return -1
              }else {
                return 0
              }
            }
          } : {
          enabled: true,
          multiColumn: true,
        },
      })
    })

    columns.unshift(
      {
        id: 'tableCheckbox',
        name: h('button', {
          className: 'w-100 text-primary btn btn-icon fs-22 rounded-0 h-100',
          children: h('i', { className: 'ri-check-double-fill' }),
          onClick: () => {
            selectOrUnselectAllRows(registros);
          }
        }),
        plugin: {
          component: RowSelection,
          props: {
            id: (row) => row.cell(index + 1).data
          }
        },
        sort: false,
        
        attributes: {
          style: 'text-align: center;padding: 0;width:35px;',
        },
        resizable: false,
      },
    )

    grid.value.updateConfig({
      columns: columns,
      data: () => {
        registros.map(value => {
          columnValues.push(
            table_schema.sis_campo_tabela.map(col => {
              if (col.chave_primaria) {
                currentDataIds.value.push(value[col.nome])
              }
              return formatValue(col, value, functions, conferirDesconferir)
            })
          )
        })

        return columnValues
      },
      sort: {
        enabled: true,
        multiColumn: true,
      },
      resizable: true,
      language: ptBR,
      className: {
        footer: 'pt-0',
        td: 'px-1 py-0',
        th: 'position-relative',
        table: 'position-relative',
      }
    }).render(document.getElementById(gridContainerName))

    grid.value.on('ready', () => {
      const targetTable = ref(document.querySelector('#table-wrapper .gridjs-wrapper .gridjs-table'));
      const observableTargetTable = document.querySelector('#table-wrapper .gridjs-wrapper .gridjs-table');
      const resizeObserver = new ResizeObserver((entries) => {
          for (const entry of entries) {
              if (entry.contentRect && entry.contentRect.width > 0) {
                  makeResizable(targetTable)
                  resizeObserver.unobserve(observableTargetTable);
              }
          }
      })
      resizeObserver.observe(observableTargetTable);
  })

    grid.value.on('cellClick', (event, cell, column, row) => {
      if (column.id == 'tableCheckbox') {
        const rowTargetDataID = row.cell(index + 1).data
        const storeSelectedRows = grid.value.config.plugin.get('tableCheckbox').props.store.state.rowIds;
        if (storeSelectedRows.includes(rowTargetDataID)) {
          grid.value.config.dispatcher.dispatch({
            type: 'UNCHECK',
            payload: {
              ROW_ID: rowTargetDataID
            }
          })
        } else {
          grid.value.config.dispatcher.dispatch({
            type: 'CHECK',
            payload: {
              ROW_ID: rowTargetDataID
            }
          })
        }
        updateCurrentSelectedRows();
        return event.preventDefault();
      }

      if (column.allowCopy) {
        return navigator.clipboard.writeText(cell.data);
      }
    })

    registros.sort(compareByColumns(sortedColumns.value))
    sortedColumns.value.forEach((sortedColumn) => {
      const column = document.querySelector(`#${gridContainerName} .gridjs-th-sort[data-column-id="${sortedColumn.columnId}"]`)
      const sortButton = column.querySelector('.gridjs-sort')

      sortButton.classList.add(`gridjs-sort-${sortedColumn.order}`)
    })
    setSortingListeners(gridContainerName)
  }

  function setSortingListeners(gridContainerName) {
    const columns = document.querySelectorAll(`#${gridContainerName} .gridjs-th-sort`)
    columns.forEach((column) => {
      column.addEventListener('click', (event) => {
        const neutrallSortButtons = document.querySelectorAll(`#${gridContainerName} .gridjs-sort-neutral`)
        neutrallSortButtons.forEach((button) => {
          button.classList.remove('gridjs-sort-asc')
          button.classList.remove('gridjs-sort-desc')
        })

        if(!event.shiftKey) {
          sortedColumns.value = []
          saveSortedColumns(gridContainerName)
        }

        const columnId = column.getAttribute('data-column-id')
        const sortButton = column.querySelector('.gridjs-sort')
        if(sortButton.classList.contains('gridjs-sort-asc')) {
          sortedColumns.value = sortedColumns.value.filter((sortedColumn) => sortedColumn.columnId !== columnId)
          sortedColumns.value.unshift({columnId, order: 'asc'})
          saveSortedColumns(gridContainerName)
        }else if(sortButton.classList.contains('gridjs-sort-desc')) {
          sortedColumns.value = sortedColumns.value.filter((sortedColumn) => sortedColumn.columnId !== columnId)
          sortedColumns.value.unshift({columnId, order: 'desc'})
          saveSortedColumns(gridContainerName)
        }else {
          sortedColumns.value = sortedColumns.value.filter((sortedColumn) => sortedColumn.columnId !== columnId)
          saveSortedColumns(gridContainerName)
        }        
      })
    })
  }

  function saveSortedColumns(containerName) {
    localStorage.setItem('sortedColumns-'+containerName, JSON.stringify(sortedColumns.value))
  }

  function setSavedSortedColumns(containerName) {
    const savedSortedColumns = localStorage.getItem('sortedColumns-'+containerName)
    if(savedSortedColumns) {
      sortedColumns.value = JSON.parse(savedSortedColumns)
    }else {
      sortedColumns.value = []
    }
  }

  function compareByColumns(columns) {
    return (a, b) => {
      let result = 0
      columns.forEach((sortedColumn) => {
        const column = table_schema.sis_campo_tabela.find((column) => column.nome === sortedColumn.columnId)
        const columnA = a[column.nome] ?? ''
        const columnB = b[column.nome] ?? ''
        if (columnA > columnB) {
          result = sortedColumn.order === 'asc' ? 1 : -1
        } else if (columnA < columnB) {
          result = sortedColumn.order === 'asc' ? -1 : 1
        }
      })
      return result
    }
  }

  async function updateConfig(registros) {
    registros.sort(compareByColumns(sortedColumns.value))
    atualizacao.value = true
    dados.value = registros
   
    const columnValues = []
    currentDataIds.value = []

    registros.map(value => {
      table_schema.sis_campo_tabela.map(col => {
        if (col.chave_primaria) {
          currentDataIds.value.push(value[col.nome])
        }
      })
    })

    emptyPreviousRowSelections()

    grid.value.updateConfig({
      columns: [
        {
          ...grid.value.config.columns[0],
          plugin: {
            component: RowSelection,
            props: {
              id: (row) => row.cell(index + 1).data
            }
          },
        },
        ...grid.value.config.columns.filter((column, index) => index > 0)
      ],
      data: () => {
        registros.map(value => {
          columnValues.push(
            table_schema.sis_campo_tabela.map(col => {
              if (col.chave_primaria) {
                currentDataIds.value.push(value[col.nome])
              }
              return formatValue(col, value, functions, conferirDesconferir)
            })
          )
        })
        return columnValues
      }
    })

    currentPageData.value = registros

    await grid.value.forceRender()
    sortedColumns.value.forEach((sortedColumn) => {
      const column = document.querySelector(`#${gridContainerName} .gridjs-th-sort[data-column-id="${sortedColumn.columnId}"]`)
      const sortButton = column.querySelector('.gridjs-sort')

      sortButton.classList.add(`gridjs-sort-${sortedColumn.order}`)
    })
    setSortingListeners(gridContainerName)
    retorno(registros)
  }

  function selectOrUnselectAllRows() {
    const checkboxPlugin = grid.value.config.plugin.get('tableCheckbox')
    const selectedRows = checkboxPlugin.props.store.state.rowIds

    if (atualizacao.value) {
      currentDataIds.value = []
      
      dados.value.map(value => {
        table_schema.sis_campo_tabela.map(col => {
          if (col.chave_primaria) {
            currentDataIds.value.push(value[col.nome])
          }
        })
      })
    }

    if (selectedRows.length != currentDataIds.value.length) {
      currentDataIds.value.map(id => {
        grid.value.config.dispatcher.dispatch({
          type: 'CHECK',
          payload: {
            ROW_ID: id
          }
        });
      })
    } else {
      currentDataIds.value.map(id => {
        grid.value.config.dispatcher.dispatch({
          type: 'UNCHECK',
          payload: {
            ROW_ID: id
          }
        });
      })
    }

    updateCurrentSelectedRows();
  }

  /** Clean previous selection state */
  function emptyPreviousRowSelections() {
    currentDataIds.value.map(id => {
      grid.value.config.dispatcher.dispatch({
        type: 'UNCHECK',
        payload: {
          ROW_ID: id
        }
      })
    })

    updateCurrentSelectedRows()
  }

  /**
   * First render of table data, the subsequent renders like "next/prev pagination" will be handled and called by the table itself
   * @param {String} dataUrl 
   */

  function updateCurrentSelectedRows() {
    try {
      currentSelectedRows.value = grid.value.config.plugin.get('tableCheckbox').props.store.state.rowIds
      if (currentSelectedRows.value.length == 1) {
        updateConciliate(currentSelectedRows.value[0])
      } else {
        updateConciliate(null)
      }
      if (conciliate.comprovante_id && conciliate.documento_id) {
        conciliarDesconciliar('22ed9d51-5c41-459c-a23b-577d7cbde4f7')
      }
    } catch (e) {
      currentSelectedRows.value = [];
    }

    return currentSelectedRows.value.length;
  }

  /**
   * 
   * @param {String} functionId 
   * @param {String} targetId 
   * @param {String} route 
   * @param {String} title 
   * @param {String} row 
   * @param {String} htmlTableRow
   * Pass a call to the server of the specified functionality
   */

  function getRowRegisterId(targetId) {
    let id;
    let register;
    table_schema.sis_campo_tabela.forEach(col => {
      if (col.chave_primaria) {
        const data = currentPageData.value.find(data => data[col.nome] == targetId);
        id = data[col.nome];
        register = { [col.nome]: id }
      }
    })

    return { registro_id: id, registro_obj: register };
  }

  function getLinkAndId(targetId) {
    let id
    let link
    let nome
    let register
    table_schema.sis_campo_tabela.forEach(col => {
      if (col.chave_primaria) {
        const data = currentPageData.value.find(data => data[col.nome] == targetId)
        id = data['id_documento']
        link = data['link_documento']
        nome = data['nome_documento']
        register = { ['id_documento']: id, ['link_documento']: link, ['nome_documento']: nome }
      }
    })

    return { registro_id: id, registro_obj: register }
  }

  function updateCurrentDataIds(ids) {
    currentDataIds.value = ids
  }

  return {
    setTableColumns,
    currentDataIds,
    formatValue,
    emptyPreviousRowSelections,
    currentSelectedRows,
    updateCurrentDataIds,
    getRowRegisterId,
    updateCurrentSelectedRows,
    updateConfig,
    getLinkAndId,
    conferirDesconferir
  }
}

<script>
import { useStore } from "vuex";

import useProgram from "../../../../composables/program";
import { onMounted } from "vue";
import TabelaDetalhe from "@/components/tabela_detalhe/index.vue";
import CustomInput from "@/components/custom_input/index.vue";
import previewPainel from "@/components/graficos/previewPainel.vue";
import { useRouter } from "vue-router";
export default {
  props: {
    program_schema: {
      type: Object,
      required: true,
    },
    dados: {
      type: Object,
      required: true,
    },
    registro_id: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const store = useStore();

    const router = useRouter();

    const formType = store.state.programForm.formType;

    const programa = {
      program_schema: props.program_schema,
      dados: props.dados,
      registro_id: props.registro_id,
    };

    const {
      setProgramForm,
      addNewDetailTableForm,
      removeDetailTableForm,
      handleFormSubmit,
      sis_detail_table,
      baseFormModifier,
      loading,
      btnloading,
    } = useProgram(formType, programa);

    function NewOrderCards(newObject) {
      newObject.forEach((val) => {
        let mConfig = sis_detail_table.value
          .find((a) => a.title === "Gráficos")
          .forms.find(
            (y) => y.getValue("id_consulta_painel") == val.id_consulta_painel
          );
        mConfig.setMutatedValues(
          "configuracao_consulta",
          JSON.stringify(val.configuracao_consulta)
        );
      });
    }

    onMounted(() => {
      setProgramForm();
    });

    return {
      routerBack: () => {
        store.commit("frameworkNavegador/setBeingReturned", true);
        window.history.length > 1 ? router.go(-1) : router.push("/");
      },
      formType,
      loading,
      btnloading,
      handleFormSubmit,
      baseFormModifier,
      sis_detail_table,
      addNewDetailTableForm,
      removeDetailTableForm,
      NewOrderCards,
    };
  },
  components: {
    TabelaDetalhe,
    previewPainel,
    CustomInput,
  },
};
</script>
<template>
  <div class="row">
    <div class="card">
      <div class="my-2 mb-3">
        <template v-if="loading">
          <div class="text-center">
            <b-spinner variant="primary"></b-spinner>
          </div>
        </template>
        <form
          v-else
          @submit.prevent="handleFormSubmit"
          class="needs-validation"
          novalidate
        >
          <ul class="nav nav-tabs mb-3" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link active"
                data-bs-toggle="tab"
                href="#arrow-program"
                role="tab"
                aria-selected="true"
              >
                <span class="">{{
                  program_schema.sis_tabela.denominacao
                }}</span>
              </a>
            </li>

            <li
              v-for="detail_table in sis_detail_table"
              :key="detail_table.schema.id_tabela"
              class="nav-item"
            >
              <a
                class="nav-link"
                data-bs-toggle="tab"
                :href="'#arrow-' + detail_table.schema.id_tabela"
                role="tab"
              >
                <span class="">{{ detail_table.title }}</span>
              </a>
            </li>
          </ul>

          <!-- Tab pane base form -->
          <div class="tab-content">
            <div class="tab-pane active" id="arrow-program" role="tabpanel">
              <div class="border p-3 mt-2">
                <div class="row">
                  <div class="col-12 col-md-4">
                    <div class="mb-3 flex-1">
                      <CustomInput
                        :formState="baseFormModifier"
                        :formType="formType"
                        :columnSchema="baseFormModifier.getColumnByName('nome')"
                        :showLabel="true"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="mb-3 flex-1">
                      <CustomInput
                        :formState="baseFormModifier"
                        :formType="formType"
                        :columnSchema="
                          baseFormModifier.getColumnByName('descricao')
                        "
                        :showLabel="true"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="mb-3 flex-1">
                      <CustomInput
                        :formState="baseFormModifier"
                        :formType="formType"
                        :columnSchema="
                          baseFormModifier.getColumnByName(
                            'intervalo_transicao'
                          )
                        "
                        :showLabel="true"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="mb-3 flex-1">
                      <CustomInput
                        :formState="baseFormModifier"
                        :formType="formType"
                        :columnSchema="
                          baseFormModifier.getColumnByName('principal')
                        "
                        :showLabel="true"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md-4">
                    <div class="mb-3 flex-1">
                      <CustomInput
                        :formState="baseFormModifier"
                        :formType="formType"
                        :columnSchema="
                          baseFormModifier.getColumnByName(
                            'id_usuario_inclusao'
                          )
                        "
                        :showLabel="true"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="mb-3 flex-1">
                      <CustomInput
                        :formState="baseFormModifier"
                        :formType="formType"
                        :columnSchema="
                          baseFormModifier.getColumnByName(
                            'id_usuario_alteracao'
                          )
                        "
                        :showLabel="true"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md-4">
                    <div class="mb-3 flex-1">
                      <CustomInput
                        :formState="baseFormModifier"
                        :formType="formType"
                        :columnSchema="
                          baseFormModifier.getColumnByName('data_inclusao')
                        "
                        :showLabel="true"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="mb-3 flex-1">
                      <CustomInput
                        :formState="baseFormModifier"
                        :formType="formType"
                        :columnSchema="
                          baseFormModifier.getColumnByName('data_alteracao')
                        "
                        :showLabel="true"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md-4">
                    <div class="mb-3 flex-1">
                      <CustomInput
                        :formState="baseFormModifier"
                        :formType="formType"
                        :columnSchema="
                          baseFormModifier.getColumnByName('id_painel')
                        "
                        :showLabel="true"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Tab panes for detail tables -->
            <div
              v-for="detail_table in sis_detail_table"
              :key="detail_table.schema.id_tabela"
              :id="'arrow-' + detail_table.schema.id_tabela"
              class="tab-pane"
              role="tabpanel"
            >
              <div class="border p-3 mb-0">
                <TabelaDetalhe
                  :isBtnLoading="btnloading"
                  :formType="formType"
                  :table="detail_table"
                  :mainTableFormModifier="baseFormModifier"
                  @addRow="addNewDetailTableForm"
                  @removeRow="removeDetailTableForm"
                  :programSchema="this.$props.program_schema"
                />
              </div>
            </div>
          </div>

          <div class="d-flex flex-column flex-md-row">
            <slot></slot>
            <div
              class="d-flex text-end flex-column-reverse d-md-block flex-1 gap-1"
            >
              <a
                @click="routerBack"
                class="btn btn-light border shadow-sm mt-1 mt-md-3 col-12 col-md-3 col-lg-2"
                title="Voltar"
              >
                <i class="ri-arrow-go-back-fill align-bottom me-1"></i> Voltar
              </a>
              <button
                v-if="formType !== 'show' && !btnloading"
                type="submit"
                class="ms-auto mx-sm-0 mx-md-3 mt-3 btn btn-primary shadow-sm col-12 col-md-3 col-lg-2"
                title="Salvar"
              >
                <span
                  ><i class="ri-save-2-fill align-bottom me-1"></i>
                  {{ formType == "creation" ? "Criar" : "Salvar" }}
                </span>
              </button>
              <b-button
                v-if="formType !== 'show' && !btnloading"
                type="button"
                id="PreviewPainel"
                class="ms-auto mt-3 btn btn-primary shadow-sm col-12 col-md-3 col-lg-2"
                title="Preview"
                v-b-modal.preview-painel
              >
                <span
                  ><i class="mdi mdi-monitor-dashboard align-bottom me-1"></i>
                  Preview
                </span>
              </b-button>
              <button
                v-if="formType !== 'show' && btnloading"
                class="me-auto mt-3 btn btn-primary btn-load shadow-sm col-12 col-md-6 col-lg-3"
              >
                <span
                  class="d-flex align-items-center justify-content-center gap-2"
                >
                  <span
                    class="spinner-border flex-shrink-0"
                    role="status"
                  ></span>
                  Carregando
                </span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <previewPainel
    v-if="
      !loading &&
      baseFormModifier.getRegisters()?.sis_consulta_painel?.registros.length > 0
    "
    :cards="
      baseFormModifier
        .getRegisters().sis_consulta_painel.registros.sort(
          (a, b) =>
            a.configuracao_consulta.ordem_exibicao -
            b.configuracao_consulta.ordem_exibicao
        )
    "
    :formPanel="baseFormModifier.getValues().dados?.sis_painel.registros[0]"
    @updateNewOrderCards="NewOrderCards"
  >
  </previewPainel>
</template>
<style></style>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";

import ContasPagarComponent from "@/components/financeiro/movimentacao/contas_pagar";

export default {
  components: {
    ContasPagarComponent,
  },
  setup() {
    const store = useStore();
    const financialAccountFormRef = ref(null);
    let loading = ref(false);

    function close() {
      financialAccountFormRef?.value?.clearData()

      store.commit("contasPagar/setShow", false);
    }
    function setShowLoading(value) {
      loading.value = value;
    }
    async function handleSave() {
      try {
        setShowLoading(true)

        const payload = getValuesByForm()

        await store.state.contasPagar.onSave(payload);

        close()
      } catch (error) {
        console.log('error', error)
      }
      finally {
        setShowLoading(false)
      }
    }
    function hasError() {
      return store.state.contasPagar.error;
    }
    function getTitle() {
      return store.state.contasPagar.title;
    }
    function getValuesByForm() {
      const payload = financialAccountFormRef.value.getValuesFromForm()

      return payload
    }

    const showModal = computed({
      get() {
        return store.state.contasPagar.show
      },
      set(value) {
        store.commit('contasPagar/setShow', value);
      }
    });

    return {
      loading,
      handleSave,
      showModal,
      hasError,
      getTitle,
      close,
      financialAccountFormRef
    };
  },
};
</script>
<template>
  <div class="contas-pagar-container">
    <b-modal
      id="bv-modal-contas-pagar"
      v-if="!hasError()"
      size="lg"
      v-model="showModal"
      hideFooter
      centered
      :title="getTitle()"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
    >
      <template v-if="loading">
        <div class="d-flex justify-content-center align-items-center mb-3">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </template>
      <template v-else>
        <b-row>
          <ContasPagarComponent
            ref="financialAccountFormRef"
          />
        </b-row>
        <b-row class="mt-3">
          <div
            class="text-end"
          >
            <button
              @click="close"
              class="btn btn-light shadow-sm col-sm-3 col-12 me-2 mb-xl-0 mb-sm-0 mb-2"
              title="Fechar"
            >
              <span>
                <i class="ri-arrow-go-back-fill align-bottom me-1"></i> Voltar
              </span>
            </button>
            <button
              @click="handleSave"
              class="btn btn-primary shadow-sm col-sm-3 col-12"
              title="Salvar"
            >
              <span
                ><i class="ri-save-2-fill align-bottom me-1"></i> Salvar
              </span>
            </button>
          </div>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>
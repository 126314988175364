<script>
import Multiselect from '@vueform/multiselect';
import flatPickr from "vue-flatpickr-component";
import 'flatpickr/dist/flatpickr.min.css';
import { Portuguese } from 'flatpickr/dist/l10n/pt.js';
import { vMaska } from "maska";
import { DateTime } from "luxon"
import { VueDraggableNext } from 'vue-draggable-next';
import { getValueListById } from '../../helpers/forms/getListaValorById';
import Swal from 'sweetalert2';
import { useRoute } from 'vue-router';
import { sendFunctionRequest } from '@/helpers/dynamicTable/sendFunctionRequest';
import { debounce } from 'vue-debounce';

export default {
    props: {
        sis_campos_tabela: {
            type: Array,
            required: true,
        },
        sis_filtros: {
            type: Array,
            required: true,
        },
        programSchema: {
            type: Object,
            required: true,
        },
        currentEditionFiltroPrograma: {
            type: Object,
            default: null,
        },
        updateCurrentEditionFiltroPrograma: {
            type: Function,
            required: true,
        },
        updateFiltros: {
            type: Function,
            required: true,
        },
        updateShowFilterBuilder: {
            type: Function,
            required: true,
        },
        operators: {
            type: Array,
            required: true,
        },
        onDeleteFilter: {
            type: Function,
            required: true,
        },
        setLastEditedFilterId: {
            type: Function,
            required: true,
        },
        loadingEditionFilterTarget: {
            type: Boolean,
            required: true,
        },
        clearBuilderFields: {
            type: Boolean,
            required: true,
        },
    },
    components: {
        Multiselect,
        flatPickr,
        VueDraggableNext
    },
    directives: {
        maska: vMaska
    },
    data() {
        return {
            DateTime,
            newCondition: [],
            filterSave: [],
            tags: null,
            currentFilterInEdition: null,
            listaValores: [],
            loadingSaveFilter: false,
            loadingDeleteFilter: false,
            browser_route: useRoute(),
            dragOptions: {
                animation: 300,
                group: 'description',
                disabled: false,
                ghostClass: 'ghost',
            },
            newFilter: {
                titulo: '',
                descricao: '',
                publico: false,
            },
            options: this.$props.sis_campos_tabela.filter(sct => sct.tipo_campo != 15).map((sct, i) => {
                return {
                    ...sct,
                    index: i + 1
                }
            }),
        }
    },
    watch: {
        currentEditionFiltroPrograma(filter) {
            if (filter) {
                this.currentFilterInEdition = filter
                this.newFilter.titulo = filter.titulo
                this.newFilter.descricao = filter.descricao
                this.newFilter.publico = filter.publico
                this.newFilter.padrao = filter.padrao
                this.loadSaveFilter(filter)
            }
            if(filter === null){
                this.clearInputsModal()
            }
        },
        clearBuilderFields() {
            this.clearInputsModal()
        }
    },
    methods: {
        handleDraggChange() {
            for(let i = 0; i < this.newCondition.length; i++){
                this.newCondition[i].ordem_exibicao = i + 1
            }
        },
        updateConditions(indexField, index) {
            if (indexField && this.newCondition[index]) {
                this.newCondition[index].selectedCondition = null
                this.newCondition[index].id_lista_valor = this.options[indexField - 1].id_lista_edicao
                this.updateOptionsList(this.newCondition[index].id_lista_valor, index)
            }
        },
        async updateOptionsList(id_lista_valor, index, searchString = ''){
            if(id_lista_valor === null && this.newCondition[index]){
                this.newCondition[index].sis_opcao_lista_valor = null;
            }else if(id_lista_valor && this.newCondition[index]){
                this.newCondition[index].loadingOptions = true;
                this.newCondition[index].sis_opcao_lista_valor = null;
                await getValueListById(id_lista_valor, searchString)
                .then(list => {
                    if(list.sis_lista_valor.sis_opcao_lista_valor){
                        this.newCondition[index].sis_opcao_lista_valor = list.sis_lista_valor.sis_opcao_lista_valor
                    }
                    if(list.sis_lista_valor.exige_filtro){
                        this.newCondition[index].lista_exige_filtro = true
                    }
                    this.newCondition[index].loadingOptions = false;
                }).catch(err => {
                    console.error(err)
                    this.newCondition[index].loadingOptions = false;
                })
            }
        },
        updateOptionsListFiltro: debounce(function(searchString, id_lista_valor, index){
                if(searchString.length < 3){
                    return
                }
                this.updateOptionsList(id_lista_valor, index, searchString)
            }, 500),
        setoriginalDynamicDate(bool, index){
            this.newCondition[index].originalDynamicDate = bool
            if(bool){
                if(this.newCondition[index].selectedCondition === 5){
                    const previousValue = this.newCondition[index].tags
                    this.newCondition[index].tags = this.newCondition[index].tags?.replace(this.DateTime.now().toISODate(), 'now()')
                    if(!previousValue || previousValue === this.newCondition[index].tags){
                        Swal.fire({
                            icon: "warning",
                            text: `A data inicial ou final deve ser igual a data de hoje (${this.DateTime.now().toLocaleString()})!`,
                            confirmButtonColor: "#34c38f",
                            confirmButtonText: "OK",
                        });
                        this.newCondition[index].tags = previousValue
                        this.newCondition[index].originalDynamicDate = false
                    }
                }else{
                    this.newCondition[index].tags = 'now()'
                }
            }else{
                if(this.newCondition[index].selectedCondition === 5){
                    this.newCondition[index].tags = this.newCondition[index].tags.replace('now()', this.DateTime.now().toISODate())
                }else{
                    this.newCondition[index].tags = this.DateTime.now().toISODate()
                }
            }
        },
        updateParam(indexList, index) {
            if (indexList && this.newCondition[index].selectedField) {
                this.newCondition[index].originalDynamicDate = false
                const fieldType = this.options[this.newCondition[index].selectedField - 1].tipo_campo;
                switch (fieldType) {
                    case 2:
                        this.newCondition[index].tags = false
                        break;
                    case 4:
                        this.newCondition[index].tags = null;
                        this.newCondition[index].rangeDateconfig.enableTime = false;
                        this.newCondition[index].rangeDateconfig.altFormat = 'd/m/Y ';
                        this.newCondition[index].rangeDateconfig.dateFormat = 'Y-m-d';
                        if (this.operators[indexList - 1].simbol === "BETWEEN") {
                            this.newCondition[index].rangeDateconfig.mode = "range";
                        }else{
                            this.newCondition[index].rangeDateconfig.mode = "single";
                        }
                        break;
                    case 5:
                        this.newCondition[index].tags = null;
                        this.newCondition[index].rangeDateconfig.altFormat = 'd/m/Y H:i:S';
                        this.newCondition[index].rangeDateconfig.dateFormat = 'Y-m-d\\TH:i:S';
                        this.newCondition[index].rangeDateconfig.noCalendar = false;
                        this.newCondition[index].rangeDateconfig.enableTime = true;
                        this.newCondition[index].rangeDateconfig.enableSeconds = true;
                        if (this.operators[indexList - 1].simbol === "BETWEEN") {
                            this.newCondition[index].rangeDateconfig.mode = "range";
                        } else {
                            this.newCondition[index].rangeDateconfig.mode = "single";
                        }
                        break;

                    case 6:
                        this.newCondition[index].tags = null
                        this.newCondition[index].rangeDateconfig.altFormat = 'H:i:S';
                        this.newCondition[index].rangeDateconfig.dateFormat = "H:i:S";
                        this.newCondition[index].rangeDateconfig.noCalendar = true;
                        this.newCondition[index].rangeDateconfig.enableTime = true;
                        this.newCondition[index].rangeDateconfig.enableSeconds = true;
                        break;
                    default:
                        this.newCondition[index].tags = []
                        break;
                }
            }
        },
        addCondition() {
            const newCondition = {
                titulo: "",
                required: false,
                id_lista_valor: null,
                searchString: '',
                lista_exige_filtro: false,
                ordem_exibicao: this.newCondition.length + 1,
                selectedField: null,
                selectedCondition: null,
                tags: null,
                rangeDateconfig: {
                    mode: "single",
                    dateFormat: "Y-m-d",
                    altInput: true,
                    altFormat: "d/m/Y",
                    enableTime: true,
                    allowInput: true,
                    locale: Portuguese,
                },
                originalDynamicDate: false,
            };
            this.isTodosInputsPreenchidos = false
            this.newCondition.push(newCondition);
        },

        removeCondition(index) {
            if (this.newCondition.length >= 1) {
                this.newCondition.splice(index, 1);
                this.isTodosInputsPreenchidos = this.newCondition.length > 0 && this.newCondition.some(filter => filter.tags.length > 0)
            }
            this.newCondition.forEach((filter, index) => {
                filter.ordem_exibicao = index + 1;
            });
        },

        removeSavedFilter(index) {
            this.filterSave.splice(index, 1)
        },

        handleChangeTag(tags, index, tagIndex = null) {
            const typeField = this.options[this.newCondition[index].selectedField - 1].tipo_campo
            let value = tags
            if(typeField === 12){
                value = tags.replace(/[^\d.,]/g, '')
                const dots = value.match(/\./g)
                if(dots && dots.length > 1){
                    value = value.replace(/\./g, '')
                    value = value.slice(0, value.length - 1) + '.' + value.slice(value.length - 1)
                }
            }

            if(tagIndex !== null){
                this.newCondition[index].tags[tagIndex] = value
            }else{
                this.newCondition[index].tags = value;
            }
        },
        handleSwitchChange(bool, index){
            this.newCondition[index].tags = bool
        },
        loadSaveFilter(f) {
            const selectFilter = f.condicoes
            this.addCondition()
            this.newCondition = selectFilter.map(sf => {
                return { ...sf, selectedField: this.options.findIndex(sct => sct.id_campo_tabela === sf.selectedFieldId) + 1, tags: sf.originalValue }
            })
            for(let i = 0; i < this.newCondition.length; i++){
                this.newCondition[i].ordem_exibicao = i + 1
                this.updateOptionsList(this.newCondition[i].id_lista_valor, i)
            }
        },
        clearInputsModal() {
            this.currentFilterInEdition = null
            this.newFilter = {
                titulo: '',
                descricao: '',
                publico: false,
                padrao: false,
            }
            this.updateCurrentEditionFiltroPrograma(null)
            this.newCondition = []
        },
        deleteCurrentFilter() {
            Swal.fire({
                title: `Deseja realmente excluir o filtro [${this.currentFilterInEdition.titulo}]?`,
                text: "Esta ação não poderá ser desfeita!",
                icon: "warning",
                confirmButtonText: "OK",
                showCancelButton: true,
                cancelButtonText: "Cancelar",
            }).then(async (result) => {
                if (result.isConfirmed) {                
                    this.loadingDeleteFilter = true
                    const body = {
                        id_filtro_programa: this.currentFilterInEdition.id_filtro_programa,
                        id_programa: this.browser_route.params.targetId,
                        condicao_adicional: "",
                        lista_id_empresa: JSON.parse(localStorage.getItem('companiesGlobalFilter')) ?? [],
                        data_trabalho: JSON.parse(localStorage.getItem('dataTrabalho')),
                        navegador: false,
                        ordenacao: null,
                        quantidade_registros: 0,
                        pagina: 0,
                        rota: 'exclui_filtro_programa',
                        dados: {
                            sis_filtro_programa: {
                                registros: [{
                                    id_filtro_programa: this.currentFilterInEdition.id_filtro_programa,
                                    id_programa: this.browser_route.params.targetId,
                                }]
                            }
                        },
                    }
                    await sendFunctionRequest(body)
                    .then(res => {
                        const data = res.data
                        if (!data.status) {
                            Swal.fire({
                                icon: "error",
                                text: data.mensagem ? data.mensagem : data.erro,
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            });
                        } else {
                            this.updateShowFilterBuilder(false)
                            this.updateFiltros(data.retorno.sis_filtro)
                            Swal.fire({
                                icon: "success",
                                text: "Filtro Excluído!",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            });
                        }
                    })
                    .catch(err => {
                        console.error(err)
                    })

                    this.loadingDeleteFilter = false                    
                }
            })
        },
        async saveCurrentFilter() {
            this.loadingSaveFilter = true
            const sis_filtro_programa = {
                registros: [{
                    id_filtro_programa: this.currentFilterInEdition?.id_filtro_programa,
                    titulo: this.newFilter.titulo,
                    descricao: this.newFilter.descricao,
                    publico: this.newFilter.publico,
                    tipo_filtro: this.newFilter.padrao ? 1 : 2,
                }]
            }
            const sis_parametro_filtro_programa = {
                registros: []
            }
            const sis_condicao_filtro_programa = {
                registros: []
            }
            const sis_condicao_fixa_filtro_programa = {
                registros: []
            }
            
            const newConditionFilter = {
                condicao: '',
                descricao: '',
            }

            sis_parametro_filtro_programa.registros.push(
                ...this.newCondition.map((newCond) => {
                    let strParam = `:${newCond.titulo.replace(/\s/g,'')}`
                    newConditionFilter.condicao += ` :AND: :${this.options[newCond.selectedField - 1].id_campo_tabela} ${this.operators.find(op => op.id === newCond.selectedCondition).simbol} ` + strParam
                    newConditionFilter.descricao += ` :AND: [${this.options[newCond.selectedField - 1].nome}] ${this.operators.find(op => op.id === newCond.selectedCondition).label} [${newCond.titulo}]`
                    
                    return {
                        id_filtro_programa: this.currentFilterInEdition?.id_filtro_programa ?? null,
                        id_parametro_filtro_programa: newCond.id_parametro_filtro_programa ?? null,
                        titulo: newCond.titulo,
                        tipo_parametro: this.options[newCond.selectedField - 1].tipo_campo,
                        obrigatorio: newCond.required,
                        id_lista_valor: newCond.id_lista_valor,
                        searchString: newCond.searchString,
                        lista_exige_filtro: newCond.lista_exige_filtro,
                        ordem_exibicao: newCond.ordem_exibicao,
                        valor: Array.isArray(newCond.tags) ? newCond.tags.join(' até ') : newCond.tags ? newCond.tags.toString() : '',
                    }
                })
            )
            newConditionFilter.condicao = newConditionFilter.condicao.split(' :AND: ').slice(1).join(' :AND: ')
            newConditionFilter.descricao = 'Customizado | ' + newConditionFilter.descricao.split(' :AND: ').slice(1).join(' :AND: ')
            sis_condicao_filtro_programa.registros.push(newConditionFilter)
            
            if(this.currentFilterInEdition){
                sis_condicao_fixa_filtro_programa.registros.push({
                    condicao: this.currentFilterInEdition.fixedCondition ?? ''
                })
            }

            const body = {
                id_programa: this.browser_route.params.targetId,
                condicao_adicional: "",
                lista_id_empresa: JSON.parse(localStorage.getItem('companiesGlobalFilter')) ?? [],
                data_trabalho: JSON.parse(localStorage.getItem('dataTrabalho')),
                navegador: false,
                ordenacao: null,
                quantidade_registros: 0,
                pagina: 0,
                rota: 'inclui_edita_filtro_programa',
                dados: {
                    sis_filtro_programa,
                    sis_parametro_filtro_programa,
                    sis_condicao_filtro_programa,
                    sis_condicao_fixa_filtro_programa,
                },
            }
            await sendFunctionRequest(body)
            .then(res => {
                const data = res.data
                if (!data.status) {
                    Swal.fire({
                        icon: "error",
                        text: data.mensagem ? data.mensagem : data.erro,
                        confirmButtonColor: "#34c38f",
                        confirmButtonText: "OK",
                    });
                } else {
                    this.updateShowFilterBuilder(false)
                    this.updateFiltros(data.retorno.sis_filtro)
                    Swal.fire({
                        icon: "success",
                        text: "Salvo!",
                        confirmButtonColor: "#34c38f",
                        confirmButtonText: "OK",
                    });
                }
            })
            .catch(err => {
                console.error(err)
            })
            this.loadingSaveFilter = false
        },
    },
    mounted() {
        // this.setSavedFilters(this.sis_filtros)
    },
    computed: {
        todosInputsPreenchidos() {
            for (const condition of this.newCondition) {
                if (!condition.selectedField || !condition.selectedCondition || condition.titulo === '') {
                    return false;
                }
            }
            return true;
        },
        getOptionsForColumn() {
            return (type) => {
                return this.operators.filter(op => op.suportedTypes.includes(type))?.sort((a, b) => a.order - b.order)
            }
        },
        getDynamicDateText(){
            return (tags) => {
                if(tags === 'now()' || tags === this.DateTime.now().toISODate()){
                    return 'Data Atual'
                }

                const [date1, date2] = tags.split(' até ')
                if(date1 === 'now()' || date1 === this.DateTime.now().toISODate()){
                    return `Data Atual até ${this.DateTime.fromISO(date2).toLocaleString()}`
                }
                if(date2 === 'now()' || date2 === this.DateTime.now().toISODate()){
                    return `${this.DateTime.fromISO(date1).toLocaleString()} até Data Atual`
                }
            }
        }
    }
}
</script>

<template>
    <div class="modal-body">
        <template v-if="loadingEditionFilterTarget">
            <div class="d-flex justify-content-center align-items-center h-100">
                <span class="d-flex align-items-center justify-content-center gap-2">
                    <span class="spinner-border flex-shrink-0 text-primary" role="status"></span> Carregando Filtro...
                </span>
            </div>
        </template>
        <template v-else>
            <h4 class="mb-4 text-center">{{currentFilterInEdition ? 'Editando ' + currentFilterInEdition.titulo : 'Novo Filtro' }}</h4>
            <div class="row mx-3 mt-3 py-2 mb-3 border">
                <div class="col-12 col-md-6 mb-2">
                    <label class="form-label text-dark">Título do Filtro <span class="text-danger">*</span></label>
                    <input 
                        type="text" 
                        class="form-control" 
                        v-model="newFilter.titulo" 
                        placeholder="Título do Filtro" 
                        required
                    >
                </div>
                <div class="col-12 col-md-6 mb-2">
                    <label class="form-label text-dark">Descrição <span class="text-danger">*</span></label>
                    <input 
                        type="text" 
                        class="form-control" 
                        v-model="newFilter.descricao" 
                        placeholder="Descrição do Filtro" 
                        required
                    >
                </div>
                <div class="col-4 col-md-2">
                    <label class="form-label text-dark">Público</label>
                        <div class="form-switch form-switch-lg">
                            <input
                                v-model="newFilter.publico"
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                            />
                        </div>
                </div>
            </div>
            <h5 class="mx-3 mb-2">Parâmetros</h5>
            <div class="mx-3 text-muted d-flex align-items-center gap-2 flex-wrap mb-0 border">        
                <template v-if="newCondition.length">
                    <VueDraggableNext
                        :list="newCondition"
                        item-key="ordem_exibicao"
                        v-bind="dragOptions"
                        class="dragArea w-100"
                        ghost-class="ghost"
                        @change="handleDraggChange"
                    >
                        <transition-group type="transition" name="flip-list">
                            <div class="w-100" v-for="filter in newCondition" :key="filter.ordem_exibicao">
                                <div :class="['draggable-fitler-item border border-2 border-dashed p-2 rounded m-2 position-relative pt-3 pt-md-2 d-flex align-items-center']">
                                    <div class="px-1 d-none d-md-block">
                                        <span class="text-muted fs-17 fw-semibold">#{{ filter.ordem_exibicao }}</span>
                                    </div>
                                    <div class="flex-1">
                                        <div class="px-2 gap-2 gap-md-4 mb-2 d-flex flex-column flex-md-row">
                                            <div class="col-11 col-md-4">
                                                <label class="form-label text-dark">Título do Parâmetro <span class="text-danger">*</span></label>
                                                <input 
                                                    type="text" 
                                                    class="form-control" 
                                                    v-model="filter.titulo" 
                                                    placeholder="Título do parâmetro" 
                                                    style="min-height: 44px;"
                                                    required
                                                >
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <label class="form-label text-dark">Obrigatório</label>
                                                    <div class="form-switch form-switch-lg">
                                                        <input
                                                            v-model="filter.required"
                                                            class="form-check-input"
                                                            type="checkbox"
                                                            role="switch"
                                                        />
                                                    </div>
                                            </div>
                                        </div>
                                        <div class="px-2 d-flex flex-column flex-md-row w-100 border-none mb-1 filters">
                                            <!-- SELECIONAR CAMPO A SER FILTRADO -->
                                            <div class="col-12 col-md-4 text-dark border border-1 rounded-start pt-1 position-relative">
                                                <Multiselect class="border-0 rounded-0" v-model="filter.selectedField"
                                                    placeholder="Selecione o campo" @input="updateConditions($event, filter.ordem_exibicao-1)" label="titulo"
                                                    style="max-height: 38px; padding: 0;padding-top:1px" :multiple="false"
                                                    :searchable="true" :value-prop="'index'" :clearOnSearch="true" :options="options">

                                                    <template v-slot:singlelabel="{ value }">
                                                        <div class="multiselect-single-label d-flex w-100">
                                                            <span class="multiselect-single-label-text text-dark">
                                                                {{ value.titulo }} ({{ value.nome }})
                                                            </span>
                                                        </div>
                                                    </template>
                                                    <template v-slot:option="{ option }">
                                                        <div class="d-flex w-100">
                                                            <span class="">
                                                                {{ option.titulo }} ({{ option.nome }})
                                                            </span>
                                                        </div>
                                                    </template>
                                                </Multiselect>
                                                <div v-if="filter.selectedField === null" class="position-absolute text-danger fs-5"
                                                style="left: 155px;top:10px;">*</div>
                                            </div>
                                            <!-- SELECIONAR TIPO DE FILTRAGEM -->
                                            <div class="col-12 col-md-4 text-dark border border-1 pt-1 position-relative" v-if="filter.selectedField">
                                                <Multiselect v-model="filter.selectedCondition"
                                                    noOptionsText="A coluna selecionada não possui comparações disponíveis"
                                                    style="max-height: 38px; padding: 0;padding-top:1px" class="border-0 rounded-0"
                                                    :multiple="false" :searchable="true" :options="getOptionsForColumn(options[filter.selectedField - 1].tipo_campo)"
                                                    placeholder="Selecione o tipo de comparação" :clearOnSearch="true"
                                                    @input="updateParam($event, filter.ordem_exibicao-1)" :value-prop="'id'" label='label'>

                                                    <template v-slot:singlelabel="{ value }">
                                                        <div class="multiselect-single-label d-flex w-100">
                                                            <span class="multiselect-single-label-text text-dark">
                                                                {{ value.label }}
                                                            </span>
                                                        </div>
                                                    </template>
                                                </Multiselect>
                                                <div v-if="filter.selectedCondition === null" class="position-absolute text-danger fs-5"
                                                style="left: 240px;top:10px;">*</div>
                                            </div>
                                            <!-- SELECIONAR OS PARAMETROS -->
                                            <div class="col-12 col-md-4 mr-1 position-relative text-muted border rounded-end border-1 pt-1"
                                                v-if="filter.selectedCondition && filter.selectedField">
                                                <div v-if="filter.loadingOptions && !filter.lista_exige_filtro" class="loading">
                                                    <div class="lds-facebook">
                                                    </div> Carregando Opções
                                                </div>
                                                <div v-else-if="filter.lista_exige_filtro" class="text-dark">
                                                    <Multiselect 
                                                        placeholder="Selecione o valor padrão"
                                                        @search-change="updateOptionsListFiltro($event, filter.id_lista_valor, filter.ordem_exibicao-1)"
                                                        v-model="filter.tags"   
                                                        style="border: none;padding:0;"
                                                        :searchable="true" 
                                                        :options="filter.sis_opcao_lista_valor" 
                                                        :no-options-text="filter.loadingOptions ? 'Carregando...' : 'Digite para pesquisar'"
                                                        :value-prop="'id'" label='titulo'
                                                    />
                                                </div>
                                                <div v-else-if="filter.sis_opcao_lista_valor" class="text-dark">
                                                    <Multiselect 
                                                        placeholder="Selecione o valor padrão"
                                                        v-model="filter.tags"   
                                                        style="border: none;padding:0;"
                                                        :multiple="false" 
                                                        :searchable="true" 
                                                        :options="filter.sis_opcao_lista_valor" 
                                                        :clearOnSearch="true"
                                                        :value-prop="'id'" label='titulo'
                                                    />
                                                </div>
                                                <template v-else-if="[4, 5, 6].includes(options[filter.selectedField - 1].tipo_campo)">
                                                    <div class="input-group">
                                                        <input v-if="filter.originalDynamicDate" type="text" class="form-control border-0 h-100 rounded-0 text-muted" :value="getDynamicDateText(filter.tags)" readonly>
                                                        <div class="flex-1 index" v-if="!filter.originalDynamicDate">
                                                            <flat-pickr
                                                                v-model="filter.tags" class="form-control border-0 h-100 rounded-0"
                                                                :placeholder="filter.rangeDateconfig.mode !== 'single' ? 'Selecione o período padrão' : 'Selecione a data padrão'"
                                                                :config="filter.rangeDateconfig" :simbol="filter.selectedCondition">
                                                            </flat-pickr>
                                                        </div>
                                                        <div class="input-group-prepend" v-if="options[filter.selectedField - 1].tipo_campo === 4">
                                                            <button
                                                                @click="setoriginalDynamicDate(!filter.originalDynamicDate, filter.ordem_exibicao - 1)"
                                                                type="button"
                                                                style="
                                                                position: absolute;
                                                                z-index: 100;
                                                                top: -4px;
                                                                right: 0;
                                                                height: 42px;"
                                                                :class="['btn rounded-0 rounded-end btn-icon border-0 border-start', filter.originalDynamicDate ? 'btn-primary text-light' : 'text-primary']" 
                                                                v-b-tooltip.hover.top="'Data Atual'">
                                                                <i class="mdi mdi-timeline-clock fs-18"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </template>
                                                <div class="d-flex h-100  align-items-center  form-check form-switch form-switch-lg ms-2"
                                                    dir="ltr" v-else-if="options[filter.selectedField - 1].tipo_campo == 2">
                                                    <input 
                                                        type="checkbox" 
                                                        class="mr-auto form-check-input"
                                                        role="switch"
                                                        @click="handleSwitchChange($event.target.checked, filter.ordem_exibicao-1)"
                                                        :checked="filter.tags === 'true'"
                                                    >
                                                    <!-- <label class="form-check-label text-muted " for="customSwitchsizelg"> {{ filter.tags ? 'Ativado' : 'Desativado' }}</label> -->
                                                </div>
                                                <div v-else-if="['BETWEEN'].includes(this.operators.find(op => op.id === filter.selectedCondition).simbol)"
                                                class="d-flex flex-wrap flex-md-nowrap align-items-center">
                                                    <input 
                                                        v-if="options[filter.selectedField - 1].tipo_campo == 7"
                                                        type="number"
                                                        v-model="filter.tags[0]"
                                                        class="form-control border-0 rounded-0 rounded-end"
                                                        step="1"
                                                        style="min-height: 40px;"
                                                    />
                                                    <input
                                                        v-else
                                                        type="text"
                                                        placeholder="Primeiro valor"
                                                        class="form-control border-0 rounded-0 rounded-end"
                                                        style="min-height: 40px;"
                                                        v-model="filter.tags[0]"
                                                        @input="handleChangeTag($event.target.value, filter.ordem_exibicao-1, 0)"
                                                    />
                                                    <div class="text-muted px-2">Até</div>
                                                    <input 
                                                        v-if="options[filter.selectedField - 1].tipo_campo == 7"
                                                        type="number"
                                                        v-model="filter.tags[1]"
                                                        class="form-control border-0 rounded-0 rounded-end"
                                                        step="1"
                                                        style="min-height: 40px;"
                                                    />
                                                    <input
                                                        v-else
                                                        type="text"
                                                        placeholder="Primeiro valor"
                                                        class="form-control border-0 rounded-0 rounded-end"
                                                        style="min-height: 40px;"
                                                        v-model="filter.tags[1]"
                                                        @input="handleChangeTag($event.target.value, filter.ordem_exibicao-1, 1)"
                                                    />
                                                </div>
                                                <input 
                                                    v-else-if="options[filter.selectedField - 1].tipo_campo == 7"
                                                    type="number"
                                                    @keyup="handleChangeTag($event.target.value, filter.ordem_exibicao-1)"
                                                    class="form-control border-0 rounded-0 rounded-end"
                                                    step="1"
                                                    style="min-height: 40px;"
                                                />
                                                <input v-else
                                                    type="text"
                                                    placeholder="Informe um valor padrão"
                                                    class="form-control border-0 rounded-0 rounded-end"
                                                    style="min-height: 40px;"
                                                    v-model="filter.tags"
                                                    @input="handleChangeTag($event.target.value, filter.ordem_exibicao-1)"
                                                />
                                            </div>
                                        </div>

                                        <button 
                                            v-if="newCondition.length >= 1" 
                                            @click="removeCondition(filter.ordem_exibicao-1)" 
                                            class="fs-20 btn bg-white btn-white rounded-circle shadow-sm border position-absolute btn-icon"
                                            style="right: -5px;top: -10px;" 
                                            v-b-tooltip.hover.top="'Remover'"
                                        >
                                            <i class="ri-delete-bin-line text-danger"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </transition-group>
                    </VueDraggableNext>
                </template>

                <button class="btn bg-primary p-3 mt-4 mb-4 rounded rounded-circle btn-icon d-flex align-items-center w-fit mx-auto text-light shadow-sm"
                    @click="addCondition" 
                    :disabled="!todosInputsPreenchidos"
                    v-b-tooltip.hover.top="'Adicionar Condição'"
                >
                    <i class="ri-add-line fs-22"></i>
                </button>
            </div>
        </template>
    </div>
    <div class="modal-footer border-top pt-1 flex-1 d-flex  flex-md-row gap-2 align-items-center justify-content-between gap-1 flex-wrap modal-filter-footer">  
        <div class="ms-auto d-flex flex-column flex-fill gap-2 flex-md-row flex-md-grow-0 align-items-md-center">
            <button v-if="loadingDeleteFilter" type="button"
                class="btn btn-danger me-2 me-md-5 btn-load"
                style="height: 40px; min-width: 130px;">
                <span class="d-flex align-items-center justify-content-center gap-2">
                    <span class="spinner-border flex-shrink-0" role="status"></span> Carregando
                </span>
            </button>
            <button v-else-if="currentFilterInEdition" type="button" 
                :disabled="loadingSaveFilter"
                class="border btn btn-danger" 
                style="height: 40px; min-width: 130px;"
                @click="deleteCurrentFilter"
            ><i class="fs-14 mdi mdi-trash-can-outline align-bottom me-1"></i> Excluir Filtro</button>

            <button type="button"
                class="btn btn-light border"
                style="height: 40px; min-width: 130px;"
                @click="updateShowFilterBuilder(false)"
            >Fechar</button>
            <button v-if="loadingSaveFilter" type="button"
                class="btn btn-primary btn-load my-2"
                style="height: 40px; min-width: 130px;">
                <span class="d-flex align-items-center justify-content-center gap-2">
                    <span class="spinner-border flex-shrink-0" role="status"></span> Carregando
                </span>
            </button>
            <button type="button" v-else
                :disabled="newCondition.length === 0 || !todosInputsPreenchidos || newFilter.titulo.length == '' || newFilter.descricao == ''"
                class="btn btn-primary my-2"
                style="height: 40px; min-width: 130px;"
                @click="saveCurrentFilter"
            ><i class="ri-save-2-fill align-bottom me-1"></i> Salvar</button>
        </div>
    </div>
</template>

<style src="@vueform/multiselect/themes/default.css"></style>
import { Grid, h } from "gridjs";
import { RowSelection } from "gridjs/plugins/selection/dist/selection";
import { ref, createApp } from "vue";
import formatColumnValue from "./formatColumnValue";
import Swal from "sweetalert2";
import ptBR from "./ptBrLanguage";
import router from "../../router/index";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { mountPermissionsArvore, mountPermissionsEmpresa } from "../permissions";
import { sendFunctionRequest } from "./sendFunctionRequest";
import { makeResizable } from "../makeResizable";
import NotFoundComponent from '@/components/not_found/index';
import putPrimaryKeyInFirstIndex from "../putPrimaryKeyInFirstIndex";


//eslint-disable-next-line
export default function useDynamicTable(
    grid,
    program_schema,
    notfound_component,
    onFunctionSuccessCallBack = null
) {
    const browser_route = useRoute()
    grid.value = new Grid();
    const currentSelectedRows = ref([]);
    const currentDataIds = ref([]);
    const currentPageData = ref([]);
    const currentRetornoDados = ref([]);

    const table_schema = program_schema.sis_tabela;
    const functions = program_schema.sis_programa.sis_funcionalidade;
    const route = useRoute()
    const store = useStore();
    const detailGridSchema = ref(null)
    const primaryKeyName = table_schema.sis_campo_tabela.find(col => col.chave_primaria).nome
    const displayingFakeSelect = ref(false)

    const hasDetailGrid = ref(
        program_schema.sis_tabela.sis_campo_tabela.filter(sct => {
            if(sct.tipo_campo == 15 && sct.exibe_navegador){
                detailGridSchema.value = sct.dados_tabela_detalhe.sis_tabela
                return true
            }
        }).length > 0 ? true : false)
    const visibleColumnsLength = ref(program_schema.sis_tabela.sis_campo_tabela.filter(sct => sct.exibe_navegador && sct.tipo_campo !== 15).length)

    function formatValue(columnObj, valueObj) {
        return formatColumnValue(columnObj, valueObj)
    }
    function getEditedColumnsSchema(columns_schema){
        const editedColumnsSchema = []
        try{
            let cpt = localStorage.getItem('config_program_table')
            cpt = JSON.parse(cpt)
            const columns = cpt?.find(obj => obj.id_programa === program_schema.sis_programa.id_programa)?.columns
            if(columns){
                for (let column of columns_schema){
                    const storedColumn = columns.find(sc => sc.id === column.id_campo_tabela)
                    editedColumnsSchema.push({
                        ...column,
                        exibe_navegador: storedColumn?.show ?? column.exibe_navegador,
                        ordem_exibicao: storedColumn?.order ?? column.ordem_exibicao,
                        largura: storedColumn?.minWidth ?? null,
                    })
                }
            }else{
                editedColumnsSchema.push(...columns_schema)    
            }
        }catch(e){
            console.log(e)
            editedColumnsSchema.push(...columns_schema)
        }
        editedColumnsSchema.sort((a,b) => a.ordem_exibicao - b.ordem_exibicao)
        
        const {sis_campo_tabela} = putPrimaryKeyInFirstIndex({sis_campo_tabela: editedColumnsSchema})
        return sis_campo_tabela
    }
    function getColumns(columns_schema, isDetailTable = false) {
        const columns = []
        const editedColumnsSchema = getEditedColumnsSchema(columns_schema)
        
        editedColumnsSchema.map(col => {
            const exibe_navegador = col.tipo_campo == 15 && col.exibe_navegador ? false : col.exibe_navegador
            
            columns.push({
                minWidth: col.largura ?? '150px',
                width: isDetailTable ? '1%' : '',
                id: col.nome,
                name: col.titulo,
                hidden: !exibe_navegador,
                allowCopy: true,
                formatter: (cell, row,) => {
                    const data = row.cell(1)?.data
                    if(!isDetailTable && data?.detail_grid_id){
                        return h('div', {
                            className: 'transition-height detail_grid',
                            style: 'white-space: wrap;',
                            id: data.detail_grid_id,
                            children: h('div', {
                                className: 'p-2 pt-0',
                                style: 'direction: ltr',
                                children: h('div', {
                                    id: data.detail_grid_wrapper_id,
                                    className: 'detail_grid_wrapper',
                                })
                            })
                        })
                    }
                    return h('div', {
                        className: 'cursor-pointer h-100 d-flex align-items-center',
                        children: [cell],
                        style: 'white-space: wrap;' + (col.formato?.includes('N') ? 'text-align: right;' : 'text-align: left;'),
                        onClick: (event) => {
                            if(isDetailTable){
                                return false
                            }
                            if (event.detail === 2) {
                                store.commit('frameworkNavegador/setIdProgram', program_schema.id_programa);
                                if (table_schema.permite_alteracao) {
                                    const f = functions.find(f => f.denominacao == 'Editar')
                                    call_program_function(f.id_funcionalidade, row.cell(1).data, f.rota_backend, f.denominacao);
                                } else {
                                    const f = functions.find(f => f.denominacao == 'Visualizar')
                                    call_program_function(f.id_funcionalidade, row.cell(1).data, f.rota_backend, f.denominacao);
                                }
                            }else if(hasDetailGrid.value){
                                const detail_container =  document.getElementById(`dt_g_id_${row.cell(1).data}`)
                                
                                if(!detail_container.classList.contains('active')){
                                    const detail_containers = document.querySelectorAll('.detail_grid')
                                    detail_containers.forEach((detail_container) => {
                                        detail_container.classList.remove('active')
                                    })
                                    detail_container.classList.add('active')

                                    const detail_container_grid = document.getElementById(`detail_grid_wrapper_${row.cell(1).data}`)
                                    if(detail_container_grid.innerHTML){
                                        detail_container_grid.innerHTML = ''
                                    }

                                    
                                    const columns = getColumns(detailGridSchema.value.sis_campo_tabela, true)
                                    const data = currentRetornoDados.value[detailGridSchema.value.nome_tabela].registros.filter(reg => reg[primaryKeyName] == row.cell(1).data)
                                    const dataFormated = data.map(value => {
                                        const obj = {}
                                        detailGridSchema.value.sis_campo_tabela.map(col => {
                                            obj[col.nome] = formatValue(col, value)
                                        })
                                        setTimeout(() => {
                                            updateFakeSelect()
                                        }, 300)
                                        return obj
                                    })
                                    
                                    if(data.length == 0){
                                        const container = document.createElement('div');
                                        const text = `Nenhum registro encontrado.${table_schema.nome_tabela == 'titulo_financeiro' ? '<br> Tente sincronizar a data do comprovante!' : ''}`
                                        container.style = "width:100%;max-width:60vw;padding:5px;";
                                        container.classList.add('notFoundDetailGrid')
                                        document.body.appendChild(container);
                                        const app = createApp(NotFoundComponent, {
                                            text
                                        })
                                        app.mount(container)
                                        document.getElementById(`detail_grid_wrapper_${row.cell(1).data}`).appendChild(container)
                                        setTimeout(() => {
                                            updateFakeSelect()
                                        }, 300)
                                        return false
                                    }
                                    new Grid({
                                        columns: columns,
                                        data: dataFormated
                                        ,
                                        sort: true,
                                        className: {
                                            td: 'p-1',
                                        },
                                        language: ptBR,
                                        style: {
                                            tr: {},
                                            th: {}
                                        },
                                    }).render(document.getElementById(`detail_grid_wrapper_${row.cell(1).data}`))
                                }
                                else{
                                    detail_container.classList.remove('active')
                                }
                                setTimeout(() => {
                                    updateFakeSelect()
                                }, 300)
                            }
                        },
                    })
                },
                attributes: (cell, row) => {
                    if(row && row.cell(1).data?.detail_grid){
                        return { colspan: visibleColumnsLength.value + 1, style: '' };
                    }
                    return {};
                },
            })
        })


        if(!isDetailTable){
            columns.unshift(
                {
                    id: 'tableCheckbox',
                    name: h('button', {
                        className: 'w-100 text-primary btn btn-icon fs-22 rounded-0 h-100',
                        children: h('i', { className: 'ri-check-double-fill' }),
                        onClick: () => {
                            selectOrUnselectAllRows();
                        }
                    }),
                    plugin: {
                        component: RowSelection,
                        props: {
                            id: (row) => row.cell(1).data
                        }
                    },
                    sort: false,
                    attributes: (cell, row) => {
                        const data = {
                            style: 'text-align: center;padding: 0;width:35px;',
                        };
                        if(row && row.cell(1).data && row.cell(1).data.detail_grid){
                            data.style = 'display:none';
                        }
                        return data;
                    },
                    resizable: false,
                },
            )
        }

        return columns
    }

    function setTableColumns(setCellClickListener = true) {
        const columns = getColumns(table_schema.sis_campo_tabela);
        
        grid.value.updateConfig({
            columns: columns,
            data: [],
            sort: {},
            language: ptBR,
            className: {
                footer: 'pt-0',
                pagination: 'text-end',
                paginationSummary: 'mb-1 fs-12',
                td: 'px-1 py-0',
                tr: hasDetailGrid.value ? 'grid-fatura-tr tr-1px' : 'tr-1px',
                th: 'position-relative',
                paginationButton: 'px-2 py-1',
                table: 'position-relative',
            },
            style: {
                td: {height: 'inherit'},
            }
        }).render(document.getElementById("table-wrapper"));

        grid.value.on('load', () => {
            displayingFakeSelect.value = false
            const targetTableBody = document.querySelector('#table-wrapper .gridjs-wrapper .gridjs-table .gridjs-tbody')
            const observer = new ResizeObserver((entries) => {
                for (const entry of entries) {
                    if (entry.contentRect && entry.contentRect.height > 69) {
                        
                        document.querySelector('#table-wrapper .gridjs-wrapper').addEventListener('scroll', (event) => {
                            if(event.target.scrollLeft > 35 && !displayingFakeSelect.value){
                                createMovableGridSelectButton()
                            }
                            if(event.target.scrollLeft < 35){
                                const fakeSelectContainer = document.querySelector('#fake-select-container')
                                fakeSelectContainer?.remove()
                                displayingFakeSelect.value = false
                            }
                        })
                        observer.unobserve(targetTableBody)
                    }
                }
            })
            observer.observe(targetTableBody)
        })

        grid.value.on('ready', () => {
            const targetTable = ref(document.querySelector('#table-wrapper .gridjs-wrapper .gridjs-table'))
            const observableTargetTable = document.querySelector('#table-wrapper .gridjs-wrapper .gridjs-table')
            const resizeObserver = new ResizeObserver((entries) => {
                for (const entry of entries) {
                    if (entry.contentRect && entry.contentRect.width > 0) {
                        makeResizable(targetTable)
                        resizeObserver.unobserve(observableTargetTable)
                    }
                }
            })
            resizeObserver.observe(observableTargetTable)
        })
        if(setCellClickListener){
            grid.value.on('cellClick', (event, cell, column, row) => {
                if (column.id == 'tableCheckbox') {
                    const rowTargetDataID = row.cell(1).data;
                    const storeSelectedRows = grid.value.config.plugin.get('tableCheckbox').props.store.state.rowIds;
                    if (storeSelectedRows.includes(rowTargetDataID)) {
                        grid.value.config.dispatcher.dispatch({
                            type: 'UNCHECK',
                            payload: {
                                ROW_ID: rowTargetDataID
                            }
                        });
                    } else {
                        grid.value.config.dispatcher.dispatch({
                            type: 'CHECK',
                            payload: {
                                ROW_ID: rowTargetDataID
                            }
                        });
                    }
                    updateCurrentSelectedRows();
                    return event.preventDefault();
                }

                if (column.allowCopy) {
                    return navigator.clipboard.writeText(cell.data);
                }

            });
        }

    }

    function fromHtmlString(htmlString){
        const template = document.createElement('template')
        template.innerHTML = htmlString
        const result = template.content.children
        if (result.length === 1) return result[0]
        return result
    }

    function createMovableGridSelectButton(){
        displayingFakeSelect.value = true
        // let fakeSelectContainerHeight = 0;
        const gridWrapper = document.querySelector('#table-wrapper .gridjs-wrapper')
        let fakeSelectContainer = document.querySelector('#fake-select-container')
        fakeSelectContainer?.remove()
        const thead = gridWrapper.querySelector('.gridjs-thead')
        const selectHeaderWidth = gridWrapper.querySelector('th[data-column-id="tableCheckbox"]').offsetWidth
        const trs = gridWrapper.querySelector('.gridjs-tbody').querySelectorAll('.gridjs-tr.tr-1px')

        let currentMode = document.documentElement.getAttribute("data-layout-mode");
        if (currentMode === "dark") {
            currentMode = 'bg-light'
        }
        if (currentMode === "light") {
            currentMode = 'bg-white'
        }
        fakeSelectContainer = fromHtmlString(`
            <table id="fake-select-container" class="${currentMode} position-absolute left-0 gridjs-table" style="top:${thead.offsetHeight+3}px; width:${selectHeaderWidth}px; background-color: #ccc; z-index: 1;">
            <tbody class="gridjs-tbody">
            ${
                (() => {
                    let htmlString = ''
                    for(let i =0; i < trs.length; i++){        
                        if(hasDetailGrid.value && i % 2 === 0 || !hasDetailGrid.value){
                            htmlString += `
                            <tr class="fake-checkbox ${trs[i].classList.value}" style="height:${getComputedStyle(trs[i]).height};">
                                <td class="gridjs-td px-1 py-0" style="text-align: center; padding: 0px; width: ${selectHeaderWidth}px;">
                                    <input type="checkbox" class="gridjs-checkbox">
                                </td>
                            </tr>
                            `
                        }else{
                            // htmlString += '' 
                            htmlString += `
                            <tr class="border ${trs[i].classList.value}" style="height:${getComputedStyle(trs[i]).height};">
                                <td class="gridjs-td px-1 py-0" style="text-align: center; padding: 0px; width: ${selectHeaderWidth}px;"></td>
                            </tr>
                            `
                        }
                    }
                    return htmlString
                })()
            }
            </tbody>
            </table>
        `)
        gridWrapper.appendChild(fakeSelectContainer)
        const fakeCheckBoxes = fakeSelectContainer.querySelectorAll('.fake-checkbox')
        Array.from(fakeCheckBoxes).forEach((fakeCheckBox, index) => {
            const targetId = currentDataIds.value[index]
            currentSelectedRows.value.includes(targetId) ? fakeCheckBox.classList.add('gridjs-tr-selected') : null

            fakeCheckBox.addEventListener('click', () => {
                const checkType = currentSelectedRows.value.includes(targetId) ? 'UNCHECK' : 'CHECK'
                
                if(checkType === 'CHECK'){
                    fakeCheckBox.classList.add('gridjs-tr-selected')
                }
                if(checkType === 'UNCHECK'){
                    fakeCheckBox.classList.remove('gridjs-tr-selected')
                }
                grid.value.config.dispatcher.dispatch({
                    type: checkType,
                    payload: {
                        ROW_ID: targetId
                    }
                });
                updateCurrentSelectedRows();
            })
        })
    }
    
    function updateFakeSelect(){
        createMovableGridSelectButton()
    }

    function selectOrUnselectAllRows() {
        const checkboxPlugin = grid.value.config.plugin.get('tableCheckbox');
        const selectedRows = checkboxPlugin.props.store.state.rowIds;

        if (selectedRows.length != currentDataIds.value.length) {
            currentDataIds.value.map(id => {
                grid.value.config.dispatcher.dispatch({
                    type: 'CHECK',
                    payload: {
                        ROW_ID: id
                    }
                });
            })
        } else {
            currentDataIds.value.map(id => {
                grid.value.config.dispatcher.dispatch({
                    type: 'UNCHECK',
                    payload: {
                        ROW_ID: id
                    }
                });
            })
        }

        updateCurrentSelectedRows();
    }

    /** Clean previous selection state */
    function emptyPreviousRowSelections() {
        currentDataIds.value.map(id => {
            grid.value.config.dispatcher.dispatch({
                type: 'UNCHECK',
                payload: {
                    ROW_ID: id
                }
            });
        })

        updateCurrentSelectedRows();
    }

    function getRegistersLimit(id_program){
        const config = JSON.parse(localStorage.getItem('config_program_table')) || [];
        const targetIndex = config.findIndex(obj => obj.id_programa === id_program.value)
        if(targetIndex !== -1){
            return parseInt(config[targetIndex].qty_registers)
        }
        return 10
    }

    /**
     * First render of table data, the subsequent renders like "next/prev pagination" will be handled and called by the table itself
     * @param {String} dataUrl 
     */
    // async function gridRenderData(program_id, searchObject) {
    async function gridRenderData(program_id, aditional_condition, setLoadingSearch) {
        const headers = {
            'Authorization': JSON.parse(localStorage.getItem('user')).token,
            'Content-Type': 'application/json'
        };
    
        emptyPreviousRowSelections();
        const lastAcessedProgram = {
            id_program: null,
            page: null,
            searchString: null,
            ordenacao: null,
            dataMovimento: null,
            beingReturned: false,
            filter: null
        }
        if(store.state.frameworkNavegador.beingReturned && store.state.frameworkNavegador.idProgram == program_schema.id_programa){
            lastAcessedProgram.id_program = store.state.frameworkNavegador.idProgram;
            lastAcessedProgram.page = store.state.frameworkNavegador.page;
            lastAcessedProgram.searchString = store.state.frameworkNavegador.searchString;
            lastAcessedProgram.ordenacao = store.state.frameworkNavegador.ordenacao;
            lastAcessedProgram.beingReturned = store.state.frameworkNavegador.beingReturned;
            lastAcessedProgram.filter = store.state.programFilter.loadedFilter;
        }

        let condicaoAdicional = null;
        condicaoAdicional = aditional_condition ? aditional_condition : lastAcessedProgram.searchString ? lastAcessedProgram.searchString : '';
        store.commit('frameworkNavegador/setSearchString', condicaoAdicional ?? '');

        setLoadingSearch(true);
        store.commit('frameworkNavegador/setBeingReturned', false);
        if(!store.state.programFilter.loadedFilter || store.state.programFilter.loadedFilter.id_programa !== program_schema.sis_programa.id_programa){
            store.commit('programFilter/setLoadedFilter', null)
        }
        const stateLoadedFilter = store.state.programFilter.loadedFilter ? store.state.programFilter.loadedFilter : lastAcessedProgram.filter ? lastAcessedProgram.filter : null;
        let searchRoute = store.state.programFilter.searchRoute ? store.state.programFilter.searchRoute : 'obtem_dados_programa_usuario';
        let dados = {}
        if(stateLoadedFilter && store.state.programFilter.data.length > 0){
            for (let index in store.state.programFilter.data){
                dados[store.state.programFilter.data[index].key] = { registros: store.state.programFilter.data[index].data }
            }
        }
        if(!stateLoadedFilter || store.state.programFilter.data.length === 0){
            dados = null
        }
        const registers_limit = getRegistersLimit(program_id)

        await grid.value.updateConfig({
            columns: [
                {
                    ...grid.value.config.columns[0], 
                    plugin: {
                        component: RowSelection,
                        props: {
                            id: (row) => row.cell(1).data
                        }
                    },
                }, ...getColumns(table_schema.sis_campo_tabela).filter((column, index) => index > 0)
            ],
            pagination: {
                enabled: true,
                limit: registers_limit,
                page: lastAcessedProgram.beingReturned ? lastAcessedProgram.page : 0,
                server: {
                    body: (prevBody, page) => {
                        prevBody = JSON.parse(prevBody)
                        prevBody.lista_id_empresa = JSON.parse(localStorage.getItem('companiesGlobalFilter')) ?? [];
                        prevBody.pagina = page;
                        store.commit('frameworkNavegador/setPage', page)
                        return JSON.stringify(prevBody);
                    }
                },
            },
            server: {
                headers: headers,
                url: `${process.env.VUE_APP_API_URL + '/program'}`,
                method: 'POST',
                body: JSON.stringify({
                    // id_programa: program_id,
                    id_programa: browser_route.params.targetId,
                    uuid_departamento: browser_route.params.departamentoId ?? null,
                    condicao_adicional: condicaoAdicional,
                    id_filtro_programa: stateLoadedFilter?.id_filtro_programa ?? null,
                    lista_id_empresa: JSON.parse(localStorage.getItem('companiesGlobalFilter')) ?? [],
                    data_trabalho: JSON.parse(localStorage.getItem('dataTrabalho')),
                    navegador: true,
                    ordenacao: null,
                    quantidade_registros: registers_limit,
                    pagina: 0,
                    rota: searchRoute,
                    dados: dados
                }),
                then: (res) => {
                    store.commit('programFilter/setSearchRoute', null)
                    const retorno = res.retorno
                    let msgErro = "";

                    if (!res.status || retorno.dados.length == 0) {
                        msgErro = res.status == false ? (res.erro ? res.erro : (res.mensagem ? res.mensagem : "Um erro genérico aconteceu!")) : "Nenhum registro encontrado!";
                        Swal.fire({
                            text: msgErro,
                            confirmButtonColor: "#34c38f",
                            confirmButtonText: "OK",
                        });
                    }

                    const data = retorno.dados;
                    const columnValues = [];

                    if (currentDataIds.value.length > 0) {
                        emptyPreviousRowSelections();
                    }
                    currentDataIds.value = [];
                    if (data.registros && data.registros.length == 0) {
                        notfound_component.value.style.display = 'block'
                        return []
                    } else {
                        notfound_component.value.style.display = 'none'
                    }

                    currentPageData.value = data[retorno.sis_programa.sis_tabela.nome_tabela].registros;
                    currentRetornoDados.value = data;
                    
                    store.commit('frameworkNavegador/setCurrentPageData', currentPageData.value)

                    const editedColumnsSchema = getEditedColumnsSchema(table_schema.sis_campo_tabela)
                    data[retorno.sis_programa.sis_tabela.nome_tabela].registros.forEach(value => {
                        columnValues.push(
                            editedColumnsSchema.map(col => {
                                if (col.chave_primaria) {
                                    currentDataIds.value.push(value[col.nome])
                                }
                                return formatValue(col, value)
                            })
                        )
                        if(hasDetailGrid.value){
                            columnValues.push([{
                                detail_grid: true, 
                                detail_grid_id: 'dt_g_id_' + currentDataIds.value[currentDataIds.value.length-1], 
                                detail_grid_wrapper_id: 'detail_grid_wrapper_' + currentDataIds.value[currentDataIds.value.length-1]
                            }])
                        }
                    });

                    if(columnValues.length === 0) {
                        notfound_component.value.style.display = 'block'
                    }else{
                        notfound_component.value.style.display = 'none'
                    }
                    return columnValues;
                },
                handle: (res) => {
                    setLoadingSearch(false)

                    if (res.status === 404) return { data: [] };
                    if (res.status === 401) {
                        console.log('invalid token')
                        router.push({
                            name: 'lock-screen-basic', query:
                            {
                                redirectFrom: router.currentRoute.value.fullPath,
                            }
                        });
                    }

                    if (res.ok) return res.json();

                    throw Error(`Error: ${res.statusText}`);
                },
                total: res => res.retorno.dados[res.retorno.sis_programa.sis_tabela.nome_tabela]?.total_registros || 0,
            },
            sort: {
                multiColumn: true,
                server: {
                    url: (prev) => {
                        return prev
                    },
                    body: (prevBody, columns) => {
                        const columnsNormalized = JSON.parse(JSON.stringify(columns))
                        // clear sort icon state
                        const sortBtn = document.querySelectorAll('#table-wrapper .gridjs-th.gridjs-th-sort .gridjs-sort');
                        sortBtn.forEach(btn => {
                            btn.classList.remove('gridjs-sort-asc');
                            btn.classList.remove('gridjs-sort-desc');
                            btn.classList.add('gridjs-sort-neutral');
                        })

                        let lastOrdenations = [];
                        if(lastAcessedProgram.beingReturned && lastAcessedProgram.ordenacao?.length > 0) {
                            columnsNormalized.forEach(column => {
                                let toBeRemoved = [];
                                lastOrdenations = lastAcessedProgram.ordenacao
                                lastAcessedProgram.ordenacao.find((ordenacao, index) => {
                                    if(ordenacao.index === column.index) {
                                        toBeRemoved.push(index);
                                    }
                                })
                                toBeRemoved.forEach(index => { lastOrdenations.splice(index, 1) })
                            })
                            if(columnsNormalized.length === 0) {
                                lastOrdenations = lastAcessedProgram.ordenacao;
                            }
                            columnsNormalized.push(...lastOrdenations);
                        }
                        columnsNormalized.forEach(column => {
                            const id_column = grid.value.config.columns[column.index].id;
                            const th = document.querySelector(`[data-column-id="${id_column}"]`);
                            th.getElementsByClassName('gridjs-sort')[0].classList.remove('gridjs-sort-neutral');
                            th.getElementsByClassName('gridjs-sort')[0]
                                .classList
                                .add(`gridjs-sort-${column.direction == 1 ? 'asc' : 'desc'}`);
                        })
                        let orderStr = '';
                        if (columnsNormalized.length > 0) {
                            columnsNormalized.map((column, i) => {
                                if (i > 0) {
                                    orderStr += ', ';
                                }
                                orderStr += `${grid.value.config.columns[column.index].id} ${column.direction == 1 ? 'asc' : 'desc'}`;
                            })
                        }

                        prevBody = JSON.parse(prevBody)
                        prevBody.ordenacao = orderStr;
                        store.commit('frameworkNavegador/setOrdenacao', columnsNormalized)

                        return JSON.stringify(prevBody);
                    }
                }
            },
        });
        await grid.value.forceRender();

        function setWidth(){
            setTimeout(() => {
                const btns = Array.from(document.querySelectorAll('#table-wrapper .gridjs-pages button'))
                if(btns.length === 2) {
                    store.commit('frameworkNavegador/setTableConfigComponentRightPosition', null);    
                    return setWidth()
                }
                const width = btns.map(btn => btn.offsetWidth + 4.8).reduce((acc, curr) => acc + curr) + 15
                store.commit('frameworkNavegador/setTableConfigComponentRightPosition', width);
            },500)
        }
        
        setWidth()
    }

    function updateCurrentSelectedRows() {
        try {
            currentSelectedRows.value = grid.value.config.plugin.get('tableCheckbox').props.store.state.rowIds;
        } catch (e) {
            currentSelectedRows.value = [];
        }

        return currentSelectedRows.value.length;
    }

    /**
     * 
     * @param {String} functionId 
     * @param {String} targetId 
     * @param {String} route 
     * @param {String} title 
     * @param {String} row 
     * @param {String} htmlTableRow
     * Pass a call to the server of the specified functionality
     */
    async function call_program_function(functionId, tableRowTargetId, backendRoute, title, htmlTableRow) {
        const { registro_id, registro_obj } = getRowRegisterId(tableRowTargetId);
        const body = {
            id_programa: program_schema.sis_programa.id_programa,
            condicao_adicional: "",
            lista_id_empresa: JSON.parse(localStorage.getItem('companiesGlobalFilter')) ?? [],
            data_trabalho: JSON.parse(localStorage.getItem('dataTrabalho')),
            navegador: false,
            ordenacao: null,
            quantidade_registros: 0,
            pagina: 0,
            rota: program_schema.sis_programa.rota_backend + backendRoute,
            dados: {
                [program_schema.sis_tabela.nome_tabela]: {
                    registros: [registro_obj]
                }
            }
        }

        if (title == 'Editar') {
            const query = Object.assign({}, route.query);
            query.registro_id = registro_id;
            query.tipo = 'edicao';
            await router.push({ query });
            store.commit('programForm/setFormType', 'edition');
        } else if (title == 'Visualizar') {
            const query = Object.assign({}, route.query)
            query.registro_id = registro_id
            query.tipo = 'visualizacao'
            await router.push({ query })
            store.commit('programForm/setFormType', 'show')
        } else if (title == 'Excluir') {
            Swal.fire({
                icon: "warning",
                text: `Tem cereteza que deseja Excluir este registro?`,
                confirmButtonText: "OK",
                showCancelButton: true,
                cancelButtonText: "Cancelar",
            }).then(async (result) => {
                if (result.value) {
                    htmlTableRow.style.display = 'none'
                    const index = currentDataIds.value.findIndex(id => id === registro_id);
                    currentDataIds.value.splice(index, 1);
                    grid.value.config.dispatcher.dispatch({
                        type: 'UNCHECK',
                        payload: {
                            ROW_ID: registro_id
                        }
                    });
                    updateCurrentSelectedRows()
                    const res = await sendFunctionRequest(body)
                    if (!res || !res.data.status) {
                        htmlTableRow.style.display = 'table-row';
                        currentDataIds.value.push(registro_id)
                        return false;
                    } else {
                        if (onFunctionSuccessCallBack) onFunctionSuccessCallBack()
                    }
                }
            })
        } else if (title == 'Liberar Permissões') {
            store.commit('permissions/setOnSave', (programasID) => sendFunctionRequest({ ...body, dados: { ...body.dados, sis_programa: { registros: programasID } } }));
            store.commit('permissions/setTitle', title);
            store.commit('permissions/setLoading', true);
            store.commit('permissions/setShow', true);
            store.commit('permissions/setUsersID', [registro_id]);
            store.dispatch('permissions/fetchPermissions',
                {
                    rota: 'obtem_arvore_menu',
                    callback: (data) => mountPermissionsArvore([data.sis_configuracao_sistema])

                });
        } else if (title == 'Liberar Empresas') {
            store.commit('permissions/setOnSave', (empresasID) => sendFunctionRequest({ ...body, dados: { ...body.dados, sis_empresa: { registros: empresasID } } }));
            store.commit('permissions/setTitle', title);
            store.commit('permissions/setLoading', true);
            store.commit('permissions/setShow', true);
            store.commit('permissions/setUsersID', [registro_id]);
            store.dispatch('permissions/fetchPermissions',
                {
                    rota: 'obtem_arvore_menu',
                    callback: (data) => mountPermissionsEmpresa(data.sis_empresa)
                });
        } else if (title == 'Bloquear Permissões') {
            Swal.fire({
                icon: "warning",
                text: `Tem cereteza que deseja bloquear TODAS as permissões deste usuário?`,
                confirmButtonText: "OK",
                showCancelButton: true,
                cancelButtonText: "Cancelar",
            }).then(async (result) => {
                if (result.value) {
                    await sendFunctionRequest(body)
                }
            })
        } else if (title == 'Bloquear Empresas') {
            Swal.fire({
                icon: "warning",
                text: `Tem cereteza que deseja bloquear TODAS as empresas para este usuário?`,
                confirmButtonText: "OK",
                showCancelButton: true,
                cancelButtonText: "Cancelar",
            }).then(async (result) => {
                if (result.value) {
                    await sendFunctionRequest(body)
                }
            })
        }
    }

    function getRowRegisterId(targetId) {
        let id;
        let register;
        let registro_data;
        table_schema.sis_campo_tabela.forEach(col => {
            if (col.chave_primaria) {
                const data = currentPageData.value.find(data => data[col.nome] == targetId);
                id = data[col.nome];
                register = { [col.nome]: id }
                registro_data = data;
            }
        })

        return { registro_id: id, registro_obj: register, registro_data };
    }

    function updateCurrentDataIds(ids) {
        currentDataIds.value = ids
    }

    return {
        setTableColumns,
        currentDataIds,
        formatValue,
        emptyPreviousRowSelections,
        gridRenderData,
        currentSelectedRows,
        updateCurrentDataIds,
        getRowRegisterId,
        updateCurrentSelectedRows,
    }
}
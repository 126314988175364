/**
 * Object that will be responsible for manipulating a form
 * E.g.: 
 * 
 *   const someForm = new BrForm();
 *   <BrFormInput
 *       :formState="someForm"
 *       formType="creation"
 *       :columnSchema="someForm.getColumnByName('denominacao')"
 *       :showLabel="true"
 *   />
 * 
 * editionListSchemas[] will receive an array of objects representing a sis_lista_valor with a property named sis_opcao_lista_valor,
 * so if a list with the specified id already exists it will not make a request again
 */
import { getCodeNamesFromListValue, getTitlesFromListValue } from './getTitlesAndCodeNames.js'
export default class BrForm {
    registers = {}
    initialValues = {}
    mutatedValues = {}
    hasChanged = false
    inCreationStep = false
    mode = 'creation'
    originalValues = {}
    deleted = false
    active = true
    detailTablesForms = null

    /**
     * @param {Object} table_schema 
     */
    constructor(table_schema, inCreationStep = false, mode = 'creation', active = true){
        this.table_schema = JSON.parse(JSON.stringify(table_schema));
        this.dataAlreadyExists = false;
        this.id = Math.random().toString(10).substring(2,5) + Date.now().toString()
        this.inCreationStep = inCreationStep;
        this.mode = mode;
        this.active = active;
    }


    setRegisters(registers) {
        this.registers = registers
    }
    getRegisters() {
        return this.registers
    }
    getId(){
        return this.id;
    }
    /**
     * @param {boolean} bool 
     * used in edition of detail table, to check if the form is in creation step
     */
    setInCreationStep(bool){
        this.inCreationStep = bool;
    }
    /**
     * @returns true if the form is in creation step or false if not
     */
    getInCreationStep(){
        return this.inCreationStep;
    }
    /**
     * used in edition of detail table, to check if the data already exists and can be updated
     */
    setDataAlreadyExists(bool){
        this.dataAlreadyExists = bool;
    }
    setMode(mode, values = null){
        if(values){
            this.initialValues = values;
            this.mutatedValues = values;
        }
        this.mode = mode;
    }
    setActive(bool){
        this.active = bool;
    }
    setDeleted(bool){
        this.deleted = bool;
    }
    dataAlreadyExists(){
        return this.dataAlreadyExists;
    }
    /** 
     * @param {Object} listValue
     * @returns a key-value pair, { key_codeName: 'value_title' }
     */
    getTitlesFromListValue = getTitlesFromListValue
    getCodeNamesFromListValue = getCodeNamesFromListValue
    /**
     * @param {String} columnName 
     * @returns the column schema of the given column name
     */
    getColumnByName(columnName){
        return this.table_schema.sis_campo_tabela.find(col => col.nome == columnName);
    }
   /**
     * @param {String} columnName 
     * @returns the column schema of the given column name
     */
    getColumnById(id){
        return this.table_schema.sis_campo_tabela.find(col => col.id == id);
    }


    getForm(){
        return [this.initialValues, this.mutatedValues, this.hasChanged]
    }
    /**
     * @param {string} key
     * @returns the value specified by the key
     */
    getValue(key){
        return this.mutatedValues[key]
    }
    /**
     * @param {Boolean} json 
     * @returns the mutated values for detail table
     */
    getValuesDetailTable(json){
        const values = this.normalizeValues({...this.mutatedValues})
        return json ? JSON.stringify(values, null, 5) : values
    }
    /**
     * @param {Boolean} json 
     * @returns the mutated values
     */
    getValues(json){
        const values = this.normalizeValues({...this.mutatedValues})
        const returnObject = {
            dados: {
                [this.table_schema.nome_tabela]:{
                    registros: [values]
                }
            }
        }
        return json ? JSON.stringify(returnObject, null, 5) : returnObject
    }
    /**
     * @param {String} key 
     * @param {any} value 
     * 
     * Set the initial values for edition forms
     */
    setInitialValues(key, value){ 
        this.initialValues[key] = value;
    }
    setInitialValuesFromObject(data, supportMultipleInputs){
        for(let i=0; i<this.table_schema.sis_campo_tabela.length; i++){
            const col = this.table_schema.sis_campo_tabela[i]
            let key = col.nome
            if(supportMultipleInputs){
                key = col.id
            }
            this.setOriginalValues(key, data[key])
            this.setInitialValues(key, data[key])
            this.setMutatedValues(key, data[key])
        }
    }
    setOriginalValues(key, value){
        this.originalValues[key] = value
    }
    getOriginalValues(){
        const values = {...this.originalValues}
        return values
    }
    /**
     * @param {String} key 
     * @param {any} value 
     * 
     * Set the current value on change value
     */
    setMutatedValues(key, value){ 
        this.mutatedValues[key] = value
    }
    /**
     * @param {Boolean} bool 
     * 
     * Set if a form was modified or not
     */
    setHasChanged(bool){
        this.hasChanged = bool
    }
    /**
     * @returns if a form was modified or not
     */
    hasChanged(){
        return this.hasChanged
    }

    /**
     * 
     * @param {*} values 
     * @returns normalized values
     */
    normalizeValues(values){
        const returnValues = {}
        this.table_schema.sis_campo_tabela.forEach(column => {
            if (column.tipo_campo == 9 || column.tipo_campo == 10 && values[column.nome]) { //json or jsonb
                try{
                    returnValues[column.nome] = JSON.parse(values[column.nome])
                }catch(e){
                    returnValues[column.nome] = values[column.nome]
                }
            }else if(column.tipo_campo == 2){
                returnValues[column.nome] = values[column.nome] ? true : false
            }else {
                returnValues[column.nome] = values[column.nome]
            }
            if(column.tipo_campo === 12 && column.formato?.includes('N') && values[column.nome]) {
                const value = values[column.nome]
                const removedBrazilianFormat = value.toString().replaceAll('.', '').replace(',', '.')

                returnValues[column.nome] = removedBrazilianFormat
            }
        })
        return returnValues
    }

    /**
     * @param {String} key
     * @param {Object} sis_lista_edicao
     * @param {Object} sis_lista_visualizacao
     * @returns normalized initial value
     */
    normalizeInitialValue(key){
        const column =  this.getColumnByName(key)
        const listValue = this.getListValue(key)
        if(listValue){
            return listValue.sis_opcao_lista_valor.find(opcao => opcao.id === this.initialValues[key])?.titulo ?? ''
        }

        switch(column.tipo_campo){
            case 12: //Numeric
                return Number(this.initialValues[key].toString().replace('.', ','))
            case 7: //Integer
                return parseInt(this.initialValues[key])
            case 4: //date
                return new Date(this.initialValues[key])
            case 5: //date time
                return new Date(this.initialValues[key])
            case 6: //time
                return new Date(this.initialValues[key])
            default:
                return this.initialValues[key]
        }
    }

    /**
     * 
     * @param {String} columnName 
     * @returns sis_lista_edicao or sis_lista_visualizacao
     */
    getListValue(columnName){
        const column = this.getColumnByName(columnName)
        return column.sis_lista_edicao ? column.sis_lista_edicao : column.sis_lista_visualizacao
    }
}
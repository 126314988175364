import Swal from "sweetalert2";
import { ApiService } from "../api.service";
import store from "../../state/store"

async function sendFunctionRequest(body, onSuccess = null, onError = null, showSwal = true, onInitRequest = null){
    if(body.solicita_tfa){
        const {sis_usuario} = JSON.parse(localStorage.getItem('sis_config'))
        const res = await ApiService({
            method: 'POST',
            url: `/check-otp`,
            data: {email: sis_usuario.email}
        })
        if(!res.data.habilitado){
            Swal.fire({
                icon: "error",
                text: "Você não possui autenticação de dois fatores habilitada.",
                confirmButtonColor: "#34c38f",  
                confirmButtonText: "OK",
            });
            if(onError){
                onError("Você não possui autenticação de dois fatores habilitada.")
            }
            return false
        }

        store.commit('massActionsModals/setProperty', [
            {
                key: 'showTFAModal',
                value: true
            },
            {
                key: 'TFAloading',
                value: false
            },
            {
                key: 'TFAonSave',
                value: async (otp_code) => {
                    body.otp = otp_code
                    store.commit('massActionsModals/setProperty', [{key: 'showTFAModal', value: false}])
                    if(onInitRequest){
                        onInitRequest()
                    }
                    return await toBeCalled()
                }
            }
        ])
    }else{
        if(onInitRequest){
            onInitRequest()
        }
        return toBeCalled()
    }

    async function toBeCalled(){
        store.commit('massActionsModals/setProperty', [{key: 'TFAloading', value: true}])
        return await ApiService({
            method: 'POST',
            url: `/program`,
            data: body,
            headers: {'authorization': JSON.parse(localStorage.getItem('user')).token}
        })
        .then((res) => {
            if(!res.data.status){
                if(showSwal){
                    Swal.fire({
                        icon: "error",
                        text: res.data.mensagem ? res.data.mensagem : res.data.erro,
                        confirmButtonColor: "#34c38f",  
                        confirmButtonText: "OK",
                    });
                }
                if(onError){
                    onError(res.data.mensagem ? res.data.mensagem : res.data.erro)
                }
                return res;
            }
            if(onSuccess){
                try{
                    onSuccess(res)
                }catch(error){
                    console.log(error.message)
                }
            }
            return res
        })
        .catch(error => {
            console.log(error.message)
            console.log(error.response?.data)
            if(showSwal){
                Swal.fire({
                    icon: "error",
                    text: error.response?.data?.mensagem ?? error.message,
                    confirmButtonColor: "#34c38f",  
                    confirmButtonText: "OK",
                });
            }
            if(onError){
                onError(error.response?.data?.mensagem ?? error.message)
            }
            return false
        }).finally(() => {
            store.commit('massActionsModals/setProperty', [{key: 'TFAloading', value: false}])
        })
    }
}

export {
    sendFunctionRequest
}
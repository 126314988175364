<script>
import { useStore } from 'vuex'
import ChamadoTemplate from './chamado_template.vue';
import ChamadoPadraoSistema from './chamado_padrao_sistema.vue';

export default {
    props: {
        program_schema: {
            type: Object,
            required: true,
        },
        dados: {
            type: Object,
            required: true,
        },
        registro_id: {
            type: String,
            required: false,
        },
    },
    setup() {
        const store = useStore()
        const formType = store.state.programForm.formType
    
        return {
            formType,
        }
    },
    components: {
        ChamadoTemplate,
        ChamadoPadraoSistema,
    },
}
</script>

<template>
    <div>
        <ChamadoPadraoSistema v-if="formType == 'creation'" :program_schema="program_schema" :dados="dados" :registro_id="registro_id" />
        <ChamadoTemplate v-else :program_schema="program_schema" :dados="dados" :registro_id="registro_id" />
    </div>
</template>
<template>
  <b-modal id="preview-painel" fade scrollable centered size="xl" hide-header>
    <div>
      <h2 class="text-center">{{ formPanel.nome }}</h2>
    </div>
    <div>
      <draggable v-bind="dragOptions" class="dragArea row p-2 p-0" :list="cards" @change="handleChange">
        <transition-group type="transition" name="flip-list">


          <div v-for="(card) in cards" :key="card.id_consulta_painel"
            :class="['col-lg-' + card.configuracao_consulta.colspan, ' d-flex justify-content-center align-items-center']"
            :style="{
        padding: '8px',
        overflowY: 'hidden',
        height: 'calc(' + card.configuracao_consulta.rowspan + ' * ((60em - 20px) / 12))'
      }">

            <Graficos v-if="card.configuracao_consulta" :allConfigComponent="seePreview(card, statemode)">

            </Graficos>

          </div>
        </transition-group>
      </draggable>
    </div>
    <template #footer>
      <b-button variant="primary" v-b-modal.preview-painel>Retornar</b-button>
    </template>
  </b-modal>
</template>

<script>
import { VueDraggableNext } from 'vue-draggable-next'
import { 
  tipoGrafico, 
  getGradient, 
  backgroundColorWithOpacity, datasetStructure } from '@/components/configuracao_consulta/funcionalidadeschart';
import Graficos from '@/components/graficos/index.vue'
import { onMounted, ref, watch } from 'vue';
import moment from 'moment'
export default {
  components: {
    draggable: VueDraggableNext
    , Graficos
  },
  emits: ['updateNewOrderCards'],
  props: {
    cards: {
      type: Array || [],
      required: true
    },
    formPanel: {
      type: Object
    },
    formSisConsultaPanel: {
      type: Array
    }
  },
  setup(props, { emit }) {
    const ordenedCards = ref([...props.cards]);
    const adjustDisplayOrder = () => {
      ordenedCards.value.forEach((card, index) => {
        card.configuracao_consulta.ordem_exibicao = index + 1;
      });
    };

    onMounted(() => {
      adjustDisplayOrder()
    })
    const handleChange = (event) => {
      const moved = event.moved;
      if (moved && moved.element) {
        const { element, oldIndex, newIndex } = moved;
        const movedCard = ordenedCards.value.find(card => card.id_consulta_painel === element.id_consulta_painel);
        const newIndexAdjusted = newIndex + 1; // Adicionando 1 para iniciar a ordem em 1
        if (movedCard) {
          movedCard.configuracao_consulta.ordem_exibicao = newIndexAdjusted;
        }
        // Atualizar ordem_exibicao dos outros cards afetados
        ordenedCards.value.forEach(card => {
          if (card && card !== movedCard) {
            if (oldIndex < newIndex) {
              if (card.configuracao_consulta.ordem_exibicao > oldIndex && card.configuracao_consulta.ordem_exibicao <= newIndexAdjusted) {
                card.configuracao_consulta.ordem_exibicao--;
              }
            } else {
              if (card.configuracao_consulta.ordem_exibicao >= newIndexAdjusted && card.configuracao_consulta.ordem_exibicao < oldIndex + 1) {
                card.configuracao_consulta.ordem_exibicao++;
              }
            }
          }
        });
        // Ordenar cards com base na nova ordem_exibicao
        ordenedCards.value.sort((a, b) => a.configuracao_consulta.ordem_exibicao - b.configuracao_consulta.ordem_exibicao);
        emit('updateNewOrderCards', ordenedCards.value)
      }
    };

    watch(() => props.cards, (newCards) => {
      ordenedCards.value = [...newCards]
    })

    function seePreview(card, state = 'light') {
      //CHARTJS EXAMPLE
      const type = card.tipo_grafico
      const colsp = card.configuracao_consulta.colspan
      const rowsp = card.configuracao_consulta.rowspan

      let interactionOptions = {};
      switch (type) {
        case 3:
          interactionOptions = {
            axis: 'x',
            mode: 'index'
          };
          break;
        case 5:
          interactionOptions = {
            axis: 'xy',
            intersect: false,
            mode: 'nearest',
          };
          break;

        default:
          interactionOptions = {
            axis: 'x',
            intersect: false,
            mode: 'index',
          }
      }
      const configurarChart = (card) => {
        const paginas = card.configuracao_consulta.eixo_x[1];
        const valoresUnicos = [paginas || 'teste'];
        const chartDatas = valoresUnicos.map(valor => {
            // Criar um objeto para esta página de dados
            const paginaData = {
              data: [], // Array para armazenar os dados
              paginacao: valor// Nome da página
            };
  
            // Preencher dados fictícios para cada dia (considerando 7 dias para exemplo)
            for (let j = 1; j <= 7; j++) {
              const data = {};
              card.configuracao_consulta.eixo_y.forEach((v) => {
                // Gerar um valor aleatório para cada métrica no eixo_y
                data[v.titulo || v.nome] = Math.floor(Math.random() * 10000 - 5000);
              });
              paginaData.data.push(data);
            }
  
            // Retornar o objeto de dados da página
            return paginaData;
          });

      const charts = chartDatas.map((obj) => {
        let datasets = []
           card.configuracao_consulta.eixo_y.forEach((v) => {
            return  datasets.push(datasetStructure(v,obj,type,state))
          });
          let mylabels = [];
          for (let j = 1; j <= obj.data.length; j++) {
            let lab = moment(`2024-0${j}-1`, 'YYYY-MM-DD').format('MMMM'); // Extrai o nome completo do mês
            mylabels.push(lab);
          }

          const options = {
            layout: {
                padding: {
                  top: 20,
                  bottom: 20
                }
              },
            responsive: true,
            maintainAspectRatio: false,
            locale: 'pt-BR',
            interaction: interactionOptions,
            plugins: {
              legend: {
                labels: {
                  boxHeight: 7,
                  boxWidth: 12,
                  color: state == 'light' ? '#494949' : '#C9C4C4'
                }
              },
              tooltip: {
                enabled: true,
                callbacks: {
                  label: function (context) {
                    let label = context.parsed.y;
                    let prefix = context.dataset.prefix || '';
                    let suffix = context.dataset.suffix || '';
                    let casadec = context.dataset.casadec;
                    let datasetLabel = context.dataset.label;
                    switch (type) {
                      case 2: // Gráfico de Barra
                        label = context.parsed.y;
                        break;

                      case 3: // Gráfico de Barra Vertical
                        label = context.parsed.x;
                        break;

                      case 4: // Gráfico de Linha
                        label = context.parsed;
                        break;

                      case 5: // Gráfico de Rosca
                        label = context.parsed.y;
                        break;
                      default:
                        label;
                        break;
                    }
                    let value = label.toLocaleString('pt-BR', { minimumFractionDigits: casadec, maximumFractionDigits: casadec });
                    return datasetLabel + ': ' + prefix + value + suffix;
                  }
                }
              },
            
              
            },
            scales: {
          x: {
            ticks: {
              color: state == 'light' ? '#494949' : '#C9C4C4',
              font: { size: 10}
            },
            grid: {
              borderColor:  card.configuracao_consulta.cor_borda,
              color: state == 'light' ? '#C4C4C4' : '#656565', 
            }
          },
       
          right: {
            display:datasets.some(a => a.yAxisID == 'right'),
            position: 'right',
            ticks: {
              color: state == 'light' ? '#494949' : '#C9C4C4',
              autoSkip: false,
              font: { size: 10}
            },
            grid: {
              borderColor: card.configuracao_consulta.cor_borda,
              color: state == 'light' ? '#C4C4C4' : '#656565',
              drawOnChartArea: false, 
            }
          },
          left: {
            display:datasets.some(a => a.yAxisID == 'left'),
            position: 'left',
            ticks: {
              color: state == 'light' ? '#494949' : '#C9C4C4',
              autoSkip: false,
              font: { size: 10}
            },
            grid: {
              borderColor: card.configuracao_consulta.cor_borda,
              color: state == 'light' ? '#C4C4C4' : '#656565'
            }
          },
         
      },
             onClick: (ev, element) => {
                let indexClicked = element[0]?.index;
                if (element) {
                  let datasets = ev.chart.data.datasets;
                  datasets.forEach((dataset) => {
                    if (typeof dataset.backgroundColor === 'function') {
                      let currentColors = dataset.backgroundColor(ev);
                      let currentBorders = dataset.borderWidth(ev)
                      let currentColorBorders = dataset.borderColor(ev)
                      if (Array.isArray(currentColors) && currentColors[indexClicked]) {
                        // Verifica se o elemento já foi clicado anteriormente
                        if (!dataset.clickedIndexes) {
                          dataset.clickedIndexes = [];
                          dataset.originalColors = currentColors.slice();
                          dataset.originalborderWidth = currentBorders.slice();
                          dataset.originalBorderColors = currentColorBorders.slice();
                        }
                        const isClicked = dataset.clickedIndexes.includes(indexClicked);
                        if (isClicked) {
                          // Restaura a cor original do elemento se já clicado
                          currentColors[indexClicked] = dataset.originalColors[indexClicked];
                          currentBorders[indexClicked] = dataset.originalborderWidth[indexClicked];
                          currentColorBorders[indexClicked] = dataset.originalBorderColors[indexClicked];
                          dataset.clickedIndexes =
                            dataset.clickedIndexes.filter(idx =>
                              idx !== indexClicked);
                        } else {
                          currentColors[indexClicked] = backgroundColorWithOpacity(currentColors[indexClicked], 1, 125);
                          currentBorders[indexClicked] = currentBorders[indexClicked] + 3;
                          currentColorBorders[indexClicked] = state == 'light' ? 'black' : 'white';
                          dataset.clickedIndexes.push(indexClicked);
                        }
                        dataset.backgroundColor = () => currentColors;
                        dataset.borderWidth = () => currentBorders;
                        dataset.borderColor = () => currentColorBorders;
                        ev.chart.update();
                      }
                    }
                  });
                }

              }
          };

          if (type === 3) {
            options.indexAxis = 'y';
          }
          const data = {

            labels: mylabels,
            datasets: datasets
          };

          const dataChart = {
            pag: obj.paginacao,
            ref: ref('sadfdsf'),
            data: data,
            options: options
          }
          return dataChart
        })

        const myDataChart = {
          cor_borda: card.configuracao_consulta.cor_borda,
          type: tipoGrafico(type),
          icone: card.configuracao_consulta.icone,
          titulo: card.titulo || 'naofoi',
          id_type: type,
          mydata: charts,
          id: card.id_consulta_painel,
          statemode: state
        }
        return myDataChart
      }

      //CARD EXEMPLO
      const configurarCard = (card) => {
        let valores = [32440.12, 20000.12]
        const valor =
          card.configuracao_consulta.valor.map((v, i) => {
            return {
              label: v.titulo || v.nome,
              valor: valores[i],
              agregador: v.agregador,
              backgroundColor: v.cor,
              prefix: v.prefixo ? v.prefixo + ' ' : v.prefixo,
              suffix: v.sufixo ? ' ' + v.sufixo : v.sufixo,
              casadec: v.casadec,
              rotulos: v.rotulo

            }
          })
        const CardChart = {
          valores: valor,
          colspan: colsp,
          rowspan: rowsp,
          id_type: type,
          icone: card.configuracao_consulta.icone,
          cor_borda: card.configuracao_consulta.cor_borda,
          titulo: card.titulo,
          id: card.id_consulta_painel,
          statemode: state
        }


        if (valor.length > 1) {

          const options = {
            layout: {
              padding: {
                right: 30,
                left: 30
              }
            },
            responsive: true,
            maintainAspectRatio: true,
            locale: 'pt-BR',
            plugins: {
              scales: {
                x: {
                  ticks: {
                    color: state == 'light' ? 'black' : 'white'
                  }
                },
                y: {
                  ticks: {
                    color: state == 'light' ? 'black' : 'white'
                  }
                }
              },
              tooltip: {
                enabled: true,
              },
              legend: {
                display: false
              }
            },
            rotation: 270,
            cutout: '75%',

          }
          const datasets =
          {
            labels: valor.map(a => a.label[0]),
            datasets: [{
              label: valor.map(a => a.label),
              data: [valor.map(a => a.valor)[0]],
              backgroundColor: function (context) {
                const chart = context.chart;
                const { ctx, chartArea } = chart;
                if (!chartArea) {
                  return
                }
                let cor =
                  valor.map((a, i) => i > 0 ?
                    backgroundColorWithOpacity(a.backgroundColor, .09) :
                    getGradient(ctx, chartArea, a.backgroundColor, type))
                return cor
              },
              prefix: valor.map(a => a.prefix),
              suffix: valor.map(a => a.suffix),
              casadec: valor.map(a => a.casadec),
              rotulos: valor.map(a => a.rotulos),
              circumference: 180,
              datalabels: {
                display: (vas) => {
                  let index = vas.dataIndex
                  let boolean = vas.dataset?.rotulos[index]
                  return boolean
                },
                align: 'start',
                borderRadius: 12,
                labels: {
                  title: {
                    font: {
                      family: 'sans-serif',
                      size: 11
                    },
                    color: state == 'light' ? 'black' : 'white',
                    clamp: true,
                    align: 'center',
                    anchor: 'start',
                    backgroundColor: '',
                    formatter: (value, context) => {
                      let index = context.dataIndex;
                      return context.chart.data.datasets[context.datasetIndex].label[index];
                    }
                  },
                  value: {
                    display: false,
                    align: 'center',
                    color: state == 'light' ? 'black' : 'white',
                    font: {
                      size: 11,
                      family: 'sans-serif'
                    },
                    anchor: 'end',
                    formatter: (val, cont) => {
                      let index = cont.dataIndex;
                      let casadec = cont.dataset.casadec[index];
                      let prefix = cont.dataset.prefix[index] || '';
                      let suffix = cont.dataset.suffix[index] || '';
                      let value = cont.dataset.data[index];

                      let teste =
                        (prefix + ' ') +
                        value.toLocaleString('pt-BR', { minimumFractionDigits: casadec, maximumFractionDigits: casadec })
                        + (' ' + suffix);

                      return teste
                    }
                  }
                },
              },
              valorAtual: [valor.map(a => a.valor)[1]]
            }]
          }
          let d = {
            data: datasets,
            options: options,
            type: 'doughnut',
            ref: ref(null)
          }
          CardChart.acelerometro = d
        }
        return CardChart
      }

      //TABELA EXEMPLO

      const configurarTable = (card) => {
        const EixoXTable = card.configuracao_consulta.col.map(v => v)
        const columns = [
          ...EixoXTable,
        ];
        const tableCardData = [];

        for (var i = 1; i <= 100; i++) {
          const tempObj = {}
          columns.forEach(colName => {
            tempObj[colName.titulo || colName.nome] = Math.floor(Math.random() * 1000)
          })
          tableCardData.push(tempObj)
        }

        const dataTable = {
          id_type: type,
          titulo: card.titulo,
          id: card.id_consulta_painel,
          colspan: colsp,
          cor_borda: card.configuracao_consulta.cor_borda,
          icone: card.configuracao_consulta.icone,
          dados: tableCardData,
          colunas: columns,
          statemode: state
        };
        return dataTable
      }
      //PUXAR PREVIEW
      switch (type) {
        case 1:

          return configurarCard(card);

        case 6:
          return configurarTable(card)

        default:
          return configurarChart(card)
      }
    }

    return {
      seePreview,
      handleChange,
      ordenedCards
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 300,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
    statemode() {
      return this.$store.state.layout.mode
    }
  }
}
</script>
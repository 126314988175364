const mountPermissionsArvore = (permissionsArray) => {
    return permissionsArray.map((data) => {
        return {
            title: data.denominacao,
            id: data.id_configuracao_sistema,
            is_group: true,
            checked: false,
            data: data.sis_modulo_sistema.map((data2) => {
                return {
                    title: data2.denominacao,
                    id: data2.id_modulo_sistema,
                    is_group: true,
                    checked: false,
                    data: data2.sis_arvore_menu.map((data3) => {
                        return {
                            title: data3.denominacao,
                            id: data3.id_arvore_menu,
                            is_group: true,
                            checked: false,
                            data: data3.sis_programa.map((data4) => {
                                return {
                                    title: data4.denominacao,
                                    id: data4.id_programa,
                                    is_group: false,
                                    checked: false,
                                    id_name: 'id_programa',
                                }
                            })
                        }
                    })
                }
            })
        }
    })
}

const mountPermissionsEmpresa = (permissionsArray) => {
    return [{
        title: "Todas as Empresas",
        id: Math.random().toString(10).replace('.',''),
        is_group: true,
        checked: false,
        data: permissionsArray.sort((a, b) => a.codigo - b.codigo).map((data) => {
            return {
                title: data.nome,
                id: data.id_empresa,
                is_group: false,
                checked: false,
                id_name: 'id_empresa',
            }
        })
    }]
}

export {
    mountPermissionsArvore,
    mountPermissionsEmpresa
}
<script>
import { useStore } from "vuex"
import { useRoute } from "vue-router"
import Swal from "sweetalert2"
import { ApiService } from "@/helpers/api.service"
import { ref, onMounted, reactive } from "vue"
import putPrimaryKeyInFirstIndex from "@/helpers/putPrimaryKeyInFirstIndex"
import moment from "moment"
import BuscadorSimples from "@/components/buscador_simples"
import PermissionsContainer from "@/components/permissions/container.vue"
import { VueDraggableNext } from 'vue-draggable-next'
import { sendFunctionRequest } from '@/helpers/dynamicTable/sendFunctionRequest'

export default {
  props: {
    programSchema: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const program_schema = {
      sis_programa: props.program_schema,
    }

    onMounted(() => {
      setProgramSchema(params, query)
    })

    const route = useRoute()
    const params = route.params
    const query = route.query
    const store = useStore()

    const dados = ref(null)
    const registro_id = ref(null)
    const pageLoading = ref(true)
    const aguardando_aprovacao_solicitacao = ref(null)
    const em_cotacao = ref(null)
    const aguardando_aprovacao_cotacao = ref(null)
    const pedido_compra = ref(null)
    const id_cotacao = ref(null)
    const nova_situacao = ref(null)

    async function setProgramSchema(params, query, search) {
      const body = {
        id_programa: params.targetId,
        condicao_adicional: "",
        lista_id_empresa:
          JSON.parse(localStorage.getItem("companiesGlobalFilter")) ?? [],
        navegador: false,
        ordenacao: null,
        quantidade_registros: 1000,
        pagina: 0,
        rota: "obtem_dados_programa_usuario",
      }

      if (store.state.programForm.formType !== "navigation") {
        body.navegador = false
      }
      if (query.registro_id) {
        body.id = query.registro_id
        body.quantidade_registros = 1
        registro_id.value = query.registro_id
      }
      if (search?.searchString) {
        body.condicao_adicional = search.searchString.value
      }
      if (search?.date) {
        body.data_movimento = search.date.value
      }

      const res = await ApiService({
        method: "POST",
        url: `/program`,
        data: body,
        headers: {
          authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      })
        .then((res) => {
          const data = res.data

          if (!data.status) {
            Swal.fire({
              icon: "error",
              text: data.mensagem ? data.mensagem : data.erro,
              confirmButtonColor: "#34c38f",
              confirmButtonText: "OK",
            })
            return false
          }

          return data.retorno
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            text: error.response.data.mensagem ?? error.message,
            confirmButtonColor: "#34c38f",
            confirmButtonText: "OK",
          })
          return false
        })

      pageLoading.value = false
      if (!res) return

      program_schema.value = res.sis_programa
      dados.value = res.dados?.cotacao.registros
      aguardando_aprovacao_solicitacao.value = dados.value.filter(item => item.situacao == 1)
      em_cotacao.value = dados.value.filter(item => item.situacao == 2)
      aguardando_aprovacao_cotacao.value = dados.value.filter(item => item.situacao == 3)
      pedido_compra.value = dados.value.filter(item => item.situacao == 4)
      program_schema.value.sis_tabela.sis_campo_tabela.sort(
        (a, b) => a.ordem_exibicao - b.ordem_exibicao
      )

      program_schema.value.sis_tabela.sis_campo_tabela.map((col) => {
        if (col.id_tabela_detalhe) {
          col.dados_tabela_detalhe.sis_tabela.sis_campo_tabela.sort(
            (a, b) => a.ordem_exibicao - b.ordem_exibicao
          )
        }
      })

      program_schema.value.sis_tabela = putPrimaryKeyInFirstIndex(
        program_schema.value.sis_tabela
      )

      store.commit("programForm/setTitle", program_schema.value.denominacao)

      store.state.programForm.formType !== "navigation"
        ? store.commit(
          "programForm/setFormType",
          store.state.programForm.formType
        )
        : store.commit("programForm/setFormType", "navigation")
    }

    function change(event) {
      if (event.added) {
        id_cotacao.value = event.added.element.id_cotacao
      }

    }

    async function newSituation(event) {
      nova_situacao.value = event.to.attributes[2].value

      const body = {
        id_programa: params.targetId,
        condicao_adicional: "",
        lista_id_empresa:
          JSON.parse(localStorage.getItem("companiesGlobalFilter")) ?? [],
        navegador: false,
        ordenacao: null,
        quantidade_registros: 1000,
        pagina: 0,
        dados: {
          cotacao: {
            registros: [
              {
                id_cotacao: id_cotacao.value,
                situacao: nova_situacao.value
              }
            ]
          }
        },
        rota: "/gestao_compra/editar",
      }

      await sendFunctionRequest(body)
        .then((res) => {
          const data = res.data
          if (!data.status) {
            Swal.fire({
              icon: "error",
              text: data.mensagem ? data.mensagem : data.erro,
              confirmButtonColor: "#34c38f",
              confirmButtonText: "OK",
            });
          } else {
            dados.value.forEach(item => {
              if (item.id_cotacao == id_cotacao.value) {
                item.situacao = nova_situacao.value
              }
            })
          }
        })
    }

    const searchObject = reactive({
      searchDisabled: false,
      handleSearch:
        async (searchString, date) => {
          searchObject.searchString = searchString
          searchObject.date = date
          setProgramSchema(params, query, { searchString, date })
        }
    })

    return {
      dados,
      moment,
      searchObject,
      program_schema,
      aguardando_aprovacao_cotacao,
      aguardando_aprovacao_solicitacao,
      em_cotacao,
      change,
      newSituation,
      pedido_compra
    }
  },
  data() {
    return {
      title: "Kanban Board",
      config: {
        wrap: true,
        altFormat: "M j, Y",
        altInput: true,
        dateFormat: "d M, Y",
      },
      viewModal: false,
      date: null,
      date1: null,
      estados: [
        {
          situacao: "1",
          title: "Aguardando Aprovação",
          colorClass: "bg-danger",
        },
        {
          situacao: "2",
          title: "Em Cotação",
          colorClass: "bg-success",
        },
        {
          situacao: "3",
          title: "Aguardando Aprovação",
          colorClass: "bg-warning",
        },
        {
          situacao: "4",
          title: "Pedido de Compra",
          colorClass: "bg-secondary",
        },
      ],
    }
  },
  computed: {
    groupedItems() {
      const grouped = []

      if (this.dados == null) {
        return "Aguardando dados..."
      }

      this.dados.forEach((item) => {
        if (!grouped[item.situacao]) {
          grouped[item.situacao] = []
        }
        grouped[item.situacao].push(item)
      })

      return grouped
    },
  },
  methods: {
    redirect(item, tipo) {
      let program
      let program_id

      if (item.situacao == 1) {
        program = 'solicitacao_compra'
        program_id = '8b3e8c40-a8bb-48dd-9969-4127640cd880'
      }
      else if (item.situacao == 4) {
        program = 'pedido_compra'
        program_id = '577489e8-5917-4718-b335-2c700a1021bc'
      }
      else {
        program = 'cotacao_compra'
        program_id = '04198fc6-1162-4e3e-b4a7-91fba0919c3a'
      }

      this.$router.push(`/suprimentos/movimentacao/${program}/${program_id}?registro_id=${item.id_cotacao}&tipo=${tipo}`)
    }
  },
  components: {
    BuscadorSimples,
    PermissionsContainer,
    draggable: VueDraggableNext,
  }
}
</script>

<template>
  <div class="position-relative">
    <div class="row">
      <div class="card">
        <PermissionsContainer />

        <div class="d-flex flex-column mt-4 mb-4">
          <div class="text-muted px-3 d-flex align-items-center gap-1 flex-wrap">
            <BuscadorSimples :onSearch="searchObject.handleSearch" :loading="searchObject.searchDisabled"
              :placeHolder="'Buscar em ' + program_schema.value?.denominacao + '...'" :filtraData="true" />
          </div>
        </div>
      </div>
      <div class="tasks-board pt-1 position-relative" id="kanbanboard">
        <div class="tasks-list">
          <div class="d-flex mb-3">
            <div class="flex-grow-1">
              <h6 class="fs-14 text-uppercase fw-semibold mb-0">
                {{ estados[0].title }}
                <small :class="estados[0].colorClass + ' badge align-bottom ms-1'">
                  {{ aguardando_aprovacao_solicitacao?.length || 0 }}
                </small>
              </h6>
            </div>
          </div>
          <div data-simplebar class="tasks-wrapper px-3 mx-n3">
            <div class="tasks">
              <draggable 
              :list="aguardando_aprovacao_solicitacao" 
              class="dragArea"
              :group="{ name: 'groupedItems', put: true }" 
              @end="newSituation" @change="change" data-situacao="1"
              style="min-height:200px;">
                <div class="card tasks-box border card-border" v-for="data of aguardando_aprovacao_solicitacao"
                  :key="data.id">
                  <div class="card-body">
                    <div class="d-flex mb-2">
                      <h6 class="fs-15 mb-0 flex-grow-1 text-truncate">
                        {{ data.empresa }}
                      </h6>
                      <div class="dropdown">
                        <a href="javascript:void(0)" class="text-muted" id="dropdownMenuLink1" data-bs-toggle="dropdown"
                          aria-expanded="false"><i class="ri-more-fill" />
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink1">
                          <li>
                            <a class="dropdown-item" role="button" @click="redirect(data, 'visualizacao')">
                              <i class="ri-eye-fill align-bottom me-2 text-muted" />
                              Visualizar
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" role="button" @click="redirect(data, 'edicao')">
                              <i class="ri-edit-2-line align-bottom me-2 text-muted" />
                              Editar
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" role="button">
                              <i class="ri-delete-bin-5-line align-bottom me-2 text-muted" />
                              Deletar
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="row mb-1">
                      <div class="col d-flex">
                        <div class="d-flex text-start">
                          <span style="white-space: nowrap">
                            {{ moment(data.data_inclusao).format("DD/MM/YY") }}
                          </span>
                        </div>
                      </div>
                      <div class="col d-flex justify-content-end">
                        <div class="d-flex text-start">
                          <span style="white-space: nowrap">{{ data.numero }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-1">
                      <div class="col d-flex">
                        <div class="d-flex text-start">
                          <span style="white-space: nowrap">{{ data.usuario_inclusao }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-1">
                      <div class="col-8 d-flex">
                        <div class="d-flex text-start">
                          <span>
                            {{ data.responsavel }}
                          </span>
                        </div>
                      </div>
                      <div class="col-4 d-flex justify-content-end">
                        <div class="d-flex text-start">
                          <span style="white-space: nowrap">{{ data.data_validade ?
                            moment(data.data_validade).format("DD/MM/YY")
                            : null }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </draggable>
            </div>
          </div>
        </div>

        <div class="tasks-list">
          <div class="d-flex mb-3">
            <div class="flex-grow-1">
              <h6 class="fs-14 text-uppercase fw-semibold mb-0">
                {{ estados[1].title }}
                <small :class="estados[1].colorClass + ' badge align-bottom ms-1'">
                  {{ em_cotacao?.length || 0 }}
                </small>
              </h6>
            </div>
          </div>
          <div data-simplebar class="tasks-wrapper px-3 mx-n3">
            <div class="tasks">
              <draggable :list="em_cotacao" class="dragArea" :group="{ name: 'groupedItems', put: true }"
                @end="newSituation" @change="change" data-situacao="2" style="min-height:200px;">
                <div class="card tasks-box border card-border" v-for="data of em_cotacao" :key="data.id">
                  <div class="card-body">
                    <div class="d-flex mb-2">
                      <h6 class="fs-15 mb-0 flex-grow-1 text-truncate">
                        {{ data.empresa }}
                      </h6>
                      <div class="dropdown">
                        <a href="javascript:void(0)" class="text-muted" id="dropdownMenuLink1" data-bs-toggle="dropdown"
                          aria-expanded="false"><i class="ri-more-fill" />
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink1">
                          <li>
                            <a class="dropdown-item" role="button" @click="redirect(data, 'visualizacao')">
                              <i class="ri-eye-fill align-bottom me-2 text-muted" />
                              Visualizar
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" role="button" @click="redirect(data, 'edicao')">
                              <i class="ri-edit-2-line align-bottom me-2 text-muted" />
                              Editar
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" role="button">
                              <i class="ri-delete-bin-5-line align-bottom me-2 text-muted" />
                              Deletar
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="row mb-1">
                      <div class="col d-flex">
                        <div class="d-flex text-start">
                          <span style="white-space: nowrap">
                            {{ moment(data.data_inclusao).format("DD/MM/YY") }}
                          </span>
                        </div>
                      </div>
                      <div class="col d-flex justify-content-end">
                        <div class="d-flex text-start">
                          <span style="white-space: nowrap">{{ data.numero }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-1">
                      <div class="col d-flex">
                        <div class="d-flex text-start">
                          <span style="white-space: nowrap">{{ data.usuario_inclusao }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-1">
                      <div class="col-8 d-flex">
                        <div class="d-flex text-start">
                          <span>
                            {{ data.responsavel }}
                          </span>
                        </div>
                      </div>
                      <div class="col-4 d-flex justify-content-end">
                        <div class="d-flex text-start">
                          <span style="white-space: nowrap">{{ data.data_validade ?
                            moment(data.data_validade).format("DD/MM/YY")
                            : null }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </draggable>
            </div>
          </div>
        </div>

        <div class="tasks-list">
          <div class="d-flex mb-3">
            <div class="flex-grow-1">
              <h6 class="fs-14 text-uppercase fw-semibold mb-0">
                {{ estados[2].title }}
                <small :class="estados[2].colorClass + ' badge align-bottom ms-1'">
                  {{ aguardando_aprovacao_cotacao?.length || 0 }}
                </small>
              </h6>
            </div>
          </div>
          <div data-simplebar class="tasks-wrapper px-3 mx-n3">
            <div class="tasks">
              <draggable :list="aguardando_aprovacao_cotacao" class="dragArea"
                :group="{ name: 'groupedItems', put: true }" @end="newSituation" @change="change" data-situacao="3"
                style="min-height:200px;">
                <div class="card tasks-box border card-border" v-for="data of aguardando_aprovacao_cotacao"
                  :key="data.id">
                  <div class="card-body">
                    <div class="d-flex mb-2">
                      <h6 class="fs-15 mb-0 flex-grow-1 text-truncate">
                        {{ data.empresa }}
                      </h6>
                      <div class="dropdown">
                        <a href="javascript:void(0)" class="text-muted" id="dropdownMenuLink1" data-bs-toggle="dropdown"
                          aria-expanded="false"><i class="ri-more-fill" />
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink1">
                          <li>
                            <a class="dropdown-item" role="button" @click="redirect(data, 'visualizacao')">
                              <i class="ri-eye-fill align-bottom me-2 text-muted" />
                              Visualizar
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" role="button" @click="redirect(data, 'edicao')">
                              <i class="ri-edit-2-line align-bottom me-2 text-muted" />
                              Editar
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" role="button">
                              <i class="ri-delete-bin-5-line align-bottom me-2 text-muted" />
                              Deletar
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="row mb-1">
                      <div class="col d-flex">
                        <div class="d-flex text-start">
                          <span style="white-space: nowrap">
                            {{ moment(data.data_inclusao).format("DD/MM/YY") }}
                          </span>
                        </div>
                      </div>
                      <div class="col d-flex justify-content-end">
                        <div class="d-flex text-start">
                          <span style="white-space: nowrap">{{ data.numero }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-1">
                      <div class="col d-flex">
                        <div class="d-flex text-start">
                          <span style="white-space: nowrap">{{ data.usuario_inclusao }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-1">
                      <div class="col-8 d-flex">
                        <div class="d-flex text-start">
                          <span>
                            {{ data.responsavel }}
                          </span>
                        </div>
                      </div>
                      <div class="col-4 d-flex justify-content-end">
                        <div class="d-flex text-start">
                          <span style="white-space: nowrap">{{ data.data_validade ?
                            moment(data.data_validade).format("DD/MM/YY")
                            : null }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </draggable>
            </div>
          </div>
        </div>

        <div class="tasks-list">
          <div class="d-flex mb-3">
            <div class="flex-grow-1">
              <h6 class="fs-14 text-uppercase fw-semibold mb-0">
                {{ estados[3].title }}
                <small :class="estados[3].colorClass + ' badge align-bottom ms-1'">
                  {{ pedido_compra?.length || 0 }}
                </small>
              </h6>
            </div>
          </div>
          <div data-simplebar class="tasks-wrapper px-3 mx-n3">
            <div class="tasks">
              <draggable :list="pedido_compra" class="dragArea" :group="{ name: 'groupedItems', put: true }"
                @end="newSituation" @change="change" data-situacao="4" style="min-height:200px;">
                <div class="card tasks-box border card-border" v-for="data of pedido_compra" :key="data.id">
                  <div class="card-body">
                    <div class="d-flex mb-2">
                      <h6 class="fs-15 mb-0 flex-grow-1 text-truncate">
                        {{ data.empresa }}
                      </h6>
                      <div class="dropdown">
                        <a href="javascript:void(0)" class="text-muted" id="dropdownMenuLink1" data-bs-toggle="dropdown"
                          aria-expanded="false"><i class="ri-more-fill" />
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink1">
                          <li>
                            <a class="dropdown-item" role="button" @click="redirect(data, 'visualizacao')">
                              <i class="ri-eye-fill align-bottom me-2 text-muted" />
                              Visualizar
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" role="button" @click="redirect(data, 'edicao')">
                              <i class="ri-edit-2-line align-bottom me-2 text-muted" />
                              Editar
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" role="button">
                              <i class="ri-delete-bin-5-line align-bottom me-2 text-muted" />
                              Deletar
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="row mb-1">
                      <div class="col d-flex">
                        <div class="d-flex text-start">
                          <span style="white-space: nowrap">
                            {{ moment(data.data_inclusao).format("DD/MM/YY") }}
                          </span>
                        </div>
                      </div>
                      <div class="col d-flex justify-content-end">
                        <div class="d-flex text-start">
                          <span style="white-space: nowrap">{{ data.numero }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-1">
                      <div class="col d-flex">
                        <div class="d-flex text-start">
                          <span style="white-space: nowrap">{{ data.usuario_inclusao }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-1">
                      <div class="col-8 d-flex">
                        <div class="d-flex text-start">
                          <span>
                            {{ data.responsavel }}
                          </span>
                        </div>
                      </div>
                      <div class="col-4 d-flex justify-content-end">
                        <div class="d-flex text-start">
                          <span style="white-space: nowrap">{{ data.data_validade ?
                            moment(data.data_validade).format("DD/MM/YY")
                            : null }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </draggable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!-- <b-modal
      v-model="viewModal"
      title="Adicione Arquivos"
      fade
      scrollable
      centered
      size="xl"
      hide-footer
    >
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <div class="text-muted">
                <h6 class="mb-3 fw-semibold text-uppercase">Summary</h6>
                <span>
                  It will be as simple as occidental in fact, it will be
                  Occidental. To an English person, it will seem like simplified
                  English, as a skeptical Cambridge friend of mine told me what
                  Occidental is. The European languages are members of the same
                  family. Their separate existence is a myth. For science,
                  music, sport, etc, Europe uses the same vocabulary. The
                  languages only differ in their grammar, their pronunciation
                  and their most common words.
                </span>

                <h6 class="mb-3 fw-semibold text-uppercase">Sub-tasks</h6>
                <ul class="ps-3 list-unstyled vstack gap-2">
                  <li>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="productTask"
                      />
                      <label class="form-check-label" for="productTask">
                        Product Design, Figma (Software), Prototype
                      </label>
                    </div>
                  </li>
                  <li>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="dashboardTask"
                        checked
                      />
                      <label class="form-check-label" for="dashboardTask">
                        Dashboards : Ecommerce, Analytics, Project,etc.
                      </label>
                    </div>
                  </li>
                  <li>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="calenderTask"
                      />
                      <label class="form-check-label" for="calenderTask">
                        Create calendar, chat and email app pages
                      </label>
                    </div>
                  </li>
                  <li>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="authenticationTask"
                      />
                      <label class="form-check-label" for="authenticationTask">
                        Add authentication pages
                      </label>
                    </div>
                  </li>
                </ul>

                <div class="pt-3 border-top border-top-dashed mt-4">
                  <h6 class="mb-3 fw-semibold text-uppercase">Tasks Tags</h6>
                  <div class="hstack flex-wrap gap-2 fs-15">
                    <div class="badge fw-medium badge-soft-info">UI/UX</div>
                    <div class="badge fw-medium badge-soft-info">Dashboard</div>
                    <div class="badge fw-medium badge-soft-info">Design</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </b-modal> -->
</template>

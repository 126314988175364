class SaldoController{

    constructor(id_conta_financeira, denominacao, saldo_disponivel_api, cheque_especial, cheque_troco){
        this.id_conta_financeira = id_conta_financeira
        this.denominacao = denominacao
        this.saldo_disponivel_api = saldo_disponivel_api ?? '0.00'
        this.cheque_especial = cheque_especial ?? '0.00'
        this.cheque_troco = cheque_troco ?? '0.00'
    }

    set_by_key(key, value){
        this[key] = value
    }
    get_by_key(key){
        if(key === 'saldo_disponivel_api' || key === 'cheque_especial' || key === 'cheque_troco'){
            return parseFloat(this[key].toString().replace(',', '.')).toFixed(2)
        }
        return this[key]
    }
    get_all(){
        return {
            id_conta_financeira: this.id_conta_financeira,
            denominacao: this.denominacao,
            saldo_disponivel_api: parseFloat(this.get_by_key('saldo_disponivel_api')),
            cheque_especial: parseFloat(this.get_by_key('cheque_especial')),
            cheque_troco: parseFloat(this.get_by_key('cheque_troco'))
        }
    }
}

export { SaldoController }
import moment from "moment";
import { Mask } from "maska";
import { h } from "gridjs";

export default function formatColumnValue(
  column,
  valueObj,
  functions,
  conferirDesconferir
) {
  let key = column.id ? column.id : column.nome;
  let toBeReturned = valueObj[key];

  if (typeof toBeReturned === "undefined" || toBeReturned === null) {
    return "";
  }

  switch (column.tipo_campo) {
    case 1: //String
      if (key == "denominacao_forma_recebimento") {
        if (valueObj["nsu"]) {
          toBeReturned = valueObj[key] + " - " + valueObj["nsu"];
        }
        if (valueObj["numero_cheque"]) {
          toBeReturned =
            valueObj[key] + " - " + valueObj["numero_cheque"];
        }
      }
      break;
    case 2: //Boolean
      if (key == "conferido") {
        const buttonClass = valueObj[key]
          ? "btn-success right"
          : "btn-danger";
        const iconClass = valueObj[key]
          ? "ms-2 ri-check-line"
          : "me-2 ri-close-line";
        const spanClass = valueObj[key] ? "Sim" : "Não";
        const rota = valueObj[key]
          ? functions.find(
              (campo_tabela) =>
                campo_tabela.id_funcionalidade ===
                "220fb561-c912-4b5a-8d7d-bf0bdb258ca0"
            ).rota_backend //Desconferir
          : functions.find(
              (campo_tabela) =>
                campo_tabela.id_funcionalidade ===
                "b0b4acf2-4e22-42cb-a43a-77d97daffc06"
            ).rota_backend; //Conferir

        toBeReturned = h("div", {
          className:
            "btn btn-sm btn-label waves-effect waves-light rounded-pill " +
            buttonClass,
          children: [
            h("i", {
              className: "label-icon align-middle rounded-pill " + iconClass,
            }),
            h("span", { className: "button-text" }, spanClass),
          ],
          style:
            "display: flex; align-items: center; justify-content: center; min-width:85px",
          onClick: () => {
            conferirDesconferir(rota, valueObj.id_comprovante);
          },
        });
      }
      if (key != "conferido" && key != "conciliado") {
        toBeReturned = valueObj[key] ? "Sim" : "Não";
      }
      break;
    case 4: //Date
      toBeReturned = valueObj[key] && !moment(valueObj[key], 'DD/MM/YYYY', true).isValid() ? moment(valueObj[key]).format("DD/MM/YYYY") : valueObj[key];
      break;
    case 5: //Date Time
      toBeReturned = valueObj[key] && !moment(valueObj[key], 'DD/MM/YYYY H:mm', true).isValid() ? moment(valueObj[key]).format("DD/MM/YYYY H:mm") : valueObj[key];
      break;
    case 6: //Time
      toBeReturned = valueObj[key] && !moment(valueObj[key], 'H[h]m[m]s[s]', true).isValid() ? moment(valueObj[key]).format("H[h]m[m]s[s]") : valueObj[key];
      break;
    default:
      toBeReturned = setMaskedValue();
  }

  function setMaskedValue() {
    if (!column.formato) return toBeReturned;

    if (column.formato.includes("N")) {
      const [, precision] = column.formato.split("N");
      const value = Number(parseFloat(valueObj[key]).toFixed(precision));

      const valueFormatted = Intl.NumberFormat('pt-BR', { 
        minimumFractionDigits: Number(precision)
      }).format(value);

      return valueFormatted
    }

    let type = "";
    const maskStr = column.formato;
    try {
      JSON.parse(maskStr);
      type = "array";
    } catch (e) {
      type = "string";
    }

    try {
      switch (type) {
        case "string":
          return new Mask({ mask: maskStr }).masked(valueObj[key]);
        case "array":
          return new Mask({ mask: JSON.parse(maskStr) }).masked(
            valueObj[key]
          );
        default:
          return toBeReturned;
      }
    } catch (error) {
      console.log(error);
    }
  }

  return toBeReturned;
}

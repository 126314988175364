async function bodyFunctionRequest(id_programa, dados, rota, id, qtd){
    return {
        id_programa: id_programa,
        condicao_adicional: "",
        lista_id_empresa: JSON.parse(localStorage.getItem('companiesGlobalFilter')) ?? [],
        data_trabalho: JSON.parse(localStorage.getItem('dataTrabalho')),
        navegador: false,
        ordenacao: null,
        quantidade_registros: qtd ?? 0,
        pagina: 0,
        dados: dados,
        rota: rota,
        id: "" + id
      }
}

export {
    bodyFunctionRequest
}
<script>
import Swal from 'sweetalert2';
import { computed, onMounted, reactive, ref } from 'vue';
import { VueDraggableNext } from 'vue-draggable-next';
import { useStore } from 'vuex';

export default {
    props: {
        programSchema: {
            type: Object,
            required: true,
        },
        restartGrid: {
            type: Function,
            required: true,
        }
    },
    setup(props) {
        const store = useStore()
        const showConfigTableModal = ref(false);
        const loadingSave = ref(false);
        const columns = ref(props.programSchema.sis_tabela.sis_campo_tabela.filter(sct => sct.exibe_navegador && sct.tipo_campo !== 15));
        const config_program_table = reactive({
            id_programa: props.programSchema.id_programa,
            qty_registers: 10,
            columns: columns.value.map(c => { return { id: c.id_campo_tabela, order: c.ordem_exibicao, show: c.exibe_navegador, minWidth: null, isPrimaryKey: c.chave_primaria } }),
        });
        const helpText = [
            'A largura minima das colunas serão "travadas" de acordo com a largura atual.',
            'Reseta a largura das colunas.',
            'Reseta a visão das colunas (ordenação, visível/oculto)',
        ]
        const columnsToSort = ref([...config_program_table.columns])
        const getColumnName = computed(() => {
            return (id) => {
                return columns.value.find(c => c.id_campo_tabela === id).titulo;
            }
        });
        const rightPosition = computed(() => {
            return store.state.frameworkNavegador.tableConfigComponentRightPosition
        })
        const setConfig = () => {
            let config = JSON.parse(localStorage.getItem('config_program_table')) || [];
                const targetIndex = config.findIndex(obj => obj.id_programa === props.programSchema.id_programa)
                if(targetIndex !== -1){
                    config_program_table.qty_registers = config[targetIndex].qty_registers
                    config_program_table.columns = config[targetIndex].columns.sort((a,b) => a.order - b.order)
                }
                columnsToSort.value = [...config_program_table.columns]
        }
        onMounted(() => {
            setConfig()
        })

        const updateConfigTableModal = (bool) => {
            showConfigTableModal.value = bool;
            if(bool){
                setConfig()
            }
        }
        const lockColumns = (bool) => {
            if(bool) {
                for(let column of config_program_table.columns){
                    column.minWidth = document.querySelector(`th[data-id-campo-tabela="${column.id}"]`)?.offsetWidth + 'px' ?? '150px'
                }
                save(false)
                Swal.fire({
                    text: `A largura das colunas de [${props.programSchema.denominacao}] foram travadas!`,
                    icon: 'success',
                    confirmButtonText: 'OK'
                }).then(() => {
                    updateConfigTableModal(false)
                    setTimeout(() => {
                        props.restartGrid()
                    },100)
                })
            } else {
                for(let column of config_program_table.columns){
                    column.minWidth = null
                }
                save(false)
                Swal.fire({
                    text: `A largura das colunas de [${props.programSchema.denominacao}] foram destravadas!`,
                    icon: 'success',
                    confirmButtonText: 'OK'
                }).then(() => {
                    updateConfigTableModal(false)
                    setTimeout(() => {
                        props.restartGrid()
                    },100)
                })
            }
        }
        const resetColumnView = () => {
            for(let column of config_program_table.columns){
                const originalColumnData = getColumnData(column.id)
                column.show = originalColumnData?.exibe_navegador
                column.order = originalColumnData?.ordem_exibicao ?? 0
            }
            
            save(false, config_program_table.columns)
            Swal.fire({
                text: `A visão das colunas de [${props.programSchema.denominacao}] foi resetada!`,
                icon: 'success',
                confirmButtonText: 'OK'
            }).then(() => {
                updateConfigTableModal(false)
                setTimeout(() => {
                    props.restartGrid()
                },100)
            })
        }
        const save = (showSaveSwal = true, configColumns = null) => {
            if(!configColumns){
                config_program_table.columns = columnsToSort.value.map((cts, index) => {
                    cts.order = index + 1
                    return cts
                })
            }
            let config = JSON.parse(localStorage.getItem('config_program_table')) || [];
            const targetIndex = config.findIndex(obj => obj.id_programa === props.programSchema.id_programa)
            if(targetIndex !== -1){
                config[targetIndex] = config_program_table
            } else {
                config.push(config_program_table)
            }
            localStorage.setItem('config_program_table', JSON.stringify(config));
            if(showSaveSwal){
                Swal.fire({
                    text: `Salvo com sucesso!`,
                    icon: 'success',
                    confirmButtonText: 'OK'
                }).then(() => {
                    updateConfigTableModal(false)
                    setTimeout(() => {
                        props.restartGrid()
                    },100)
                })
            }
        }

        const dragOptions = {
            animation: 300,
            group: 'description',
            disabled: false,
            ghostClass: 'ghost',
        }
        function getColumnData(id_campo_tabela){
            return props.programSchema.sis_tabela.sis_campo_tabela.find(sct => sct.id_campo_tabela === id_campo_tabela)
        }
        const setShowColumn = (index, bool) => {
            columnsToSort.value[index].show = bool
        }
        const change_qty_registers = (event) => {
            const {value} = event.target
            config_program_table.qty_registers = value
            store.commit('frameworkNavegador/setPage', 0);
            save()
        }
        const getSelectedQty = computed(() => {
            return (value) => value == config_program_table.qty_registers
        })
        const onMove = (event) => {
            if(event.draggedContext.element.isPrimaryKey ||  event.relatedContext.element.isPrimaryKey) return false
        }

        return {
            dragOptions,
            getColumnName,
            showConfigTableModal,
            loadingSave,
            columns,
            config_program_table,
            lockColumns,
            save,
            updateConfigTableModal,
            getColumnData,
            columnsToSort,
            setShowColumn,
            resetColumnView,
            rightPosition,
            change_qty_registers,
            getSelectedQty,
            onMove,
            helpText
        }
    },
    components: {
        VueDraggableNext
    },
};
</script>
    
<template>
    <b-modal size="xl" hide-footer fade centered hide-header body-class="p-0 border-0" footer-class="border-0" v-model="showConfigTableModal" @hidden="updateConfigTableModal(false)">
        <div class="modal-content border-0">
            <div class="modal-body p-0">
                <div class="px-4 my-4">
                    <h4 class="">Configuração de Tabela - {{programSchema.denominacao}}</h4>
                </div>
                <div class="px-4 mb-4">
                    <ul class="nav nav-tabs mb-3" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" data-bs-toggle="tab" href="#arrow-tab1" role="tab" aria-selected="true">
                                <span class="">Geral</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="tab" href="#arrow-tab2" role="tab">
                                <span class="">Visão de Colunas</span>
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane active" id="arrow-tab1" role="tabpanel">
                            <div class="border p-3 mb-0">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="gap-2 d-grid general-grid">
                                            <div class="input-group">
                                                <button @click="lockColumns(true)" class="btn btn-soft-primary border py-0 rounded-0 rounded-start" style="height:37px;">
                                                    <i class="mdi mdi-arrow-horizontal-lock align-middle fs-18"></i> Travar largura das colunas
                                                </button>
                                                <div class="d-flex align-items-center px-1 bg-light rounded-end text-primary" v-b-tooltip.hover.top="helpText[0]">
                                                    <i class="mdi mdi-help-circle fs-18"></i>
                                                </div>
                                            </div>
                                            <div class="input-group">
                                                <button  @click="lockColumns(false)" class="btn btn-soft-primary border py-0" style="height:37px;">
                                                    <i class="mdi mdi-arrow-expand-horizontal align-middle fs-18"></i> Destravar colunas
                                                </button>
                                                <div class="d-flex align-items-center px-1 bg-light rounded-end text-primary" v-b-tooltip.hover.top="helpText[1]">
                                                    <i class="mdi mdi-help-circle fs-18"></i>
                                                </div>
                                            </div>
                                            <div class="input-group">
                                                <button @click="resetColumnView()" class="btn btn-soft-primary border py-0" style="height:37px;">
                                                    <i class="ri-restart-fill align-middle fs-18"></i> Resetar visão das colunas
                                                </button>
                                                <div class="d-flex align-items-center px-1 bg-light rounded-end text-primary" v-b-tooltip.hover.top="helpText[2]">
                                                    <i class="mdi mdi-help-circle fs-18"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="arrow-tab2" role="tabpanel">
                            <div class="border p-3 mb-0">
                                <div class="row">
                                    <div class="col-12">
                                        <div v-if="columnsToSort.length">
                                            <VueDraggableNext
                                                :list="columnsToSort"
                                                v-bind="dragOptions"
                                                :move="onMove"
                                            >
                                                <transition-group type="transition" name="flip-list">
                                                    <div v-for="(col, index) in columnsToSort" :key="col.order" class="d-flex gap-1 flex-row mb-1 draggable-item">
                                                        <div class="d-flex text bg-light border-dashed border cursor-pointer" @click="setShowColumn(index, !col.show)">
                                                            <span class="p-2 text-center" style="width: 40px;">
                                                                <i :class="['link-primary', col.show ? 'mdi mdi-eye' : 'mdi mdi-eye-off']"></i>
                                                            </span>
                                                        </div>
                                                        <div class="flex-1 d-flex text bg-light border-dashed border">
                                                            <span class=" p-2 text-center" style="width:40px;">
                                                                <i :class="['link-primary', col.isPrimaryKey ? 'ri-lock-2-fill' : 'ri-drag-move-2-line']"></i>
                                                            </span>
                                                            <span class=" p-2 flex-1 fs-14 text-primary" style="">{{ getColumnData(col.id)?.titulo }} <span class="small ms-3">({{getColumnData(col.id)?.nome}})</span></span>
                                                        </div>
                                                    </div>
                                                </transition-group>
                                            </VueDraggableNext>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer px-4 pt-2 d-flex justify-content-between" style="max-height: 75px;">
                <div class="d-flex gap-1 ms-auto">
                    <button class="btn btn-light border" style="width: 135px;" data-bs-dismiss="modal">Fechar</button>
                    <button v-if="!loadingSave" @click="save" type="button" class="btn btn-primary" style="width: 135px;">Salvar</button>
                    <button v-else class="btn btn-primary btn-load" style="width: 135px;">
                        <span class="d-flex align-items-center justify-content-center gap-2">
                            <span class="spinner-border flex-shrink-0" role="status"></span> Salvar
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </b-modal>

    <div :style="{right: rightPosition ? rightPosition+'px' : '15px'}" id="config_table" class="d-flex align-items-center">
        <button  @click="updateConfigTableModal(true)" type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-18 px-2" v-b-tooltip.hover.top="'Configurar Tabela'">
            <i class="ri-settings-3-fill align-bottom text-primary"></i>
        </button>
        <select class="form-control p-1 text-center select ms-1" style="height:29.5px;" @change="change_qty_registers">
            <option value="200" :selected="getSelectedQty(200)">200</option>
            <option value="100" :selected="getSelectedQty(100)">100</option>
            <option value="50" :selected="getSelectedQty(50)">50</option>
            <option value="10" :selected="getSelectedQty(10)">10</option>
            <option value="1" :selected="getSelectedQty(1)">1</option>
        </select>
    </div>
</template>
<style @scoped>
#config_table {
    position: absolute;
    bottom: 44px;
    z-index: 100;
}
.general-grid{
    grid-template-columns: 1fr 1fr;
}
.select{
    background-color: var(--vz-input-bg);
    background-clip: padding-box;
    border: 1px solid var(--vz-input-border);
}
</style>
import Swal from "sweetalert2";
import { ApiService } from "../../helpers/api.service";

async function getValueListById(listId, stringMatch){
    const body = {
        condicao_adicional: stringMatch,
        lista_id_empresa: JSON.parse(localStorage.getItem('companiesGlobalFilter')) ?? [],
        data_trabalho: JSON.parse(localStorage.getItem('dataTrabalho')),
        navegador: false,
        ordenacao: null,
        quantidade_registros: 0,
        pagina: 0,
        rota: 'obtem_dados_lista_valor',
        id_lista_valor: listId
    }

    return await ApiService({
        method: 'POST',
        url: `/program`,
        data: body,
        headers: {'authorization': JSON.parse(localStorage.getItem('user')).token}
    })
    .then(res => {
        const data = res.data;

        if(!data.status){
            Swal.fire({
                icon: "error",
                text: data.mensagem ? data.mensagem : data.erro,
                confirmButtonColor: "#34c38f",  
                confirmButtonText: "OK",
            });
            return false;
        }

        return data.retorno;
    })
    .catch(error => {
        Swal.fire({
            icon: "error",
            text: error.response.data.mensagem ?? error.message,
            confirmButtonColor: "#34c38f",  
            confirmButtonText: "OK",
        });
        return false;
    })
}

export {
    getValueListById
}
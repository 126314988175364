<script>
import moment from 'moment';

export default {
    props: {
        comment: {
            type: Object,
            required: true,
        },
        mentioned_comment: {
            type: Function,
            required: true,
        },
        function_show_document: {
            type: Object,
            required: true,
        },
        function_download_document: {
            type: Object,
            required: true,
        },
        function_remove_document: {
            type: Object,
            required: true,
        },
        current_user: {
            type: Object,
            required: true,
        },
        showFiles: {
            type: Function,
            required: true,
        },
        downloadFile: {
            type: Function,
            required: true,
        },
        remove_document: {
            type: Function,
            required: true,
        },
        comment_to_mention: {
            type: Function,
            required: false,
        },
    },
    setup() {
        return {
            moment,
        }
    },
};
</script>
<template>
    <div class="d-flex flex-column mb-2">
        <div class="d-flex align-items-center gap-3">
            <div style="width:32px;">
                <img :src="'https://api.dicebear.com/9.x/thumbs/svg?seed=' + comment.usuario_inclusao" alt="" class="avatar-xs rounded-circle" />
            </div>
            <h5 class="fs-13 fw-semibold mb-0">{{ comment.usuario_inclusao }} <small class="text-muted">{{moment(comment.data_inclusao).format("DD MMM YYYY - H:mm")}}</small></h5>
        </div>
        <div class="d-flex align-items-center gap-3">
            <div style="width:32px;"></div>
            <div class="flex-grow-1 bg-light rounded">                                    
                <div v-if="mentioned_comment(comment.id_chamado_ocorrencia_mencionado)" class="ms-3 pt-1 px-1 mt-2 mb-2 bg-light rounded opacity-75 border-start border-3">
                    <div class="d-flex mt-4">
                        <div class="flex-shrink-0">
                            <img :src="'https://api.dicebear.com/9.x/thumbs/svg?seed=' + mentioned_comment(comment.id_chamado_ocorrencia_mencionado).usuario_inclusao" alt="" class="avatar-xs rounded-circle" />
                        </div>
                        <div class="flex-grow-1 ms-3">
                            <h5 class="fs-13">{{ mentioned_comment(comment.id_chamado_ocorrencia_mencionado).usuario_inclusao }} <small class="text-muted">{{moment(mentioned_comment(comment.id_chamado_ocorrencia_mencionado).data_inclusao).format("DD MMM YYYY - H:mm")}}</small></h5>
                            
                            <div v-if="mentioned_comment(comment.id_chamado_ocorrencia_mencionado).tipo == 12 && mentioned_comment(comment.id_chamado_ocorrencia_mencionado).documment">
                                <div class="d-flex align-items-center border mb-3 rounded py-1 bg-light">
                                    <div class="flex-shrink-0 avatar-sm border-end">
                                        <div class="avatar-title bg-light">
                                            <i class="mdi mdi-file-document-outline fs-20 text-muted"></i>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1 mx-3">
                                        <h6 class="mb-1">{{mentioned_comment(comment.id_chamado_ocorrencia_mencionado).documment.nome_documento}}</h6>
                                        <div class="hstack gap-3 justify-content-end">
                                            <span class="text-muted me-auto">{{ (mentioned_comment(comment.id_chamado_ocorrencia_mencionado).documment.tamanho / 1048576).toFixed(2) }} MB</span>
                                            <a v-if="function_show_document" @click="showFiles([mentioned_comment(comment.id_chamado_ocorrencia_mencionado).documment])" href="javascript:void(0);" class="text-muted" v-b-tooltip.hover.top="'Visualizar anexo'"><i class="mdi link-info mdi-file-eye align-bottom fs-16"></i></a>
                                            <a v-if="function_download_document" @click="downloadFile({documents: [mentioned_comment(comment.id_chamado_ocorrencia_mencionado).documment], single: true})" href="javascript:void(0);" class="text-muted" v-b-tooltip.hover.top="'Baixar anexo'"><i class="mdi link-primary mdi-file-download align-bottom fs-16"></i></a>
                                            <i v-if="mentioned_comment(comment.id_chamado_ocorrencia_mencionado).documment.loading" class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i>
                                            <a v-else-if="function_remove_document && mentioned_comment(comment.id_chamado_ocorrencia_mencionado).documment.id_usuario_inclusao == current_user.id_usuario" @click="remove_document(mentioned_comment(comment.id_chamado_ocorrencia_mencionado).documment)" href="javascript:void(0);" class="text-muted" v-b-tooltip.hover.top="'Excluir anexo'"><i class="mdi link-danger mdi-file-remove align-bottom fs-16"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="comment p-1 text-muted" v-html="mentioned_comment(comment.id_chamado_ocorrencia_mencionado).mensagem"></div>
                        </div>
                    </div>
                </div>
                <div v-if="comment.tipo == 12 && comment.documment">
                    <div class="d-flex align-items-center border border-bottom-0 py-1">
                        <div class="flex-shrink-0 avatar-sm border-end">
                            <div class="avatar-title bg-light">
                                <i class="mdi mdi-file-document-outline fs-20 text-muted"></i>
                            </div>
                        </div>
                        <div class="flex-grow-1 mx-3">
                            <h6 class="mb-1">{{comment.documment.nome_documento}}</h6>
                            <div class="hstack gap-3 justify-content-end">
                                <span class="text-muted me-auto">{{ (comment.documment.tamanho / 1048576).toFixed(2) }} MB</span>
                                <a v-if="function_show_document" @click="showFiles([comment.documment])" href="javascript:void(0);" class="text-muted" v-b-tooltip.hover.top="'Visualizar anexo'"><i class="mdi link-info mdi-file-eye align-bottom fs-16"></i></a>
                                <a v-if="function_download_document" @click="downloadFile({documents: [comment.documment], single: true})" href="javascript:void(0);" class="text-muted" v-b-tooltip.hover.top="'Baixar anexo'"><i class="mdi link-primary mdi-file-download align-bottom fs-16"></i></a>
                                <i v-if="comment.documment.loading" class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i>
                                <a v-else-if="function_remove_document && comment.documment.id_usuario_inclusao == current_user.id_usuario" @click="remove_document(comment.documment)" href="javascript:void(0);" class="text-muted" v-b-tooltip.hover.top="'Excluir anexo'"><i class="mdi link-danger mdi-file-remove align-bottom fs-16"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="comment p-1 text-muted" v-html="comment.mensagem"></div>
                <button v-if="comment_to_mention" @click="() => comment_to_mention(comment)" class=" border-top text-start w-100 border-0 badge text-muted bg-light rounded-0 rounded-bottom"><i class="mdi mdi-reply"></i> Mencionar comentário</button>
            </div>
        </div>
    </div>
</template>
<script>

    export default {
        name:"BrPageLoading",
        props: {
            text: {
                type: String,
                required: false,
            }
        },
    };
</script>

<template>
    <div id="page-loading-container">
        <b-spinner variant="primary"></b-spinner>
        <span>{{this.$props.text || 'Carregando...'}}</span>
    </div>
</template>
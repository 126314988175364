<script>
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import {
  Grid
  , PluginPosition
  , h
} from "gridjs";
import { watch, onMounted } from "vue";
import ptBR from "../../helpers/dynamicTable/";
import { backgroundColorWithOpacity } from '../configuracao_consulta/funcionalidadeschart'
export default {

  components: {
    Vue3ChartJs
  },
  props: {
    allConfigComponent: {
      type: Object || undefined
    }
  },

  setup(props) {
    function Position(a) {
      switch (a) {
        case 1:
          a = `text-left justify-content-start`
          break;
        case 2:
          a = `text-center justify-content-center`
          break;
        case 3:
          a = `text-right justify-content-end`
          break;
      }
      return a
    }

    function setTable(config) {
      const gridContainer = document.getElementById('tabella');
     
      const colunas = []
      const columns = config.colunas
      columns.map((v, i) => {
        colunas.push({
          id: v.nome + v.agregador_total,
          name: h('div', {
            className: 'text-center p-0',
            style: {
              textAlign: 'center',

            }
          }, v.titulo || v.nome),
          formatter: (cell) => {
            const vcell = v.agregador > 1 ? (v.prefixo + ' ' || '') + Number(cell).toLocaleString('pt-BR', { minimumFractionDigits: v.casadec, maximumFractionDigits: v.casadec }) + (' ' + v.sufixo || '') : cell

            return h('div', {
              id: i,
              className: `m-auto h-100 d-flex align-items-center ${Position(v.posicao_columns) || 'center'} `,
            }, vcell);
          },

          sort: {
            compare: (a, b) => {
              const regexDate = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(.\d+)?(Z|[+-]\d{2}:\d{2})?)?$/;
              if (v.agregador_total !== 1) {
                if (a > b) {
                  return 1;
                } else if (a < b) {
                  return -1;
                } else {
                  return 0
                }
              }
              else if (regexDate.test(a)) {
                if (new Date(a) > new Date(b)) {
                  return 1;
                } else if (new Date(b) > new Date(a)) {
                  return -1;
                } else {
                  return 0;
                }
              }
              else if (a > b) {
                return 1;
              }
              else if (b > a) {
                return -1;
              }
              else {
                return 0;
              }
            },
            enabled: true,
            multiColumn: true,
          }
        })
      })
      const dados = config.dados.map(obj => config.colunas.map((v) => obj[v.titulo || v.nome])).slice(0, -1)
      const footer = config.dados.map(obj => config.colunas.map((v) => obj[v.titulo || v.nome] === undefined ? '' : obj[v.titulo || v.nome])).pop()
      function calcularLarguraColunas() {
        const larguraColunas = [];
        const headerCells = document.getElementById(config.id).querySelectorAll('thead tr th ');

        headerCells.forEach(cell => {
          larguraColunas.push(cell.offsetWidth);
        });

        return larguraColunas;
      }
      function creationFooter() {
        const larguraColunas = calcularLarguraColunas();
        // Mapeia as células do tfoot
        const tds = footer.map((cell, index) => {
          const formcell = Number(cell) == Number ? Number(cell) : cell
          const formatad = formcell ? `${columns[index].prefixo || ''} ${formcell.toLocaleString('pt-BR', { minimumFractionDigits: columns[index].casadec, maximumFractionDigits: columns[index].casadec })} ${columns[index].sufixo || ''}` : ''
          return h('td', {
            style: {
              width: `${larguraColunas[index]}px`,
            },
            className: `${Position(columns[index].posicao_columns)}`
          }, formatad);
        });
        const tr = h('tr', { className: 'p-0 m-0 fw-semibold' }, tds);
        const tfoot = h('tfooter', {}, tr);
        return tfoot;
      }
      let footerPlugin = {
        id: 'footer-plugin',
        component: creationFooter,
        position: PluginPosition.Footer
      }
      const grid = new Grid({
         columns: colunas,
        data: dados,
        fixedHeader: true,
        height: `${gridContainer.offsetHeight - 40}px`,
        className: {
          footer: 'py-2 sticky-bottom bg-light',
          thead: 'bg-light',
          td: 'p-0 m-0 w-100',
          table: ''
        },
        sort: true,
        plugins: [
          footerPlugin
        ],
        language: ptBR,
        style: {
          td: {
            'max-width': '4px',
            'min-height': '72px',
            'cursor': 'pointer'
          },
          th: {
            'max-width': '4px',


          }
        }
      });

      const footerContainer = document.createElement('div');
      footerContainer.className = 'gridjs-foot';
      gridContainer.innerHTML = '';
      grid.render(document.getElementById('tabella'));

      let prev = document.querySelector('#preview-Chart')
      const debounce = (func, delay = 500) => {
        let timer;
        return function () {
          clearTimeout(timer);
          timer = setTimeout(func.bind(this, ...arguments), delay)
        };
      };
   
      const handleResize = debounce(() => {

        grid.updateConfig({
          fixedHeader: true,
          height: `${gridContainer.offsetHeight - 30}px`,
          plugins: [footerPlugin],
        }).forceRender();
      },
       1000)

      prev?.addEventListener('onclick', handleResize)
      window.addEventListener('resize', handleResize);
   

     
      grid.updateConfig({
        data: dados,
      }).forceRender();

    }
    onMounted(() => {
      if (props.allConfigComponent?.id_type == 6) {
        setTable(props.allConfigComponent)
 
      }
    })
    watch(() => props.allConfigComponent, (newValue) => {
      if (newValue?.id_type == 6) {
        setTable(newValue)
      } else {
        document.getElementById('tabella').innerHTML = '';
      }
    });

    return {
      backgroundColorWithOpacity
    }
  }

};

</script>
<template>
  <b-modal id="preview-chart" :size="allConfigComponent && allConfigComponent?.id_type == 6 ?
    `fullscreen` :
    allConfigComponent && allConfigComponent?.id_type == 1 ? 'md' :
      `xl`" title="Preview" fade scrollable centered>
    <div class="container-fluid h-100">

      <div class="card card-animate overflow-hidden d-flex flex-column p-0 m-0 " :style="{
    backgroundColor: '',
    border: '1px solid ' + allConfigComponent?.cor_borda || '', height: 'inherit'
  }">
        <div class="card-body">
          <template v-if="allConfigComponent">
            <!-- CARDS -->

            <div id='carrd' v-if="allConfigComponent?.id_type === 1" class="d-flex justify-content-between m-0">
              <div style="z-index: 2;">

                <div class="d-flex align-items-center ">
                  <div class="avatar-xs m-0  flex-shrink-0">
                    <span class="avatar-title m-0  rounded fs-3" :style="'background-color: ' + 'rgba(0, 0, 0, 0.03)'">
                      <i :class="allConfigComponent.icone + ''" :style="'color:' + allConfigComponent.cor_borda"></i>
                    </span>
                  </div>
                  <div class="flex-grow-1 ps-3">
                    <h5 class="text-muted text-uppercase fs-13 mb-0">{{ allConfigComponent.label }}</h5>
                  </div>
                </div>
                <div v-if="allConfigComponent.acelerometro" id="graficocard" style=" width: 100%; height: 100%;">
                  <vue3-chart-js class="h-100 w-100" :key="Date.now()" :id="allConfigComponent.id"
                    :type="allConfigComponent.acelerometro.type" :data="allConfigComponent.acelerometro.data"
                    :ref="allConfigComponent.acelerometro.ref"
                    :options="allConfigComponent.acelerometro.options"></vue3-chart-js>
                </div>
                <div v-else id="valorcard" class="d-flex justify-content-between m-0 p-0 w-100 h-100">
                  <div class="mt-2 pt-1">
                    <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                      {{ allConfigComponent.valores[0].prefix }}
                      <span>
                        {{ allConfigComponent.valores[0].valor.toLocaleString('pt-BR',
    {
      minimumFractionDigits: allConfigComponent.valores[0].casadec, maximumFractionDigits:
        allConfigComponent.valores[0].casadec
    }) }}
                      </span>
                      {{ allConfigComponent.valores[0].suffix }}
                    </h4>
                  </div>
                </div>
              </div>
              <div>

              </div>

            </div>
            <!-- GRAFICO -->
            <div v-else-if="allConfigComponent.id_type !== 6" class="m-0 p-2 " style="height: 93%; width:100%">
              <div class="d-flex justify-content-between">
                <div class="d-flex w-100 align-items-center">
                  <div class="avatar-xs m-0  flex-shrink-0">
                    <span class="avatar-title m-0  rounded fs-3"
                      :style="'background-color: ' + backgroundColorWithOpacity(allConfigComponent.cor_borda, .3)">
                      <i :class="allConfigComponent.icone + ''" :style="'color:' + allConfigComponent.cor_borda"></i>
                    </span>
                  </div>
                  <div class="flex-grow-1 ps-3">
                    <h5 class="text-muted text-uppercase fs-13 mb-0">{{ allConfigComponent.titulo }}</h5>
                  </div>
                </div>
              </div>
              <div class="h-100 w-100">
                <vue3-chart-js :key="Date.now()" :id="'tesste'" :type="allConfigComponent.type"
                  :data="allConfigComponent.mydata.data" :ref="Graficos"
                  :options="allConfigComponent.mydata.options"></vue3-chart-js>
              </div>
            </div>
          </template>
          <!-- TABELA -->
          <div class="m-0 p-2 " v-if="allConfigComponent?.id_type == 6">
            <div class="d-flex align-items-center">
              <div class="avatar-xs m-0  flex-shrink-0">
                <span class="avatar-title m-0  rounded fs-3"
                  :style="'background-color: ' + backgroundColorWithOpacity(allConfigComponent.cor_borda, .3)">
                  <i :class="allConfigComponent.icone + ''" :style="'color:' + allConfigComponent.cor_borda"></i>
                </span>
              </div>
              <div class="flex-grow-1 ps-3">
                <h5 class="text-muted text-uppercase fs-13 mb-0">{{ allConfigComponent.titulo }}</h5>
              </div>
            </div>

          </div>
          <div class="table-container m-0" id='tabella'>
          </div>
        </div>

      </div>

    </div>
    <template #footer>
      <b-button variant="primary" v-b-modal.configurar-consulta-modal>Retornar</b-button>
    </template>

  </b-modal>
</template>
<script>
import { useStore } from 'vuex';
import useProgram from '../../../../composables/program';
import { computed, onMounted, provide, ref } from 'vue';
import TabelaDetalhe from '@/components/tabela_detalhe/index.vue';
import FormBotoes from '@/components/forms_bottom_buttons/index.vue'
import CustomInput from '@/components/custom_input/index.vue';
import ChatBox from "@/components/chat/chat-box.vue";
import { useRoute } from 'vue-router'
import ChatBoxController from '@/components/chat/chat-box-controller'

export default {
  props: {
    program_schema: {
      type: Object,
      required: true,
    },
    dados: {
      type: Object,
      required: true,
    },
    registro_id: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const formType = store.state.programForm.formType;

    const programa = {
      program_schema: props.program_schema,
      dados: props.dados,
      registro_id: props.registro_id
    };
    const {
      setProgramForm,
      addNewDetailTableForm,
      removeDetailTableForm,
      handleFormSubmit,
      sis_detail_table,
      baseFormModifier,
      loading,
      btnloading,
      resetMainAndDetailTableForms
    } = useProgram(formType, programa);

    onMounted(() => {
      setProgramForm();
    });

    const primaryKeyName = programa.program_schema.sis_tabela.sis_campo_tabela.find(
      (col) => col.chave_primaria
    ).nome;
    const registerTargetId = programa.dados[programa.program_schema.sis_tabela.nome_tabela].registros[0][primaryKeyName]
    const chatController = ref(new ChatBoxController(programa.program_schema, programa.dados, route.params.targetId, registerTargetId))
    chatController.value.setMovimentoMessages(props.dados.sis_anotacao?.registros || []);
    const handleSubmitMessage = (message, id) => chatController.value.handleSubmitMessage(message, id)
    const handleDeleteMessage = (id) => chatController.value.handleDeleteMessage(id)
    const movimentoMessages = computed(() => chatController.value.getMessages())

    provide('resetMainAndDetailTableForms', resetMainAndDetailTableForms)
    provide('id_empresa', programa.dados?.[programa.program_schema.sis_tabela.nome_tabela].registros[0].id_empresa ?? null)

    return {
      handleDeleteMessage,
      handleSubmitMessage,
      movimentoMessages,
      chatController,
      formType,
      loading,
      btnloading,
      handleFormSubmit,
      baseFormModifier,
      sis_detail_table,
      addNewDetailTableForm,
      removeDetailTableForm,
    };
  },
  components: {
    TabelaDetalhe,
    FormBotoes,
    CustomInput,
    ChatBox
  }

}
</script>
<template>
  <div class="row">
    <div class="card">
      <div class="my-2 mb-3">
        <template v-if="loading">
          <div class="text-center">
            <b-spinner variant="primary"></b-spinner>
          </div>
        </template>
        <form v-else @submit.prevent="handleFormSubmit" class="needs-validation" novalidate>
          <ul class="nav nav-tabs mb-3" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" data-bs-toggle="tab" href="#arrow-program" role="tab" aria-selected="true">
                <span class="">{{ program_schema.sis_tabela.denominacao }}</span>
              </a>
            </li>

            <li v-for="detail_table in sis_detail_table" :key="detail_table.schema.id_tabela" class="nav-item">
              <a class="nav-link" data-bs-toggle="tab" :href="'#arrow-' + detail_table.schema.id_tabela" role="tab">
                <span class="">{{ detail_table.title }}</span>
              </a>
            </li>
          </ul>

          <!-- Tab pane base form -->
          <div class="tab-content">
            <div class="tab-pane active" id="arrow-program" role="tabpanel">

              <div class="border p-3 mb-0">
                <div class="row">
                  <div class='col-12 col-md-6'>
                    <div class='mb-3'>
                      <CustomInput :formState='baseFormModifier' :formType='formType'
                        :columnSchema='baseFormModifier.getColumnByName("id_titulo_financeiro")' :showLabel='true' />
                    </div>
                  </div>
                  <div class='col-12 col-md-6'>
                    <div class='mb-3'>
                      <CustomInput :formState='baseFormModifier' :formType='formType'
                        :columnSchema='baseFormModifier.getColumnByName("nome_empresa")' :showLabel='true' />
                    </div>
                  </div>
                  <div class='col-12 col-md-6'>
                    <div class='mb-3'>
                      <CustomInput :formState='baseFormModifier' :formType='formType'
                        :columnSchema='baseFormModifier.getColumnByName("codigo_credor_devedor")' :showLabel='true' />
                    </div>
                  </div>
                  <div class='col-12 col-md-6'>
                    <div class='mb-3'>
                      <CustomInput :formState='baseFormModifier' :formType='formType'
                        :columnSchema='baseFormModifier.getColumnByName("nome_credor_devedor")' :showLabel='true' />
                    </div>
                  </div>
                  <div class='col-12 col-md-6'>
                    <div class='mb-3'>
                      <CustomInput :formState='baseFormModifier' :formType='formType'
                        :columnSchema='baseFormModifier.getColumnByName("titulo_natureza_titulo")' :showLabel='true' />
                    </div>
                  </div>
                  <div class='col-12 col-md-6'>
                    <div class='mb-3'>
                      <CustomInput :formState='baseFormModifier' :formType='formType'
                        :columnSchema='baseFormModifier.getColumnByName("titulo_situacao")' :showLabel='true' />
                    </div>
                  </div>
                  <div class='col-12 col-md-6'>
                    <div class='mb-3'>
                      <CustomInput :formState='baseFormModifier' :formType='formType'
                        :columnSchema='baseFormModifier.getColumnByName("data_emissao")' :showLabel='true' />
                    </div>
                  </div>
                  <div class='col-12 col-md-6'>
                    <div class='mb-3'>
                      <CustomInput :formState='baseFormModifier' :formType='formType'
                        :columnSchema='baseFormModifier.getColumnByName("data_vencimento")' :showLabel='true' />
                    </div>
                  </div>
                  <div class='col-12 col-md-6'>
                    <div class='mb-3'>
                      <CustomInput :formState='baseFormModifier' :formType='formType'
                        :columnSchema='baseFormModifier.getColumnByName("numero_titulo")' :showLabel='true' />
                    </div>
                  </div>
                  <div class='col-12 col-md-6'>
                    <div class='mb-3'>
                      <CustomInput :formState='baseFormModifier' :formType='formType'
                        :columnSchema='baseFormModifier.getColumnByName("numero_parcela")' :showLabel='true' />
                    </div>
                  </div>
                  <div class='col-12'>
                    <div class='mb-3'>
                      <CustomInput :formState='baseFormModifier' :formType='formType'
                        :columnSchema='baseFormModifier.getColumnByName("observacao")' :showLabel='true' />
                    </div>
                  </div>
                </div>
              </div>

              <div class="border p-3 mt-2">
                <div class="row">
                  <div class='col-12 col-md-6'>
                    <div class='mb-3'>
                      <CustomInput :formState='baseFormModifier' :formType='formType'
                        :columnSchema='baseFormModifier.getColumnByName("data_lancamento")' :showLabel='true' />
                    </div>
                  </div>
                  <div class='col-12 col-md-6'>
                    <div class='mb-3'>
                      <CustomInput :formState='baseFormModifier' :formType='formType'
                        :columnSchema='baseFormModifier.getColumnByName("usuario_lancamento")' :showLabel='true' />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="row">
                      <div class='col-6'>
                        <div class='mb-3'>
                          <CustomInput :formState='baseFormModifier' :formType='formType'
                            :columnSchema='baseFormModifier.getColumnByName("lancamento_automatico")' :showLabel='true' />
                        </div>
                      </div>
                      <div class='col-6'>
                        <div class='mb-3'>
                          <CustomInput :formState='baseFormModifier' :formType='formType'
                            :columnSchema='baseFormModifier.getColumnByName("boleto_emitido")' :showLabel='true' />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class='col-12 col-md-6'>
                    <div class='mb-3'>
                      <CustomInput :formState='baseFormModifier' :formType='formType'
                        :columnSchema='baseFormModifier.getColumnByName("codigo_barras_boleto")'
                        allowCopy="ri-barcode-line" :leftCopy="true" :showLabel='true' />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class='col-12'>
                    <div class='mb-3'>
                      <CustomInput :formState='baseFormModifier' :formType='formType'
                        :columnSchema='baseFormModifier.getColumnByName("observacao_lancamento")' :showLabel='true' />
                    </div>
                  </div>
                </div>
              </div>

              <div class="border p-3 mt-2">
                <div class="row">
                  <div class='col-12 col-md-4'>
                    <div class='mb-3 input-align-right'>
                      <CustomInput :formState='baseFormModifier' :formType='formType'
                        :columnSchema='baseFormModifier.getColumnByName("valor_titulo")' :showLabel='true' />
                    </div>
                  </div>
                  <div class='col-12 col-md-4'>
                    <div class='mb-3 input-align-right'>
                      <CustomInput :formState='baseFormModifier' :formType='formType'
                        :columnSchema='baseFormModifier.getColumnByName("multa_cobranca")' :showLabel='true' />
                    </div>
                  </div>
                  <div class='col-12 col-md-4'>
                    <div class='mb-3 input-align-right'>
                      <CustomInput :formState='baseFormModifier' :formType='formType'
                        :columnSchema='baseFormModifier.getColumnByName("juros_cobranca")' :showLabel='true' />
                    </div>
                  </div>
                  <div class='col-12 col-md-4'>
                    <div class='mb-3 input-align-right'>
                      <CustomInput :formState='baseFormModifier' :formType='formType'
                        :columnSchema='baseFormModifier.getColumnByName("tarifa_transacao")' :showLabel='true' />
                    </div>
                  </div>
                  <div class='col-12 col-md-4'>
                    <div class='mb-3 bold-label input-align-right'>
                      <CustomInput :formState='baseFormModifier' :formType='formType'
                        :columnSchema='baseFormModifier.getColumnByName("valor_liquido")' :showLabel='true' />
                    </div>
                  </div>
                  <div class='col-12 col-md-4'>
                    <div class='mb-3 bold-label input-align-right'>
                      <CustomInput :formState='baseFormModifier' :formType='formType'
                        :columnSchema='baseFormModifier.getColumnByName("valor_liquidado")' :showLabel='true' />
                    </div>
                  </div>
                </div>
              </div>

              <div class="border p-3 mt-2">
                <div class="row">
                  <div class='col-12 col-md-6'>
                    <div class='mb-3'>
                      <CustomInput :formState='baseFormModifier' :formType='formType'
                        :columnSchema='baseFormModifier.getColumnByName("data_liquidacao")' :showLabel='true' />
                    </div>
                  </div>
                  <div class='col-12 col-md-6'>
                    <div class='mb-3'>
                      <CustomInput :formState='baseFormModifier' :formType='formType'
                        :columnSchema='baseFormModifier.getColumnByName("usuario_liquidacao")' :showLabel='true' />
                    </div>
                  </div>
                  <div class='col-12'>
                    <div class='mb-3'>
                      <CustomInput :formState='baseFormModifier' :formType='formType'
                        :columnSchema='baseFormModifier.getColumnByName("observacao_liquidacao")' :showLabel='true' />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Tab panes for detail tables -->
            <div v-for="detail_table in sis_detail_table" :key="detail_table.schema.id_tabela"
              :id="'arrow-' + detail_table.schema.id_tabela" class="tab-pane" role="tabpanel">
              <div class="border p-3 mb-0">
                <TabelaDetalhe :formType="formType" :table="detail_table" :mainTableFormModifier="baseFormModifier"
                  @addRow="addNewDetailTableForm" @removeRow="removeDetailTableForm"
                  :programSchema="this.$props.program_schema" />
              </div>
            </div>
          </div>

          <FormBotoes :formType="formType" :loading="btnloading" />
        </form>
      </div>
    </div>

    <div class="chat-container">
      <ChatBox :chatMessagesData="movimentoMessages" :onSubmitNewMessage="handleSubmitMessage"
        :onDeleteMessage="handleDeleteMessage" />
    </div>
  </div>
</template>
<script>
import { ref, watch } from 'vue';
import { useStore } from 'vuex';
import { toNumber } from '@vue/shared';
export default {
    props: {
    },
    setup() {
        const store = useStore();
        const show = ref(false);
        const authCode = ref([]);
        const handleAuthCode = () => {
            if (store.state.massActionsModals.loading) {
                return false;
            }

            store.state.massActionsModals.TFAonSave(authCode.value.join(''))
        }
        watch(() => store.state.massActionsModals.showTFAModal, (value) => {
            show.value = value;
            authCode.value = [];
            if(value){
                setTimeout(() => {
                    document.getElementById('tfa-massaction-digit1-input').focus()  
                }, 400)
            }
        })
        watch(() => show.value, (value) => {
            // store.state.massActionsModals.TFAonCloseWithoutSave()
            if (!value) {
                store.commit('massActionsModals/setProperty', [
                    {
                        key: 'showTFAModal',
                        value: false
                    }
                ])
                
                if(document.getElementsByTagName('body')[0].attributes['data-bs-padding-right'])
                document.getElementsByTagName('body')[0].attributes.removeNamedItem('data-bs-padding-right')
            }
        })
        const moveToNext = (e) => {
            const index = e.target.dataset.index;
            if(e.key == 'Backspace' || e.code == 'Backspace'){
                if(index > 1){
                document.getElementById(`tfa-massaction-digit${toNumber(index) - 1}-input`).focus();
                }
            }
            if(e.target.value == '' || e.target.value == ' ' || !/[0-9]/.test(e.target.value)){
                e.target.value = '';
                return false;
            }
            if(index == 6 && (
                authCode.value[0] !== '' && 
                authCode.value[1] !== '' && 
                authCode.value[2] !== '' && 
                authCode.value[3] !== '' &&
                authCode.value[4] !== '' &&
                authCode.value[5] !== '')){
                handleAuthCode()
            }else{
                if(index < 6){
                document.getElementById(`tfa-massaction-digit${toNumber(index) + 1}-input`).focus();
                }
            }
        }
        const pasteAuthCode = (e) => {
            const value = e.clipboardData.getData('Text');
            if(value.length == 6){
                authCode.value = value.split('');
                handleAuthCode()
            }
        }

        return {
            show,
            handleAuthCode,
            authCode,
            moveToNext,
            pasteAuthCode,
        }
    },
}
</script>

<template>
    <b-modal size="md" v-model="show" centered hideFooter no-close-on-esc no-close-on-backdrop and hide-header-close>
        <div>
            <div class="row mb-4">
                <div class="col-12">
                    <div class="card-body pt-0">
                        <div class="p-2">
                            <div class="mb-4">
                                <div class="avatar-lg mx-auto">
                                    <div class="avatar-title bg-light text-primary display-5 rounded-circle">
                                        <i class="ri-lock-password-fill"></i>
                                    </div>
                                </div>
                            </div>

                            <div class="text-muted text-center mb-4 mx-lg-3">
                                <p>Insira seu código de verificação do Google Authenticator</p>
                            </div>

                            <form @submit.prevent="handleAuthCode">
                                <div v-if="$store.state.massActionsModals.TFAloading" class="mt-3 d-flex justify-content-center">
                                    <b-spinner variant="success"></b-spinner>
                                </div>
                                <div v-else class="d-flex gap-2">
                                    <input type="text" v-for="index in 6" :key="index" v-model="authCode[index - 1]"
                                        :disabled="$store.state.massActionsModals.TFAloading" :data-index="index" @keyup="moveToNext"
                                        @paste="pasteAuthCode" maxLength="1" pattern="\d*"
                                        :id="`tfa-massaction-digit${index}-input`"
                                        :class="(index === 3 ? 'me-3' : '') + ' form-control form-control-lg bg-light border-light text-center text-primary fw-bold'">
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>
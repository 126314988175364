<script>
import { useStore } from 'vuex';
import useProgram from '../../../../composables/program';
import { onMounted } from 'vue';
import TabelaDetalhe from '@/components/tabela_detalhe/index.vue';
import FormBotoes from '@/components/forms_bottom_buttons/index.vue'
import CustomInput from '@/components/custom_input/index.vue';

export default {
  props: {
    program_schema: {
      type: Object,
      required: true,
    },
    dados: {
      type: Object,
      required: true,
    },
    registro_id: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const store = useStore();
    const formType = store.state.programForm.formType;
    const programa = {
      program_schema: props.program_schema,
      dados: props.dados,
      registro_id: props.registro_id
    };
    const {
      setProgramForm,
      addNewDetailTableForm,
      removeDetailTableForm,
      handleFormSubmit,
      sis_detail_table,
      baseFormModifier,
      loading,
      btnloading
    } = useProgram(formType, programa);

    onMounted(() => {
      setProgramForm();
    });

    return {
      formType,
      loading,
      btnloading,
      handleFormSubmit,
      baseFormModifier,
      sis_detail_table,
      addNewDetailTableForm,
      removeDetailTableForm,
    };
  },
  components: {
    TabelaDetalhe,
    FormBotoes,
    CustomInput
  }

}
</script>
<template>
  <div class="row">
    <div class="card">
      <div class="my-2 mb-3">
        <template v-if="loading">
          <div class="text-center">
            <b-spinner variant="primary"></b-spinner>
          </div>
        </template>
        <form v-else @submit.prevent="handleFormSubmit" class="needs-validation" novalidate>
          <ul class="nav nav-tabs mb-3" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" data-bs-toggle="tab" href="#arrow-program" role="tab" aria-selected="true">
                <span class="">Tabela</span>
              </a>
            </li>

            <li v-for="detail_table in sis_detail_table" :key="detail_table.schema.id_tabela" class="nav-item">
              <a class="nav-link" data-bs-toggle="tab" :href="'#arrow-' + detail_table.schema.id_tabela" role="tab">
                <span class="">{{detail_table.title}}</span>
              </a>
            </li>
          </ul>

          <div class="tab-content">
            <div class="tab-pane active" id="arrow-program" role="tabpannel">
              <div class="border p-3 mb-0">
                <div class="row">
                  <div class="col-12 col-md-4">
                    <div class="mb-3">
                      <CustomInput :formState="baseFormModifier" :formType="formType"
                        :columnSchema="baseFormModifier.getColumnByName('tipo_tabela')" :showLabel="true" />
                    </div>
                  </div>
                  <div class="col-12 col-md-2">
                    <div class="mb-3 flex-1">
                      <CustomInput :formState="baseFormModifier" :formType="formType"
                        :columnSchema="baseFormModifier.getColumnByName('auditoria_obrigatoria')" :showLabel="true" />
                    </div>
                  </div>
                  <div class="col-12 col-md-2">
                    <div class="mb-3">
                      <CustomInput :formState="baseFormModifier" :formType="formType"
                        :columnSchema="baseFormModifier.getColumnByName('auditoria_habilitada')" :showLabel="true" />
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="mb-3">
                      <CustomInput :formState="baseFormModifier" :formType="formType"
                        :columnSchema="baseFormModifier.getColumnByName('tempo_permanencia_auditoria')"
                        :showLabel="true" />
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="mb-3">
                      <CustomInput :formState="baseFormModifier" :formType="formType"
                        :columnSchema="baseFormModifier.getColumnByName('nome_tabela')" :showLabel="true" />
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="mb-3">
                      <CustomInput :formState="baseFormModifier" :formType="formType"
                        :columnSchema="baseFormModifier.getColumnByName('denominacao')" :showLabel="true" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="border p-3 mt-2">
                <div class="row">
                  <div class="col-12 col-md-4">
                    <div class="mb-3">
                      <CustomInput :formState="baseFormModifier" :formType="formType"
                        :columnSchema="baseFormModifier.getColumnByName('id_tabela')" :showLabel="true" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-for="detail_table in sis_detail_table" :key="detail_table.schema.id_tabela"
              :id="'arrow-' + detail_table.schema.id_tabela" class="tab-pane" role="tabpanel">
              <div class="border p-3 mb-0">
                <TabelaDetalhe 
                :formType="formType" 
                :table="detail_table"
                :mainTableFormModifier="baseFormModifier"
                @addRow="addNewDetailTableForm"
                @removeRow="removeDetailTableForm" 
            />
              </div>
            </div>
          </div>

          <FormBotoes :formType="formType" :loading="btnloading" />
        </form>
      </div>
    </div>
  </div>
</template>
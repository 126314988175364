<script>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { SaldoController } from './updateSaldo';

export default {
    props: {
    },
    setup() {
        const store = useStore();
        const show = ref(false);
        const saveLoading = ref(false)
        const registersController = ref([])
        const registers = ref([])
        
        watch(() => store.state.massActionsModals.showUpdateSaldoModal, (value) => {
            show.value = value;
            if(value) {
                for(let i = 0; i < store.state.massActionsModals.objects.length; i++) {
                    const data = store.state.massActionsModals.objects[i]
                    registersController.value.push(new SaldoController(data.id_conta_financeira, data.denominacao, data.saldo_disponivel_api, data.cheque_especial, data.cheque_troco))
                }
            }
        })
        watch(() => show.value, (value) => {
            if(!value) {
                saveLoading.value = false
                store.commit('massActionsModals/setProperty', [
                    {
                        key: 'showUpdateSaldoModal',
                        value: false
                    }
                ])
                registersController.value = []
                registers.value = []
                
                if(document.getElementsByTagName('body')[0].attributes['data-bs-padding-right'])
                document.getElementsByTagName('body')[0].attributes.removeNamedItem('data-bs-padding-right')
            }
        })

        const get_by_key = computed(() => (key, index) => {
            return registersController.value[index].get_by_key(key)
        })
        
        const save = async () => {
            registers.value = []
            for(let i = 0; i < registersController.value.length; i++) {
                registers.value.push(registersController.value[i].get_all())
            }
            
            saveLoading.value = true
            await store.state.massActionsModals.onSave({conta_financeira: {registros: registers.value}})
            show.value = false
            saveLoading.value = false
        }

        return {
            show,
            saveLoading,
            save,
            registers,
            registersController,
            get_by_key
        }
    },
}
</script>

<template>
    <b-modal size="lg" v-model="show" centered scrollable :title="$store.state.massActionsModals?.title">
        <div>
            <div class="row mb-4">
                <div class="col-12">
                    <table class="table table-bordered table-nowrap">
                        <thead>
                            <tr class="bg-light">
                                <th scope="col">Denominação</th>
                                <th scope="col">Saldo Disponível</th>
                                <th scope="col">Cheque Especial</th>
                                <th scope="col">Cheque Troco</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="dataController, index in registersController" :key="dataController.id_conta_financeira">
                                <td class="m-0 p-0 fix-td-height bg-light">
                                    <div class="px-1 h-100 d-flex align-items-center">
                                        <p class="m-0 fw-semibold">{{dataController.get_by_key('denominacao', index)}}</p>
                                    </div>
                                </td>
                                <td class="m-0 p-0 fix-td-height">
                                    <input type="number" class="form-control border-dashed rounded-0" style="text-align:right;" :value="get_by_key('saldo_disponivel_api', index)" @change="dataController.set_by_key('saldo_disponivel_api', $event.target.value)" />
                                </td>
                                <td class="m-0 p-0 fix-td-height">
                                    <input type="number" class="form-control border-dashed rounded-0" style="text-align:right;" :value="get_by_key('cheque_especial', index)" @change="dataController.set_by_key('cheque_especial', $event.target.value)" />
                                </td>
                                <td class="m-0 p-0 fix-td-height">
                                    <input type="number" class="form-control border-dashed rounded-0" style="text-align:right;" :value="get_by_key('cheque_troco', index)" @change="dataController.set_by_key('cheque_troco', $event.target.value)" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <template #footer>
            <div class="row w-100">
                <button v-if="saveLoading" type="button"
                    class="mt-2 ms-auto btn btn-primary shadow-sm col-12 col-md-3 btn-load"
                    style="height: 40px; min-width: 100px;">
                    <span class="d-flex align-items-center justify-content-center gap-2">
                        <span class="spinner-border flex-shrink-0" role="status"></span> Carregando
                    </span>
                </button>
                <button type="button" v-else
                    class="mt-2 ms-auto btn btn-primary shadow-sm col-12 col-md-3"
                    style="height: 40px; min-width: 100px;"
                    @click="save"
                ><i class="ri-save-2-fill align-bottom me-1"></i> Salvar</button>
            </div>
        </template>
    </b-modal>
</template>
<script>
import Swal from 'sweetalert2';
import { useStore } from 'vuex';
// import { mountPermissionsArvore, mountPermissionsEmpresa } from '@/helpers/permissions';
// Remover modal de permissões
import { sendFunctionRequest } from '@/helpers/dynamicTable/sendFunctionRequest';
import { bodyFunctionRequest } from "@/helpers/dynamicTable/bodyFunctionRequest";
import { useRoute } from 'vue-router';
import router from '@/router';
import { downloadDocument } from '../../helpers/donwloadDocument.js';
import { computed, ref } from 'vue';
import print from 'vue3-print-nb'
import { v4 as uuidv4 } from 'uuid';
import { getValueListById } from '@/helpers/forms/getListaValorById';
import { getListValueIdByFunctionalityId } from '../../helpers/static_get_value_list_id_by_function_id.js';

export default {
    name: 'BrMassActions',
    props: {
        programSchema: {
            type: Object,
            required: true,
        },
        tableFunctions: {
            type: Object,
            required: true,
        },
        docsTableFunctions: {
            type: Object,
            required: false
        },
        conciliateTableFunctions: {
            type: Object,
            required: false
        },
        grid: {
            type: Object,
            required: true,
        },
        movimento_venda: {
            type: String,
            required: false
        },
        showUploadModal: {
            type: Function,
            required: false
        },
        showFileModal: {
            type: Function,
            required: false
        },
        arquivos: {
            type: Array,
            required: false
        },
        tipoModal: {
            type: String,
            required: false
        },
        createTable: {
            type: Function,
            required: false
        },
        dados: {
            type: Object,
            required: false,
        },
        conferimento: {
            type: Array,
            required: false
        },
        restartGrid: {
            type: Function,
            required: false
        },
    },
    setup(props) {
        const store = useStore();
        const _route = useRoute();
        const notInclude = [
            '7f83862c-c1a4-4109-8db5-a9e1857b4ffa', // Acessar
            '22ed9d51-5c41-459c-a23b-577d7cbde4f7', // Conciliar
            'b0b4acf2-4e22-42cb-a43a-77d97daffc06', // Conferir
            '68ec7d3d-e6cc-449b-8829-e636a812b0c4', // Desconciliar
            '220fb561-c912-4b5a-8d7d-bf0bdb258ca0', // Desconferir
            'b9d6d7c6-34e6-44b2-a846-147306fe1c23', // Download Documentos
            '80bb4224-7116-46f6-8c33-f349059858fd', // Editar
            'c03afdc4-9ef4-4545-b23a-2beaa330a9c0', // Editar Comentário
            '16dba857-ca79-4bac-bdad-ca9d5595e5ea', // Excluir
            '779adf9e-1da7-4984-b787-9b0d74469dd8', // Excluir Comentário
            '07fdec1e-448a-4d9a-90ea-4fc6f3e16b6d', // Excluir Documentos
            '7f96d25e-352f-4f48-872c-266e8b0ee835', // Incluir
            '4cbc785e-81ca-4f1c-8fcd-a751e3f0535d', // Incluir Comentário
            '2d3de029-490a-4fc0-b3cd-e7447cb791b5', // Sincronizar Petros
            'f1fed607-4a32-40e6-b41c-0e718f2db64d', // Visualizar
            'a52a0b8c-9fa7-42dc-be20-45d49ca1b426', // Visualizar Auditoria
            '3e95322a-8f41-4c21-8bfa-9c9908cdbeac', // Visualizar Documentos
            '0d0111ba-a01d-4c15-a752-9a42e9fa28e9', // Enviar Email,
            'dc928673-e5e6-481d-8e1b-eac550b57bdf', // Incluir Itens
            '9c7f15ca-91d8-4b63-9482-39b2b91e1fb6', // Imprimir
            'aa6e3c22-575c-411b-9217-b9265be3e713', // Obter Dados Consulta SQL
            'dc928673-e5e6-481d-8e1b-eac550b57bdf', // Incluir Itens
            'd965c114-bc5b-4d9b-8994-4904afb6425b', // Excluir Itens
            'c74f780a-8876-42f5-87ec-c28d98f2ae3f', // Aprovar Item
            '40cec6b1-9fce-47d5-b0e2-eadd3be50c63', // Reprovar Item
            'dd34d8e4-d993-4e6b-8c99-a411a959550a', // Incluir Documentos
            'b9d6d7c6-34e6-44b2-a846-147306fe1c23', // Download Documentos
            '07fdec1e-448a-4d9a-90ea-4fc6f3e16b6d', // Excluir Documentos
            '5b9e1fe9-5fea-4b0f-8b8f-6b0ef0db9456', // Visualizar Respostas
            '98bfd29e-b2ed-42b6-a7e8-183a4d7dce98', // Incluir Formulário Resposta
            'c6674252-0013-4ba3-97aa-c872083d43d0', // Clonar Formulário
            '9fd75089-9219-4fc0-ae85-75b3cebffe4b', // Incluir Resposta Formulario
            '00f8ffb3-6e8f-4ed5-946d-aff1d5f85919', // DDA consultar
            'a45a1974-5f8e-4a77-8623-d669598a6092', // Visualizar Ocultos
            '10085b65-bc93-45b7-8cf7-373968647559', // Adicionar Comentário
            '5990ed7d-361f-47bd-9c0b-8cb1fd54b227', // Atualizar Situação
            'a4200f8f-2c5f-473a-b28d-b07143e4aba4', // Atualizar Prioridade
            '1947c87d-82a4-4e50-9c85-a65885b79978', // Atualizar Descrição
            '93049f4d-ea1c-49b5-aac2-ccf973e45629', // Replicar Configuração
            'f9ec7e6d-9d0a-44e8-bb19-9fe899cbc73d', // Gerar Cotação
            'ffa1e923-ddc3-40c2-90c2-ff235d6edb26', // Gerar Pedido
            '3fd198d6-43e4-4a13-8def-873718d2138c', // Adicionar Fornecedor
            'e2e7e7a5-59a8-4b98-b7d7-483da5356dbd', // Adicionar nova negociação
            'a2ec1dcd-7c12-4d06-86f0-f6776bb558a1', // Visualizar melhores preços
            '3a8f6cc2-fd51-4eba-850c-c0a1c7e5afc1', // Avaliar Chamado
            '6fe3cfca-a6bb-4365-9705-54bf2a45cd5a', // Configurar Título Financeiro
            '6d22f883-df90-4d94-9077-4d0ce3ec516a', // Configurar Boleto
            '84a4e6a3-967d-44ec-92bf-517002fb6c4a', // Configurar PIX
            '75c360a4-80b8-481a-ab91-41e9d303c409', // Configurar TED/DOC
            '1de8c5d7-fb67-41d8-b990-eb76ccc6c115', // Configurar Tributo/Concessionaria
            'c8d1240f-680d-49c2-bf18-64d53ef47430', // Configurar QR Code
            '9fda9342-5381-4ead-a81d-627899de92d1', // Atualizar Certificao Digital
            '74993cdf-cdeb-46d2-84a2-73b3b8569780', // Sincronizar Eventos
            'df6b1c8b-de6c-4fe6-b5f1-e229dc7b86d7', // Chamado Atualizar data de vencimento
            '0056c8c9-c9bf-45b9-9cb8-2134decff719', // Chamado Editar Resposta Formulário
            '58c3fd6e-2c93-4f02-ba6c-46ccd76711c2', // Chamado Incluir Avaliação
            '6c7c2e33-6c14-4b9c-a09f-f0a767841ede', // Chamado Atualiza Última Leitura
            'ed684b56-30f6-440b-8cfa-23c2b4f492a7', // Chamado Mesclar
        ]

        const printModal = ref(false)
        const enviou = ref(false)
        const movimento_financeiro = ref(null)
        const impressao_movimento_financeiro = ref(null)
        const getFunctionById = (id) => props.programSchema.sis_funcionalidade.find((f) => f.id_funcionalidade === id)

        //eslint-disable-next-line
        async function handleMassAction(function_) {
            const asyncJob = {
                id: uuidv4(),
                title: null,
                message: null,
                status: 'running'
            }

            const ids = props.tableFunctions.currentSelectedRows;
            if (ids.length == 0 && function_.exige_registro) {
                return smallSelection(function_.denominacao)
            }

            if (ids.length > 1 && !function_.permite_operacoes_lote) {
                bigSelection(function_.denominacao)
            }

            const title = function_.denominacao;
            const route = function_.rota_backend;
            const body = {
                solicita_tfa: function_.solicita_tfa,
                id_programa: props.programSchema.id_programa,
                condicao_adicional: "",
                lista_id_empresa: JSON.parse(localStorage.getItem('companiesGlobalFilter')) ?? [],
                data_trabalho: JSON.parse(localStorage.getItem('dataTrabalho')),
                navegador: false,
                ordenacao: null,
                quantidade_registros: 0,
                pagina: 0,
                rota: props.programSchema.rota_backend + route,
                dados: {
                    [props.programSchema.sis_tabela.nome_tabela]: {
                        registros: []
                    }
                }
            }
            let swalText = `Tem certeza que deseja executar a operação "${title}" em ${ids.length} registro(s)?`
            if(!function_.exige_registro){
                swalText = `Tem certeza que deseja executar a operação "${title}"?`
            }
            Swal.fire({
                icon: "warning",
                text: swalText,
                confirmButtonText: "OK",
                showCancelButton: true,
                cancelButtonText: "Cancelar",
            }).then(async (result) => {
                if(result.value && !([
                    '60ace7db-6428-4c0c-9640-5f29f0d70f91' //liberar permissões
                    , '2a0bcc35-4452-4edb-b5fe-c92eba2f721c' //liberar empresas
                    , 'f749b6e3-bdae-4bbc-9558-29f8dd50a8a3' // Visualizar detalhes
                    , '315e16ca-bcd2-44a5-b4c9-cd7aa7215cb4' // Alterar Saldo
                    , '6c85577f-7d12-4083-9566-1466e59f3d1b' // Remover Participantes
                    , 'c36a3b5c-946e-4ea9-9032-a2655ab8abc4' // Adicionar Participantes
                    , '4fef3e70-5ec0-4347-a1ed-f00dfff05630' // Adicionar / Alterar Responsável
                    , '6135668e-b9da-4873-9453-5d7855c42156' // Abrir Chamado
                    , 'f1bad18b-eccc-4d91-a462-4bfb75e0f9e3' // Transferir departamento/categoria
                ]).includes(function_.id_funcionalidade)){
                    asyncJob.title = props.programSchema.denominacao + ' > ' + function_.denominacao
                    asyncJob.message = 'Operação em andamento...'
                    asyncJob.status = 'running'
                    store.commit('asyncJobs/addJob', asyncJob)
                }

                if (result.value && title == 'Excluir') {
                    const currentDataIds = props.tableFunctions.currentDataIds
                    const gridRows = props.grid.config.tableRef.current.props.data._rows
                    const targetTableRows = []
                    const register_objs = []
                    ids.forEach((id) => {
                        const index = currentDataIds.findIndex(cdid => cdid === id)
                        currentDataIds.splice(index, 1)
                        props.grid.config.dispatcher.dispatch({
                            type: 'UNCHECK',
                            payload: {
                                ROW_ID: id
                            }
                        });
                        props.tableFunctions.updateCurrentSelectedRows()
                        const htmlTableRowIndex = gridRows.findIndex(row => row._cells[1].data == id)
                        targetTableRows.push(htmlTableRowIndex)
                        register_objs.push(props.tableFunctions.getRowRegisterId(id).registro_obj)
                    })

                    displayTableRows(targetTableRows, 'none')

                    body.dados[props.programSchema.sis_tabela.nome_tabela].registros = [...register_objs]

                    const res = await sendFunctionRequest(body)

                    if (!res) {
                        asyncJob.message = 'Ocorreu um erro inesperado!'
                        asyncJob.status = 'failed'
                        store.commit('asyncJobs/updateJob', { ...asyncJob })
                        displayTableRows(targetTableRows, 'table-row');
                        currentDataIds.push(...ids)
                        return false
                    }

                    const data = res.data;
                    if (!data.status) {
                        asyncJob.message = data.mensagem ? data.mensagem : data.erro
                        asyncJob.status = 'failed'
                        store.commit('asyncJobs/updateJob', { ...asyncJob })

                        Swal.fire({
                            icon: "error",
                            text: data.mensagem ? data.mensagem : data.erro,
                            confirmButtonColor: "#34c38f",
                            confirmButtonText: "OK",
                        });
                        displayTableRows(targetTableRows, 'table-row');
                        currentDataIds.push(...ids)
                    }
                    else {
                        currentDataIds.push(...ids)
                    }
                } else if(result.value && 
                    [
                        '60ace7db-6428-4c0c-9640-5f29f0d70f91', // Liberar permissões de programas
                        '2a0bcc35-4452-4edb-b5fe-c92eba2f721c', // Liberar permissões de empresas
                        '6c85577f-7d12-4083-9566-1466e59f3d1b', // Remover Participantes
                        'c36a3b5c-946e-4ea9-9032-a2655ab8abc4', // Adicionar Participantes
                        '4fef3e70-5ec0-4347-a1ed-f00dfff05630', // Adicionar / Alterar Responsável
                        'f1bad18b-eccc-4d91-a462-4bfb75e0f9e3', // Transferir departamento/categoria
                    ].includes(function_.id_funcionalidade)){                   
                    body.dados[props.programSchema.sis_tabela.nome_tabela].registros = ids.map(id => props.tableFunctions.getRowRegisterId(id).registro_obj)
                    
                    
                    const listValueIdOrNull = getListValueIdByFunctionalityId({ id_funcionalidade: function_.id_funcionalidade, registros: body.dados[props.programSchema.sis_tabela.nome_tabela].registros })
                    if (!listValueIdOrNull) {
                      return Swal.fire({
                        icon: "error",
                        text: `Funcionalidade com id [${function_.id_funcionalidade}] não possui lista-valor associada`,
                        confirmButtonColor: "#34c38f",
                        confirmButtonText: "OK",
                      });
                    }
                    const res = await getValueListById(listValueIdOrNull.id_lista_valor, listValueIdOrNull.condicao_adicional)
                    if (!res) {
                        Swal.fire({
                            icon: "error",
                            text: 'Erro ao buscar lista de valores',
                            confirmButtonColor: "#34c38f",
                            confirmButtonText: "OK",
                        });
                        return false
                    }

                    store.commit('massActionsModals/setProperty', [
                        {
                            key: 'showDynamicFilterValueListModal',
                            value: true
                        },
                        {
                            key: 'title',
                            value: ''
                        },
                        {
                            key: 'sis_lista_valor',
                            value: res.sis_lista_valor
                        },
                        {
                            key: 'onSave',
                            value: (ids_object) => {
                                return sendFunctionRequest(
                                    { ...body, dados: { ...body.dados, ...ids_object }},
                                    () => {
                                        asyncJob.message = 'Operação concluída!'
                                        asyncJob.status = 'done'
                                        store.commit('asyncJobs/updateJob', { ...asyncJob })
                                        
                                        uncheckAllRows()
                                        Swal.fire({
                                            icon: "success",
                                            text: `Salvo!`,
                                            confirmButtonText: "OK",
                                        })
                                        props.restartGrid()
                                    },
                                    (error) => {
                                        asyncJob.message = error
                                        asyncJob.status = 'failed'
                                        store.commit('asyncJobs/updateJob', { ...asyncJob })
                                    },
                                    true,
                                    () => {
                                        asyncJob.title = props.programSchema.denominacao + ' > ' + function_.denominacao
                                        asyncJob.message = 'Operação em andamento...'
                                        asyncJob.status = 'running'
                                        store.commit('asyncJobs/addJob', asyncJob)
                                    }
                                )
                            }
                        }
                    ])
                }else if(result.value && function_.id_funcionalidade === 'f749b6e3-bdae-4bbc-9558-29f8dd50a8a3'){ //Visualizar detalhes
                    body.dados[props.programSchema.sis_tabela.nome_tabela].registros = ids.map(id => props.tableFunctions.getRowRegisterId(id).registro_obj)

                    await sendFunctionRequest(body,
                        (res) => {
                            uncheckAllRows();
                            asyncJob.message = 'Operação concluída!'
                            asyncJob.status = 'done'
                            store.commit('asyncJobs/updateJob', { ...asyncJob })
                            const data = res.data.retorno
                            showVisualizarDetalhesModal(data)
                        },
                        (error) => {
                            Swal.fire({
                                icon: "error",
                                text: error,
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            });
                            asyncJob.message = error
                            asyncJob.status = 'failed'
                            store.commit('asyncJobs/updateJob', { ...asyncJob })
                        }, 
                        false,
                        () => {
                            asyncJob.title = props.programSchema.denominacao + ' > ' + 'Obtendo registros...'
                            asyncJob.message = 'Operação em andamento...'
                            asyncJob.status = 'running'
                            store.commit('asyncJobs/addJob', asyncJob)
                        }
                    )
                }else if(result.value && function_.id_funcionalidade === '6135668e-b9da-4873-9453-5d7855c42156'){ // Abrir Chamado
                    const primary_key_name = props.programSchema.sis_tabela.sis_campo_tabela.find(sct => sct.chave_primaria).nome
                    const primary_keys = ids.map(id => {return {id_chave_registro_b: props.tableFunctions.getRowRegisterId(id).registro_obj[primary_key_name]}})
                    store.commit('massActionsModals/setProperty', [
                        {
                            key: 'showOpenTicketModal',
                            value: true
                        },
                        {
                            key: 'title',
                            value: function_.denominacao
                        },
                        {
                            key: 'additional_data',
                            value: {
                                nome_tabela: 'sis_relacao_registro',
                                data: primary_keys
                            }
                        },
                        {
                            key: 'program_schema',
                            value: props.programSchema
                        },
                        {
                            key: 'onSave',
                            value: (objects) => {
                                return sendFunctionRequest(
                                    { ...body, dados: { ...body.dados, ...objects }},
                                    () => {
                                        asyncJob.message = 'Operação concluída!'
                                        asyncJob.status = 'done'
                                        store.commit('asyncJobs/updateJob', { ...asyncJob })
                                        
                                        uncheckAllRows()
                                        Swal.fire({
                                            icon: "success",
                                            text: `Salvo!`,
                                            confirmButtonText: "OK",
                                        })
                                        props.restartGrid()
                                    },
                                    (error) => {
                                        asyncJob.message = error
                                        asyncJob.status = 'failed'
                                        store.commit('asyncJobs/updateJob', { ...asyncJob })
                                    },
                                    true,
                                    () => {
                                        asyncJob.title = props.programSchema.denominacao + ' > ' + function_.denominacao
                                        asyncJob.message = 'Operação em andamento...'
                                        asyncJob.status = 'running'
                                        store.commit('asyncJobs/addJob', asyncJob)
                                    }
                                )
                            }
                        }
                    ])
                }else if(result.value && function_.id_funcionalidade === '315e16ca-bcd2-44a5-b4c9-cd7aa7215cb4'){ // Alterar Saldo
                    // body.dados[props.programSchema.sis_tabela.nome_tabela].registros = ids.map(id => props.tableFunctions.getRowRegisterId(id).registro_obj)
                    // ids.map(id => console.log(props.tableFunctions.getRowRegisterId(id)))

                    store.commit('massActionsModals/setProperty', [
                        {
                            key: 'showUpdateSaldoModal',
                            value: true
                        },
                        {
                            key: 'title',
                            value: function_.denominacao
                        },
                        {
                            key: 'objects',
                            value: ids.map(id => props.tableFunctions.getRowRegisterId(id).registro_data)
                        },
                        {
                            key: 'onSave',
                            value: (objects) => {
                                return sendFunctionRequest(
                                    { ...body, dados: { ...body.dados, ...objects }},
                                    () => {
                                        asyncJob.message = 'Operação concluída!'
                                        asyncJob.status = 'done'
                                        store.commit('asyncJobs/updateJob', { ...asyncJob })
                                        
                                        uncheckAllRows()
                                        Swal.fire({
                                            icon: "success",
                                            text: `Salvo!`,
                                            confirmButtonText: "OK",
                                        })
                                        props.restartGrid()
                                    },
                                    (error) => {
                                        asyncJob.message = error
                                        asyncJob.status = 'failed'
                                        store.commit('asyncJobs/updateJob', { ...asyncJob })
                                    },
                                    true,
                                    () => {
                                        asyncJob.title = props.programSchema.denominacao + ' > ' + function_.denominacao
                                        asyncJob.message = 'Operação em andamento...'
                                        asyncJob.status = 'running'
                                        store.commit('asyncJobs/addJob', asyncJob)
                                    }
                                )
                            }
                        }
                    ])
                }else if (result.value && (title == 'Remover Permissões' || title == 'Remover Empresas')) {
                    body.dados[props.programSchema.sis_tabela.nome_tabela].registros = ids.map(id => props.tableFunctions.getRowRegisterId(id).registro_obj);
                    if (result.value) {
                        await sendFunctionRequest(body,
                            () => {
                                uncheckAllRows();
                                Swal.fire({
                                    icon: "success",
                                    text: `Salvo!"`,
                                    confirmButtonText: "OK",
                                })
                            },
                            (error) => {
                                asyncJob.message = error
                                asyncJob.status = 'failed'
                                store.commit('asyncJobs/updateJob', { ...asyncJob })
                            }
                        )
                    }
                } else if (result.value && title == 'Desconciliar') {
                    const envio = {
                        id_programa: _route.params.targetId,
                        condicao_adicional: "",
                        lista_id_empresa: JSON.parse(localStorage.getItem('companiesGlobalFilter')) ?? [],
                        data_trabalho: JSON.parse(localStorage.getItem('dataTrabalho')),
                        navegador: false,
                        ordenacao: null,
                        quantidade_registros: 0,
                        pagina: 0,
                        dados: {
                            comprovante: {
                                registros: body.dados[props.programSchema.sis_tabela.nome_tabela].registros = ids.map(id => props.tableFunctions.getRowRegisterId(id).registro_obj)
                            },
                        },
                        rota: props.programSchema.rota_backend + route,
                        id: "" + _route.query.registro_id
                    }

                    await sendFunctionRequest(
                        envio,
                        null,
                        (error) => {
                            asyncJob.message = error
                            asyncJob.status = 'failed'
                            store.commit('asyncJobs/updateJob', { ...asyncJob })
                        }
                    )
                    .then((res) => {
                        const data = res.data
                        if (!data.status) {
                            asyncJob.message = data.mensagem ? data.mensagem : data.erro
                            asyncJob.status = 'failed'
                            store.commit('asyncJobs/updateJob', { ...asyncJob })

                            Swal.fire({
                                icon: "error",
                                text: data.mensagem ? data.mensagem : data.erro,
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            });
                        } else {
                            props.tableFunctions.updateConfig(data.retorno.dados.comprovante.registros)
                            props.docsTableFunctions.updateConfig(data.retorno.dados.sis_documento.registros)
                        }
                    })
                } else if (result.value && title == 'Excluir Documentos') {
                    const envio = {
                        id_programa: _route.params.targetId,
                        condicao_adicional: "",
                        lista_id_empresa: JSON.parse(localStorage.getItem('companiesGlobalFilter')) ?? [],
            data_trabalho: JSON.parse(localStorage.getItem('dataTrabalho')),
                        navegador: false,
                        ordenacao: null,
                        quantidade_registros: 0,
                        pagina: 0,
                        dados: {
                            sis_documento: {
                                registros: ids.map(id => props.docsTableFunctions.getLinkAndId(id).registro_obj)
                            },
                        },
                        rota: props.programSchema.rota_backend + route,
                        id: "" + _route.query.registro_id
                    }

                    await sendFunctionRequest(
                        envio,
                        null,
                        (error) => {
                            asyncJob.message = error
                            asyncJob.status = 'failed'
                            store.commit('asyncJobs/updateJob', { ...asyncJob })
                        }
                    )
                    .then((res) => {
                        const data = res.data
                        if (!data.status) {
                            asyncJob.message = data.mensagem ? data.mensagem : data.erro
                            asyncJob.status = 'failed'
                            store.commit('asyncJobs/updateJob', { ...asyncJob })

                            Swal.fire({
                                icon: "error",
                                text: data.mensagem ? data.mensagem : data.erro,
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            });
                        } else {
                            Swal.fire({
                                icon: "success",
                                text: 'Registros apagados com sucesso!',
                                confirmButtonText: "OK",
                            })
                            props.tableFunctions.updateConfig(data.retorno.dados.comprovante.registros)
                            props.docsTableFunctions.updateConfig(data.retorno.dados.sis_documento.registros)
                        }
                    })
                } else if (result.value && ['f8768f85-670f-4d87-a657-3b62f3f2243f', '2e3139bb-3939-48ef-aa67-808bec6bd30a'].includes(function_.id_funcionalidade)) { //Agendar Pagamento && Gerar Contas a Pagar
                    body.dados[props.programSchema.sis_tabela.nome_tabela].registros = ids ? ids.map(id => props.tableFunctions.getRowRegisterId(id).registro_obj) : [];
                    
                    const with_approvals = ids.map(id => props.tableFunctions.getRowRegisterId(id).registro_data).filter(registro => registro.qtde_aprovacao > 0)
                    const financialAccountIdListaValor = "9731677c-8dfa-4bf5-8832-c25ab05be9ab"
                    const typeOfChargeIdListaValor = "4c8d1b30-6479-48d3-aa08-de583c2b2911"

                    const [financialAccount, typeOfCharge] = await Promise.all([
                        getValueListById(financialAccountIdListaValor),
                        getValueListById(typeOfChargeIdListaValor),
                    ])

                    store.commit('contasPagar/setListFinancialAccounts', financialAccount.sis_lista_valor.sis_opcao_lista_valor);
                    store.commit('contasPagar/setListOfTypeCharge', typeOfCharge.sis_lista_valor.sis_opcao_lista_valor);
                    store.commit('contasPagar/setTitle', title);
                    store.commit('contasPagar/setShow', true);
                    store.commit('contasPagar/setOnSave', (schedulePayment) => {
                        const continue_on_save = () => {
                            sendFunctionRequest(
                                {
                                ...body,
                                dados: {
                                    ...body.dados,
                                    ["agendar_pagamento"]: { registros: [schedulePayment] }
                                }},
                                () => {
                                uncheckAllRows();
                                Swal.fire({
                                    icon: "success",
                                    text: `Salvo!"`,
                                    confirmButtonText: "OK",
                                })
                                store.commit('contasPagar/setTitle', title);
                                store.commit('contasPagar/setShow', false);
                                },
                                (error) => {
                                asyncJob.message = error
                                asyncJob.status = 'failed'
                                store.commit('asyncJobs/updateJob', { ...asyncJob })
                                }
                            )
                        }
                        if(with_approvals.length > 0){
                            Swal.fire({
                                icon: "error",
                                html: `<p>Os seguintes IDs perderão as aprovações: <br><span class="bg-light">${with_approvals.map(registro => registro.id_titulo_financeiro).join(', ')}</span></p>
                                        <p>Tem certeza que deseja continuar?</p>`,
                                confirmButtonText: "OK",
                            }).then((result2) => {
                                if(result2.value){
                                    continue_on_save()
                                }
                            })
                        }else{
                            continue_on_save()
                        }
                    });
                } else if (result.value) {
                    body.dados[props.programSchema.sis_tabela.nome_tabela].registros = ids ? ids.map(id => props.tableFunctions.getRowRegisterId(id).registro_obj) : [];
                    if(function_.id_funcionalidade == '2d3de029-490a-4fc0-b3cd-e7447cb791b5') {
                        const data_movimento = document.querySelector('.movimento_venda_date_filter')?.value ?? null
                        if(data_movimento){
                            body.data_movimento = data_movimento
                        }
                    }

                    if (function_.id_funcionalidade == 'a45a1974-5f8e-4a77-8623-d669598a6092') {
                      const route = '/debito_direto_autorizado/visualizar_ocultos'

                      store.commit('frameworkNavegador/setTituloFinanceiroVisualizarOcultos', route)

                      Reflect.deleteProperty(body, 'condicao_adicional')
                    }

                    await sendFunctionRequest(body,
                        () => {
                            uncheckAllRows();
                            Swal.fire({
                                icon: "success",
                                text: `Sucesso!"`,
                                confirmButtonText: "OK",
                            })
                            props.restartGrid()
                        },
                        (error) => {
                            asyncJob.message = error
                            asyncJob.status = 'failed'
                            store.commit('asyncJobs/updateJob', { ...asyncJob })
                        }
                    )

                  if (function_.id_funcionalidade == 'a45a1974-5f8e-4a77-8623-d669598a6092') {
                    store.commit('frameworkNavegador/setTituloFinanceiroVisualizarOcultos', null)
                  }
                }

                if(result.value && asyncJob.status == 'running'){
                  asyncJob.message = 'Operação concluída!'
                  asyncJob.status = 'done'
                  store.commit('asyncJobs/updateJob', { ...asyncJob })
                }
            })
        }
        function displayTableRows(indexes, style) {
            const gridBodyElm = [].slice.call(document.getElementsByClassName('gridjs-tbody'))[0];
            indexes.forEach(index => {
                gridBodyElm.childNodes[index].style.display = style;
            })
        }
        async function documentActions(function_) {
            props.tipoModal(props.movimento_venda)
            const ids = props.tableFunctions.currentSelectedRows;
            if (ids.length == 0) {
                return smallSelection(function_.denominacao)
            }

            if (ids.length > 1 && !function_.permite_operacoes_lote) {
                bigSelection(function_.denominacao)
            }

            const route = function_.rota_backend;
            const envio = {
                id_programa: _route.params.targetId,
                condicao_adicional: "",
                lista_id_empresa: JSON.parse(localStorage.getItem('companiesGlobalFilter')) ?? [],
                data_trabalho: JSON.parse(localStorage.getItem('dataTrabalho')),
                navegador: false,
                ordenacao: null,
                quantidade_registros: 0,
                pagina: 0,
                dados: {
                    sis_documento: {
                        registros: props.movimento_venda == 'Comprovante' ?
                            ids.map(id => props.tableFunctions.getLinkAndId(id).registro_obj)
                            : ids.map(id => props.docsTableFunctions.getLinkAndId(id).registro_obj)
                    },
                },
                rota: props.programSchema.rota_backend + route,
                id: "" + _route.query.registro_id
            }

            await sendFunctionRequest(envio)
                .then((res) => {
                    const data = res.data
                    if (!data.status) {
                        Swal.fire({
                            icon: "error",
                            text: data.mensagem ? data.mensagem : data.erro,
                            confirmButtonColor: "#34c38f",
                            confirmButtonText: "OK",
                        });
                    } else {
                        function_.id_funcionalidade == 'b9d6d7c6-34e6-44b2-a846-147306fe1c23' ? // Download Documentos
                            downloadDocument(props.movimento_venda + '.zip', data.retorno)
                            : (props.arquivos(
                                props.movimento_venda === 'Documento'
                                    ? data.retorno.dados.sis_documento.registros
                                    : filtro(data.retorno.dados.sis_documento.registros, props.dados)
                            ), props.showFileModal())
                    }

                    function filtro(primeiroArray, segundoArray) {
                        var resultado = []
                        var conferido = []
                        primeiroArray == null ? primeiroArray = [] : primeiroArray
                        for (const primeiroItem of primeiroArray) {
                            const segundoItem = segundoArray.find(item => item.id_documento === primeiroItem.id_documento)
                            resultado.push(primeiroItem)
                            conferido.push(segundoItem)
                        }
                        props.conferimento(removerProxy(conferido))

                        return resultado
                    }

                    function removerProxy(objeto) {
                        if (Array.isArray(objeto)) {
                            return objeto.map(item => removerProxy(item))
                        } else if (objeto !== null && typeof objeto === 'object') {
                            const novoObjeto = {}
                            Object.keys(objeto).forEach(chave => {
                                novoObjeto[chave] = removerProxy(objeto[chave])
                            })
                            return novoObjeto
                        } else {
                            return objeto
                        }
                    }
                })

        }
        function uncheckAllRows() {
            props.tableFunctions.currentDataIds.map(id => {
                props.grid.config.dispatcher.dispatch({
                    type: 'UNCHECK',
                    payload: {
                        ROW_ID: id
                    }
                });
                props.tableFunctions.updateCurrentSelectedRows()
            })
            const fakeCheckBoxes = document.querySelector('#fake-select-container')?.querySelectorAll('.fake-checkbox')
            if(fakeCheckBoxes){
                Array.from(fakeCheckBoxes).forEach((fakeCheckBox) => {
                    fakeCheckBox.classList.remove('gridjs-tr-selected')
                })
            }
        }
        function bigSelection(action) {
            Swal.fire({
                icon: "warning",
                text: `É permitido selecionar somente um registro para executar a ação "${action}".`,
                confirmButtonText: "OK",
            })
        }
        function smallSelection(action) {
            Swal.fire({
                icon: "warning",
                text: `É necessário selecionar um registro para executar a ação "${action}".`,
                confirmButtonText: "OK",
            })
        }
        async function callNewRegister() {
            const query = Object.assign({}, _route.query)
            query.tipo = 'criacao'
            await router.push({ query })
            store.commit('programForm/setFormType', 'creation')
        }
        async function callShowRegister() {
            const ids = props.tableFunctions.currentSelectedRows;
            if (ids.length > 1) {
                bigSelection('Visualizar')
            } else if (ids.length == 0) {
                smallSelection('Visualizar')
            } else if (ids.length == 1) {
                const query = Object.assign({}, _route.query)
                query.registro_id = ids[0];
                query.tipo = 'visualizacao'
                await router.push({ query })
                store.commit('programForm/setFormType', 'show')
            }
        }
        async function callEdition() {
            const ids = props.tableFunctions.currentSelectedRows;
            if (ids.length > 1) {
                bigSelection('Editar')
            } else if (ids.length == 0) {
                smallSelection('Editar')
            } else if (ids.length == 1) {
                const query = Object.assign({}, _route.query)
                query.registro_id = ids[0];
                query.tipo = 'edicao'
                await router.push({ query })
                store.commit('programForm/setFormType', 'edition')
            }
        }
        async function callPrinting() {
            const ids = props.tableFunctions.currentSelectedRows;
            if (ids.length > 1) {
                bigSelection('Imprimir')
            } else if (ids.length == 0) {
                smallSelection('Imprimir')
            } else if (ids.length == 1) {
                printModal.value = true
            }
        }
        function acessPrint(codigo, senha, callback) {
            const id = props.tableFunctions.currentSelectedRows;

            bodyFunctionRequest(
                _route.params.targetId,
                null,
                'obtem_dados_programa_usuario',
                id,
                1
            )
                .then(async (data) => await sendFunctionRequest(data))
                .then(async (data) => {
                    if (!data.data.status) {
                        Swal.fire({
                            icon: 'error',
                            text: data.mensagem ? data.data.mensagem : data.data.erro,
                            confirmButtonColor: '#34c38f',
                            confirmButtonText: 'OK',
                        });
                        callback(false);
                        return Promise.reject('Erro na segunda chamada');
                    } else {
                        const dados = {
                            movimento_financeiro: {
                                registros: [
                                    {
                                        id_movimento_financeiro: id[0],
                                        codigo: codigo,
                                        senha_numerica: senha,
                                    },
                                ],
                            },
                        }

                        movimento_financeiro.value = data.data.retorno.dados.movimento_financeiro.registros[0];
                        impressao_movimento_financeiro.value = data.data.retorno.dados.impressao_movimento_financeiro.registros;
                        callback(true);

                        return await bodyFunctionRequest(
                            _route.params.targetId,
                            dados,
                            props.programSchema.rota_backend +
                            getFunctionById('9c7f15ca-91d8-4b63-9482-39b2b91e1fb6').rota_backend,
                            id
                        );
                    }
                })
                .then(async (data) => await sendFunctionRequest(data))
                .then((data) => {
                    if (!data.data?.status) {
                        Swal.fire({
                            icon: 'error',
                            text: data.data.mensagem ? data.data.mensagem : data.data.erro,
                            confirmButtonColor: '#34c38f',
                            confirmButtonText: 'OK',
                        });
                        callback(false);
                    } else {
                        enviou.value = true
                        callback(true)
                    }
                })
                .catch((error) => {
                    callback(null, error);
                });
        }
        function formatarData(dataString) {
            const dataOriginal = new Date(dataString);

            const dia = String(dataOriginal.getDate()).padStart(2, '0');
            const mes = String(dataOriginal.getMonth() + 1).padStart(2, '0');
            const ano = dataOriginal.getFullYear();
            const horas = String(dataOriginal.getHours()).padStart(2, '0');
            const minutos = String(dataOriginal.getMinutes()).padStart(2, '0');

            const dataFormatada = `${dia}/${mes}/${ano} às ${horas}:${minutos}`;

            return dataFormatada;
        }
        function formatarMoeda(valor) {
            const valorFormatado = parseFloat(valor).toFixed(2);
            const [parteInteira, parteDecimal] = valorFormatado.split('.');
            const parteInteiraFormatada = parteInteira.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            const valorEmReais = `R$ ${parteInteiraFormatada},${parteDecimal}`;

            return valorEmReais;
        }
        const sortListOfFunctions = computed(() => {
            const staticOrderList = [
                '/liberar_empresas',
                '/remover_empresas',
                '/liberar_permissoes',
                '/remover_permissoes',
                '/pausar',
                '/despausar',
                '/agendar_pagamento',
                '/aprovar_pagamento',
                '/adicionar_alterar_responsavel',
                '/adicionar_participantes',
                '/remover_participantes',
            ]
            const { sis_funcionalidade } = props.programSchema
            const functionalitiesByStaticOrderList = []
            staticOrderList.forEach(
                item => {
                    const func = sis_funcionalidade.find(sis => sis.rota_backend === item)
                    if (func) {
                        functionalitiesByStaticOrderList.push(func)
                    }
                }
            )
            const functionalities = [...functionalitiesByStaticOrderList, ...sis_funcionalidade]
            const removedDuplicateValues = [...new Set(functionalities)]

            return removedDuplicateValues.filter(func => !notInclude.includes(func.id_funcionalidade))
        })

        function showVisualizarDetalhesModal(data){
            store.commit('massActionsModals/setProperty', [
                {
                    key: 'showVisualizarDetalhesContasAPagarModal',
                    value: true
                },
                {
                    key: 'data',
                    value: data
                },
                {
                    key: 'programSchema',
                    value: props.programSchema
                },
            ])
        }

        return {
            getFunctionById,
            handleMassAction,
            callNewRegister,
            callEdition,
            callPrinting,
            callShowRegister,
            documentActions,
            sortListOfFunctions,
            printModal,
            enviou,
            acessPrint,
            movimento_financeiro,
            impressao_movimento_financeiro,
            formatarData,
            formatarMoeda,
            showNewResponseFormModal: ref(false),
        }
    },
    data() {
        return {
            codigo: null,
            senha: null
        }
    },
    methods: {
        async enviar(codigo, senha) {
            this.acessPrint(codigo, senha, (data) => {
                if (data) {
                    this.$nextTick(() => {
                        this.$refs.printButton?.click();
                    })
                        .then(() => {
                            this.printModal = false
                            this.enviou = false
                        })
                }
            })
        }
    },
    directives: {
        print
    },
}
</script>

<template>
    <div>
        <button
            v-if="getFunctionById('7f96d25e-352f-4f48-872c-266e8b0ee835')?.funcionalidade_habilitada"
            type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20" v-b-tooltip.hover.top="'Incluir'"
            @click="callNewRegister">
            <i
                :class="[getFunctionById('7f96d25e-352f-4f48-872c-266e8b0ee835')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
        </button>
        <button
            v-if="getFunctionById('80bb4224-7116-46f6-8c33-f349059858fd')?.funcionalidade_habilitada && $store.state.programForm.formType == 'navigation'"
            type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20" v-b-tooltip.hover.top="'Editar'"
            @click="callEdition">
            <i
                :class="[getFunctionById('80bb4224-7116-46f6-8c33-f349059858fd')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
        </button>
        <button
            v-if="getFunctionById('16dba857-ca79-4bac-bdad-ca9d5595e5ea')?.funcionalidade_habilitada"
            type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20" v-b-tooltip.hover.top="'Excluir'"
            @click="handleMassAction(getFunctionById('16dba857-ca79-4bac-bdad-ca9d5595e5ea'))">
            <i
                :class="[getFunctionById('16dba857-ca79-4bac-bdad-ca9d5595e5ea')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
        </button>
        <button
            v-if="getFunctionById('f1fed607-4a32-40e6-b41c-0e718f2db64d')?.funcionalidade_habilitada && $store.state.programForm.formType == 'navigation'"
            type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20" v-b-tooltip.hover.top="'Visualizar'"
            @click="callShowRegister">
            <i
                :class="[getFunctionById('f1fed607-4a32-40e6-b41c-0e718f2db64d')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
        </button>
        <button
            v-if="getFunctionById('a45a1974-5f8e-4a77-8623-d669598a6092')?.funcionalidade_habilitada && $store.state.programForm.formType == 'navigation'"
            type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20" v-b-tooltip.hover.top="'Visualizar Ocultos'"
            @click="handleMassAction(getFunctionById('a45a1974-5f8e-4a77-8623-d669598a6092'))">
            <i
                :class="[getFunctionById('a45a1974-5f8e-4a77-8623-d669598a6092')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
        </button>
        <button
            v-if="getFunctionById('a52a0b8c-9fa7-42dc-be20-45d49ca1b426')?.funcionalidade_habilitada && $store.state.programForm.formType == 'navigation'"
            type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
            v-b-tooltip.hover.top="'Visualizar Auditoria'"
            @click="handleMassAction(getFunctionById('a52a0b8c-9fa7-42dc-be20-45d49ca1b426'))">
            <i
                :class="[getFunctionById('a52a0b8c-9fa7-42dc-be20-45d49ca1b426')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
        </button>
        <button
            v-if="getFunctionById('c6674252-0013-4ba3-97aa-c872083d43d0')?.funcionalidade_habilitada && $store.state.programForm.formType == 'navigation'"
            type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
            v-b-tooltip.hover.top="'Clonar Formulário'"
            @click="handleMassAction(getFunctionById('c6674252-0013-4ba3-97aa-c872083d43d0'))">
            <i
                :class="[getFunctionById('c6674252-0013-4ba3-97aa-c872083d43d0')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
        </button>
        <button
            v-if="getFunctionById('2d3de029-490a-4fc0-b3cd-e7447cb791b5')?.funcionalidade_habilitada && $store.state.programForm.formType == 'navigation'"
            type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20" v-b-tooltip.hover.top="'Sincronizar Petros'"
            @click="handleMassAction(getFunctionById('2d3de029-490a-4fc0-b3cd-e7447cb791b5'))">
            <i
                :class="[getFunctionById('2d3de029-490a-4fc0-b3cd-e7447cb791b5')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
        </button>
        <button
            v-if="getFunctionById('68ec7d3d-e6cc-449b-8829-e636a812b0c4')?.funcionalidade_habilitada && this.$props.movimento_venda == 'Comprovante'"
            type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20" v-b-tooltip.hover.top="'Desconciliar'"
            @click="handleMassAction(getFunctionById('68ec7d3d-e6cc-449b-8829-e636a812b0c4'))">
            <i
                :class="[getFunctionById('68ec7d3d-e6cc-449b-8829-e636a812b0c4')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
        </button>
        <button
            v-if="getFunctionById('dd34d8e4-d993-4e6b-8c99-a411a959550a')?.funcionalidade_habilitada && this.$props.movimento_venda == 'Documento'"
            type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
            v-b-tooltip.hover.top="'Adicionar Documentos'" @click="this.$props.showUploadModal">
            <i
                :class="[getFunctionById('dd34d8e4-d993-4e6b-8c99-a411a959550a')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
        </button>
        <button
            v-if="getFunctionById('b9d6d7c6-34e6-44b2-a846-147306fe1c23')?.funcionalidade_habilitada && $store.state.programForm.formType != 'navigation'"
            type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
            v-b-tooltip.hover.top="'Download Documentos'"
            @click="documentActions(getFunctionById('b9d6d7c6-34e6-44b2-a846-147306fe1c23'))">
            <i
                :class="[getFunctionById('b9d6d7c6-34e6-44b2-a846-147306fe1c23')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
        </button>
        <button
            v-if="getFunctionById('3e95322a-8f41-4c21-8bfa-9c9908cdbeac')?.funcionalidade_habilitada && $store.state.programForm.formType != 'navigation'"
            type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
            v-b-tooltip.hover.top="'Visualizar Documentos'"
            @click="documentActions(getFunctionById('3e95322a-8f41-4c21-8bfa-9c9908cdbeac'))">
            <i
                :class="[getFunctionById('3e95322a-8f41-4c21-8bfa-9c9908cdbeac')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
        </button>
        <button
            v-if="getFunctionById('07fdec1e-448a-4d9a-90ea-4fc6f3e16b6d')?.funcionalidade_habilitada && this.$props.movimento_venda == 'Documento'"
            type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20" v-b-tooltip.hover.top="'Excluir Documentos'"
            @click="handleMassAction(getFunctionById('07fdec1e-448a-4d9a-90ea-4fc6f3e16b6d'))">
            <i
                :class="[getFunctionById('07fdec1e-448a-4d9a-90ea-4fc6f3e16b6d')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
        </button>
        <button
            v-if="getFunctionById('9c7f15ca-91d8-4b63-9482-39b2b91e1fb6')?.funcionalidade_habilitada"
            type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
            v-b-tooltip.hover.top="getFunctionById('9c7f15ca-91d8-4b63-9482-39b2b91e1fb6')?.denominacao"
            @click="callPrinting">
            <i
                :class="[getFunctionById('9c7f15ca-91d8-4b63-9482-39b2b91e1fb6')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
        </button>
        <button
            v-if="getFunctionById('00f8ffb3-6e8f-4ed5-946d-aff1d5f85919')?.funcionalidade_habilitada"
            type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20" v-b-tooltip.hover.top="getFunctionById('00f8ffb3-6e8f-4ed5-946d-aff1d5f85919')?.denominacao"
            @click="handleMassAction(getFunctionById('00f8ffb3-6e8f-4ed5-946d-aff1d5f85919'))">
            <i
                :class="[getFunctionById('00f8ffb3-6e8f-4ed5-946d-aff1d5f85919')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
        </button>
    </div>
    <!-- Dynamic buttons -->
    <div v-if="this.$props.tableFunctions.currentSelectedRows.length > 0 && sortListOfFunctions.length > 0">
        <div data-aos="fade-right" data-aos-duration="500" class="hstack gap-1 align-items-center flex-wrap">
            <template v-for="function_ in sortListOfFunctions" :key="function_.id_funcionalidade">
                <button type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                    v-b-tooltip.hover.top="function_.denominacao" @click="handleMassAction(function_)">
                    <i :class="[function_.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
                </button>
            </template>
        </div>
    </div>

    <b-modal id="generateOtpModal" v-model="printModal" fade centered hide-header hide-footer>
        <div class="modal-header" v-if="!enviou">
            <h5 class="modal-title text-primary">Digite e-mail e senha para prosseguir!</h5>
        </div>
        <div class="modal-body">
            <div class="container">
                <div class="row">
                    <div class="p-2">
                        <div v-if="!enviou">

                            <div class="mb-3">
                                <label class="form-label">
                                    Código
                                </label>
                                <input v-model="codigo" type="text" class="form-control"
                                    placeholder="Coloque seu Email ou sua Conta">
                            </div>

                            <div class="mb-3">
                                <label class="form-label" for="password-input1">
                                    Senha
                                </label>
                                <div class="position-relative auth-pass-inputgroup mb-3">
                                    <input v-model="senha" type="password" class="form-control pe-5 password-input1"
                                        placeholder="Coloque sua senha">
                                </div>
                            </div>

                            <div class="mt-3 d-flex justify-content-center">
                                <button class="btn btn-success w-100" @click="enviar(codigo, senha)">
                                    Prosseguir
                                </button>
                            </div>
                        </div>
                        <div v-else>
                            <div id="printMe">
                                <div class="logo-container">
                                    <img src="@/assets/images/logos/logoAzul.png" class="logo" alt="Logo Azul">
                                </div>

                                <span class="mt-2 text-center d-block">
                                    {{ movimento_financeiro?.nome_empresa }}
                                </span>

                                <span v-if="movimento_financeiro?.impresso" class="my-2 d-block text-center">
                                    <strong>Segunda via</strong>
                                </span>

                                <hr class="my-2">

                                <div class="text-center">
                                    <span class="d-block" style="font-size: 10px;">
                                        ID Transação: <strong>{{ movimento_financeiro?.end_to_end_id }}</strong>
                                    </span>
                                    <span class="d-block">
                                        NSU: <strong>{{ movimento_financeiro?.documento_origem }}</strong>
                                    </span>
                                </div>
                                <hr class="my-2">

                                <span class="d-block">
                                    Data: {{ formatarData(movimento_financeiro?.data_movimento) }}
                                </span>
                                <span class="d-block">
                                    Valor: <strong>{{ formatarMoeda(movimento_financeiro?.valor_movimento) }}</strong>
                                </span>
                                <span class="d-block">
                                    Observação: {{ movimento_financeiro?.observacao }}
                                </span>
                                <hr class="my-2">

                                <div class="text-center">
                                    <span class="d-block">Horário local: {{ formatarData(new Date()) }}</span>
                                </div>
                                <hr class="my-2">

                                <div class="text-center">
                                    <span class="d-block"><strong>Lista de impressões:</strong></span>
                                    <span v-for="item in impressao_movimento_financeiro" :key="item" class="d-block">
                                        {{ formatarData(item.data_impressao) }}
                                        <br>
                                        {{ item.usuario_impressao }}
                                        <br><br>
                                    </span>
                                </div>
                            </div>

                            <button class="btn btn-primary" ref="printButton" v-print="'#printMe'"
                                style="display: none;">Imprimir</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<style scoped>
.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    max-width: 200px;
    width: 100%;
    height: auto;
}

@media print {
  @page {
    size: auto;
    margin: 2mm;
  }
}
</style>
<script>
import { useStore } from 'vuex';

export default {
    props:{
        placeHolder: {
            type: String,
            required: false,
        },
        onSearch: {
            type: Function,
            required: true,
        },
        loadingSearch: {
            type: Boolean,
            required: true,
        },
        updateAditionalCondition: {
            type: Function,
            required: true,
        },
        aditional_condition: {
            type: String,
            required: false,
        },
    },
    setup(props){
        const store = useStore();
        if(store.state.frameworkNavegador.beingReturned){
            props.updateAditionalCondition(store.state.frameworkNavegador.searchString)
        }
        const onChange = (event) => {
            props.updateAditionalCondition(event.target.value)
        }
        return {
            handleSearch: async () => await props.onSearch(),
            onChange
        }
    },
    components: {
    },
}
</script>

<template>
    <form @submit.prevent="handleSearch(searchString)" novalidate class="flex-1">
        <input :disabled="loadingSearch" type="text" class="form-control rounded-0 rounded-start" :value="aditional_condition" @change="onChange" :placeholder="placeHolder ?? '...'">
    </form>
</template>
<script>
    import Permissions from '@/components/permissions'

    export default {
        name: "Permissions",
        props: {
            permissions: {
                type: Array,
                required: true,
            },
            parentSwitchToFalse: {
                type: Function,
                default: null,
            },
            parentSwitchToTrue: {
                type: Function,
                default: null,
            },
            returnPermissionsID: {
                type: Function,
                default: null,
            },
            transformReturnDataProps: {
                type: Function,
                default: null,
            },
            depth: {
                type: Number,
                default: 0,
            },
        },
        components: {
            'Permissions': Permissions,
        },
        data() {
            return {
                reactiveData: [...this.$props.permissions],
            }
        },
        computed: {
            reactiveDataComputed: {
                get() {
                    return this.$props.permissions
                },
                set(value){
                    this.reactiveData = value;
                }
            }
        },
        watch: {
            reactiveDataComputed(newValue) {
                this.reactiveData = newValue;
            }
        },
        methods: {
            handleSwitch(permission) {
                if(permission.checked) {
                    this.inDepthCheck(permission) 
                }else{ 
                    this.inDepthUncheck(permission)
                }
                
                this.transformReturnData();
            },
            inDepthCheck(permission) {
                if(this.$props.parentSwitchToTrue) {this.$props.parentSwitchToTrue()}

                if(permission.is_group){
                    permission.data.forEach((item) => {
                        item.checked = true;
                        if(item.is_group){
                            this.inDepthCheck(item);
                        }
                    })
                }
            },
            inDepthUncheck(permission) {
                if(this.$props.parentSwitchToFalse) {this.$props.parentSwitchToFalse()}

                if(permission.is_group){
                    permission.data.forEach((item) => {
                        item.checked = false;
                        if(item.is_group){
                            this.inDepthUncheck(item);
                        }
                    })
                }
            },
            handleParentSwitchToFalse(permission) {
                permission.checked = false;
                if(this.$props.parentSwitchToFalse) {this.$props.parentSwitchToFalse()}
            },
            handleParentSwitchToTrue(permission) {
                var allChecked = true;
                
                permission.data.forEach((item) => {
                    if(!item.checked){
                        allChecked = false;
                    }
                })

                if(allChecked){ permission.checked = true}
                if(this.$props.parentSwitchToTrue) {this.$props.parentSwitchToTrue()}
                
            },
            transformDataKeyValue(dataArray, array = []){
                dataArray.forEach((permission) => {
                    if(permission.is_group){
                        permission.data.forEach((item) => {
                            if(item.is_group){
                                array = this.transformDataKeyValue(item.data, array);
                            }else{
                                if(item.checked){
                                    array.push({ [item.id_name]: item.id })
                                }
                            }
                        })
                    }else{
                        if(permission.checked){
                            array.push({ [permission.id_name]: permission.id })
                        }
                    }
                })
                return array;

            },
            transformReturnData() {
                if(this.$props.depth == 0) {
                    const trasnformedData = this.transformDataKeyValue(this.reactiveData)
                    return this.$props.returnPermissionsID(trasnformedData)
                }else{this.$props.transformReturnDataProps()}
            }
        },
    }
</script>
<template>
    <div>
        <div v-for="permission, index in reactiveData" :key="permission.id">
            <div v-if="permission.is_group" class="mt-1">
                <div class="d-flex">
                    <i class="mdi mdi-file-tree fs-22 mt-1 me-1 text-primary cursor-pointer" data-bs-toggle="collapse" :data-bs-target="'#collapse' + permission.id + index" :aria-controls="'collapse' + permission.id + index"></i> 
                    <div class="flex-1 d-flex align-items-center bg-primary px-2 rounded">
                        <div class="form-check form-switch form-switch-success">
                            <input class="form-check-input" type="checkbox" @change="handleSwitch(permission)" :value="permission.checked" v-model="permission.checked" :id="'switch-' + permission.id">
                        </div>
                        <button class="btn text-white flex-1 text-start" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse' + permission.id + index" aria-expanded="false" :aria-controls="'collapse' + permission.id + index">
                            {{ permission.title }}
                        </button> 
                    </div>      
                </div>
                <div v-if="permission.data" :class="['collapse border-start border-muted', $props.depth == 0 ? 'show' : '']" :id="'collapse' + permission.id + index">
                    <div class="card shadow-none mb-0">
                        <div :class="['card-body ps-4', !permission.data[0].is_group ? 'p-3 pt-0' : 'p-0']">
                            <Permissions
                                class="flex-1" 
                                :permissions="permission.data" 
                                :parentSwitchToFalse="() => handleParentSwitchToFalse(permission)"
                                :parentSwitchToTrue="() => handleParentSwitchToTrue(permission)" 
                                :transformReturnDataProps="transformReturnData"
                                :depth="$props.depth + 1" 
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="form-check d-flex permission-select-item pt-2">
                <div class="form-check form-switch form-switch-success form-check-inline border-bottom pb-2 flex-1 d-flex gap-2">
                    <input class="form-check-input" type="checkbox" @change="handleSwitch(permission)" :value="permission.checked" v-model="permission.checked" :id="'switch-' + permission.id">
                    <label class="form-check-label flex-1 text-primary" :for="'switch-' + permission.id">    
                        {{ permission.title }}
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>